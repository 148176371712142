import React from 'react'
import { useGetPoItemByIdQuery } from '../../../redux/ErpServices/PoServices'
import { Loader } from '../../../Basic/components'
import { DELETE } from '../../../icons'
import { substract } from '../../../Utils/helper'

const AccessoryPoItem = ({ poItemId, index, handleInputChange, readOnly, qty, removeItem, purchaseInwardId }) => {
    const { data, isLoading, isFetching } = useGetPoItemByIdQuery({ id: poItemId, purchaseInwardId }, { skip: !poItemId })
    if (isLoading || isFetching) return <Loader />
    const poItem = data.data
    let poQty = parseFloat(poItem.qty).toFixed(3)
    let alreadyCancelQty = poItem.alreadyCancelData?._sum.qty ? poItem.alreadyCancelData._sum.qty : "0.000";
    let alreadyInwardedQty = poItem.alreadyInwardedData?._sum?.qty ? parseFloat(poItem.alreadyInwardedData._sum.qty).toFixed(3) : "0.000";
    let balanceQty = substract(substract(poQty, alreadyCancelQty), alreadyInwardedQty).toFixed(3)
    let alreadyReturnedQty = poItem.alreadyReturnedData?._sum?.qty ? parseFloat(poItem.alreadyReturnedData._sum.qty).toFixed(3) : "0.000";



    return (
        <tr key={poItemId}>{console.log(poItem, "poItemaccess")}
            <td className='text-left   table-data'>{index + 1}</td>
            <td className='text-left   table-data'>{poItem.Po.docId}</td>
            <td className='text-left   table-data'>{poItem.Accessory.aliasName}</td>
            <td className='text-left   table-data'>{poItem.Accessory.accessoryItem.name}</td>
            <td className='text-left   table-data'>{poItem.Accessory.accessoryItem.AccessoryGroup.name}</td>
            <td className='text-left   table-data'>{poItem.Color.name}</td>
            <td className='text-right   table-data'>{poItem.Size.name}</td>
            <td className='text-left   table-data'>{poItem.Uom.name}</td>
            <td className='text-right  table-data'>{poQty}</td>
            <td className='text-right   table-data'>{alreadyCancelQty}</td>
            <td className='text-right   table-data'>{alreadyInwardedQty}</td>
            <td className='text-right table-data'>{alreadyReturnedQty}</td>
            <td className='text-right table-data'>{balanceQty}</td>

            <td className='table-data text-right w-16'>
                <input
                    onKeyDown={e => {
                        if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                        if (e.altKey) { e.preventDefault() }
                    }}
                    min={"0"}
                    type="number"
                    className="text-right rounded   w-full py-1 table-data-input"
                    value={qty}
                    disabled={readOnly}
                    onChange={(event) => {
                        if (event.target.value < 0) return
                        if (!event.target.value) {
                            handleInputChange(0, index, "qty", balanceQty);
                            return
                        }
                        handleInputChange(event.target.value, index, "qty", balanceQty);
                    }}
                    onBlur={(e) => {
                        if (!e.target.value) {
                            handleInputChange(0.000, index, "qty", balanceQty);
                            return
                        }
                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty", balanceQty)
                    }}
                />
            </td>
            <td className='text-right  w-12 table-data'>{poItem.price}</td>

            {!readOnly &&
                <td className='table-data w-12'>
                    <div tabIndex={-1} onClick={() => removeItem(poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                        {DELETE}
                    </div>
                </td>
            }
        </tr>
    )
}

export default AccessoryPoItem
