


export default function FabricAddress({from,to}){


    return(
        <div className="">
            <table className=" text-xs border border-gray-500 text-xs table-auto w-full ">
                <tbody>
                    <tr >
                        <td className="table-data text-left px-1 py-1 "><h3 className="font-bold px-5">From : {from.name}</h3><br/>
                        <div className="text-left px-14">
                           {from.address}
                           <div className="px-0 p-7">
                           {from.gstno}<br/>{from.panno}
                           </div>
                           
                            </div>
                            </td>
                        <td className="table-data text-left px-1 py-1 "><h3 className="font-bold px-5"> To : {to.name}</h3><br/>
                        <div className="text-left px-10">
                        {to.address}<br/>
                        <div  className="px-1 p-10 -mt-[10px] ">
                        {to.gstno}<br/>{to.panno}
                        </div>
                       
                        </div>
                        </td>
                    </tr>
                </tbody>
                
            </table>
        </div>
    )

}