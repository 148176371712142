import React, { useMemo, useState } from 'react'
import { substract } from '../../../Utils/helper';
import { useGetInterOfficeStockTransferFinishedGoodsByIdQuery } from '../../../redux/ErpServices/InterOfficeTransferFinishedGoodsServices';

const StockTransferFinishedGoodsDetailsFillGridForReceipt = ({ gridDetails, setGridDetails, setFillGrid, id, deliveryId }) => {
    const [localProductionDeliveryDetails, setLocaProductionDeliveryDetails] = useState(gridDetails);

    function handleDone() {
        setGridDetails(localProductionDeliveryDetails);
        setFillGrid(false);
    }
    const { data: interOfficeStockTransfer } = useGetInterOfficeStockTransferFinishedGoodsByIdQuery({ id: deliveryId, params: { stockTransferReceiptId: id, balQtyFilter: true } }, { skip: !deliveryId })

    let fillGridDetails = useMemo(() => interOfficeStockTransfer?.data?.StockTransferFinishedGoodsDeliveryDetails ? interOfficeStockTransfer?.data?.StockTransferFinishedGoodsDeliveryDetails.map(i => ({ ...i, stockTransferDeliveryDetailsId: i.id })) : [], [interOfficeStockTransfer])

    function addItem(item) {
        setLocaProductionDeliveryDetails(localInwardItems => {
            let newItems = structuredClone(localInwardItems);
            newItems.push({ ...item, stockTransferDeliveryDetailsId: item.id });
            return newItems
        });
    }
    function removeItem(removeItem) {
        setLocaProductionDeliveryDetails(localInwardItems => {
            return localInwardItems.filter(item =>
                !(
                    removeItem.stockTransferDeliveryDetailsId === item.stockTransferDeliveryDetailsId
                )
            )
        });
    }

    function isItemChecked(checkItem) {
        let item = localProductionDeliveryDetails.find(item =>
            checkItem.stockTransferDeliveryDetailsId === item.stockTransferDeliveryDetailsId
        )
        if (!item) return false
        return true
    }


    function handleCheckBoxChange(value, item) {
        if (value) {
            addItem(item)
        } else {
            removeItem(item)
        }
    }


    function handleSelectAllChange(value) {
        if (value) {
            fillGridDetails.forEach(item => addItem(item))
        } else {
            fillGridDetails.forEach(item => removeItem(item))
        }
    }

    function getSelectAll() {
        return fillGridDetails.every(item => isItemChecked(item))
    }

    return (
        <div>
            <div className={`bg-gray-200 z-50 overflow-auto w-[1000px]`}>
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10 text-xs"> Inter Office Stock Delivery Items</div>
                </div>
                <table className="border border-gray-500 w-full text-xs text-start">
                    <thead className="border border-gray-500">
                        <tr>
                            <th className='w-8 p-5'>
                                Mark All
                                <input type="checkbox" className='w-full' onChange={(e) => handleSelectAllChange(e.target.checked)}
                                    checked={getSelectAll()}
                                />
                            </th>
                            <th className="w-20 border border-gray-500">S.no</th>
                            <th className="border border-gray-500">Delivery No.</th>
                            <th className="border border-gray-500">Style</th>
                            <th className="border border-gray-500">Color</th>
                            <th className="border border-gray-500">Size</th>
                            <th className="border border-gray-500">Prev. Process</th>
                            <th className="border border-gray-500">Del. Qty</th>
                            <th className="border border-gray-500">A. Rec Qty</th>
                            <th className="border border-gray-500">Bal. Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fillGridDetails.map((item, index) =>
                            <tr key={index} className='table-row' onClick={() => {
                                handleCheckBoxChange(!isItemChecked(item), { ...item, stockTransferDeliveryDetailsId: item.id, qty: 0 })
                            }}>
                                <td className='table-data'>
                                    <input type="checkbox" className='w-full table-data-input'
                                        checked={isItemChecked(item)} />
                                </td>
                                <td>{index + 1}</td>
                                <td>{item?.StockTransferFinishedGoods?.docId}</td>
                                <td>{item?.Style?.sku}</td>
                                <td className='text-center'>{item?.Color?.name}</td>
                                <td className='text-center'>{item?.Size?.name}</td>
                                <td className='text-center'>{item?.PrevProcess?.name}</td>
                                <td className='text-right'>{item.qty}</td>
                                <td className='text-right'>{item.aRecQty}</td>
                                <td className='text-right'>{substract(item.qty, item.aRecQty)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='flex justify-end'>
                <button className='p-1 bg-blue-400 rounded-lg' onClick={handleDone}>Done</button>
            </div>
        </div>
    )
}

export default StockTransferFinishedGoodsDetailsFillGridForReceipt;