import React, { useCallback, useEffect, useRef, useState } from 'react'
import OrderEntryDeatils from './OrderEntryDeatils';
import LineSetting from './LineSetting';
import { useGetLineOperationMasterByIdQuery, useGetLineOperationMasterQuery } from '../../../redux/ErpServices/LineAndOperationMasterService';
import { useGetOperationNameQuery } from '../../../redux/ErpServices/operationNameServices';
import { useGetOperationBulletInQuery } from '../../../redux/ErpServices/OperationBulletInService';
import secureLocalStorage from "react-secure-storage";
import { DateInput, DropdownInput, MultiSelectDropdown, TextInput } from '../../../Inputs';
import { dropDownListObject, multiSelectOption } from '../../../Utils/contructObject';
import { useGetPartyCategoryMasterQuery } from '../../../redux/ErpServices/PartyCategoryMasterServices';
import { useGetStyleMasterQuery } from '../../../redux/ErpServices/StyleMasterServices';
import { useGetSizeTemplateByIdQuery, useGetSizeTemplateQuery } from '../../../redux/ErpServices/SizeTemplateMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import FormHeader from '../../../Basic/components/FormHeader';
import { toast } from 'react-toastify';
import { useAddOrderEntryMutation, useDeleteOrderEntryMutation, useGetOrderEntryByIdQuery, useGetOrderEntryQuery, useUpdateOrderEntryMutation } from '../../../redux/ErpServices/OrderEntryService';
import { useDispatch } from 'react-redux';
import { useGetLineSettingByIdQuery, useGetLineSettingQuery } from '../../../redux/ErpServices/LineSettingServices';
import moment from 'moment';
const OrderEntry = () => {
    const MODEL = "Order Entry";
    const [order, setOrder] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [styleId, setStyleId] = useState([]);
    const [buyerName, setBuyerName] = useState("");
    const [orderQty, setOrderQty] = useState("");
    const [lineOperationId, setLineOperationId] = useState('')
    const [orderNo, setOrderNo] = useState('')
    const [id, setId] = useState("");
    const [sizeTemp, setSizeTemp] = useState("")
    const [orderEntryDet, setOrderEntryDet] = useState([])
    const [seasonMaster, setseasonMaster] = useState('')
    const date = moment(new Date()).format("YYYY-MM-DD");
    const [orderDate, setOrderDate] = useState(date)
    const [shipDate, setShipDate] = useState('')
    const dispatch = useDispatch();
    const onNew = () => {
        setId("");
        setReadOnly(false);
        setSearchValue("");
    };
    const childRecord = useRef(0);
    const skipData = !buyerName || !styleId;
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId",
        ),
        orderEntryLine: true,
        lineOperationId: lineOperationId


    };
    const { data: buyerData } =
        useGetPartyCategoryMasterQuery({ params: { ...params, active: true } });
    const [lineSettingdet, setLineSettingdet] = useState([]);
    const { data: lineData } = useGetLineSettingQuery({ params, searchParams: searchValue })
    const {
        data: lineOperationData,
    } = useGetLineSettingByIdQuery(lineOperationId, { skip: !lineOperationId });
    console.log(lineOperationData, 'lineOperationData');


    const { data: operationNameData } = useGetOperationNameQuery();

    const { data: sizeTempData } = useGetSizeTemplateQuery({ params, searchParams: searchValue });
    const {
        data: singleSizeData,

    } = useGetSizeTemplateByIdQuery(sizeTemp, { skip: !sizeTemp });
    const { data: styleData } =
        useGetStyleMasterQuery({ params: { ...params, active: true } });
    const { data: colorList } = useGetColorMasterQuery({ params });
    console.log(colorList, 'colorList');


    const { data: sectionData, isLoading: isSectionData, isFetching: isSectionDatafetch } =
        useGetOrderEntryQuery({});
    const {
        data: singleData,
        isFetching: isSingleFetching,
    } = useGetOrderEntryByIdQuery(id, { skip: !id });
    console.log(lineSettingdet, 'lineSettingdet');


    const [addData] = useAddOrderEntryMutation();
    const [updateData] = useUpdateOrderEntryMutation();
    const [removeData] = useDeleteOrderEntryMutation();
    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                dispatch({
                    type: `lineOperation/invalidateTags`,
                    payload: ['lineOperation'],
                });
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const data = {
        buyerName,
        styleId,
        orderQty,
        lineOperationId,
        orderNo,
        id,
        orderEntryDet,
        orderDate,
        shipDate
    };
    const syncFormWithDb = useCallback((data) => {
        if (id) { setReadOnly(true) }
        setBuyerName(data?.name ? data.name : "");
        setStyleId(data?.styleId ? data.styleId : []);
        setOrderQty(data?.orderQty ? data.orderQty : "");
        setLineOperationId(data?.lineOperationId ? data.lineOperationId : "");
        setOrderNo(data?.orderNo ? data.orderNo : "");
        setLineSettingdet(data?.LineSettingDet ? data?.LineSettingDet : [])
        setOrderEntryDet(data?.orderEntryDet ? data.orderEntryDet : [])
        setseasonMaster(data?.seasonMaster ? data.seasonMaster : '')
        setOrderDate(moment(new Date()).format("YYYY-MM-DD"));
        setShipDate(data?.shipDate ? moment.utc(data?.shipDate).format('YYYY-MM-DD') : "")
    }, [id]);

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, id, syncFormWithDb, singleData]);
    const validateData = (data) => {
        if (data.buyerName) {
            return true;
        }
        return false;
    };
    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId("")
            syncFormWithDb(undefined)
            toast.success(text + "Successfully");

        } catch (error) {
            console.log("handle")


        }
    };
    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };
    // if (!form)
    //     return (
    //         <ReportTemplate
    //             heading={MODEL}
    //             tableHeaders={tableHeaders}
    //             tableDataNames={tableDataNames}
    //             loading={
    //                 isLoading || isFetching
    //             }
    //             setForm={setForm}
    //             data={allData?.data}
    //             onClick={onDataClick}
    //             onNew={onNew}
    //             searchValue={searchValue}
    //             setSearchValue={setSearchValue}
    //         />
    //     );
    return (
        <div className='  rounded overflow-clip border border-gray-300 h-full'>
            <FormHeader
                onNew={onNew}
                onClose={() => {
                    setOrderEntryDet([]

                    )
                }}
                model={MODEL}
                saveData={saveData}
                setReadOnly={setReadOnly}
                deleteData={deleteData}
                childRecord={childRecord.current}
            />
            <div className='flex justify-end p-1 px-4'>     <button className={`flex bg-gray-300  items-center text-xs p-1 rounded ${!order && "page-heading text-white"}`} autoFocus={true} onClick={() => setOrder(false)} >   Order Entry</button>
                <button
                    className={`bg-gray-300 text-black  text-xs p-1 rounded flex items-center ${order && "page-heading text-white"}`}
                    onClick={() => setOrder(true)}>
                    Order Details
                </button></div>
            <div className='grid grid-cols-6 my-2 items-center'>
                <TextInput name="Order No" type="text" value={orderNo} setValue={setOrderNo} required={true} readOnly={readOnly} />
                <TextInput name="Order Qty" type="text" value={orderQty} setValue={setOrderQty} required={true} readOnly={readOnly} />
                <DateInput name="Order Date" value={orderDate} setValue={setOrderDate} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                {console.log(orderDate, 'orderDate')
                }
                <DateInput name="Order Date" value={shipDate} setValue={setShipDate} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />


                <DropdownInput name="Buyer" options={dropDownListObject((buyerData?.data ? buyerData?.data : []), "name", "id")} value={buyerName} setValue={setBuyerName} readOnly={readOnly} required={true} />

                <DropdownInput name="Size Template" options={dropDownListObject((sizeTempData?.data ? sizeTempData?.data : []), "name", "id")} value={sizeTemp} setValue={setSizeTemp} readOnly={readOnly} required={true} />
                {console.log(styleData, 'style')
                }
                < MultiSelectDropdown name={"Style"}
                    setSelected={setStyleId}
                    selected={styleId}
                    options={(styleData?.data) ? multiSelectOption(id ? styleData.data : styleData.data.filter(item => item.active), "name", "id") : []} readOnly={readOnly} className="" />
            </div>

            <div className='flex'>
                <div className='px-4 w-[100%] border-gray-400 h-[80%]'>
                    <OrderEntryDeatils
                        singleSizeData={singleSizeData?.data || []}
                        styleId={styleId}
                        colorList={colorList?.data || []}
                        orderEntryDet={orderEntryDet}
                        setOrderEntryDet={setOrderEntryDet}
                        orderQty={orderQty}
                    />
                </div>
            </div>
        </div>
    )
}

export default OrderEntry
