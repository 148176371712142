import React from 'react'

const Address = ({ branchDetails, supplierDetails }) => {
  return (
    <div className="text-xs p-2 grid grid-cols-2 border-y border-gray-500 items-center justify-around">
      <div className=''>
        <div className=' font-bold   text-sm'>From : </div>
        <h2 className='font-bold text-sm text-green-700'>{branchDetails?.branchName}</h2>
        <div>{branchDetails?.address}</div>
        <div>Mobile No :{branchDetails?.contactMobile}</div>
        {branchDetails.panNo &&
          <div className=''>PAN NO :{branchDetails?.panNo}</div>
        }
        {branchDetails.gstNo &&
          <div className=''>GST No :{branchDetails?.gstNo}</div>
        }
        {branchDetails.contactEmail &&
          <div className=''>EMAIL :{branchDetails?.contactEmail}</div>
        }
      </div>
      <div className='p-1'>
        <div className=' font-bold text-sm '>To :</div>
        <h2 className='font-bold text-sm text-green-700'>{supplierDetails.name}</h2>
        <div> {supplierDetails.address} </div>
        <div className=''>Mobile NO :{supplierDetails.contactMobile}</div>
        <div className=''>PAN NO :{supplierDetails.panNo}</div>
        <div className=''>GST NO :{supplierDetails.gstNo}</div>
        <div className=''> EMAIL :{supplierDetails.email}</div>
      </div>
    </div>
  )
}


export default Address