import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { DELETE } from '../../../icons'
import { priceWithTax } from '../../../Utils/helper'
import { useGetPartyByIdQuery } from '../../../redux/ErpServices/PartyMasterServices'

const FInishedGoodsSalesDetailItem = ({ index, getStockItem, row, handleInputChange, handleDeleteRow, isWholeSale, readOnly, supplierId }) => {

    const { data: supplierItemDetail, isLoading: isSupplierDetailLoading, isFetching: isSupplierDetailFetching } = useGetPartyByIdQuery(supplierId, { skip: !supplierId })

    useEffect(() => {
        if (!(supplierItemDetail?.data) || isSupplierDetailFetching || isSupplierDetailFetching) return
        const styleWiseDetails = supplierItemDetail?.data?.PriceTemplate?.styleWiseDetails;
        let currentStyleData = styleWiseDetails.find(i => parseInt(i.styleId) === parseInt(row.styleId));
        if (!currentStyleData) return;
        const currentSizeWiseData = (currentStyleData?.SizeDetails ? currentStyleData?.SizeDetails : []).find(i => parseInt(i.sizeId) === parseInt(row.sizeId));
        if (!currentSizeWiseData) return;
        handleInputChange(parseFloat(currentSizeWiseData.price).toFixed(2), index, "price")
    }, [supplierItemDetail, isSupplierDetailLoading, isSupplierDetailFetching, handleInputChange, index])
    return (
        <tr key={index} className="w-full table-row">
            <td className='table-data'>{index + 1}</td>
            <td className='table-data '>
                {getStockItem(row, "styleName")}
            </td>
            <td className='table-data '>
                {getStockItem(row, "colorName")}
            </td>
            <td className='table-data'>
                {getStockItem(row, "sizeName")}
            </td>
            <td className='table-data'>
                {getStockItem(row, "uomName")}
            </td>
            <td className='table-data text-right'>
                {getStockItem(row, "qty")}
            </td>
            <td className='table-data'>
                <input type="number"
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "qty") } }}
                    value={(!row.qty) ? 0 : row.qty}
                    min={0}
                    autoFocus={index === 0}
                    onChange={(e) => {
                        if (parseFloat(e.target.value) > parseFloat(getStockItem(row, "qty"))) {
                            toast.info("Delivery Qty Cannot be more than Stock Qty", { position: "top-center" })
                            return
                        }
                        handleInputChange(e.target.value, index, "qty")
                    }}
                    className="text-right rounded py-1 w-full px-1 table-data-input"
                    inputMode='decimal'
                    onBlur={(e) =>
                        handleInputChange(parseFloat(e.target.value).toFixed(2), index, "qty")
                    } />
            </td>
            <td className='table-data'>
                {isWholeSale ?
                    <>
                        {(!row.price) ? 0 : row.price}
                    </>
                    :
                    <input type="number"
                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "price") } }}
                        value={(!row.price) ? 0 : row.price}
                        onChange={(e) => { handleInputChange(e.target.value, index, "price") }}
                        className="text-right rounded py-1 w-full px-1 table-data-input"
                        inputMode='decimal'
                        onBlur={(e) =>
                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "price")
                        } />
                }
            </td>
            {
                isWholeSale &&
                <>
                    <td className='table-data'>
                        <input type="number"
                            onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "tax") } }}
                            value={(!row.tax) ? 0 : row.tax}
                            onChange={(e) => { handleInputChange(e.target.value, index, "tax") }}
                            className="text-right rounded py-1 w-full px-1 table-data-input"
                            inputMode='decimal'
                            onBlur={(e) =>
                                handleInputChange(parseFloat(e.target.value).toFixed(2), index, "tax")
                            } />
                    </td>
                    <td className='table-data text-right'>
                        {priceWithTax(row.price, row.tax).toFixed(2)}
                    </td>
                </>
            }
            <td className='table-data text-right'>
                {(priceWithTax(row.price, row.tax) * parseFloat(row.qty)).toFixed(2)}
            </td>
            {readOnly
                ?
                ""
                :
                <td className='table-data w-20'>
                    <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer'>
                        {DELETE}
                    </div>
                </td>
            }
        </tr>
    )
}

export default FInishedGoodsSalesDetailItem
