import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LEDGER_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const LedgerApi = createApi({
  reducerPath: "Ledger",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["Ledger"],
  endpoints: (builder) => ({
    getLedger: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: LEDGER_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: LEDGER_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["Ledger"],
    }),
    getLedgerById: builder.query({
      query: (id) => {
        return {
          url: `${LEDGER_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["Ledger"],
    }),
    addLedgerData: builder.mutation({
      query: (payload) => ({
        url: LEDGER_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Ledger"],
    }),
    updateLedgerData: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${LEDGER_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Ledger"],
    }),
    deleteLedgerData: builder.mutation({
      query: (id) => ({
        url: `${LEDGER_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Ledger"],
    }),
  }),
});

export const {
  useGetLedgerQuery,
  useGetLedgerByIdQuery,
  useAddLedgerDataMutation,
  useUpdateLedgerDataMutation,
  useDeleteLedgerDataMutation
} = LedgerApi;

export default LedgerApi;
