import React, { Fragment, useState } from 'react'
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import CuttingDeliveryItems from './CuttingDeliveryItems';
import { useGetCuttingOrderByIdQuery } from '../../../redux/ErpServices/CuttingOrderServices';

const CuttingDelivery = ({ id }) => {
    const [selectedDelivery, setSelectedDelivery] = useState(0);
    const {
        data: singleData,
    } = useGetCuttingOrderByIdQuery({ id }, { skip: !id });
    const cuttingDeliveryDetails = singleData?.data?.CuttingDelivery ? singleData?.data?.CuttingDelivery : []
    return (
        <div>
            <table className="table-data border border-gray-500 text-xs table-auto w-full">
                <thead className="bg-blue-200 border border-gray-500 top-0">
                    <tr className="border border-gray-500">
                        <th className="table-data w-2 text-center">S.no</th>
                        <th className="table-data w-20">Del. No.</th>
                        <th className="table-data w-72">Del. Date</th>
                        <th className="table-data w-20">Due Date</th>
                    </tr>
                </thead>
                <tbody>
                    {cuttingDeliveryDetails.map((delivery, index) =>
                        <Fragment key={delivery.id}>
                            <tr className='border-2 border-gray-500 '>
                                <td className="table-data w-2 text-center">
                                    {index + 1}
                                </td>
                                <td className="table-data w-2 text-center">
                                    {delivery.docId}
                                </td>
                                <td className="table-data w-2 text-center">
                                    {getDateFromDateTimeToDisplay(delivery.createdAt)}
                                </td>
                                <td className="table-data w-2 text-center">
                                    {getDateFromDateTimeToDisplay(delivery.dueDate)}
                                </td>
                            </tr>
                            {(selectedDelivery === index) &&
                                <tr className='border border-gray-500'>
                                    <td colSpan={4}>
                                        <CuttingDeliveryItems cuttingDeliveryItems={delivery?.CuttingDeliveryDetails ? delivery?.CuttingDeliveryDetails : []} />
                                    </td>
                                </tr>
                            }
                        </Fragment>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default CuttingDelivery
