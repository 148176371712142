export const EMPLOYEE_API = "employees";
export const CITY_API = "cities";
export const STATE_API = "states";
export const COUNTRY_API = "countries";
export const FLOOR_MASTER_API = "floorMaster";
export const LOGIN_API = "users/login";
export const COMPANY_API = "companies";
export const BRANCHES_API = "branches";
export const USERS_API = "users";
export const PAGES_API = "pages";
export const PAGES_GROUP_API = "pageGroup";
export const ROLES_API = "roles";
export const SUBSCRIPTION_API = "subscriptions";
export const FIN_YEAR_API = "finYear";
export const EMPLOYEE_CATEGORY_API = "employeeCategories";


export const PARTY_CATEGORY_API = "partyCategories"
export const CURRENCY_API = "currency"
export const PARTY_API = "party"
export const CONTENT_API = "content"
export const YARN_TYPE_API = "yarnType"
export const COUNTS_API = "counts"
export const HSN_API = "hsm"
export const YARN_BLEND_API = 'yarnBlend'
export const YARN_API = 'yarn'
export const FABRIC_TYPE_API = 'fabricType'
export const FABRIC_API = 'fabric'
export const ACCESSORY_GROUP_API = 'accessoryGroup'
export const ACCESSORY_ITEM_API = 'accessoryItem'
export const ACCESSORY_API = 'accessory'
export const COLOR_API = 'color'
export const PO_API = 'po'
export const UNIT_OF_MEASUREMENT_API = 'unitOfMeasurement'
export const PAY_TERM_API = 'payTerm'
export const TAX_TERM_API = 'taxTerm'
export const TAX_TEMPLATE_API = 'taxTemplate'
export const LOCATION_API = 'location'
export const DESIGN_API = "design"
export const GUAGE_API = "gauge"
export const GSM_API = "gsm"
export const LOOPLENGTH_API = "looplength"
export const DIA_API = "dia"
export const SIZE_API = "size"
export const PURCHASE_INWARD_OR_RETURN_API = "purchaseInwardOrReturn"
export const STOCK_API = "stock"
export const STYLE_API = "style"
export const PROCESS_API = "process"
export const SIZE_TEMPLATE_API = "sizeTemplate"
export const PROCESS_DELIVERY_API = "processDelivery"
export const PROCESS_INWARD_API = "processInward"
export const TERMS_AND_CONDITIONS_API = "termsAndCondition"
export const CUTTING_ORDER_API = "cuttingOrder"
export const CUTTING_EXCESS_FABRIC_RETURN_API = "cuttingExcessFabricReturn"
export const CUTTING_DELIVERY_API = "cuttingDelivery"
export const LOSS_REASON_API = "lossReason"
export const CUTTING_RECEIPT_API = "cuttingReceipt"
export const BILL_ENTRY_API = "billEntry"
export const DIRECT_INWARD_OR_RETURN_API = "directInwardOrReturn"
export const PAYOUT_API = "payOut"
export const LEDGER_API = "ledger"
export const ADVANCE_ADJUSTMENT_API = "advanceAdjustment"
export const PROCESS_DELIVERY_RETURN_API = "processDeliveryReturn"
export const PRODUCTION_DELIVERY_API = "productionDelivery"
export const PRODUCTION_RECEIPT_API = "productionReceipt"
export const RAWMATERIAL_SALES_API = "rawMaterialSales"
export const FINISHED_GOODS_SALES_API = "finishedGoodsSales"
export const FINISHED_GOODS_SALES_DELIVERY_API = "finishedGoodsSalesDelivery"
export const RAW_MATERIAL_OPENING_STOCK_API = "rawMaterialOpeningStock"
export const FINISHED_GOODS_OPENING_STOCK_API = "finishedGoodsOpeningStock"
export const PORTION_API = "portionMaster"
export const INTER_OFFICE_STOCK_TRANSFER_API = "interOfficeDeliveryFinishedGoods";
export const PRICE_TEMPLATE_MASTER_API = "priceTemplateMaster";
export const PRINTING_JOB_WORK_API = "printingJobWork";
export const ONLINE_SALES_DELIVERY_API = "onlineSalesDelivery"
export const ONLINE_SALES_RETURN_API = "onlineSalesReturn"
export const CREDIT_DEBIT_API = "creditDebitNote"
export const MACHINETYPE_MASTER_API = "machineTypeMaster"
export const MACHINE_MASTER_API = "machineMast"
export const RFID_MASTER_API = 'rfidMaster'
export const IOT_DEVICE_MAST = 'iotDeviceMast'
export const OPERATION_NAME = 'operationName'
export const OPERATION_GROUP_API = 'operationGroupApi'
export const BRAND_MASTER_API = 'brandMasterApi'
export const BATCH_MASTER_API = 'batchMasterApi'
export const DESIGNATION_MASTER = 'designationMaster'
export const SECTION_MASTER_API = 'sectionMaster'
export const GRADE_MASTER_API = "grademaster"
export const LINE_OPERATION_MASTER_API = "line&operationMaster"
export const HOLIDAYS_CALENDER_API = "holidays"
export const DESTINATION_MASTER_API = "destinationMaster"
export const BREAKDOWN_MASTER_API = 'breakdownMaster'
export const SHIFT_MASTER = "shiftMaster"
export const SEASON_MASTER = 'seasonMaster'
export const CATEGORY_MASTER = 'categoryMaster'
export const OPERATION_BULLETIN = 'operationBulletIn'
export const GROUP_MASTER = 'groupMaster'
export const LINE_SETTING = 'lineSetting'
export const ORDER_ENTRY = 'orderEntry'


