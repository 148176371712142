import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DESIGNATION_MASTER } from "../../Api";


const BASE_URL = process.env.REACT_APP_SERVER_URL;

const DesignationMasterApi = createApi({
    reducerPath: "designationMaster",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["DesignationMasterApi"],
    endpoints: (builder) => ({
        getDesignationMaster: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: DESIGNATION_MASTER + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: DESIGNATION_MASTER,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["DesignationMaster"],
        }),
        getDesignationMasterById: builder.query({
            query: (id) => {
                return {
                    url: `${DESIGNATION_MASTER}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["DesignationMaster"],
        }),
        addDesignationMaster: builder.mutation({
            query: (payload) => ({
                url: DESIGNATION_MASTER,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["DesignationMaster"],
        }),
        updateDesignationMaster: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${DESIGNATION_MASTER}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["DesignationMaster"],
        }),
        deleteDesignationMaster: builder.mutation({
            query: (id) => ({
                url: `${DESIGNATION_MASTER}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["DesignationMaster"],
        }),
    }),
});

export const {
    useGetDesignationMasterQuery,
    useGetDesignationMasterByIdQuery,
    useAddDesignationMasterMutation,
    useUpdateDesignationMasterMutation,
    useDeleteDesignationMasterMutation,


} = DesignationMasterApi;

export default DesignationMasterApi;
