import React from 'react'
import Address from './Address';
import QRCode from "react-qr-code";
import { getCommonParams, getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import {
  useGetBranchByIdQuery
} from '../../../redux/services/BranchMasterService';
import useTaxDetailsHook from '../../../CustomHooks/TaxHookDetails';
import TaxDetails from './TaxDetails';
import { useGetPrintingJobWorkByIdQuery } from '../../../redux/ErpServices/PrintingJobWorkServices';
import { useGetCompanyByIdQuery } from '../../../redux/services/CompanyMasterService';
import SalesOrder from './SalesOrder';
const numberToText = require('number-to-text')
require('number-to-text/converters/en-in');

export default function PrintFormatPrintingJobWork({ innerRef, id }) {
  const {
    data: singleData,
  } = useGetPrintingJobWorkByIdQuery({ id }, { skip: !id });

  const retreivedData = singleData?.data ? singleData?.data : {};

  const gridDetails = retreivedData?.PrintingJobWorkDet ? retreivedData?.PrintingJobWorkDet : [];

  const { branchId, companyId } = getCommonParams()

  const { data } = useGetBranchByIdQuery(branchId)

  const branchData = data?.data ? data.data : {};

  const { data: companyApiData } = useGetCompanyByIdQuery(companyId)

  const companyData = companyApiData?.data ? companyApiData?.data : {};

  const { isLoading: isTaxHookDetailsLoading, ...taxDetails } = useTaxDetailsHook({ poItems: gridDetails.map(i => ({ ...i, taxPercent: i.tax })), taxTypeId: retreivedData.taxTemplateId, discountType: retreivedData.discountType, discountValue: retreivedData.discountValue })


  return (
    <div className="h-[950px] w-full border-2 m-0 border-black" id='poPrint' ref={innerRef}>
      <div>
        <div className='flex'>
        </div>
        <div className='text-center p-1 text-md flex items-center justify-center font-bold text-green-700 w-full border-t border-gray-500'> Printing Job Work Bill </div>
        <div className='grid grid-cols-6 text-sm border border-gray-600' >
          <div className='grid grid-cols-2 col-span-5 w-full py-2 items-center'>
            <div className='flex ml-1'><span className="font-bold">Doc Id :</span> {retreivedData?.docId}</div>
            <div className='flex ml-1'><span className="font-bold">Doc Date :</span> {getDateFromDateTimeToDisplay(retreivedData?.createdAt)}</div>
          </div>
          <div className='mr-2 p-1 col-span-1'>
            <QRCode value={retreivedData?.docId ? retreivedData?.docId : ""} size={80} />
          </div>
        </div>
        <Address branchData={branchData} supplierDetails={retreivedData?.Supplier ? retreivedData?.Supplier : {}} />
        <SalesOrder finishedGoodsSalesDetails={gridDetails.map(i => ({ ...i, taxPercent: i.tax }))} singleData={singleData?.data} taxDetails={taxDetails} />
        <div className='flex justify-end'>
          <table className='border border-gray-500 text-center'>
            <tbody>
              <tr className='border border-gray-500 '><th className='p-2 bg-green-200 text-xs' colSpan={2}>Tax Details</th></tr>
              <TaxDetails finishedGoodsSalesDetails={gridDetails.map(i => ({ ...i, taxPercent: i.tax }))} taxTemplateId={retreivedData?.taxTemplateId} discountType={retreivedData?.discountType ? retreivedData?.discountType : ""} discountValue={(retreivedData?.discountValue ? retreivedData?.discountValue : 0)} />
              <tr className='border border-gray-500 text-xs '>
                <td className='table-data p-1'>
                  ROUNDOFF
                </td>
                <td className='table-data text-right p-1'>
                  {parseFloat(taxDetails?.roundOffAmount).toFixed(2)}
                </td>
              </tr>
              <tr className='border border-gray-500 text-xs'>
                <td className='table-data p-1 bg-green-200 text-xs'>
                  NET AMOUNT
                </td>
                <td className='table-data p-1 text-xs'>
                  {parseFloat(taxDetails?.netAmount).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className=' w-full mt-[200px]'>
        <div className='grid grid-cols-2 py-5'>
          <div className='w-full border border-gray-600 grid'>
            <div className='grid grid-cols-2'>
              <h1 className='font-bold text-sm'>Amount in words:
              </h1>
              <span className='text-xs'>
                Rs.{numberToText.convertToText(taxDetails?.netAmount ? taxDetails?.netAmount : "0", { language: "en-in", separator: "" })} Only
              </span>
            </div>
            <div className='grid grid-cols-2'>
              <h1 className='font-bold text-sm'>Remarks:
              </h1>
              <span className='text-xs'>
                {retreivedData?.remarks}
              </span>
            </div>
          </div>
          <div className='w-full border border-gray-600 items-center px-5'>
            <h1 className='font-bold text-sm'>Bank Details : </h1>
            <div className='grid grid-cols-2 items-center'>
              <span className=''>
                Bank Name:
              </span>
              <span className='text-xs'>
                {companyData.bankName}
              </span>
            </div>
            <div className='grid grid-cols-2 items-center'>
              <span className=''>
                Ac.No:
              </span>
              <span className='text-xs'>
                {companyData.accNo}
              </span>
            </div>
            <div className='grid grid-cols-2 items-center'>
              <span className=''>
                Branch:
              </span>
              <span className='text-xs'>
                {companyData.branchName}
              </span>
            </div>
            <div className='grid grid-cols-2 items-center'>
              <span className=''>
                IFSC:
              </span>
              <span className='text-xs'>
                {companyData.ifscCode}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-1'>
        <div className='text-sm text-right px-2 font-bold italic'>
          For {branchData?.branchName}
        </div>
        <div className='grid grid-rows-1 grid-flow-col p-2 font-bold text-xs mt-8 justify-around'>
          <h1>Prepared By</h1>
          <h1>Verified By</h1>
          <h1>Received By</h1>
          <h1>Approved By</h1>
        </div>
      </div>
    </div>
  )
}



