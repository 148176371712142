import { faEraser, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect } from 'react';

export default function DataTable({
  setLineSettingdet,
  lineSettingdet,
  readOnly,
  operationBulletInData,
  lineOperationData
}) {



  const operationNameToDeviceMap = lineOperationData.reduce((map, lineOp) => {
    if (lineOp.LineOperationDet) {
      lineOp.LineOperationDet.forEach(detail => {
        map[detail.operationNameId] = detail.IOTDevice.code;
      });
    }
    return map;
  }, {});
  useEffect(() => {
    if (lineSettingdet.length === 0 && operationBulletInData.length > 0) {
      setLineSettingdet(
        operationBulletInData.flatMap(data =>
          data.OperationBulletInDet.map(item => ({
            ...item,
            iOTDevice: operationNameToDeviceMap[item.operationNameId],
            targetPerHour: calculateTargetPerHour(item.samTime),  // Calculate targetPerHour here if needed
          }))
        )
      );
    }
  }, [operationBulletInData, lineSettingdet, setLineSettingdet, operationNameToDeviceMap]);




  const handleCheckboxChange = (index) => {
    setLineSettingdet(prev =>
      prev.map((item, id) =>
        id === index ? { ...item, isActive: !item.isActive } : item
      )
    );
  };

  function isSelectAll() {
    return lineSettingdet.every((item) => item.isActive);
  }

  const handleSelectAll = () => {
    setLineSettingdet(prev =>
      prev.map(item => ({ ...item, isActive: !isSelectAll() }))
    );
  };

  const calculateTargetPerHour = (samTime) => {
    if (samTime > 0) {
      return (60 / samTime).toFixed(2);  // Format to 2 decimal places
    }
    return '';
  };



  const onDelete = (index) => {
    setLineSettingdet(prev => prev.filter((_, i) => i !== index));
  };



  const handleClear = () => {
    setLineSettingdet([]);
  };

  return (
    <div>
      <fieldset className="frame border-gray-300 rounded-lg p-1">
        <legend className="sub-heading p-1">Allocate Operation</legend>
        <button onClick={handleClear} className='flex w-full justify-end'>
          <FontAwesomeIcon className='text-lg text-red-600' icon={faEraser} />
        </button>

        <div className="grid grid-cols-1">
          <table className="w-auto h-full">
            <thead>
              <tr className="font-semibold text-[14px] border border-gray-300">
                <td className="text-center border border-gray-300 w-[5%]">S no</td>
                <td className="border border-gray-300 w-[10%]">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      checked={isSelectAll()}
                      onChange={handleSelectAll}
                      className="mr-2"
                    />
                    Active
                  </div>
                </td>
                <td className="text-center border border-gray-300 p-1">Process Group</td>
                <td className="text-center border border-gray-300">Operation Name</td>
                <td className="text-center border border-gray-300 w-[8%]">Operation Seq</td>
                <td className="text-center border border-gray-300 w-[8%]">SAM Time</td>
                <td className="text-center border border-gray-300 w-[8%]">Target/Hour</td>
                <td className="text-center border border-gray-300">Device ID</td> {/* Add Device ID column */}
                <td className="text-center border border-gray-300 w-[8%]">Delete</td>
              </tr>
            </thead>
            <tbody className="border border-gray-300">
              {lineSettingdet?.map((field, index) => (
                <tr key={field.id} className="border border-gray-300 text-[12px]">
                  {console.log(lineSettingdet, 'lineOperationData')}
                  <td>{index + 1}</td>
                  <td className="border border-gray-300 p-1 text-center">
                    <input
                      type="checkbox"
                      checked={field.isActive}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={readOnly}
                    />
                  </td>
                  <td className="border border-gray-300">
                    {field.operationGroupName}
                  </td>
                  <td className="border border-gray-300">
                    {field.operationName}
                  </td>
                  <td className="border border-gray-300">
                    {field.operationSeq}
                  </td>
                  <td className="border border-gray-300">
                    {field.samTime}
                  </td>
                  <td className="border border-gray-300">
                    {field.targetPerHour}
                  </td>

                  <td className="border border-gray-300">
                    {field.iOTDevice}
                  </td>
                  <td onClick={() => onDelete(index)} className="text-center border-gray-300">
                    <FontAwesomeIcon icon={faTrashCan} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </fieldset>
    </div>
  );
}
