import React, { useState } from 'react'
import { useGetStyleMasterQuery } from '../../../redux/ErpServices/StyleMasterServices'
import { getCommonParams } from '../../../Utils/helper'
import Modal from '../../../UiComponents/Modal'
import StyleDetails from './StyleDetails'
import { VIEW } from '../../../icons'

const StyleWiseDetails = ({ readonly, styleWiseDetails, setStyleWiseDetails }) => {
    const [currentSelectedStyle, setCurrentSelectedStyle] = useState("");
    const { companyId } = getCommonParams()
    const { data: styleData } = useGetStyleMasterQuery({ params: { companyId, SizeTemplate: true } });
    const styleList = styleData?.data ? styleData?.data : []
    function isItemAdded(style) {
        return styleWiseDetails.findIndex(i => parseInt(i.styleId) === parseInt(style.id)) !== -1;
    }
    function removeItem(style) {
        setStyleWiseDetails(prev => prev.filter(i => parseInt(i.styleId) !== parseInt(style.id)))
    }
    function addItem(style) {
        setStyleWiseDetails(prev => [...prev, { styleId: parseInt(style.id), SizeDetails: style.SizeTemplate?.SizeTemplateOnSize ? style.SizeTemplate?.SizeTemplateOnSize : [], Style: { sku: style.sku } }])
    }
    function handleCheckboxChange(style) {
        if (isItemAdded(style)) {
            removeItem(style)
        } else {
            addItem(style)
        }
    }
    function getSelectAll() {
        return styleList.every(i => isItemAdded(i))
    }
    function markUnMarkAll() {
        if (getSelectAll()) {
            styleList.forEach(style => {
                removeItem(style)
            });
        } else {
            styleList.forEach(style => {
                addItem(style)
            });
        }
    }
    return (
        <>
            <Modal isOpen={Number.isInteger(currentSelectedStyle)} onClose={() => setCurrentSelectedStyle("")} >
                <StyleDetails currentSelectedStyle={currentSelectedStyle} styleWiseDetails={styleWiseDetails} setStyleWiseDetails={setStyleWiseDetails} readonly={readonly} />
            </Modal>
            <div>
                <table className="border border-gray-500 table-fixed" >
                    <thead className="border border-gray-500">
                        <tr>
                            <th className="border border-gray-500 w-14 text-xs">
                                <input type="checkbox" name="" id="" checked={getSelectAll()} disabled={readonly}
                                    onChange={() => markUnMarkAll()} />
                            </th>
                            <th className="border border-gray-500 w-24 text-xs p-0.5">Style</th>
                            <th className="border border-gray-500 w-24 text-xs p-0.5">Size Wise</th>
                        </tr>
                    </thead>
                    <tbody>
                        {styleList.map((style) =>
                            <tr key={style.id}>
                                <td className="border border-gray-500 w-14 text-xs flex justify-center">
                                    <input type="checkbox" name="" id="" checked={isItemAdded(style)} disabled={readonly}
                                        onChange={() => handleCheckboxChange(style)} />
                                </td>
                                <td className="border border-gray-500 text-xs ">
                                    {style.sku}
                                </td>
                                <td className="border border-gray-500 text-xs flex justify-center" onClick={() => setCurrentSelectedStyle(style.id)}>
                                    {isItemAdded(style) && VIEW}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default StyleWiseDetails
