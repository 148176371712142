import React, { useEffect, useState, useRef, useCallback } from "react";

import {
  useGetInterOfficeStockTransferFinishedGoodsQuery,
  useGetInterOfficeStockTransferFinishedGoodsByIdQuery,
  useAddInterOfficeStockTransferFinishedGoodsMutation,
  useUpdateInterOfficeStockTransferFinishedGoodsMutation,
  useDeleteInterOfficeStockTransferFinishedGoodsMutation,
} from "../../../redux/ErpServices/InterOfficeTransferFinishedGoodsServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DropdownInput, DisabledInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { findFromList, getCommonParams, isGridDatasValid } from "../../../Utils/helper";
import { Loader } from "../../../Basic/components";

import {
  useGetLocationMasterQuery,
} from "../../../redux/ErpServices/LocationMasterServices";
import {
  useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import Modal from "../../../UiComponents/Modal";

import StockTransferFinishedGoodsFillGridForDelivery from "./StockTransferFinishedGoodsSalesDetailsFillGrid";
import { deliveryOrReceiptTypes } from "../../../Utils/DropdownData";
import StockTransferDetailsForDelivery from "./StockTransferFinishedGoodsSalesDetails";
import Consolidation from "../Consolidation";
import StockTransferFinishedGoodsReport from "./StockTransferFinishedGoodsReport";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { useReactToPrint } from "@etsoo/reactprint";
import StockTransferFinishedGoodsDetailsForReceipt from "./StockTransferFinishedGoodsSalesDetailsForReceipt";
import StockTransferFinishedGoodsDetailsFillGridForReceipt from "./StockTransferFinishedGoodsSalesDetailsFillGridForReceipt";
import moment from "moment";
import StockTransferDeliverySelection from "../../ReusableComponents/StockTransferDeliverySelection";
import { PrintFormatInterOfficeDelivery } from "..";

const MODEL = "STOCK TRANSFER";

export default function Form() {


  const dispatch = useDispatch()

  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [docId, setDocId] = useState("");
  const [docDate, setDocDate] = useState(new Date());
  const [stockDetailsFillGrid, setStockDetailsFillGrid] = useState(false);
  const [gridDetails, setGridDetails] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [delOrRec, setDelOrRec] = useState("DELIVERY");
  const [remarks, setRemarks] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [formReport, setFormReport] = useState(false);
  const [deliveryToBranchId, setDeliveryToBranchId] = useState("");
  const [prevDeliveryId, setPrevDeliveryId] = useState("");

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: docId,
    pageStyle: ``
  })


  const [searchValue, setSearchValue] = useState("");
  const childRecord = useRef(0);

  const { branchId, companyId, finYearId, userId } = getCommonParams()


  const { data: locationData, isLoading: isLocationLoading, isFetching: isLocationFetching } = useGetLocationMasterQuery({ params: { branchId }, searchParams: searchValue });
  const { data: branchList } = useGetBranchQuery({ params: { companyId } });

  const { data: allData, isLoading, isFetching } = useGetInterOfficeStockTransferFinishedGoodsQuery({ params: { branchId, finYearId, delOrRec }, searchParams: searchValue });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetInterOfficeStockTransferFinishedGoodsByIdQuery({ id }, { skip: !id });

  const [addData] = useAddInterOfficeStockTransferFinishedGoodsMutation();
  const [updateData] = useUpdateInterOfficeStockTransferFinishedGoodsMutation();
  const [removeData] = useDeleteInterOfficeStockTransferFinishedGoodsMutation();

  const getNextDocId = useCallback(() => {
    if (id) return
    if (isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.docId) {
      setDocId(data.docId);
    }
    setLocationId(data?.storeId ? findFromList(data?.storeId, (locationData?.data ? locationData.data : []), "locationId") : "")
    setStoreId(data?.storeId ? data?.storeId : "");
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");
    setGridDetails(data?.delOrRec ? (data.delOrRec === "DELIVERY" ? (data?.StockTransferFinishedGoodsDeliveryDetails ? data?.StockTransferFinishedGoodsDeliveryDetails : []) : (data?.StockTransferFinishedGoodsReceiptDetails ? data?.StockTransferFinishedGoodsReceiptDetails : [])) : [])
    setSpecialInstructions(data?.specialInstructions ? data?.specialInstructions : "")
    setRemarks(data?.remarks ? data?.remarks : "")
    setDeliveryToBranchId(data?.deliveryToBranchId ? data?.deliveryToBranchId : "");
    setDelOrRec(data?.delOrRec ? data.delOrRec : "DELIVERY");
    setDocDate(data?.docDate ? data?.docDate : moment(new Date()).format("DD-MM-YYYY"));
    setPrevDeliveryId(data?.prevDeliveryId ? data?.prevDeliveryId : "");
  }, [id, locationData]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    delOrRec,
    storeId,
    deliveryToBranchId,
    branchId,
    vehicleNo,
    specialInstructions,
    remarks,
    gridDetails,
    id, userId,
    finYearId,
    prevDeliveryId
  }

  const isDelivery = delOrRec === "DELIVERY"

  const validateData = (data) => {
    return data.vehicleNo && data.storeId
      && data.gridDetails.length > 0
      && isGridDatasValid(data.gridDetails, false, ["qty"])
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 0) {
        toast.success(text + "Successfully");
        onNew()
      } else {
        toast.error(returnData.message);
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });

    } catch (error) {
      console.log("handle", error);
    }
  };


  const saveData = () => {
    dispatch({
      type: `stock/invalidateTags`,
      payload: ['Stock'],
    });
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
    getNextDocId();
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const getTotalIssuedQty = () => {
    const totalQty = gridDetails.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.qty ? currentValue?.qty : 0))
    }, 0)
    return totalQty
  }


  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLocationLoading || isLocationFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  if (isLocationFetching || isLocationLoading) return <Loader />
  const storeOptions = locationData ?
    locationData.data.filter(item => parseInt(item.locationId) === parseInt(locationId)) :
    [];
  return (
    <>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <StockTransferFinishedGoodsReport
          delOrRec={delOrRec}
          heading={MODEL}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          allData={allData}
          tableWidth="100%"
          setForm={setForm}
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <Modal isOpen={stockDetailsFillGrid} onClose={() => { setStockDetailsFillGrid(false) }} widthClass={"bg-gray-300"}>
        {isDelivery
          ?
          <StockTransferFinishedGoodsFillGridForDelivery
            id={id}
            storeId={storeId}
            setFillGrid={setStockDetailsFillGrid} productionDeliveryDetails={gridDetails}
            setProductionDeliveryDetails={setGridDetails} />
          :
          <StockTransferFinishedGoodsDetailsFillGridForReceipt
            id={id}
            deliveryId={prevDeliveryId}
            setFillGrid={setStockDetailsFillGrid} gridDetails={gridDetails}
            setGridDetails={setGridDetails} />
        }
      </Modal>
      <div className="hidden">
        <PrintFormatInterOfficeDelivery remarks={remarks} finishedGoodsSalesDetails={gridDetails} innerRef={componentRef} id={id ? id : ""} />
      </div>
      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
      >
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}
            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            onPrint={id ? handlePrint : null}
            openReport={() => setFormReport(true)}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <div >
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                      <legend className='sub-heading'>Sales</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-4">
                          <DisabledInput name={"Doc. Id"} value={docId} />
                          <DisabledInput name="Doc. Date" value={docDate} />
                          <DropdownInput
                            name="Delivery / Receipt"
                            options={deliveryOrReceiptTypes}
                            value={delOrRec}
                            setValue={setDelOrRec}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          {isDelivery
                            ?
                            <DropdownInput name="Delivery To" options={dropDownListObject((branchList ? branchList.data : []).filter(i => parseInt(i.id) !== parseInt(branchId)), "branchName", "id")} value={deliveryToBranchId} setValue={setDeliveryToBranchId} required={true} readOnly={readOnly} />
                            :
                            <>
                              <DropdownInput name="Delivery From" options={dropDownListObject((branchList ? branchList.data : []).filter(i => parseInt(i.id) !== parseInt(branchId)), "branchName", "id")} value={deliveryToBranchId} setValue={setDeliveryToBranchId} required={true} readOnly={readOnly} />
                              <StockTransferDeliverySelection prevDeliveryId={prevDeliveryId} deliveryFromId={deliveryToBranchId} setPrevDeliveryId={setPrevDeliveryId} readOnly={readOnly} />
                            </>
                          }
                          <DropdownInput name="Location"
                            options={branchList ? (dropDownListObject(id ? branchList.data : branchList.data.filter(item => item.active), "branchName", "id")) : []}
                            value={locationId}
                            setValue={(value) => { setLocationId(value); setStoreId("") }}
                            required={true} readOnly={id || readOnly} />
                          <DropdownInput name="Store"
                            options={dropDownListObject(id ? storeOptions : storeOptions.filter(item => item.active), "storeName", "id")}
                            value={storeId} setValue={setStoreId} required={true} readOnly={id || readOnly} />
                        </div>
                      </div>
                    </fieldset>
                    {
                      isDelivery
                        ?
                        <StockTransferDetailsForDelivery id={id} readOnly={readOnly} setStockDetailsFillGrid={setStockDetailsFillGrid}
                          gridDetails={gridDetails} setGridDetails={setGridDetails}
                          storeId={storeId} />
                        :
                        <StockTransferFinishedGoodsDetailsForReceipt id={id} readOnly={readOnly} setStockDetailsFillGrid={setStockDetailsFillGrid}
                          gridDetails={gridDetails} setGridDetails={setGridDetails}
                          prevDeliveryId={prevDeliveryId}
                        />
                    }
                    <Consolidation vehicleNo={vehicleNo} setVehicleNo={setVehicleNo}
                      specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions}
                      remarks={remarks} setRemarks={setRemarks} totalQty={getTotalIssuedQty()} readOnly={readOnly} />
                    <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                      <FormReport
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setId={setId}
                        tableHeaders={tableHeaders}
                        tableDataNames={tableDataNames}
                        data={allData?.data ? allData?.data : []}
                        loading={
                          isLocationLoading || isLocationFetching
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}