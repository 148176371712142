import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf'
import { getDateFromDateTimeToDisplay, getEntryType } from '../../../Utils/helper';
import commaNumber from 'comma-number';

const LedgerReportPrintFormat = ({ data, partyName, startDate, endDate }) => {
    const ledgerData = data?.data ? data.data : []

    // Calculate the total credit and debit amounts
    const totalCredit = ledgerData.filter(item => item.creditOrDebit === "Credit").reduce((total, entry) => total + entry.amount, 0);
    const totalDebit = ledgerData.filter(item => item.creditOrDebit === "Debit").reduce((total, entry) => total + entry.amount, 0);
    const balance = (totalCredit + totalDebit + (data?.openingBalance ? data?.openingBalance : 0))
    return (
        <Document width={500} height={300} >
            <Page size="A4" style={{ fontFamily: "Times-Roman", ...tw("relative pb-[50px] px-2") }}>
                <View fixed>
                    <View style={tw("text-center mb-10 text-xl")}>
                        <Text style={{ fontFamily: "Times-Bold" }}>{partyName} Ledger Report</Text>
                        <Text style={tw("text-sm")}>{startDate} to {endDate}</Text>
                    </View>
                    <View style={{ ...tw("w-full flex flex-row border-l border-t text-sm text-right"), fontFamily: "Times-Bold" }}>
                        <Text style={tw("w-[5%] border-r border-b  p-1")}>
                            S.No
                        </Text>
                        <Text style={tw("w-[20%] border-r border-b  p-1")}>
                            Date
                        </Text>
                        <Text style={tw("w-[20%] border-r border-b  p-1")}>
                            Type
                        </Text>
                        <Text style={tw("w-[20%] border-r border-b  p-1")}>
                            Bill No
                        </Text>
                        <Text style={tw("w-[20%] border-r border-b  p-1")}>
                            Credit
                        </Text>
                        <Text style={tw("w-[20%] border-r border-b  p-1")}>
                            Debit
                        </Text>
                    </View>
                </View>

                <View style={tw("text-xs")}>
                    <View style={{ ...tw("w-full flex flex-row border-b border-l"), fontFamily: "Times-Bold" }}>
                        <Text style={tw("w-[5%] p-1")}>
                        </Text>
                        <Text style={tw("w-[20%] p-1")}>
                        </Text>
                        <Text style={tw("w-[20%] p-1 ")}>
                        </Text>
                        <Text style={tw("w-[20%] p-1")}>
                        </Text>
                        <Text style={{ ...tw("w-[20%] border-l border-r text-center p-1") }}>
                            Opening Balance
                        </Text>
                        <Text style={tw("w-[20%] border-r text-right p-1")}>
                            {parseFloat(data?.openingBalance || 0).toFixed(2)}
                        </Text>
                    </View>

                    {/* {Array.from({ length: 10 }).map(_ =>
                        <> */}
                    {
                        ledgerData.map((entry, index) => (
                            <View key={index} style={tw('w-full flex flex-row border-l')} wrap={false}>
                                <Text style={tw("w-[5%] border-r border-b text-left p-1")}>{index + 1}</Text>
                                <Text style={tw("w-[20%] border-r border-b text-left p-1")}>{getDateFromDateTimeToDisplay(entry.partyBillDate)}</Text>
                                <Text style={tw("w-[20%] border-r border-b text-left p-1")}>
                                    {getEntryType((entry?.EntryType ? entry?.EntryType : "").split('_').join(' '), partyName)}
                                </Text>
                                <Text style={tw("w-[20%] border-r border-b text-left p-1")}>{entry.partyBillNo}</Text>
                                <Text style={tw("w-[20%] border-r border-b text-right p-1")}>{entry.creditOrDebit === "Credit" ? commaNumber(Math.abs(entry.amount).toFixed(2)) : ""}</Text>
                                <Text style={tw("w-[20%] border-r border-b text-right p-1")}>{entry.creditOrDebit === "Debit" ? commaNumber(Math.abs(entry.amount).toFixed(2)) : ""}</Text>
                            </View>
                        ))
                    }
                    {/* </>
                    )
                    } */}
                    <View style={{ ...tw("w-full flex flex-row"), fontFamily: "Times-Bold" }}>
                        <Text style={tw("w-[5%] p-1")}>
                        </Text>
                        <Text style={tw("w-[20%] p-1")}>
                        </Text>
                        <Text style={tw("w-[20%]  p-1")}>
                        </Text>
                        <Text style={tw("w-[20%] p-1 text-left border-x border-b")}>
                            Totals
                        </Text>
                        <Text style={tw("w-[20%] border-r border-b text-right p-1")}>
                            {commaNumber(Math.abs(totalCredit).toFixed(2))}
                        </Text>
                        <Text style={tw("w-[20%] border-r border-b text-right p-1")}>
                            {commaNumber(Math.abs(totalDebit).toFixed(2))}
                        </Text>
                    </View>
                    <View style={{ ...tw("w-full flex flex-row"), fontFamily: "Times-Bold" }}>
                        <Text style={tw("w-[5%] p-1")}>
                        </Text>
                        <Text style={tw("w-[20%] p-1")}>
                        </Text>
                        <Text style={tw("w-[20%] p-1 ")}>
                        </Text>
                        <Text style={tw("w-[20%] p-1")}>
                        </Text>
                        <Text style={{ ...tw("w-[20%] border-l border-r border-b text-center p-1") }}>
                            Closing Balance
                        </Text>
                        <Text style={tw("w-[20%] border-r border-b text-right p-1")}>
                            {parseFloat(balance).toFixed(2)}
                        </Text>
                    </View>
                </View>
                <View fixed style={tw("absolute bottom-5")}>
                    <View style={tw("text-center w-full pb-1 pt-1 px-2 text-xs ")}>
                        <Text render={({ pageNumber, totalPages }) => (
                            `Page ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default LedgerReportPrintFormat