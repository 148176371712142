import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { TextInput, CheckBox, DropdownInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";

import { useGetIOTDeviceQuery } from "../../../redux/ErpServices/IOTDeviceMasterService";
import { useAddLineOperationMasterDataMutation, useDeleteLineOperationMasterDataMutation, useGetLineOperationMasterByIdQuery, useGetLineOperationMasterQuery, useUpdateLineOperationMasterDataMutation } from "../../../redux/ErpServices/LineAndOperationMasterService";
import { useGetOperationNameQuery } from "../../../redux/ErpServices/operationNameServices";
import { useGetEmployeeCategoryByIdQuery, useGetEmployeeCategoryQuery } from "../../../redux/services/EmployeeCategoryMasterService";
import { useGetEmployeeByIdQuery, useGetEmployeeQuery } from "../../../redux/services/EmployeeMasterService";

import DataTable from "./TemplateFillGrid";
import { GenerateButton } from "../../../Buttons";
import { useGetFloorMasterQuery } from "../../../redux/services/FloorMasterService";
const MODEL = "Line & Operation Master";

export default function Form() {
    const [form, setForm] = useState(true);
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [noOfMachines, setNoOfMachines] = useState("");
    const [active, setActive] = useState(true);
    const [employeeRFID, setEmployeeRFID] = useState("")
    const [name, setName] = useState("");
    const [lineoperationDet, setLineOperationDet] = useState([]);
    const [floor, setFloor] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const childRecord = useRef(0);
    const dispatch = useDispatch();

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId",
            employeeRFID
        ),

    };
    const { data: iotDeviceData, } = useGetIOTDeviceQuery({ params });
    const { data: employeeRfid } = useGetEmployeeQuery({ params });


    const { data: operationNameData, } = useGetOperationNameQuery({ params });
    const { data: allData, isLoading, isFetching } = useGetLineOperationMasterQuery({ params, searchParams: searchValue });

    const { data: floorData, isLoading: isCountryLoading, isFetching: isCountryFetching } =
        useGetFloorMasterQuery({ params });
    const floorName = floorData?.data || []

    const {
        data: singleData,
        isFetching: isSingleFetching,

    } = useGetLineOperationMasterByIdQuery(id, { skip: !id });


    const [addData] = useAddLineOperationMasterDataMutation();
    const [updateData] = useUpdateLineOperationMasterDataMutation();
    const [removeData] = useDeleteLineOperationMasterDataMutation();

    const syncFormWithDb = useCallback((data) => {
        if (id) { setReadOnly(true); setActive(true) }

        setNoOfMachines(data?.noOfMachines ? data.noOfMachines : "");
        setActive(id ? (data?.active ? data.active : false) : true);


        setName(data?.name ? data.name : "");
        setEmployeeRFID(data?.rfidId ? data?.rfidId : "")
        setFloor(data?.floorId ? data?.floorId : '')
        setLineOperationDet(data?.LineOperationDet ? data?.LineOperationDet : [])
        childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id]);

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, id, syncFormWithDb, singleData]);

    // useEffect(() => {
    //     const linelength = noOfMachines;
    //     setLineOperationDet(prev => {
    //         let newObj = structuredClone(prev)
    //         newObj = [...prev, { iOTDeviceId: "", operationNameId: "" }]
    //         return newObj
    //     })

    // }, [noOfMachines])

    const data = {
        id,
        name,
        active,
        noOfMachines,
        lineoperationDet,
        floor
    };
    console.log(data, 'data');


    const validateData = (data) => {
        if (data.floor) {
            return true;
        }
        return false;
    };

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId("")
            syncFormWithDb(undefined)
            toast.success(text + "Successfully");

        } catch (error) {
            console.log("handle")


        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                dispatch({
                    type: `lineOperation/invalidateTags`,
                    payload: ['lineOperation'],
                });
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setReadOnly(false);
        setForm(true);
        setSearchValue("");
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }
    const tableHeaders = [
        "operationName", "Floor", "Machines", "Status"
    ]
    const tableDataNames = ["dataObj.name", "dataObj.floorId",
        "dataObj.noOfMachines", 'dataObj.active ? ACTIVE : INACTIVE']

    function generateRows() {
        const isValidRow = (row) => row.isActive || row.iOTDeviceId || row.operationNameId;
        setLineOperationDet(prev => {
            let oldValidData = prev.filter(i => isValidRow(i));
            if (noOfMachines < oldValidData.length) {
                toast.warning("Cannot Reduce No of Machines when Data entered...!!! Please delete rows Manually", { position: "top-center" });
                return prev;
            }
            let newData = Array.from({ length: noOfMachines - oldValidData.length }).map(i => ({
                iOTDeviceId: "", operationNameId: "", isActive: false
            }));
            return [...oldValidData, ...newData]
        })
    }

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme">
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                    childRecord={childRecord.current}
                />
                <div className="flex w-full grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="flex w-[75%] border overflow-auto">
                        <div className="mr-1 md:ml-2 h-[20%] w-full ">
                            <fieldset className="frame my-1">
                                <legend className="sub-heading">Line & Operation Info</legend>
                                <div className="grid grid-cols-4 my-2 items-center">
                                    <TextInput
                                        name="Line Name"
                                        type="text"
                                        value={name}
                                        setValue={setName}
                                        required={true}
                                        readOnly={readOnly}
                                        disabled={(childRecord.current > 0)}
                                    />
                                    <DropdownInput
                                        name="Floor"
                                        options={dropDownListObject(id ? floorData?.data || [] : floorData?.data || [].filter(item => item.active), "name", "id")}
                                        value={floor}
                                        setValue={setFloor}
                                        required={true}
                                        readOnly={readOnly}
                                    />
                                    <div className="flex items-center justify-start">
                                        <div className="w-[50%] flex">
                                            <TextInput
                                                name="No.of.Machines"
                                                type="text"
                                                value={noOfMachines}
                                                setValue={setNoOfMachines}
                                                required={true}
                                                readOnly={readOnly}
                                                disabled={(childRecord.current > 0)}
                                            />
                                            <div className="w-[10%]" title="Generate">
                                                <GenerateButton name="" onClick={generateRows} /></div>
                                        </div>
                                    </div>
                                    <CheckBox
                                        name="Active"
                                        readOnly={readOnly}
                                        value={active}
                                        setValue={setActive}
                                    />
                                </div>

                            </fieldset>
                            <div className='w-[70%] border-gray-400 h-[80%]'><DataTable
                                readOnly={readOnly}
                                operationData={operationNameData?.data || []}
                                deviceData={iotDeviceData?.data || []}
                                lineoperationDet={lineoperationDet} setLineOperationDet={setLineOperationDet}
                                noOfMachines={noOfMachines}
                            /></div>
                        </div>
                    </div>
                    <div className="frame hidden w-[25%] md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
