import React, { useCallback } from 'react';
import YarnPoItem from './YarnPoItem';


const YarnInwardItems = ({ inwardItems, setInwardItems, readOnly, removeItem, purchaseInwardId, storeId }) => {
    const handleInputChange = useCallback((value, index, field) => {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            newBlend[index][field] = value;
            if (field !== "inwardQty" && newBlend[index]["noOfBags"] && newBlend[index]["weightPerBag"]) {
                let tempInwardQty = (parseFloat(newBlend[index]["noOfBags"]) * parseFloat(newBlend[index]["weightPerBag"])).toFixed(3)
                newBlend[index]["inwardQty"] = tempInwardQty
            }
            return newBlend
        });
    }, [setInwardItems])

    const handleInputChangeLotNo = useCallback((value, index, lotIndex, field) => {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]["lotDetails"]) return inwardItems
            newBlend[index]["lotDetails"][lotIndex][field] = value;
            console.log(field, newBlend[index]["lotDetails"][lotIndex]["noOfBags"])
            if ((field === "weightPerBag" || field === "noOfBags") && newBlend[index]["lotDetails"][lotIndex]["noOfBags"] && newBlend[index]["lotDetails"][lotIndex]["weightPerBag"]) {
                let tempInwardQty = (parseFloat(newBlend[index]["lotDetails"][lotIndex]["noOfBags"]) * parseFloat(newBlend[index]["lotDetails"][lotIndex]["weightPerBag"])).toFixed(3)
                newBlend[index]["lotDetails"][lotIndex]["qty"] = tempInwardQty
            }
            return newBlend
        });
    }, [setInwardItems]);

    return (
        <>
            <div className={`w-full overflow-y-auto py-1`}>
                <table className=" text-xs border border-gray-500 table-auto w-full">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8'>
                            <th className="table-data  w-2 text-center">S.no</th>
                            <th className="table-data  w-16 text-center">P.no</th>
                            <th className="table-data w-44">Items</th>
                            <th className="table-data w-14">Colors</th>
                            <th className="table-data  w-14">UOM</th>
                            <th className="table-data  w-14"> Stock Qty</th>
                            <th className="table-data  w-14"> Stock Bags</th>
                            <th className="table-data w-14">A.Inward Qty</th>
                            <th className="table-data w-14">A.Inward Bags</th>
                            <th className="table-data w-14">A.Return Qty</th>
                            <th className="table-data w-14">A.Return Bags</th>
                            <th className="table-data  w-14">Allowed Return Qty</th>
                            <th className="table-data  w-14">Allowed Return Bags</th>
                            <th className="table-data  w-10">Lot Det.<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">No. of Bags<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Return Qty</th>
                            <th className="table-data  w-16">Po Price</th>
                            <th className="table-data  w-16">Gross</th>
                            {!readOnly &&
                                <th className='table-data  w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) => <YarnPoItem key={item.poItemsId} storeId={storeId} handleInputChangeLotNo={handleInputChangeLotNo} item={item} stockId={item.stockId} noOfBags={item.noOfBags} purchaseInwardId={purchaseInwardId} removeItem={removeItem} readOnly={readOnly} qty={item.qty} poItemId={item.poItemsId} index={index} handleInputChange={handleInputChange} />)}
                        {Array.from({ length: 8 - inwardItems.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                <td className='table-data'>
                                </td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                {!readOnly &&
                                    <td className="table-data w-10"></td>
                                }
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default YarnInwardItems