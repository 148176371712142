import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetLoopLengthQuery } from '../../../redux/ErpServices/LoopLengthMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTime, isBetweenRange, substract } from '../../../Utils/helper';
import {
    useGetPoQuery,
} from "../../../redux/ErpServices/PoServices";
import { DELETE, PLUS } from '../../../icons';
import { toast } from 'react-toastify';
import { params } from '../../../Utils/helper';

const FabricInwardItems = ({ finalInwardItems, setFinalInwardItems, readOnly, removeItem, openSelection}) => {
    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(finalInwardItems);
        newBlend[index][field] = value;
        setFinalInwardItems(newBlend);
    };

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )

    const { data: poList, isLoading: poListLoading, isFetching: poListFetching } = useGetPoQuery({ params });

    const { data: fabricList } =
        useGetFabricMasterQuery({ params: { companyId } });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { companyId } });

    const { data: designList } =
        useGetdesignQuery({ params: { companyId } });

    const { data: gaugeList } =
        useGetGaugeQuery({ params: { companyId } });

    const { data: loopLengthList } =
        useGetLoopLengthQuery({ params: { companyId } });

    const { data: gsmList } =
        useGetgsmQuery({ params: { companyId } });

    const { data: diaList } =
        useGetDiaQuery({ params: { companyId } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params: { companyId } });


    if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList || !poList) return <Loader />
    return (
        <>
            <div className={`relative w-full overflow-y-auto py-1`}>
                <table className="border border-gray-500 text-xs table-auto w-full">
                    <thead className='bg-blue-200 top-0'>
                        <tr className='h-8'>
                            <th className=' w-8 table-data '>
                                S.no
                            </th>
                            <th className=' w-24 table-data'>
                                Po.no
                            </th>
                            <th className=' w-24 table-data'>
                                Po.Date
                            </th>
                            <th className=' w-24 table-data'>
                                Due Date
                            </th>
                            <th className='w-24 table-data'>
                                Fabric Name
                            </th>
                            <th className='w-16 table-data'>
                                Color
                            </th>
                            <th className='w-16 table-data'>
                                Design
                            </th>
                            <th className='w-12 table-data'>
                                Gauge
                            </th>
                            <th className='w-12 table-data'>
                                LL
                            </th>
                            <th className='w-12 table-data'>
                                Gsm
                            </th>
                            <th className='w-12 table-data'>
                                K-Dia
                            </th>
                            <th className='w-12 table-data'>
                                F-Dia
                            </th>
                            <th className='w-12 table-data'>
                                Uom
                            </th>
                            <th className='w-12 table-data'>
                                Po qty
                            </th>
                            <th className='table-data'>
                                Already Inwarded qty
                            </th>
                            <th className='table-data'>
                                Balance
                            </th>
                            <th className='table-data'>
                                Inward
                            </th>
                            <th className='w-12 table-data'>
                                Price
                            </th>
                            <th className='w-16 table-data'>
                                Gross
                            </th>
                            {!readOnly
                                &&
                                <th className='w-20'>
                                    <div onClick={openSelection}
                                        className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                                        {PLUS}
                                    </div>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto border border-gray-600 h-full w-full'>
                        {finalInwardItems.map((item, index) => (
                            <tr key={index} className="w-full table-row">
                                <td className='text-left px-1 table-data shadow-xl '>
                                    {index + 1}
                                </td>
                                <td className='text-left px-1  table-data shadow-xl'>
                                    {item.poDocId}
                                </td>
                                <td className='text-left px-1  table-data shadow-xl'>
                                    {getDateFromDateTime(item.poDate)}
                                </td>
                                <td className='text-left px-1 table-data shadow-xl'>
                                    {getDateFromDateTime(item.dueDate)}
                                </td>
                                <td className='text-left px-1 table-data shadow-xl'>
                                    {findFromList(item.fabricId, fabricList.data, "aliasName")}
                                </td>
                                <td className='text-left  table-data shadow-xl'>
                                    {findFromList(item.colorId, colorList.data, "name")}
                                </td>
                                <td className='text-left px-1 table-data shadow-xl'>
                                    {findFromList(item.designId, designList.data, "name")}
                                </td>
                                <td className='text-right px-1 table-data shadow-xl'>
                                    {findFromList(item.gaugeId, gaugeList.data, "name")}
                                </td>
                                <td className='text-right px-1 table-data shadow-xl'>
                                    {findFromList(item.loopLengthId, loopLengthList.data, "name")}
                                </td>
                                <td className='text-right px-1 table-data shadow-xl'>
                                    {findFromList(item.gsmId, gsmList.data, "name")}
                                </td>
                                <td className='text-right px-1  table-data shadow-xl'>
                                    {findFromList(item.kDiaId, diaList.data, "name")}
                                </td>
                                <td className='text-right px-1 table-data shadow-xl'>
                                    {findFromList(item.fDiaId, diaList.data, "name")}
                                </td>
                                <td className='text-left px-1 table-data shadow-xl'>
                                    {findFromList(item.uomId, uomList.data, "name")}
                                </td>
                                <td className='text-right px-1 table-data shadow-xl'>
                                    {substract(item.qty, item.alreadyCancelQty).toFixed(3)}
                                </td>
                                <td className='table-data text-right px-1'>
                                    {(parseFloat(item.alreadyInwardedQty) + parseFloat(item.alreadyReturnedQty)).toFixed(3)}
                                </td>
                                <td className='table-data text-right px-1'>
                                    {substract(substract(item.qty, item.alreadyCancelQty), (parseFloat(item.alreadyInwardedQty) + parseFloat(item.alreadyReturnedQty))).toFixed(3)}
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "inwardQty") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1  px-1 w-full table-data-input"
                                        value={item.inwardQty}
                                        disabled={readOnly}
                                        onChange={(event) => {
                                            if(!event.target.value){
                                                handleInputChange(0, index, "inwardQty");
                                                return
                                            }
                                            if (isBetweenRange(0, substract(substract(item.qty, item.alreadyCancelQty), (parseFloat(item.alreadyInwardedQty) + parseFloat(item.alreadyReturnedQty))), event.target.value)) {
                                                handleInputChange(event.target.value.replace(/^0+/, ''), index, "inwardQty")
                                            } else {
                                                toast.info("Inward Qty Cannot be more than balance Qty", { position: 'top-center' })
                                            }
                                        }}
                                        onBlur={(e) =>
                                            {
                                                if(!e.target.value){
                                                    handleInputChange(0.000, index, "inwardQty");
                                                    return
                                                }
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "inwardQty")
                                        }}
                                    />
                                </td>
                                <td className='text-right px-1  table-data shadow-xl'>
                                    {item.price}
                                </td>
                                <td className='text-right px-1  table-data shadow-xl'>
                                    {(parseFloat(item.price) * parseFloat(item.inwardQty)).toFixed(2)}
                                </td>
                                {!readOnly &&
                                    <td className='table-data w-12'>
                                        <div tabIndex={-1} onClick={() => removeItem(item.poNo, item.poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                            {DELETE}
                                        </div>
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default FabricInwardItems