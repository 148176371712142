import React from 'react'
import YarnProgramItem from './YarnProgramItem';
import { useGetProcessDeliveryByIdQuery } from '../../../redux/ErpServices/ProcessDeliveryServices';
import { substract, sumArray } from '../../../Utils/helper';

const YarnPoItemSelection = ({ id, rawMaterialType, dataObj }) => {
    const {
        data: singleData,
    } = useGetProcessDeliveryByIdQuery({ id }, { skip: !id });
    let processDeliveryProgramDetails = singleData?.data?.ProcessDeliveryProgramDetails ? singleData?.data?.ProcessDeliveryProgramDetails : []

    return (
        <div className='border bg-gray-200'>
            <table className='table-auto w-full text-xs border border-gray-500 '>
                <thead className=' border-b border-gray-500'>
                    <tr className='bg-blue-200 border border-gray-500 sticky top-10 '>
                        <th className="table-data  w-2 text-center">S.no</th>
                        <th className="table-data w-28">Yarn</th>
                        <th className="table-data w-14">Colors</th>
                        <th className="table-data  w-14">UOM</th>
                        <th className="table-data w-10">No. of Bags</th>
                        <th className="table-data w-10">Weight Per Bag</th>
                        <th className="table-data w-10">Prog. Qty</th>
                        <th className="table-data  w-10"> In Qty</th>
                        <th className="table-data  w-10"> In Bags</th>
                        <th className="table-data  w-10">Bal. Qty</th>
                        <th className="table-data  w-10">Bal. Bags</th>
                        <th className="table-data  w-10">Bill Qty</th>
                        <th className="table-data  w-10">Bal Bill Qty</th>
                        <th className="table-data  w-10">Raw Materials</th>
                    </tr>
                </thead>
                <tbody>
                    {(processDeliveryProgramDetails)
                        .map((item, index) =>
                            <YarnProgramItem dataObj={dataObj} delivery={singleData?.data} rawMaterialType={rawMaterialType} key={item.id} id={item.id} index={index} item={item} />)
                    }
                    <tr className='table-row'>
                        <td colSpan={3} className='table-data'></td>
                        <td>Totals</td>
                        <td className='text-right  table-data'>{sumArray(processDeliveryProgramDetails.filter(item => item.noOfBags), "noOfBags")}</td>
                        <td></td>
                        <td className='text-right  table-data'>{sumArray(processDeliveryProgramDetails, "qty").toFixed(3)}</td>
                        <td className='text-right  table-data'>{sumArray(processDeliveryProgramDetails, "alreadyInwardedQty").toFixed(3)}</td>
                        <td className='text-right  table-data'>{sumArray(processDeliveryProgramDetails, "alreadyInwardedBags")}</td>
                        <td className='text-right  table-data'>{processDeliveryProgramDetails.reduce((a, c) => a + substract(c.qty, c.alreadyInwardedQty), 0).toFixed(3)}</td>
                        <td className='text-right  table-data'>{processDeliveryProgramDetails.reduce((a, c) => {
                            let total = substract(c.noOfBags, c.alreadyInwardedBags)
                            total = total > 0 ? total : 0;
                            return a + total
                        }
                            , 0).toFixed(3)}</td>
                        <td className='text-right  table-data'>{sumArray(processDeliveryProgramDetails, "alreadyBillQty").toFixed(3)}</td>
                        <td className='text-right  table-data'>{processDeliveryProgramDetails.reduce((a, c) => {
                            let total = substract(c.alreadyInwardedQty, c.alreadyBillQty)
                            total = total > 0 ? total : 0;
                            return a + total
                        }, 0).toFixed(3)}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    )
}

export default YarnPoItemSelection