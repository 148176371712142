import React from 'react'
import Address from './Address';
import YarnPurchaseOrder from './YarnPurchaseOrder';
import RainDot from "../../../assets/RainDot.png"
import QRCode from "react-qr-code";
import FabricPurchaseOrder from './FabricPurchaseOrder';
import { getCommonParams, getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useGetPartyByIdQuery } from '../../../redux/ErpServices/PartyMasterServices';
import useTaxDetailsHook from '../../../CustomHooks/TaxHookDetails';
// import TaxDetails from './TaxDetails';
import { useGetTermsAndConditionsQuery } from '../../../redux/services/TermsAndConditionsService';
import { useGetRawMaterialSalesByIdQuery } from '../../../redux/ErpServices/RawMaterialSalesServices';
import { useGetBranchByIdQuery } from '../../../redux/services/BranchMasterService';
import TaxDetailsSplitUp from '../TaxSplitUp';
const numberToText = require('number-to-text')
require('number-to-text/converters/en-in');

export default function Form({ poType, remarks, rawMaterials, innerRef, id, discountType, discountValue, taxTemplateId, isSupplierOutside }) {

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetRawMaterialSalesByIdQuery({ id }, { skip: !id });


  const { data: supplierDetails } =
    useGetPartyByIdQuery(singleData?.data?.supplierId, { skip: !singleData?.data?.supplierId });

  const { ...taxDetails } = useTaxDetailsHook({
    poItems: rawMaterials, taxTypeId: taxTemplateId,
    discountType: discountType, discountValue: discountValue
  })

  const { data: termsAndCondition } = useGetTermsAndConditionsQuery({ params: { poType } })

  const { branchId } = getCommonParams()

  const { data } = useGetBranchByIdQuery(branchId)

  const branchData = data?.data ? data.data : {};

  if (!singleData || isSingleFetching || isSingleLoading || !termsAndCondition || !taxDetails) return null

  return (
    <div className="h-[950px] w-full flex flex-col justify-between border-2 m-0 border-black" id='poPrint' ref={innerRef}>
      <div>
        <div className='flex'>
          <div className='w-1/4 flex items-center justify-center'>
            <img src={RainDot} alt='logo' className="h-16" />
          </div>
          <div className='text-center p-1 text-md flex items-center justify-center font-bold text-green-700 w-full'> RAW MATERIAL SALES
          </div>
        </div>
        <div className=''>
          <table className="text-xs border border-gray-500 w-full table-auto ">
            <tbody>
              <tr className='text-xs'>
                <td >Doc. Id: {singleData?.data?.docId}</td>
                <td> Del. Date : {getDateFromDateTimeToDisplay(singleData?.data?.createdAt)}</td>
                <td> Due. Date : {getDateFromDateTimeToDisplay(singleData?.data?.dueDate)}</td>
                <td className='table-data px-14 py-1 w-1/6'>
                  <QRCode value={singleData?.data?.docId} size={80} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Address supplierDetails={supplierDetails} fromAddress={branchData} />
        {singleData?.data?.poType.toLowerCase().includes("yarn")
          ?
          <YarnPurchaseOrder rawMaterials={rawMaterials} singleData={singleData?.data} taxDetails={taxDetails} />
          :
          <FabricPurchaseOrder rawMaterials={rawMaterials} singleData={singleData?.data} taxDetails={taxDetails} />

        }
        <div className='flex justify-end'>
          <table className='border border-gray-500 text-center'>
            <tbody>
              <tr className='border border-gray-500 '><th className='p-2 bg-green-200 text-xs' colSpan={2}>Tax Details</th></tr>
              <TaxDetailsSplitUp isSupplierOutside={isSupplierOutside} items={rawMaterials} taxTemplateId={taxTemplateId} discountType={discountType} discountValue={discountValue} />
              <tr className='border border-gray-500 text-xs '>
                <td className='table-data p-1'>
                  ROUNDOFF
                </td>
                <td className='table-data p-1'>
                </td>
                <td className='table-data text-right p-1'>
                  {parseFloat(taxDetails?.roundOffAmount).toFixed(2)}
                </td>
              </tr>
              <tr className='border border-gray-500 text-xs'>
                <td className='table-data p-1 bg-green-200 text-xs'>
                  DIS. AMOUNT
                </td>
                <td className='table-data p-1'>
                </td>
                <td className='table-data p-1 text-xs'>
                  {parseFloat(taxDetails?.overAllDiscountAmount).toFixed(2)}
                </td>
              </tr>
              <tr className='border border-gray-500 text-xs'>
                <td className='table-data p-1 bg-green-200 text-xs'>
                  NET AMOUNT
                </td>
                <td className='table-data p-1'>
                </td>
                <td className='table-data p-1 text-xs'>
                  {parseFloat(taxDetails?.netAmount).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div className=' w-full'>
        <div className='w-full '>
          <div className='w-full border border-gray-600 flex items-center'>
            <h1 className='font-bold text-sm'>Amount in words    :
            </h1>
            <span className='text-xs'>
              Rs.{numberToText.convertToText(taxDetails?.netAmount ? taxDetails?.netAmount : 0, { language: "en-in", separator: "" })} Only
            </span>
          </div>
          <div className='w-full border border-gray-600 flex items-center'><h1 className='font-bold text-sm'>Remarks : </h1>
            <span className='text-xs'>
              {remarks}
            </span>
          </div>
          <div className='w-full '>
            <h1 className='text-sm w-full font-bold'>Terms and Condition :
            </h1>
            <h1 className='text-[10px] w-full '>
              {(termsAndCondition.data ? termsAndCondition.data.filter(item => item.isPurchaseOrder).map((value) =>
                <pre key={value.id} className='font-sans'>{value.description}</pre>
              ) : "")}</h1>
          </div>
        </div>
        <div className=''>
          <div className='text-sm text-right px-24 font-bold'>
            For PS-TEX
          </div>
          <div className='border grid grid-rows-1 grid-flow-col p-2 font-bold text-xs '>
            <h1>Prepared By:</h1>
            <h1>Verified By:</h1>
            <h1>Received By:</h1>
            <h1>Approved By :</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

