import React from 'react';
import YarnPoItem from './YarnPoItem';
import YarnDirectItem from './YarnDirectItem';

const YarnInwardItems = ({ inwardItems, setInwardItems, readOnly, removeItem, billEntryId }) => {
    const handleInputChange = (value, index, field) => {
        setInwardItems(inwardItems => {
            if(!inwardItems[index]) return inwardItems
            const newBlend = structuredClone(inwardItems);
            newBlend[index][field] = value;
            return newBlend
        });
    };
    return (
        <>
            <div className={`w-full overflow-y-auto py-1`}>
                <table className=" text-xs border border-gray-500 table-auto w-full">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8'>
                            <th className="table-data  w-2 text-center">S.no</th>
                            <th className="table-data  w-10 text-center">P.no</th>
                            <th className="table-data  w-32">Yarn</th>
                            <th className="table-data  w-20">Color</th>
                            <th className="table-data  w-10">Uom</th>
                            <th className="table-data w-10">Po. Qty</th>
                            <th className="table-data w-10">Grn.Qty</th>
                            <th className="table-data  w-10"> Rtn.Qty</th>
                            <th className="table-data  w-10"> Can.Qty</th>
                            <th className="table-data  w-10">Po.Rate</th>
                            <th className="table-data  w-10"> A.Bill.Qty</th>
                            <th className="table-data  w-10"> Bal.Bill. Qty</th>
                            <th className="table-data  w-10">Bill.Qty</th>
                            <th className="table-data  w-10">Bill.Rate</th>
                            <th className="table-data  w-10">Bill.Amount</th>
                            <th className="table-data  w-10">Disc.Type</th>
                            <th className="table-data  w-10">Disc.Value</th>
                            <th className="table-data  w-10">Amount</th>
                            <th className="table-data  w-10">Tax</th>
                            <th className="table-data  w-10">Notes</th>
                            {!readOnly &&
                                <th className='table-data  w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) =>
                            <>
                                {item?.isPoItem ?
                                    <YarnPoItem item={item} billEntryId={billEntryId} removeItem={removeItem}
                                        readOnly={readOnly} key={index} index={index} handleInputChange={handleInputChange} />
                                    :
                                    <YarnDirectItem item={item} billEntryId={billEntryId} removeItem={removeItem}
                                        readOnly={readOnly} key={index} index={index} handleInputChange={handleInputChange} />
                                }
                            </>
                        )}
                        {
                            Array.from({ length: 8 - inwardItems.length }).map(i =>
                                <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                    {Array.from({ length: 20 }).map(i =>
                                        <td className="table-data   "></td>
                                    )}
                                    {!readOnly &&
                                        <td className="table-data w-10"></td>
                                    }
                                </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default YarnInwardItems