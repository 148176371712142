import React from 'react'
import { View } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';

export const DottedLine = () => {
    return (
        <View style={tw("border border-t border-dashed")}>

        </View>
    )
}
