import React, { useEffect, useState } from 'react'
import { useGetFabricMasterQuery } from "../../../redux/ErpServices/FabricMasterServices";
import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";
import { useGetdesignQuery } from "../../../redux/ErpServices/DesignMasterServices";
import { useGetGaugeQuery } from "../../../redux/ErpServices/GaugeMasterServices";
import { useGetLoopLengthQuery } from "../../../redux/ErpServices/LoopLengthMasterServices";
import { useGetgsmQuery } from "../../../redux/ErpServices/GsmMasterServices";
import { useGetDiaQuery } from "../../../redux/ErpServices/DiaMasterServices";
import secureLocalStorage from 'react-secure-storage';
import { Loader } from '../../../Basic/components';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { findFromList, getAllowableReturnQty, getDateFromDateTime, substract } from '../../../Utils/helper';
import PoNumber from '../PoNumber';



const FabricPoItemSelection = ({ poItems, addItem, removeItem, isItemInPoAdded, storeId, inwardOrReturn }) => {
    const [localItems, setLocalItems] = useState([]);

    const [searchFilter, setSearchFilter] = useState([])

    useEffect(() => {
        let fullList = []
        poItems.forEach((poItem) => {
            poItem.poItems.forEach((item) => {
                if (inwardOrReturn === "PurchaseInward" || inwardOrReturn === "PurchaseCancel") {
                    if ((item.alreadyInwardedQty + item.alreadyReturnedQty) !== substract(item.qty, item.alreadyCancelQty)) {
                        fullList.push({ ...item,poDocId: poItem.docId, poNo: poItem.id, poDate: poItem.createdAt, dueDate: poItem.dueDate, poItemId: item.poItemId })
                    }
                } else {
                    if (getAllowableReturnQty(item.alreadyInwardedQty, item.alreadyReturnedQty, item.stockQty) !== 0) {
                        fullList.push({ ...item,poDocId: poItem.docId, poNo: poItem.id, poDate: poItem.createdAt, dueDate: poItem.dueDate, poItemId: item.poItemId })
                    }
                }
            })
        })
        setLocalItems(fullList)
    }, [poItems, setLocalItems, searchFilter])

    function filterRecords(localItems) {
        let newLocalItems = [...localItems]
        newLocalItems = newLocalItems.filter(item => {
            for (let filter of searchFilter) {
                let fieldName = filter.fieldName
                let searchValue = filter.searchValue
                let objTransformFunc = filter.objTransformFunc
                let itemObj = objTransformFunc(item[fieldName])
                if (!(`${itemObj}`.toLowerCase().includes(searchValue.toLowerCase()))) return false
            }
            return true
        })
        return newLocalItems
    }

    useEffect(() => {
        setLocalItems(prev => filterRecords(prev))
    }, [setLocalItems, searchFilter, poItems])


    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const { data: fabricList, isLoading: fabricListLoading, isFetching: fabricListFetching } = useGetFabricMasterQuery({ companyId })
    const { data: uomList, isLoading: uomLoading, isFetching: uomFetching } = useGetUnitOfMeasurementMasterQuery({ companyId })
    const { data: colorList, isLoading: colorLoading, isFetching: colorFetching } = useGetColorMasterQuery({ companyId })
    const { data: designList, isLoading: designLoading, isFetching: designFetching } = useGetdesignQuery({ companyId })
    const { data: gaugeList, isLoading: gaugeLoading, isFetching: gaugeFetching } = useGetGaugeQuery({ companyId })
    const { data: loopList, isLoading: loopLoading, isFetching: loopFetching } = useGetLoopLengthQuery({ companyId })
    const { data: gsmList, isLoading: gsmLoading, isFetching: gsmFetching } = useGetgsmQuery({ companyId })
    const { data: diaList, isLoading: diaLoading, isFetching: diaFetching } = useGetDiaQuery({ companyId })

    if (fabricListLoading || fabricListFetching || uomFetching || uomLoading || colorFetching || colorLoading || designLoading || designFetching ||
        gaugeLoading || gaugeFetching || loopLoading || loopFetching || gsmLoading || gsmFetching || diaLoading || diaFetching) {
        return <tr>
            <td>
                <Loader />
            </td>
        </tr>
    }

    function handleCheckBoxChange(poNo, value, poItemId) {
        console.log(poNo, value, poItemId, "addItems")
        if (value) {
            addItem(poNo, poItemId)
        } else {
            removeItem(poNo, poItemId)
        }
    }



    function handleSelectAllChange(value) {
        if (value) {
            localItems.forEach(item => addItem(item.poNo, item.poItemId))
        } else {
            localItems.forEach(item => removeItem(item.poNo, item.poItemId))
        }
    }

    function getSelectAll() {
        return localItems.every(item => isItemInPoAdded(item.poNo, item.poItemId))
    }

    function changeSearchFilter(field, value, objTransformFunc) {
        setSearchFilter(prev => {
            if (!value) {
                return prev.filter(item => item.fieldName !== field)
            }
            let newSearchFilter = [...prev]
            let index = newSearchFilter.findIndex(item => item.fieldName === field)
            if (index !== -1) {
                newSearchFilter[index]["searchValue"] = value
            } else {
                newSearchFilter.push({ fieldName: field, searchValue: value, objTransformFunc });
            }
            return newSearchFilter
        })
    }

    let count = 1;
    return (
        <div className=' bg-gray-200'>
            <table className='w-full text-xs border border-gray-500'>
                <thead className='bg-blue-200 top-0 border border-gray-500'>
                    <tr >
                        <th className='w-8 p-5 '>
                        </th>
                        <th className='text-center w-8 table-data'>
                            S.no
                        </th>
                        <th className='text-center w-24 table-data'>
                            Po.no
                        </th>
                        <th className='text-center w-24 table-data'>
                            Po.Date
                        </th>
                        <th className='text-center w-24 table-data'>
                            Due Date
                        </th>
                        <th className='w-24 table-data'>
                            Fabric Name
                        </th>
                        <th className='w-16 table-data'>
                            Color
                        </th>
                        <th className='w-16 table-data'>
                            Design
                        </th>
                        <th className='w-12 table-data'>
                            Gauge
                        </th>
                        <th className='w-12 table-data'>
                            LL
                        </th>
                        <th className='w-12 table-data'>
                            Gsm
                        </th>
                        <th className='w-12 table-data'>
                            K-Dia
                        </th>
                        <th className='w-12 table-data'>
                            F-Dia
                        </th>
                        <th className='w-12 table-data'>
                            Uom
                        </th>
                        <th className='w-12 table-data'>
                            Po qty
                        </th>
                        <th className='table-data'>
                            Already Inwarded qty
                        </th>
                        <th className='table-data'>
                            Balance qty
                        </th>
                        <th className='w-12 table-data'>
                            Price
                        </th>
                        <th className='w-16 table-data'>
                            Gross
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='sticky top-20 bg-blue-200 border border-gray-500 '>
                        <td className='text-center  table-data '>
                            <input type="checkbox" onChange={(e) => handleSelectAllChange(e.target.checked)}
                                checked={getSelectAll()}
                            />
                        </td>
                        <td className='text-left px-1 table-data '>
                        </td>
                        <td className='text-left px-2  table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("poNo", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-left px-1  table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("poDate", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-left px-1  table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("dueDate", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-left px-1  table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("fabricId", e.target.value, (item) => findFromList(item, fabricList.data, "aliasName"))
                                }} />
                        </td>
                        <td className='text-left px-1  table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("colorId", e.target.value, (item) => findFromList(item, colorList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-left px-1 table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("designId", e.target.value, (item) => findFromList(item, designList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-right px-1 table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("gaugeId", e.target.value, (item) => findFromList(item, gaugeList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-right px-1 table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("loopLengthId", e.target.value, (item) => findFromList(item, loopList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-right px-1 table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("gsmId", e.target.value, (item) => findFromList(item, gsmList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-right px-1 table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("kDiaId", e.target.value, (item) => findFromList(item, diaList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-right px-1 table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("fDiaId", e.target.value, (item) => findFromList(item, diaList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-left px-1 table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("uomId", e.target.value, (item) => findFromList(item, uomList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center table-data '>
                        </td>
                        <td className='text-center table-data '>
                        </td>
                        <td className='text-center table-data '>
                        </td>
                        <td className='text-center table-data '>
                        </td>
                        <td className='text-center table-data '>
                        </td>
                    </tr>
                    {localItems.map((item, index) =>
                        <tr key={index} className='py-2 table-row'>
                            <td className='text-center p-1 table-data '>
                                <input type="checkbox" className='table-data-input' onChange={(e) =>
                                    handleCheckBoxChange(item.poNo, !isItemInPoAdded(item.poNo, item.poItemId), item.poItemId)}
                                    checked={isItemInPoAdded(item.poNo, item.poItemId)} />
                            </td>
                            <td className='text-left px-1  table-data '>
                                {count++}
                            </td>
                            <td className='text-left px-1 table-data'>
                                {item.poDocId}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {getDateFromDateTime(item.poDate)}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {getDateFromDateTime(item.dueDate)}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {findFromList(item.fabricId, fabricList.data, "aliasName")}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {findFromList(item.colorId, colorList.data, "name")}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {findFromList(item.designId, designList.data, "name")}
                            </td>
                            <td className='text-right px-1  table-data '>
                                {findFromList(item.gaugeId, gaugeList.data, "name")}
                            </td>
                            <td className='text-right px-1  table-data '>
                                {findFromList(item.loopLengthId, loopList.data, "name")}
                            </td>
                            <td className='text-right px-1  table-data '>
                                {findFromList(item.gsmId, gsmList.data, "name")}
                            </td>
                            <td className='text-right px-1  table-data '>
                                {findFromList(item.kDiaId, diaList.data, "name")}
                            </td>
                            <td className='text-right px-1  table-data '>
                                {findFromList(item.fDiaId, diaList.data, "name")}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {findFromList(item.uomId, uomList.data, "name")}
                            </td>
                            <td className='text-right px-1 table-data '>
                                {substract(item.qty, item.alreadyCancelQty).toFixed(3)}
                            </td>
                            <td className='text-right px-1 table-data '>
                                {(item.alreadyInwardedQty + item.alreadyReturnedQty).toFixed(3)}
                            </td>
                            <td className='text-right px-1 table-data '>
                                {substract(substract(item.qty, item.alreadyCancelQty), (item.alreadyInwardedQty + item.alreadyReturnedQty)).toFixed(3)}
                            </td>
                            <td className='text-right px-1 table-data '>
                                {item.price}
                            </td>
                            <td className='text-right px-1  '>
                                {(parseFloat(item.price) * parseFloat(item.qty)).toFixed(2)}
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>

    )
}

export default FabricPoItemSelection