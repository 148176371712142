import React, { useState } from 'react';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from "react-secure-storage";
import { useGetPartyQuery } from '../../../redux/ErpServices/PartyMasterServices';
import { findFromList } from '../../../Utils/helper';
import YarnPoItemSelection from './YarnPoItemSelection';
import FabricPoItemSelection from './FabricPoItemSelection';
import AccessoryPoItemSelection from './AccessoryPoItemSelection';

const PoItemsSelection = ({ transtype, po, supplierId, setInwardItems, inwardItems, setInwardItemSelection, inwardOrReturn, storeId }) => {
    const [localInwardItems, setLocalInwardItems] = useState(inwardItems);
    let filteredPoList = po.filter(p => {
        return (p.transType === transtype) && (parseInt(p.supplierId) === parseInt(supplierId))
    })

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const { data: supplierList, isLoading: supplierLoading, isFetching: supplierFetching } =
        useGetPartyQuery({ params: { companyId, active: true } });

    if (supplierFetching || supplierLoading) return <Loader />

    function addItem(poNo, poItemId) {
        setLocalInwardItems(localInwardItems => {
            let newItems = structuredClone(localInwardItems);
            let newItem = { poNo, poItemId }
            newItems.push(newItem);
            return newItems
        });
    }
    function removeItem(poNo, poItemId) {
        setLocalInwardItems(localInwardItems => {
            let newItems = structuredClone(localInwardItems);
            newItems = newItems.filter(item => !((parseInt(item.poNo) === parseInt(poNo)) && (item.poItemId === poItemId)))
            return newItems
        });
    }
    function isItemInPoAdded(poNo, poItemId) {
        let item = localInwardItems.find(item => ((parseInt(item.poNo) === parseInt(poNo)) && (item.poItemId === poItemId)))
        if (!item) return false
        return true
    }
    function handleDone() {
        setInwardItems(localInwardItems);
        setInwardItemSelection(false);
    }

    function handleCancel() {
        setLocalInwardItems([]);
        setInwardItemSelection(false);
    }
    return (
        <div className=''>
            <div className='w-[1100px] h-[500px] overflow-auto'>
                <div className='flex justify-between text-center bg-blue-200 rounded-b-md mb-7 sticky top-0 '>
                    <div className='p-2 rounded-lg flex items-center gap-5'>
                        <label className='text-xs font-semibold'>TransType</label>
                        <input className='text-xs h-6 rounded border border-gray-500 bg-white' value={transtype} disabled={true} />
                    </div>
                    <div className='p-2 rounded-lg flex items-center gap-5'>
                        <label className='text-xs font-semibold'>Supplier</label>
                        <input className='text-xs h-6 rounded border border-gray-500 bg-white' value={findFromList(supplierId, supplierList.data, "aliasName")} disabled={true} />
                    </div>
                </div>
                <div>
                    {
                        filteredPoList.length !== 0 ?
                            <>
                                {transtype.includes("Yarn") ?
                                    <YarnPoItemSelection storeId={storeId} inwardOrReturn={inwardOrReturn} transtype={transtype} poItems={filteredPoList} addItem={addItem} removeItem={removeItem} isItemInPoAdded={isItemInPoAdded} />
                                    :
                                    <>
                                        {transtype.includes("Fabric") ?

                                            <FabricPoItemSelection storeId={storeId} inwardOrReturn={inwardOrReturn} transtype={transtype} poItems={filteredPoList} addItem={addItem} removeItem={removeItem} isItemInPoAdded={isItemInPoAdded} />
                                            :
                                            <AccessoryPoItemSelection storeId={storeId} inwardOrReturn={inwardOrReturn} transtype={transtype} poItems={filteredPoList} addItem={addItem} removeItem={removeItem} isItemInPoAdded={isItemInPoAdded} />
                                        }
                                    </>
                                }
                            </>
                            :
                            <div className="text-center">No Items Found</div>
                    }
                </div>
            </div>
            <div className='flex item-end justify-end gap-4 mt-3'>
                <button onClick={handleDone} className='bg-lime-400 hover:bg-lime-600 hover:text-white p-1 px-3 text-sm rounded font-semibold transition'>
                    Done
                </button>
                <button onClick={handleCancel} className='bg-red-400 hover:bg-red-600 hover:text-white p-1 text-sm rounded font-semibold transition'>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default PoItemsSelection