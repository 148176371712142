import React, { useRef, } from 'react'
import { Document, Page, View, PDFViewer, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import Header from '../PrintFormatUtils/Header';
import { getCommonParams, getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useGetBranchByIdQuery } from '../../../redux/services/BranchMasterService';
import { useGetFinishedGoodsSalesByIdQuery } from '../../../redux/ErpServices/finishedGoodsSalesServices';
import TaxSplitup from '../PrintFormatUtils/TaxSplitup';
import { useGetCompanyByIdQuery } from '../../../redux/services/CompanyMasterService';
import BankDetails from '../PrintFormatUtils/BankDetails';
import Signature from '../PrintFormatUtils/Signature';
import * as numToText from 'num-to-text';
import QRCode from "qrcode";
import { RAIN_DOT_WEBSITE_LINK } from '../../../Constants';
import useTaxDetailsWithTaxSplitUp from '../../../CustomHooks/TaxHookDetails/useTaxDetailsWithTaxSplitUp';
import PageNumberFooter from '../PrintFormatUtils/PageNumberFooter';


const PrintFormatWholeSale = ({ id, isSupplierOutside }) => {
    const qrCode = useRef()
    QRCode.toDataURL(RAIN_DOT_WEBSITE_LINK, function (err, url) {
        qrCode.current = url;
    })
    const row = [5, 20, 10, 10, 15, 5, 10, 10, 10, 10];

    const { branchId, companyId } = getCommonParams();
    const { data: branchData } = useGetBranchByIdQuery(branchId, { skip: !branchId });

    const { data: companyData } = useGetCompanyByIdQuery(companyId, { skip: !companyId })

    const { data } = useGetFinishedGoodsSalesByIdQuery({ id }, { skip: !id });

    const nonGridData = data?.data;

    const to = nonGridData?.Supplier;

    const docId = nonGridData?.docId;
    const docDate = getDateFromDateTimeToDisplay(nonGridData?.createdAt);

    const gridDetails = nonGridData?.FinishedGoodsSalesDetails || [];
    // let gridDetails = [];
    // if ((nonGridData?.FinishedGoodsSalesDetails || []).length !== 0) {
    //     gridDetails = Array.from({ length: 10 }).map(_ => nonGridData?.FinishedGoodsSalesDetails[0] || []);
    // }

    const { taxGridData, igstAmount, sgstAmount, cgstAmount, netAmount, grossAmount, roundOffAmount } = useTaxDetailsWithTaxSplitUp({ taxTemplateId: nonGridData?.taxTemplateId, items: gridDetails, discountType: nonGridData?.discountType, discountValue: nonGridData?.discountValue, isSupplierOutside })
    const pagesName = ["Original", "Duplicate", "Triplicate"]

    return (
        <>
            <PDFViewer style={tw("h-full w-full border ")} key={data} >
                <Document width={500} height={300} style={tw("w-full")} >
                    {pagesName.map(page =>
                        <Page key={page} size="A4" style={{ fontFamily: "Times-Roman", border: 1, ...tw("relative p-2 pb-[150px] flex flex-col") }}>
                            <View fixed>
                                <Header invoiceType={nonGridData?.invoiceType} page={page} poNo={nonGridData?.poNo} eWayBillNo={nonGridData?.eWayBillNo} qrCode={qrCode.current} branchData={branchData} to={to} docId={docId} docDate={docDate} />
                            </View>
                            <View style={tw("text-xs border-b border-gray-500 flex flex-col w-full")}>
                                <View style={{ ...tw("w-full flex flex-row border-b border-gray-500 border-l text-center items-center"), fontFamily: "Times-Bold" }}>
                                    <Text style={tw(`w-[${row[0]}%] p-1 border-r border-gray-500`)}>
                                        S.no
                                    </Text>
                                    <Text style={tw(`w-[${row[1]}%] p-1 border-r border-gray-500`)}>
                                        Description
                                    </Text>
                                    <Text style={tw(`w-[${row[2]}%] p-1 border-r border-gray-500`)}>
                                        Style
                                    </Text>
                                    <Text style={tw(`w-[${row[3]}%] p-1 border-r border-gray-500`)}>
                                        Hsn
                                    </Text>
                                    <Text style={tw(`w-[${row[4]}%] p-1 border-r border-gray-500`)}>
                                        Color
                                    </Text>
                                    <Text style={tw(`w-[${row[5]}%] p-1 border-r border-gray-500`)}>
                                        Size
                                    </Text>
                                    <Text style={tw(`w-[${row[6]}%] p-1 border-r border-gray-500`)}>
                                        Uom
                                    </Text>
                                    <Text style={{ ...tw(`w-[${row[7]}%] border-r text-center p-1 border-gray-500`) }}>
                                        Qty
                                    </Text>
                                    <Text style={tw(`w-[${row[8]}%] border-r text-center p-1 border-gray-500`)}>
                                        Price
                                    </Text>
                                    <Text style={tw(`w-[${row[9]}%] border-r text-center p-1 border-gray-500`)}>
                                        Amount
                                    </Text>
                                </View>
                                {
                                    gridDetails.map((item, index) => (
                                        <View key={index} style={tw(`w-full flex flex-row border-l border-gray-500 h-[20px] ${(index + 1) === gridDetails.length ? "border-b" : ""}`)} wrap={false}>
                                            <Text style={tw(`w-[${row[0]}%] p-1 border-r border-gray-500 text-center`)}>
                                                {index + 1}
                                            </Text>
                                            <Text style={tw(`w-[${row[1]}%] p-1 border-r border-gray-500`)}>
                                                {item?.Style?.material}
                                            </Text>
                                            <Text style={tw(`w-[${row[2]}%] p-1 border-r border-gray-500`)}>
                                                {item?.Style?.sku}
                                            </Text>
                                            <Text style={tw(`w-[${row[3]}%] p-1 border-r text-center border-gray-500`)}>
                                                {item?.Style?.hsn}
                                            </Text>
                                            <Text style={tw(`w-[${row[4]}%] p-1 border-r border-gray-500`)}>
                                                {item?.Color?.name}
                                            </Text>
                                            <Text style={tw(`w-[${row[5]}%] p-1 border-r border-gray-500 text-center`)}>
                                                {item?.Size?.name}
                                            </Text>
                                            <Text style={tw(`w-[${row[6]}%] p-1 border-gray-500 text-center`)}>
                                                {item?.Uom?.name}
                                            </Text>
                                            <Text style={{ ...tw(`w-[${row[7]}%] border-l border-r text-center p-1 border-gray-500`) }}>
                                                {parseFloat(item?.qty || 0)}
                                            </Text>
                                            <Text style={tw(`w-[${row[8]}%] border-r text-right p-1 border-gray-500`)}>
                                                {parseFloat(item?.price).toFixed(2)}
                                            </Text>
                                            <Text style={tw(`w-[${row[9]}%] border-r text-right p-1 border-gray-500`)}>
                                                {(parseFloat(item?.qty) * parseFloat(item?.price)).toFixed(2)}
                                            </Text>
                                        </View>
                                    ))
                                }
                                {Array.from({ length: 16 - (gridDetails.length) }).map((_, i) =>
                                    <View key={i} style={tw('w-full flex flex-row border-l border-gray-500 h-[20px]')} wrap={false}>
                                        <Text style={tw(`w-[${row[0]}%] p-1 border-r border-gray-500 text-center`)}>
                                        </Text>
                                        <Text style={tw(`w-[${row[1]}%] p-1 border-r border-gray-500`)}>
                                        </Text>
                                        <Text style={tw(`w-[${row[2]}%] p-1 border-r border-gray-500`)}>
                                        </Text>
                                        <Text style={tw(`w-[${row[3]}%] p-1 border-r border-gray-500`)}>
                                        </Text>
                                        <Text style={tw(`w-[${row[4]}%] p-1 border-r border-gray-500`)}>
                                        </Text>
                                        <Text style={tw(`w-[${row[5]}%] p-1 border-r border-gray-500 text-center`)}>
                                        </Text>
                                        <Text style={tw(`w-[${row[6]}%] p-1 border-gray-500 text-center`)}>
                                        </Text>
                                        <Text style={{ ...tw(`w-[${row[7]}%] border-l border-r text-center p-1 border-gray-500`) }}>
                                        </Text>
                                        <Text style={tw(`w-[${row[8]}%] border-r text-right p-1 border-gray-500`)}>
                                        </Text>
                                        <Text style={tw(`w-[${row[9]}%] border-r text-right p-1 border-gray-500`)}>
                                        </Text>
                                    </View>
                                )}
                                <TaxSplitup taxGridData={taxGridData} row={row} numberInWords={netAmount ? numToText(netAmount || 0, 'in') : ""} grossAmount={grossAmount} roundOffAmount={roundOffAmount} isSupplierOutside={isSupplierOutside} sgstAmount={sgstAmount} cgstAmount={cgstAmount} igstAmount={igstAmount} netAmount={netAmount} />
                            </View>
                            <View style={tw("flex-1 ")}></View>
                            <View fixed style={tw("absolute left-2 bottom-5 w-full flex items-center text-sm w-full")}>
                                <View style={tw("w-full")} render={({ subPageNumber, subPageTotalPages }) => (subPageNumber === subPageTotalPages) ?
                                    <View style={tw("w-full border-x border-gray-500 ")}>
                                        <BankDetails bankData={companyData?.data} />
                                        <Signature />
                                    </View>
                                    : <>
                                    </>} />
                                <PageNumberFooter />
                            </View>
                        </Page>
                    )}
                </Document>
            </PDFViewer>
        </>
    )
}

export default PrintFormatWholeSale
