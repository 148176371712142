import React from 'react'

const Address = ({ supplierDetails, branchData }) => {
  if (!supplierDetails) return null
  return (
    <div>
      <div className=''>
        <table className="text-xs  w-full p-2 table-auto border border-gray-500">
          <thead className='text-left p-1 ml-1'>
            <tr className=''>
              <th className=' font-bold w-1/2 table-data text-sm'>FROM : </th>
              <th className=' font-bold text-sm '>TO :</th>
            </tr>
          </thead>
          <tbody>
            <tr className=' text-xs'>
              <td className='p-1 table-data'>
                <h2 className='font-bold text-sm text-green-700'>{branchData?.branchName}</h2>
                <div> {branchData?.address} </div>
                <div className=''>Mobile NO :{branchData?.contactMobile}</div>
                <div className=''>PAN NO :{branchData?.panNo}</div>
                <div className=''>GST NO :{branchData?.gstNo}</div>
                <div className=''> EMAIL :{branchData?.email}</div>
              </td >
              <div className='mt-0'>
                <td className=''>
                  <h2 className='font-bold text-sm text-green-700'>{supplierDetails?.name}</h2>
                  <div>{supplierDetails?.address}</div>
                  <div>Mobile NO  :{supplierDetails?.contactMobile}</div>
                  {supplierDetails?.panNo &&
                    <div className=''>PAN NO :{supplierDetails?.panNo}</div>
                  }
                  {supplierDetails?.gstNo &&
                    <div className=''>GST No :{supplierDetails?.gstNo}</div>
                  }
                  {supplierDetails?.email &&
                    <div className=''>EMAIL :{supplierDetails?.email}</div>
                  }
                </td>

              </div>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  )
}


export default Address