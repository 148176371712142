import React from 'react'
import Fabric from './Fabric'
import Yarn from './Yarn'

const Program = ({ finishedGoodsType, programDetails, setProgramDetails, setCurrentProgramIndex, readOnly, inwardDetails, setInwardDetails }) => {
    return (
        <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border 
        border-gray-600 overflow-auto h-[200px] p-0.5'>
            <legend className='sub-heading'>Program Details</legend>
            {finishedGoodsType.includes("F")
                ?
                <Fabric inwardDetails={inwardDetails} setInwardDetails={setInwardDetails} readOnly={readOnly} finishedGoodsType={finishedGoodsType} programDetails={programDetails} setProgramDetails={setProgramDetails} setCurrentProgramIndex={setCurrentProgramIndex} />
                :
                <>
                    {finishedGoodsType.includes("Y")
                        ?
                        <Yarn inwardDetails={inwardDetails} setInwardDetails={setInwardDetails} readOnly={readOnly} finishedGoodsType={finishedGoodsType} programDetails={programDetails} setProgramDetails={setProgramDetails} setCurrentProgramIndex={setCurrentProgramIndex} />
                        :
                        <></>
                    }
                </>
            }
        </fieldset>
    )
}

export default Program