import React from 'react';
import { useGetYarnMasterQuery } from '../../../redux/ErpServices/YarnMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getCommonParams, priceWithTax } from '../../../Utils/helper';
import { toast } from 'react-toastify';
import { DELETE, PLUS } from '../../../icons';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import { useGetStockQuery } from '../../../redux/ErpServices/StockServices';
import { getYarnStockItem } from '../../../Utils/stockHelper';

const Yarn = ({ rawMaterials, setRawMaterials, readOnly, setFillGrid, removeItem, storeId, id, itemType }) => {
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(rawMaterials);
        newBlend[index][field] = value;
        if (field !== "qty") {
            let noOfBags = newBlend[index]?.noOfBags ? parseFloat(newBlend[index]["noOfBags"]) : 0;
            let weightPerBag = newBlend[index]?.weightPerBag ? parseFloat(newBlend[index]["weightPerBag"]) : 0;
            newBlend[index]["qty"] = (noOfBags * weightPerBag).toFixed(3)
        }
        setRawMaterials(newBlend);
    };

    const { data: yarnList } =
        useGetYarnMasterQuery({ params });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { ...params, active: true } });
    const { data: processList, isLoading: isProcessLoading, isFetching: isProcessFetching } = useGetProcessQuery({ params });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });

    function getTotals(field) {
        const total = rawMaterials.reduce((accumulator, current) => {
            return accumulator + parseFloat(current[field] ? current[field] : 0)
        }, 0)
        return parseFloat(total)
    }
    const { branchId } = getCommonParams()
    const { data: stockData
    } = useGetStockQuery({
        params: {
            branchId, itemType, isGetStock: true,
            storeId, rawMaterialSalesId: id
        }
    });
    let stockList = stockData?.data ? stockData?.data : []

    if (!yarnList || !colorList || !uomList || !rawMaterials || isProcessFetching || isProcessLoading || isColorFetching || isColorLoading) return <Loader />
    return (
        <>
            <div className={`relative w-full overflow-y-auto`}>
                <table className=" text-xs table-fixed  w-full">
                    <thead className='bg-blue-200 top-0'>
                        <tr className=''>
                            <th className=' table-data  w-7'>
                                S.no
                            </th>
                            <th className='table-data w-32'>
                                Yarn Name
                            </th>
                            <th className='table-data w-20'>
                                Color
                            </th>
                            <th className='table-data w-20'>
                                Uom
                            </th>
                            <th className='table-data  w-16'>
                                Lot No.
                            </th>
                            <th className='table-data  w-16'>
                                Prev. Process
                            </th>
                            <th className='table-data w-20'>
                                Stock bags
                            </th>
                            <th className='table-data w-20'>
                                Stock qty
                            </th>
                            <th className='table-data w-20'>
                                Weight Per Bag
                            </th>
                            <th className='table-data w-20'>
                                No. of bags
                            </th>
                            <th className="table-data  w-20">
                                Issue qty
                            </th>
                            <th className="table-data  w-20">
                                Price
                            </th>
                            <th className="table-data  w-20">
                                Tax
                            </th>
                            <th className="table-data  w-20">
                                Price with Tax
                            </th>
                            {!readOnly &&
                                <th className='table-data w-10'>
                                    <div onClick={() => setFillGrid(true)}
                                        className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                                        {PLUS}
                                    </div>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {rawMaterials.map((item, index) => (
                            <tr key={index} className="w-full table-item">
                                <td className="table-data  w-2  py-1 text-center">
                                    {index + 1}
                                </td>
                                <td className='table-data '>
                                    {findFromList(item.yarnId, yarnList.data, "aliasName")}
                                </td>
                                <td className=' table-data '>
                                    {findFromList(item.colorId, colorList.data, "name")}
                                </td>
                                <td className=' table-data '>
                                    {findFromList(item.uomId, uomList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {item.lotNo}
                                </td>
                                <td className=' px-1 table-data  '>
                                    {findFromList(item.processId, processList.data, "name")}
                                </td>
                                <td className=' text-right'>
                                    {getYarnStockItem(item, "noOfBags", stockList)}
                                </td>
                                <td className=' text-right'>
                                    {getYarnStockItem(item, "qty", stockList)}
                                </td>
                                <td className='table-data'>
                                    <input
                                        min={"0"}
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "weightPerBag") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-full px-1 table-data-input"
                                        value={(!item.weightPerBag) ? 0 : item.weightPerBag}
                                        disabled={readOnly}
                                        inputMode='decimal'
                                        onChange={(e) => {
                                            let noOfBags = item?.noOfBags ? parseFloat(item["noOfBags"]) : 0;
                                            let weightPerBag = e.target.value ? parseFloat(e.target.value) : 0
                                            let tempQty = (noOfBags * weightPerBag)
                                            if (parseFloat(tempQty) > parseFloat(item.stockQty)) {
                                                toast.info("Issue Qty Cannot be more than Stock Qty", { position: 'top-center' })
                                                return
                                            }
                                            handleInputChange(e.target.value.replace(/^0+/, ''), index, "weightPerBag")
                                        }
                                        }
                                        onBlur={(e) =>
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "weightPerBag")
                                        }
                                    />
                                </td>
                                <td className='table-data '>
                                    <input
                                        min={"0"}
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0", index, "noOfBags") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-full px-1 table-data-input"
                                        value={(!item.noOfBags) ? 0 : item.noOfBags}
                                        disabled={readOnly}
                                        inputMode='decimal'
                                        onChange={(e) => {
                                            if (parseFloat(e.target.value) > parseFloat(item.stockBags)) {
                                                toast.info("Issue Bags Cannot be more than Stock Bags", { position: 'top-center' })
                                                return
                                            }
                                            let noOfBags = e.target.value ? parseFloat(e.target.value) : 0
                                            let weightPerBag = item?.weightPerBag ? parseFloat(item["weightPerBag"]) : 0;
                                            let tempQty = (noOfBags * weightPerBag).toFixed(3)
                                            if (parseFloat(tempQty) > parseFloat(item.stockQty)) {
                                                toast.info("Issue Qty Cannot be more than Stock Qty", { position: 'top-center' })
                                                return
                                            }
                                            handleInputChange(e.target.value.replace(/^0+/, ''), index, "noOfBags")
                                        }
                                        }
                                        onBlur={(e) =>
                                            handleInputChange(parseFloat(e.target.value), index, "noOfBags")
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        min={"0"}
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "qty") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 px-1 w-full table-data-input"
                                        value={(!item.qty) ? 0 : item.qty}
                                        disabled={true}
                                        onChange={(e) => {
                                            if (parseFloat(e.target.value) > parseFloat(item.stockQty)) {
                                                toast.info("Issue Qty Cannot be more than Stock Qty", { position: 'top-center' })
                                                return
                                            }
                                            handleInputChange(e.target.value, index, "qty")
                                        }
                                        }
                                        onBlur={(e) =>
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        onKeyDown={e => {
                                            if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                            if (e.key === "Delete") { handleInputChange("0.00", index, "price") }
                                        }}
                                        min={"0"}
                                        type="number"
                                        className="text-right rounded py-1 w-16 px-1 table-data-input"
                                        onFocus={(e) => e.target.select()}
                                        value={(!item.price) ? 0 : item.price}
                                        autoFocus={index === 0}
                                        disabled={readOnly || Boolean(item?.alreadyInwardedData?._sum?.qty) || Boolean(item?.alreadyCancelData?._sum?.qty) || Boolean(item?.alreadyCancelData?._sum?.qty)}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "price")
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "price");
                                        }}
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        onKeyDown={e => {
                                            if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                            if (e.key === "Delete") { handleInputChange("0.00", index, "taxPercent") }
                                        }}
                                        min={"0"}
                                        type="number"
                                        className="text-right rounded py-1 w-16 px-1 table-data-input"
                                        onFocus={(e) => e.target.select()}
                                        value={(!item.taxPercent) ? 0 : item.taxPercent}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "taxPercent")
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "taxPercent");
                                        }
                                        }
                                    />
                                </td>
                                <td className='table-data text-right px-1'>
                                    {priceWithTax(item.price, item.taxPercent).toFixed(2)}
                                </td>
                                {!readOnly
                                    &&
                                    <td className=' w-full'>
                                        <div tabIndex={-1} onClick={() => removeItem(item)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                            {DELETE}
                                        </div>
                                    </td>
                                }
                            </tr>
                        ))}
                        {Array.from({ length: 8 - rawMaterials.length }).map(i =>
                            <tr className='w-full font-bold h-6 border-gray-400 border'>
                                <td className='table-data'>
                                </td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                {!readOnly
                                    &&
                                    <td className="table-data"></td>
                                }
                            </tr>)
                        }
                        <tr className='bg-blue-200 w-full font-bold'>
                            <td className="table-data   w-full font-bold text-center" colSpan={12}>Total</td>
                            <td className="table-data    text-right">{getTotals("noOfBags")}</td>
                            <td className="table-data    text-right">{getTotals("qty").toFixed(3)}</td>
                            {!readOnly
                                &&
                                <td className="table-data   "></td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Yarn