import React from 'react'
import {
    View, Text
} from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';


const TaxSplitup = ({ sgstAmount, cgstAmount, igstAmount, grossAmount, netAmount, roundOffAmount, numberInWords, row, taxGridData, isSupplierOutside }) => {
    const taxDetails = [
        { name: "Sub Total", amount: (grossAmount || 0).toFixed(2), bold: true },
    ]
    for (let index = 0; index < taxGridData.length; index++) {
        const taxItem = taxGridData[index];
        if (isSupplierOutside) {
            taxDetails.push(...[
                { name: "IGST", value: (taxItem?.taxPercent || 0), amount: (taxItem?.igstAmount || 0).toFixed(2) },
            ]);
        } else {
            taxDetails.push(...[
                { name: "SGST", value: (taxItem?.taxPercent || 0) / 2, amount: (taxItem?.sgstAmount || 0).toFixed(2) },
                { name: "CGST", value: (taxItem?.taxPercent || 0) / 2, amount: (taxItem?.cgstAmount || 0).toFixed(2) },
            ]);
        }
    }
    taxDetails.push(...[{ name: "Round Off", amount: (roundOffAmount || 0).toFixed(2) },
    { name: "Total", amount: (netAmount || 0).toFixed(2), bold: true, total: true }])
    return (
        <View style={tw("text-right ")} wrap={false} >
            {taxDetails.map((tax, index) =>
                <View key={index} style={tw(`w-full flex flex-row border-l border-gray-500 h-[20px] ${tax.total ? "border-y" : ""} `)} wrap={false}>
                    {/* <Text style={tw(`w-[${row[0]}%] p-1 border-r border-gray-500 text-center`)}>
                    </Text>
                    <Text style={tw(`w-[${row[1]}%] p-1 border-r border-gray-500`)}>
                    </Text>
                    <Text style={tw(`w-[${row[2]}%] p-1 border-r border-gray-500`)}>
                    </Text>
                    <Text style={tw(`w-[${row[3]}%] p-1 border-r border-gray-500`)}>
                    </Text>
                    <Text style={tw(`w-[${row[4]}%] p-1 border-r border-gray-500 text-center`)}>
                    </Text>
                    <Text style={{ ...tw(`w-[${row[5]}%] p-1 ${tax.total ? "" : ""}`), fontFamily: tax.bold ? "Times-Bold" : undefined }}>
                        {tax.name}
                    </Text>
                    <Text style={{ ...tw(`w-[${row[6]}%] border-l border-r text-center p-1 border-gray-500`) }}>
                    </Text>
                    <Text style={tw(`w-[${row[7]}%] border-r text-right p-1 border-gray-500`)}>
                    </Text>
                    <Text style={tw(`w-[${row[8]}%] border-r text-right p-1 border-gray-500`)}>
                        {tax.amount}
                    </Text> */}
                    {row.map((_, index) =>
                        <Text key={index} style={{ ...tw(`w-[${row[index]}%]  border-r border-gray-500 p-1`), fontFamily: (index !== row.length - 1) && (tax.bold) ? "Times-Bold" : undefined }}>
                            {(index === (row.length - 4)) ? tax?.name : ""}
                            {(index === (row.length - 1)) ? tax?.amount : ""}
                            {(index === (row.length - 2)) ? tax?.value : ""}
                        </Text>
                    )}
                </View>
            )}
            <View style={{ ...tw(" border-x border-gray-500 text-right flex flex-row gap-4 p-1 h-[20px]"), fontFamily: "Times-Bold" }}>
                <Text>
                    Rupees :
                </Text>
                <Text style={{ fontFamily: "Times-Bold" }}>
                    {numberInWords} Only
                </Text>
            </View>
        </View>
    )
}

export default TaxSplitup