import React from 'react'
import { DropdownInput } from '../../Inputs'
import { dropDownListObject } from '../../Utils/contructObject'
import { useGetInterOfficeStockTransferFinishedGoodsQuery } from '../../redux/ErpServices/InterOfficeTransferFinishedGoodsServices'
import { getCommonParams } from '../../Utils/helper'

const StockTransferDeliverySelection = ({ prevDeliveryId, setPrevDeliveryId, readOnly, deliveryFromId }) => {
    const { branchId } = getCommonParams()
    const { data } = useGetInterOfficeStockTransferFinishedGoodsQuery({ params: { deliveryId: branchId, delOrRec: "DELIVERY", deliveryFromId, balQtyFilterForDelivery: true } }, { skip: !deliveryFromId })
    let deliveryItems = data?.data ? data?.data : []
    return (
        <DropdownInput name="Delivery No." options={dropDownListObject(deliveryItems, "docId", "id")} value={prevDeliveryId} setValue={setPrevDeliveryId}
            required={true} readOnly={readOnly} />
    )
}

export default StockTransferDeliverySelection
