import React, { useState } from 'react'
import Yarn from './Yarn'
import StockSelectionFillGrid from './StockSelectionFillGrid';
import Modal from '../../../UiComponents/Modal';
import Fabric from './Fabric';

const RawMaterial = ({ storeId, rawMaterialType, rawMaterials, setRawMaterials, readOnly, id }) => {
  const [fillGrid, setFillGrid] = useState(false);

  function removeFabricItem(removeItem) {
    setRawMaterials(localInwardItems => {
      return localInwardItems.filter(item =>
        !(removeItem.fabricId === item.fabricId
          &&
          removeItem.designId === item.designId
          &&
          removeItem.gaugeId === item.gaugeId
          &&
          removeItem.loopLengthId === item.loopLengthId
          &&
          removeItem.gsmId === item.gsmId
          &&
          removeItem.kDiaId === item.kDiaId
          &&
          removeItem.fDiaId === item.fDiaId
          &&
          removeItem.colorId === item.colorId
          &&
          removeItem.uomId === item.uomId
          &&
          removeItem.lotNo === item.lotNo
          &&
          removeItem.processId === item.processId
          &&
          removeItem.storeId === item.storeId
        )
      )
    });
  }

  function removeYarnItem(removeItem) {
    setRawMaterials(localInwardItems => {
      return localInwardItems.filter(item =>
        !(removeItem.yarnId === item.yarnId
          &&
          removeItem.colorId === item.colorId
          &&
          removeItem.uomId === item.uomId
          &&
          removeItem.lotNo === item.lotNo
          &&
          removeItem.processId === item.processId
          &&
          removeItem.storeId === item.storeId
        )
      )
    });
  }

  const getIssuedPropertyYarn = (stockItem, property) => {
    return 0
    // const issueDetails = rawMaterials.map(item => item.rawMaterials).flat()
    // const stockItemIssuedDetails = issueDetails.filter(item =>
    //   !(
    //     stockItem.yarnId === item.yarnId
    //     &&
    //     stockItem.colorId === item.colorId
    //     &&
    //     stockItem.uomId === item.uomId
    //     &&
    //     stockItem.lotNo === item.lotNo
    //     &&
    //     stockItem.processId === item.processId
    //     &&
    //     stockItem.storeId === item.storeId
    //   )
    // )
    // const totalQty = stockItemIssuedDetails.reduce((accumulation, currentValue) => {
    //   return (parseFloat(accumulation) + parseFloat(currentValue[property] ? currentValue[property] : 0))
    // }, 0)
    // return totalQty
  }

  const getIssuedPropertyFabric = (stockItem, property) => {
    const issueDetails = rawMaterials.map(item => item.rawMaterials).flat()
    const stockItemIssuedDetails = issueDetails.filter(item =>
      !(
        stockItem.fabricId === item.fabricId
        &&
        stockItem.designId === item.designId
        &&
        stockItem.gaugeId === item.gaugeId
        &&
        stockItem.loopLengthId === item.loopLengthId
        &&
        stockItem.gsmId === item.gsmId
        &&
        stockItem.kDiaId === item.kDiaId
        &&
        stockItem.fDiaId === item.fDiaId
        &&
        stockItem.colorId === item.colorId
        &&
        stockItem.uomId === item.uomId
        &&
        stockItem.lotNo === item.lotNo
        &&
        stockItem.processId === item.processId
        &&
        stockItem.storeId === item.storeId
      )
    )
    const totalQty = stockItemIssuedDetails.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue[property] ? currentValue[property] : 0))
    }, 0)
    return totalQty
  }

  return (
    <fieldset className={`frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 overflow-auto ${rawMaterialType ? "" : "hidden"}`}>
      <legend className='sub-heading'>Delivery from stock</legend>
      <Modal isOpen={fillGrid} onClose={() => { setFillGrid(false) }} widthClass={"bg-gray-300"}>
        <StockSelectionFillGrid id={id}
          storeId={storeId} getIssuedPropertyYarn={getIssuedPropertyYarn} getIssuedPropertyFabric={getIssuedPropertyFabric} rawMaterialType={rawMaterialType} rawMaterials={rawMaterials} setRawMaterials={setRawMaterials} setFillGrid={setFillGrid} />
      </Modal>
      <div>
        {rawMaterialType.includes("Yarn")
          ?
          <Yarn itemType={rawMaterialType} id={id} storeId={storeId} readOnly={readOnly} removeItem={removeYarnItem}
            setFillGrid={setFillGrid} getIssuedProperty={getIssuedPropertyYarn} rawMaterials={rawMaterials} setRawMaterials={setRawMaterials} />
          :
          <>
            {rawMaterialType.includes("Fabric") ?
              <Fabric id={id} storeId={storeId} itemType={rawMaterialType} readOnly={readOnly} removeItem={removeFabricItem} setFillGrid={setFillGrid}
                getIssuedProperty={getIssuedPropertyFabric} rawMaterials={rawMaterials} setRawMaterials={setRawMaterials} />
              :
              <></>
            }
          </>
        }
      </div>
    </fieldset>
  )
}

export default RawMaterial