import React from 'react'
import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { useGetLoopLengthQuery } from '../../../redux/ErpServices/LoopLengthMasterServices';
import { Loader } from '../../../Basic/components';
import { findFromList } from '../../../Utils/helper';
import secureLocalStorage from 'react-secure-storage';



const params = {
  companyId: secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  ),
};
const YarnProgram = ({ fabricTotal, programDetails }) => {
  const { data: fabricList } =
    useGetFabricMasterQuery({ params });

  const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
    useGetColorMasterQuery({ params });

  const { data: uomList } =
    useGetUnitOfMeasurementMasterQuery({ params });

  const { data: gaugeList } =
    useGetGaugeQuery({ params });

  const { data: designList } =
    useGetdesignQuery({ params });

  const { data: gsmList } =
    useGetgsmQuery({ params });

  const { data: loopLengthList } =
    useGetLoopLengthQuery({ params });

  const { data: diaList } =
    useGetDiaQuery({ params });
  if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList) return <Loader />
  return (
    <div>
      <div className='w-full text-sm font-bold mt-3'>
        <h1>
          YARN PROGRAM
        </h1>
      </div>
      <table className="  border border-gray-500 table-auto w-full">
        <thead className=' border border-gray-500 top-0'>
          <tr className='h-3'>
            <th className="table-data  w-16 text-center">S.no</th>
            <th className="table-data text-center">YARN DESCRIPTION</th>
            <th className="table-data ">COLOR</th>
            <th className="table-data ">UOM</th>
            <th className="table-data  ">NO.OF.BAGS</th>
            <th className="table-data  w-16">QTY</th>
            <th className="table-data  ">PROS.COST</th>
          </tr>
        </thead>
        <tbody>
          {programDetails.map((value, index) => (
            <tr className='border border-gray-500' key={index}>
              <td className='table-data text-center px-1 h-3 '>{index + 1} </td>
              <td className='table-data  px-1 h-3 '>{value.Yarn?.aliasName} </td>
              <td className='table-data  px-1 h-3 '>{value.Color?.name} </td>
              <td className='table-data text-right px-1 h-3 '>{value.Uom?.name} </td>
              <td className='table-data text-right px-1 h-3 '>{value.noOfBags} </td>
              <td className='table-data text-right px-1 h-3 '> {value.qty}</td>
              <td className='table-data text-right px-1 h-3 '> {value.processCost}</td>
            </tr>
          ))}
          <tr>
            <td className='table-data text-center  px-1 h-3' colSpan={6}>TOTAL</td>
            <td className=' px-1 h-3 text-right'>{programDetails.reduce((a, c) => a + parseFloat(c.qty), 0).toFixed(3)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default YarnProgram