import React from 'react'
import { substract } from '../../../Utils/helper'

const CuttingDeliveryItems = ({ cuttingDeliveryItems }) => {
    return (
        <div>
            <table className="table-data border border-gray-500 text-xs table-auto w-full">
                <thead className="bg-blue-200 border border-gray-500 top-0">
                    <tr className="border border-gray-500">
                        <th className="table-data w-2 text-center">S.no</th>
                        <th className="table-data w-20">Prev.Process</th>
                        <th className="table-data w-72">Fabric</th>
                        <th className="table-data w-20">Color</th>
                        <th className="table-data w-20"> Design</th>
                        <th className="table-data w-20">Gsm</th>
                        <th className="table-data w-20">GG</th>
                        <th className="table-data w-20">LL</th>
                        <th className="table-data w-20">K.Dia</th>
                        <th className="table-data w-20">F.Dia</th>
                        <th className="table-data w-20">Uom</th>
                        <th className="table-data w-20">Lot. No</th>
                        <th className="table-data w-20">Issue Roll</th>
                        <th className="table-data w-20">Issue Qty</th>
                        <th className="table-data w-20">A. Used Qty</th>
                        <th className="table-data w-20">Bal. Qty</th>
                    </tr>
                </thead>
                <tbody>
                    {cuttingDeliveryItems?.map((deliveryItem, index) =>
                        <tr className="border border-gray-500" key={deliveryItem?.id} >
                            <td className='table-data '>
                                {index + 1}
                            </td>
                            <td className='text-left px-1 table-data'>{deliveryItem?.Process?.name}</td>
                            <td className='text-left px-1 table-data'>{deliveryItem?.Fabric?.aliasName}</td>
                            <td className='text-left px-1 table-data'>{deliveryItem?.Color?.name}</td>
                            <td className='text-left px-1  table-data'>{deliveryItem?.Design?.name}</td>
                            <td className='text-right px-1  table-data'>{deliveryItem?.Gauge?.name}</td>
                            <td className='text-right px-1  table-data'>{deliveryItem?.LoopLength?.name}</td>
                            <td className='text-left px-1  table-data'>{deliveryItem?.Gsm?.name}</td>
                            <td className='text-right px-1  table-data'>{deliveryItem?.KDia?.name}</td>
                            <td className='text-right px-1  table-data'>{deliveryItem?.FDia?.name}</td>
                            <td className='text-left px-1  table-data'>{deliveryItem?.Uom?.name}</td>
                            <td className='text-left px-1  table-data'>{deliveryItem?.lotNo}</td>
                            <td className='text-right px-1  table-data'>{deliveryItem?.delRolls}</td>
                            <td className='text-right px-1  table-data'>{parseFloat(deliveryItem?.delQty ? deliveryItem?.delQty : 0).toFixed(3)}</td>
                            <td className='text-right px-1  table-data'>{parseFloat(deliveryItem?.alreadyUsedQty ? deliveryItem?.alreadyUsedQty : 0).toFixed(3)}</td>
                            <td className='text-right px-1  table-data'>{
                                parseFloat(substract(deliveryItem?.delQty, deliveryItem?.alreadyUsedQty)).toFixed(3)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default CuttingDeliveryItems
