import React, { useEffect, useState } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetYarnMasterQuery } from '../../../redux/ErpServices/YarnMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { toast } from "react-toastify"
import { Loader } from '../../../Basic/components';
import { VIEW } from '../../../icons';
import TaxDetailsFullTemplate from '../TaxDetailsCompleteTemplate';
import Modal from '../../../UiComponents/Modal';
import secureLocalStorage from "react-secure-storage";
import { priceWithTax } from '../../../Utils/helper';

const YarnDirectItems = ({ id, transType, poItems, setPoItems, readOnly, isSupplierOutside, taxTypeId }) => {
    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const params = {
        companyId
    };
    const [currentSelectedIndex, setCurrentSelectedIndex] = useState("")
    const [greyObj, setGreyObj] = useState({})

   
    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(poItems);
        newBlend[index][field] = value;
        if (field === "yarnId") {
            newBlend[index]["taxPercent"] = findYarnTax(value)
        }
        if(field !== "qty"){
            newBlend[index]["qty"] = (parseFloat(newBlend[index]["noOfBags"]) * parseFloat(newBlend[index]["weightPerBag"])).toFixed(3)
        }
        setPoItems(newBlend);
    };

    useEffect(() => {
        if (poItems.length !== 0) return
        setPoItems(Array.from({ length: 8 }, i => {
            return { yarnId: "", qty: "0.000", inwardQty: "0.000", colorId: "", uomId: "", price: "0.00", discountType: "Percentage", discountValue: "0.00", noOfBags: "0", weightPerBag: "0.000" }
        }))
    }, [greyObj, transType, setPoItems, poItems])

    const addRow = () => {
        const newRow = { yarnId: "", qty: "", inwardQty: "", colorId: "", uomId: "", price: "", discountType: "Percentage", discountValue: "0.00" };
        setPoItems([...poItems, newRow]);
    };
    const handleDeleteRow = id => {
        setPoItems(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
    };

    const { data: yarnList } =
        useGetYarnMasterQuery({ params });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params});

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });

    function findYarnTax(id) {
        if (!yarnList) return 0
        let yarnItem = yarnList.data.find(item => parseInt(item.id) === parseInt(id))
        return yarnItem ? yarnItem.taxPercent : 0
    }

    const Mandatory = (e,value) => {
        console.log("valueYarn",value);

        if(!value){
            toast.info("Please fill the required field",{position:"top-center"});
            e.target.focus();
        }
    }

    useEffect(() => {
        if (transType.toLowerCase().includes("grey")) {
            if (colorList) {
                let colors = colorList.data
                let greyObj = colors.find(c => c.name.toUpperCase() === "GREY")
                setGreyObj(greyObj ? greyObj : "")
            }
        } else {
            setGreyObj("")
        }
    }, [colorList, isColorFetching, isColorLoading, transType])

    if (!yarnList || !colorList || !uomList) return <Loader />
    return (
        <>
            <Modal isOpen={Number.isInteger(currentSelectedIndex)} onClose={() => setCurrentSelectedIndex("")}>
                <TaxDetailsFullTemplate readOnly={readOnly} setCurrentSelectedIndex={setCurrentSelectedIndex} taxTypeId={taxTypeId} currentIndex={currentSelectedIndex} poItems={poItems} handleInputChange={handleInputChange} isSupplierOutside={isSupplierOutside} />
            </Modal>
            <div className={` relative w-full overflow-y-auto py-1`}>
                <table className="border border-gray-500 text-xs table-auto  w-full">
                    <thead className='bg-blue-200 top-0 border border-gray-500'>
                        <tr>
                            <th className="table-data  w-2 text-center">S.no</th>
                            <th className="table-data ">Items<span className="text-red-500">*</span></th>
                            <th className="table-data ">Colors<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">No. of Bags<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Weight Per Bag<span className="text-red-500">*</span></th>
                            <th className="table-data  w-20">UOM<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Quantity<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Price<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Price (with Tax)<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Gross</th>
                            <th className="table-data  w-20">View Tax</th>
                            {!readOnly
                                &&
                                <th className='w-20'>
                                    <div onClick={addRow}
                                        className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                                        {PLUS}
                                    </div>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {poItems.map((row, index) => (
                            <tr key={index} className="w-full table-row">
                                <td className="table-data text-left px-1 py-1 w-2">
                                    {index + 1}
                                </td>
                                <td className='table-data text-left px-1'>
                                    <select 
                                      onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "yarnId") } }}
                                    tabIndex={"0"} disabled={readOnly} className='text-left w-full rounded py-1 table-data-input' value={row.yarnId}
                                     onChange={(e) => handleInputChange(e.target.value, index, "yarnId")}
                                         onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "yarnId")
                                            Mandatory(e,(e.target.value));
                                         }
                                        }
                                        >
                                        <option hidden>
                                        </option>
                                        {(id?yarnList.data:yarnList.data.filter(item=>item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.aliasName}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-left px-1'>
                                    <select 
                                      onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "colorId") } }}
                                    disabled={readOnly} className='text-left w-full rounded py-1 table-data-input' value={row.colorId}
                                     onChange={(e) => handleInputChange(e.target.value, index, "colorId")}
                                    onBlur={(e) => {
                                        handleInputChange(e.target.value, index, "colorId")
                                        Mandatory(e,(e.target.value));
                                     }
                                    }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id?colorList.data :colorList.data.filter(item=>item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-right px-1'>
                                    <input
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0", index, "noOfBags") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-16 px-1 table-data-input"
                                        value={(!row.noOfBags) ? 0 : row.noOfBags}
                                        disabled={readOnly}
                                        inputMode='decimal'
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "noOfBags")
                                        }
                                        onBlur={(e) =>{
                                            handleInputChange(parseFloat(e.target.value), index, "noOfBags");
                                            Mandatory(e,parseFloat(e.target.value));

                                        }
                                    }
                                    
                                    />
                                </td>
                                <td className='table-data text-right px-1'>
                                    <input
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "weightPerBag") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-16 px-1 table-data-input"
                                        value={(!row.weightPerBag) ? 0 : row.weightPerBag}
                                        disabled={readOnly}
                                        inputMode='decimal'
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "weightPerBag")
                                        }
                                        onBlur={(e) =>{
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "weightPerBag")
                                            Mandatory(e,parseFloat(e.target.value));
                                        }
                                            
                                        }
                                    />
                                </td>
                                <td className='table-data text-left px-1'>
                                    <select 
                                      onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "uomId") } }}
                                    disabled={readOnly} className='text-left w-20 rounded py-1 table-data-input' value={row.uomId}
                                     onChange={(e) => handleInputChange(e.target.value, index, "uomId")}
                                    onBlur={(e) => {
                                        handleInputChange(e.target.value, index, "uomId")
                                        Mandatory(e,(e.target.value));
                                     }
                                    }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id?uomList.data:uomList.data.filter(item=>item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-right px-1'>
                                    <input
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "qty") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-16 px-1 table-data-input"
                                        value={(!row.qty) ? 0 : row.qty}
                                        disabled={readOnly}
                                        inputMode='decimal'
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "qty")
                                        }
                                        onBlur={(e) =>{
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                                            Mandatory(e,parseFloat(e.target.value));
                                        }
                                    }
                                    />
                                </td>

                                <td className='table-data text-right px-1'>
                                    <input
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "price") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-16 px-1 table-data-input"
                                        value={(!row.price) ? 0 : row.price}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "price")
                                        }
                                        onBlur={(e) =>{
                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "price")
                                            Mandatory(e,parseFloat(e.target.value));
                                        }
                                    }
                                    />
                                </td>
                                <td className='table-data text-right px-1'>
                                    {priceWithTax(row.price, row.taxPercent).toFixed(2)}
                                </td>
                                <td className='table-data text-left px-1'>
                                    <input
                                        type="number"
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-16 px-1 table-data-input"
                                        value={(!row.qty || !row.price) ? 0 : (parseFloat(row.qty) * parseFloat(row.price))}
                                        disabled={true}
                                    />
                                </td>
                                <td className='px-1'>
                                    <button
                                        className="text-center rounded py-1 w-20"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                setCurrentSelectedIndex(index);
                                            }
                                        }}
                                        onClick={() => {
                                            if (!taxTypeId) return toast.info("Please select Tax Type", { position: "top-center" });
                                            setCurrentSelectedIndex(index)
                                        }
                                        }
                                    >
                                        {VIEW}
                                    </button>
                                </td>
                                {!readOnly
                                    &&
                                    <td className=' w-20'>
                                        <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                            {DELETE}
                                        </div>
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>

    )
}

export default YarnDirectItems