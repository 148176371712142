import React from 'react';
import { substract } from '../../../Utils/helper';

const ProductionDeliveryDetailsFillGrid = ({ id, productionDeliveryDetailsFillData, setProductionDeliveryDetailsFillData, isPacking,
  productionReceiptDetails, setProductionReceiptDetails, onDone }) => {

  const addItem = (id) => {
    setProductionReceiptDetails(prev => {
      let newInwardDetails = structuredClone(prev);
      newInwardDetails.push({
        productionDeliveryDetailsId: id, receivedQty: 0
      })
      return newInwardDetails
    })
  }
  const deleteItem = (id) => {
    setProductionReceiptDetails(prev => {
      return prev.filter(item => parseInt(item.productionDeliveryDetailsId) !== parseInt(id))
    })
  }

  const isItemSelected = (id) => {
    let foundIndex = productionReceiptDetails.findIndex(item => parseInt(item.productionDeliveryDetailsId) === parseInt(id))
    return foundIndex !== -1
  }

  const handleChangeInwardProgramDetails = (id, processCost) => {
    if (isItemSelected(id)) {
      deleteItem(id)
    } else {
      addItem(id, processCost)
    }
  }

  return (
    <>
      <div className={`w-full `}>
        <div className='flex justify-between mb-2'>
          <h1 className='text-center mx-auto font-bold'>Production Delivery Details</h1>
          <button className='text-center font-bold bg-blue-400 text-gray-100 p-1 rounded-lg' onClick={onDone}>DONE</button>
        </div>
        <table className=" text-xs table-fixed w-full">
          <thead className='bg-blue-200 top-0'>
            <tr>
              <th className="table-data w-10"></th>
              <th className="table-data w-10 text-center">S.no</th>
              {!isPacking &&
                <th className="table-data w-48">Portion</th>
              }
              <th className="table-data w-48">Colors</th>
              <th className="table-data w-32">Size</th>
              <th className="table-data w-20">Uom</th>
              <th className="table-data w-20">Del Qty</th>
              <th className="table-data w-20">Process Cost</th>
              <th className="table-data  w-16">A. Inward Qty<span className="text-red-500">*</span></th>
              <th className="table-data  w-16">Bal. Qty<span className="text-red-500">*</span></th>
            </tr>
          </thead>
          <tbody className='overflow-y-auto  h-full w-full'>
            {productionDeliveryDetailsFillData.filter(row => substract(row.delQty, row?.alreadyReceivedQty ? row?.alreadyReceivedQty : 0) > 0).map((row, index) => (
              <tr key={index} className="w-full table-row" onClick={() => { handleChangeInwardProgramDetails(row.id, row.processCost) }} >
                <td className="table-data flex justify-items-center items-center ">
                  <input type='checkbox' checked={isItemSelected(row.id)} />
                </td>
                <td className="table-data  ">
                  {index + 1}
                </td>
                {!isPacking &&
                  <td className='table-data'>
                    {row?.Portion?.name}
                  </td>
                }
                <td className='table-data'>
                  {row?.Color?.name}
                </td>
                <td className='table-data'>
                  {row?.Size?.name}
                </td>
                <td className='table-data'>
                  {row?.Uom?.name}
                </td>
                <td className='table-data text-right'>
                  {row?.delQty}
                </td>
                <td className='table-data text-right'>
                  {row?.processCost}
                </td>
                <td className='text-right table-data'>
                  {row?.alreadyReceivedQty}
                </td>
                <td className='text-right table-data'>
                  {substract(row.delQty, row?.alreadyReceivedQty ? row?.alreadyReceivedQty : 0).toFixed(3)}
                </td>
              </tr>
            ))}
            {Array.from({ length: 5 - productionDeliveryDetailsFillData.length }).map(i =>
              <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                <td className='table-data'>
                </td>
                {!isPacking &&
                  <td className="table-data   "></td>
                }
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data  "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
              </tr>)
            }
          </tbody>
        </table>
      </div>
    </>

  )
}

export default ProductionDeliveryDetailsFillGrid