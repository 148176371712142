import React, { useEffect } from 'react'
import { useState } from "react";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices"
import { EMPTY_ICON } from "../../../icons";
import { Loader } from "../../../Basic/components";
import { findFromList, getCommonParams, getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from "../../../Utils/helper";

import secureLocalStorage from 'react-secure-storage';

import { showEntries } from '../../../Utils/DropdownData';
import { useGetProcessInwardQuery } from '../../../redux/ErpServices/ProcessInwardServices';
import ReactPaginate from 'react-paginate';


const ProcessInwardFormReport = ({
  heading,
  tableWidth = "50%",
  loading,
  onClick,
  processId
}) => {
  const [poNo, setPoNo] = useState("");
  const [poDate, setPoDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [showEntry, setShowEntry] = useState("10")
  const [supplier, setSupplier] = useState("");

  const [totalCount, setTotalCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const params = {
    branchId, companyId
  };
  const { finYearId } = getCommonParams()
  const { data, isLoading, isFetching } = useGetProcessInwardQuery({ params: { branchId, processId, finYearId, pagination: true, dataPerPage: showEntry, pageNumber: currentPageNumber } });
  useEffect(() => {
    if (data?.totalCount) {
      setTotalCount(data?.totalCount)
    }
  }, [data, isLoading, isFetching])

  const { data: supplierList } =
    useGetPartyQuery({ params: { ...params } });

  if (isLoading || isFetching || !supplierList) {
    return <tr>
      <td>
        <Loader />
      </td>
    </tr>
  }


  return (
    <div className="flex flex-col w-full h-[95%] overflow-auto">
      <div className="md:flex md:items-center md:justify-between page-heading p-2">
        <div className="heading text-center md:mx-10">{heading}</div>
        <div className="flex sub-heading justify-center md:justify-start items-center mr-9">
          <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
          <select value={showEntry}
            onChange={(e) => setShowEntry(e.target.value)} className='h-6 w-40 border border-gray-500 rounded'>
            {showEntries.map((option) => <option value={option.value} >{option.show}</option>)}
          </select>

        </div>
      </div>
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            {(data?.data ? data?.data : []).length === 0 ? (
              <div className="flex-1 flex justify-center text-blue-900 items-center text-3xl">
                <p>{EMPTY_ICON} No Data Found...! </p>
              </div>
            ) : (
              <div
                className="md:grid md:justify-items-stretch "
                style={{ width: tableWidth }}
              >
                <table className="table-auto text-center">
                  <thead className="border-2 table-header">
                    <tr>
                      <th
                        className="border-2  top-0 stick-bg"
                      >
                        <label>Inward.No</label><input
                          type="text"
                          className="text-black h-6 focus:outline-none md:ml-3 border border-gray-400 rounded-lg"
                          placeholder="Search"
                          value={poNo}
                          onChange={(e) => {
                            setPoNo(e.target.value);
                          }}
                        />
                      </th>
                      <th
                        className="border-2  top-0 stick-bg"
                      >
                        <label>Inward.Date</label><input
                          type="text"
                          className="text-black h-6 focus:outline-none md:ml-3 border border-gray-400 rounded-lg"
                          placeholder="Search"
                          value={poDate}
                          onChange={(e) => {
                            setPoDate(e.target.value);
                          }}
                        />
                      </th>
                      <th

                        className="border-2  top-0 stick-bg"
                      >
                        <label>Supplier</label><input
                          type="text"
                          className="text-black h-6 focus:outline-none md:ml-3 border border-gray-400 rounded-lg"
                          placeholder="Search"
                          value={supplier}
                          onChange={(e) => {
                            setSupplier(e.target.value);
                          }}
                        />
                      </th>
                      {/* <th
                        className="border-2  top-0 stick-bg"
                      >
                        <label>Item Type</label><input
                          type="text"
                          className="text-black h-6 focus:outline-none md:ml-3 border border-gray-400 rounded-lg"
                          placeholder="Search"
                          value={poType}
                          onChange={(e) => {
                            setPoType(e.target.value);
                          }}
                        />
                      </th> */}

                      <th className="border-2  top-0 stick-bg">

                        <label>SupplierDc.No</label><input
                          type="text"
                          className="text-black h-6 focus:outline-none md:ml-3 border border-gray-400 rounded-lg"
                          placeholder="Search"
                          value={dueDate}
                          onChange={(e) => {
                            setDueDate(e.target.value);
                          }}
                        />
                      </th>
                      <th className="border-2  top-0 stick-bg">

                        <label>Delivery No.</label>
                        {/* <input
                          type="text"
                          className="text-black h-6 focus:outline-none md:ml-3 border border-gray-400 rounded-lg"
                          placeholder="Search"
                          value={dueDate}
                          onChange={(e) => {
                            setDueDate(e.target.value);
                          }}
                        /> */}
                      </th>

                    </tr>
                  </thead>
                  <tbody className="border-2">


                    {(data?.data ? data?.data : []).map((dataObj, index) => (
                      <tr
                        key={index}
                        className="border-2 table-row "
                        onClick={() => onClick(dataObj.id)}>
                        <td className='py-1'>{dataObj.docId}</td>
                        <td className='py-1'>{getDateFromDateTimeToDisplay(dataObj.userInwardDate)} </td>
                        <td className='py-1'>{findFromList(dataObj.ProcessDelivery.supplierId, supplierList.data, "aliasName")}</td>
                        <td className='py-1'>{dataObj.dcNo}</td>
                        <td className='py-1'>{dataObj.ProcessDelivery.docId}</td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </>
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
        pageCount={Math.ceil(totalCount / showEntry)}
        marginPagesDisplayed={1}
        onPageChange={(e) => {
          setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
        }}
        containerClassName={"flex justify-center m-2 gap-5 items-center"}
        pageClassName={"border custom-circle text-center "}
        disabledClassName={"p-1 bg-gray-200"}
        previousLinkClassName={"border p-1 text-center"}
        nextLinkClassName={"border p-1"}
        activeClassName={"bg-blue-900 text-white px-2 "} />

    </div >
  )
}

export default ProcessInwardFormReport