import React, { useEffect } from 'react'
import { useGetPoItemByIdQuery } from '../../../redux/ErpServices/PoServices'
import { Loader } from '../../../Basic/components'
import { DELETE, VIEW } from '../../../icons'
import { substract, sumArray } from '../../../Utils/helper'
import { toast } from 'react-toastify'
import { useState } from 'react'
import Modal from '../../../UiComponents/Modal'
import { YarnLotGrid } from './LotGrid'

const YarnPoItem = ({ item, index, handleInputChange, readOnly, removeItem, purchaseInwardId, handleInputChangeLotNo, addNewLotNo, removeLotNo }) => {
    const [lotGrid, setLotGrid] = useState(false)
    const { data, isLoading, isFetching } = useGetPoItemByIdQuery({ id: item.poItemsId, purchaseInwardId }, { skip: !(item?.poItemsId) })
    useEffect(() => {
        if (purchaseInwardId) return
        if (isLoading || isFetching) return
        if (data?.data) {
            handleInputChange(parseFloat(data.data.weightPerBag).toFixed(3), index, "weightPerBag", 0)
            handleInputChange([{ lotNo: "", qty: "0.000", noOfBags: 0, weightPerBag: parseFloat(data.data.weightPerBag).toFixed(3) }], index, "lotDetails", 0)
        }
    }, [isFetching, isLoading, data, purchaseInwardId])
    if (isLoading || isFetching) return <Loader />
    const poItem = data.data
    let poQty = parseFloat(poItem.qty).toFixed(3)
    let cancelQty = poItem.alreadyCancelData?._sum.qty ? poItem.alreadyCancelData._sum.qty : "0.000";
    let alreadyInwardedQty = poItem.alreadyInwardedData?._sum?.qty ? parseFloat(poItem.alreadyInwardedData._sum.qty).toFixed(3) : "0.000";
    let alreadyReturnedQty = poItem.alreadyReturnedData?._sum?.qty ? parseFloat(poItem.alreadyReturnedData._sum.qty).toFixed(3) : "0.000";
    let balanceQty = substract(substract(poQty, cancelQty), substract(alreadyInwardedQty, alreadyReturnedQty)).toFixed(3)
    let poBags = poItem.noOfBags
    let cancelBags = poItem.alreadyCancelData?._sum.noOfBags ? poItem.alreadyCancelData._sum.noOfBags : 0.000;
    let alreadyInwardedBags = poItem.alreadyInwardedData?._sum?.noOfBags ? poItem.alreadyInwardedData._sum.noOfBags : 0.000;
    let alreadyReturnedBags = poItem.alreadyReturnedData?._sum?.noOfBags ? parseFloat(poItem.alreadyReturnedData._sum.noOfBags).toFixed(3) : "0.000";
    let balanceBags = substract(substract(poBags, cancelBags), substract(alreadyInwardedBags, alreadyReturnedBags))
    let lotNoArr = item?.lotDetails ? item.lotDetails.map(item => item.lotNo) : []
    let isLotNoUnique = new Set(lotNoArr).size === lotNoArr.length
    function onClose() {
        if (!isLotNoUnique) {
            toast.info("Lot No Should be Unique", { position: "top-center" })
            return
        }
        setLotGrid(false)
    }
    return (
        <>
            <Modal widthClass={"max-h-[600px] overflow-auto"} onClose={onClose} isOpen={lotGrid}>
                <YarnLotGrid
                    readOnly={readOnly}
                    onClose={onClose}
                    addNewLotNo={addNewLotNo}
                    removeLotNo={removeLotNo}
                    handleInputChangeLotNo={handleInputChangeLotNo}
                    index={index} lotDetails={item?.lotDetails ? item?.lotDetails : []} balanceQty={balanceQty} balanceBags={balanceBags} />
            </Modal>
            <tr key={item.poItemsId} className='table-row'>
                <td className='text-center   table-data'>{index + 1}</td>
                <td className='text-left  table-data'>{poItem.Po.docId}</td>
                <td className='text-left  table-data'>{poItem.Yarn.aliasName}</td>
                <td className='text-left   table-data'>{poItem.Color.name}</td>
                <td className='text-left   table-data'>{poItem.Uom.name}</td>
                <td className='text-right  table-data'>{poQty}</td>
                <td className='text-right  table-data'>{poBags}</td>
                <td className='text-right  table-data'>{cancelQty}</td>
                <td className='text-right  table-data'>{cancelBags}</td>
                <td className='text-right  table-data'>{alreadyInwardedQty}</td>
                <td className='text-right  table-data'>{alreadyInwardedBags}</td>
                <td className='text-right  table-data'>{alreadyReturnedQty}</td>
                <td className='text-right  table-data'>{alreadyReturnedBags}</td>
                <td className='text-right   table-data'>{balanceQty}</td>
                <td className='text-right   table-data'>{balanceBags}</td>
                <td className='text-center table-data'>
                    <button onClick={() => setLotGrid(true)} className='w-full'>
                        {VIEW}
                    </button>
                </td>
                <td className='text-left table-data'>
                    <input
                        autoFocus={index === 0}
                        onKeyDown={e => {
                            if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                            if (e.key === "Delete") { handleInputChange("0", index, "noOfBags", balanceQty) }
                        }}
                        min={"0"}
                        type="number"
                        className="text-right rounded py-1   w-full table-data-input"
                        value={sumArray(item?.lotDetails ? item?.lotDetails : [], "noOfBags")}
                        disabled={true}
                        onChange={(event) => {
                            if (!event.target.value) {
                                handleInputChange(0, index, "noOfBags", balanceQty);
                                return
                            }
                            handleInputChange(event.target.value, index, "noOfBags", balanceQty);
                        }}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                handleInputChange(0.000, index, "noOfBags", balanceQty);
                                return
                            }
                            handleInputChange(e.target.value, index, "noOfBags", balanceQty)
                        }}
                    />
                </td>
                {/* <td className='text-right   table-data'>
                    <input
                        type="number"
                        onKeyDown={e => {
                            if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                            if (e.key === "Delete") { handleInputChange("0.000", index, "weightPerBag") }
                        }}
                        min={"0"}
                        className="text-right rounded py-1   w-full table-data-input"
                        value={item.weightPerBag}
                        disabled={true}
                        onChange={(event) => {
                            if (!event.target.value) {
                                handleInputChange(0, index, "weightPerBag", balanceQty);
                                return
                            }
                            handleInputChange(event.target.value, index, "weightPerBag", balanceQty);
                        }}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                handleInputChange(0.000, index, "weightPerBag");
                                return
                            }
                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "weightPerBag", balanceQty)
                        }}
                    />
                </td> */}
                <td className='text-left   table-data'>
                    <input
                        type="number"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');"
                        className="text-right rounded py-1   w-full table-data-input"
                        min={"0"}
                        value={sumArray(item?.lotDetails ? item?.lotDetails : [], "qty")}
                        disabled={true}
                        onKeyDown={(e) => {
                            if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                            let value = e.target.value
                            if (e.key === "altKey") {
                                let qty = parseInt(item["noOfBags"]) * parseFloat(item["weightPerBag"])
                                let excessQty = parseInt(item["noOfBags"]) * 2
                                if ((substract(qty, excessQty)) > parseFloat(value)) {
                                    toast.info("Deficient Qty Cannot be Less than 2kg Per Bag", { position: 'top-center' })
                                    e.preventDefault()
                                    return
                                }
                            }
                        }}
                        onChange={(event) => {
                            if (event.target.value < 0) return
                            if (!event.target.value) {
                                handleInputChange(0, index, "qty");
                                return
                            }
                            handleInputChange(event.target.value, index, "qty", balanceQty);
                        }}
                        onBlur={(e) => {
                            let value = e.target.value
                            if (!e.target.value) {
                                handleInputChange(0.000, index, "qty");
                                return
                            }
                            let qty = parseInt(item["noOfBags"]) * parseFloat(item["weightPerBag"])
                            let excessQty = parseInt(item["noOfBags"]) * 2
                            if ((substract(qty, excessQty)) > parseFloat(value)) {
                                toast.info("Deficient Qty Cannot be Less than 2kg Per Bag", { position: 'top-center' })
                                e.target.focus()
                                return
                            }
                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty", balanceQty)
                        }}
                    />
                </td>
                <td className='text-right   table-data'>{parseFloat(poItem.price).toFixed(2)}</td>
                <td className='text-right   table-data'>{(parseFloat(poItem.price) * parseFloat(sumArray(item?.lotDetails ? item?.lotDetails : [], "qty"))).toFixed(2)}</td>
                {!readOnly &&
                    <td className='table-data w-12'>
                        <div tabIndex={-1} onClick={() => removeItem(item.poItemsId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                            {DELETE}
                        </div>
                    </td>
                }
            </tr>
        </>
    )
}

export default YarnPoItem
