import React from 'react'
import { useGetCuttingOrderByIdQuery } from '../../../redux/ErpServices/CuttingOrderServices';
import { substract } from '../../../Utils/helper';

const CuttingOrderDetails = ({ id }) => {
    const {
        data: singleData,
    } = useGetCuttingOrderByIdQuery({ id }, { skip: !id });
    const cuttingOrderItems = singleData?.data?.CuttingOrderDetails ? singleData?.data?.CuttingOrderDetails : []
    return (
        <div className='w-full'>
            <table className='table-fixed text-center w-full'>
                <thead className="border-2 table-header ">
                    <th className="border-2  top-0 stick-bg ">
                        <label>S.no.</label>
                    </th>
                    <th className="border-2  top-0 stick-bg ">
                        <label>Portion</label>
                    </th>
                    <th className="border-2  top-0 stick-bg ">
                        <label>Color</label>
                    </th>
                    <th className="border-2  top-0 stick-bg ">
                        <label>Size</label>
                    </th>
                    <th className="border-2  top-0 stick-bg ">
                        <label>Uom</label>
                    </th>
                    <th className="border-2  top-0 stick-bg ">
                        <label>Order Qty</label>
                    </th>
                    <th className="border-2  top-0 stick-bg ">
                        <label>Inward Qty</label>
                    </th>
                    <th className="border-2  top-0 stick-bg ">
                        <label>Bal. Qty</label>
                    </th>
                </thead>
                <tbody>
                    {cuttingOrderItems.map((item, index) =>
                        <tr
                            className="border-2 table-row "
                            key={item.id}>
                            <td>
                                {index + 1}
                            </td>
                            <td>
                                {item?.Portion?.name}
                            </td>
                            <td>
                                {item?.Color?.name}
                            </td>
                            <td>
                                {item?.Size?.name}
                            </td>
                            <td>
                                {item?.UOM?.name}
                            </td>
                            <td>
                                {(item?.orderQty ? item?.orderQty : 0).toFixed(2)}
                            </td>
                            <td>
                                {(item?.alreadyReceivedQty ? item?.alreadyReceivedQty : item?.alreadyReceivedQty).toFixed(2)}
                            </td>
                            <td>
                                {substract(item?.orderQty, item?.alreadyReceivedQty).toFixed(2)}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

        </div>
    )
}

export default CuttingOrderDetails
