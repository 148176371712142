import React from 'react';
import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { substract } from '../../../Utils/helper';
import { useGetLoopLengthQuery } from '../../../redux/ErpServices/LoopLengthMasterServices';

const CuttingOrderDetailsFillGrid = ({ id, cuttingReceiptInwardDetailsFillData, setCuttingReceiptInwardDetailsFillData,
  cuttingReceiptInwardDetails, setCuttingReceiptInwardDetails, onDone }) => {
  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };

  const addItem = (id) => {
    setCuttingReceiptInwardDetails(prev => {
      let newInwardDetails = structuredClone(prev);
      newInwardDetails.push({
        cuttingOrderDetailsId: id, receivedQty: 0
      })
      return newInwardDetails
    })
  }
  const deleteItem = (id) => {
    setCuttingReceiptInwardDetails(prev => {
      return prev.filter(item => parseInt(item.cuttingOrderDetailsId) !== parseInt(id))
    })
  }

  const isItemSelected = (id) => {
    let foundIndex = cuttingReceiptInwardDetails.findIndex(item => parseInt(item.cuttingOrderDetailsId) === parseInt(id))
    return foundIndex !== -1
  }

  const handleChangeInwardProgramDetails = (id, processCost) => {
    if (isItemSelected(id)) {
      deleteItem(id)
    } else {
      addItem(id, processCost)
    }
  }

  const { data: fabricList } =
    useGetFabricMasterQuery({ params });

  const { data: colorList } =
    useGetColorMasterQuery({ params });

  const { data: uomList } =
    useGetUnitOfMeasurementMasterQuery({ params });

  const { data: gaugeList } =
    useGetGaugeQuery({ params });

  const { data: designList } =
    useGetdesignQuery({ params });

  const { data: gsmList } =
    useGetgsmQuery({ params });

  const { data: loopLengthList } =
    useGetLoopLengthQuery({ params });

  const { data: diaList } =
    useGetDiaQuery({ params });


  if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList) return <Loader />
  return (
    <>
      <div className={`w-full `}>
        <div className='flex justify-between mb-2'>
          <h1 className='text-center mx-auto font-bold'>Cutting Order Details</h1>
          <button className='text-center font-bold bg-blue-400 text-gray-100 p-1 rounded-lg' onClick={onDone}>DONE</button>
        </div>
        <table className=" text-xs table-fixed w-full">
          <thead className='bg-blue-200 top-0'>
            <tr>
              <th className="table-data w-10"></th>
              <th className="table-data w-10 text-center">S.no</th>
              <th className="table-data w-48">Portion</th>
              <th className="table-data w-48">Color</th>
              <th className="table-data w-32">Size</th>
              <th className="table-data w-20">Uom</th>
              <th className="table-data w-20">Order Qty</th>
              <th className="table-data w-20">Cutting Price</th>
              <th className="table-data  w-16">A. Inward Qty<span className="text-red-500">*</span></th>
              <th className="table-data  w-16">Bal. Qty<span className="text-red-500">*</span></th>
            </tr>
          </thead>
          <tbody className='overflow-y-auto  h-full w-full'>
            {cuttingReceiptInwardDetailsFillData.filter(row => substract(row.orderQty, row?.alreadyReceivedQty ? row?.alreadyReceivedQty : 0) > 0).map((row, index) => (
              <tr key={index} className="w-full table-row" onClick={() => { handleChangeInwardProgramDetails(row.id, row.processCost) }} >
                <td className="table-data flex justify-items-center items-center ">
                  <input type='checkbox' checked={isItemSelected(row.id)} />
                </td>
                <td className="table-data  ">
                  {index + 1}
                </td>
                <td className='table-data'>
                  {row?.Portion?.name}
                </td>
                <td className='table-data'>
                  {row?.Color?.name}
                </td>
                <td className='table-data'>
                  {row?.Size?.name}
                </td>
                <td className='table-data'>
                  {row?.UOM?.name}
                </td>
                <td className='table-data text-right'>
                  {row.orderQty}
                </td>
                <td className='table-data text-right'>
                  {row.cuttingPrice}
                </td>
                <td className='text-right table-data'>
                  {row?.alreadyReceivedQty}
                </td>
                <td className='text-right table-data'>
                  {substract(row.orderQty, row?.alreadyReceivedQty ? row?.alreadyReceivedQty : 0).toFixed(3)}
                </td>
              </tr>
            ))}
            {Array.from({ length: 5 - cuttingReceiptInwardDetailsFillData.length }).map(i =>
              <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                <td className='table-data'>
                </td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data  "></td>
                <td className="table-data   "></td>
              </tr>)
            }
          </tbody>
        </table>
      </div>
    </>

  )
}

export default CuttingOrderDetailsFillGrid