import React, { useCallback, useEffect, useMemo } from 'react';
import { PLUS } from '../../../icons';
import { toast } from 'react-toastify';
import { useGetFinishedGoodsSalesDeliveryQuery } from '../../../redux/ErpServices/finishedGoodsSalesDeliveryServices';
import FinishedGoodsSalesDeliveryItem from './FinishedGoodsSalesDeliveryItem';

const FinishedGoodsSalesDetailsFromDelivery = ({ id, readOnly, productionDeliveryDetails, setProductionDeliveryDetails,
  setStockDetailsFillGrid, supplierId }) => {
  const handleInputChange = useCallback((value, index, field) => {
    setProductionDeliveryDetails(productionDeliveryDetails => {
      const newBlend = structuredClone(productionDeliveryDetails);
      newBlend[index][field] = value;
      return newBlend
    });
  }, [setProductionDeliveryDetails]);
  const { data } = useGetFinishedGoodsSalesDeliveryQuery({ params: { itemWise: true, supplierId, finishedGoodsSalesId: id } }, { skip: !supplierId })


  let finishedGoodsSalesDeliveryDetails = useMemo(() => data?.data ? data?.data.map(i => ({ ...i, finishedGoodsSalesDeliveryDetailsId: i.id })) : [], [data])

  const getStockItem = useCallback((checkItem) => {
    let item = finishedGoodsSalesDeliveryDetails.find(item =>
      checkItem.finishedGoodsSalesDeliveryDetailsId === item.finishedGoodsSalesDeliveryDetailsId
    )
    if (!item) return {}
    return item
  }, [finishedGoodsSalesDeliveryDetails])

  useEffect(() => {
    setProductionDeliveryDetails(prev => {
      if (prev.length === 0) return prev
      return prev.map(item => {
        let newItem = structuredClone(item)
        const stockQty = getStockItem(item, "qty")
        if (parseFloat(stockQty) < parseFloat(item.qty)) {
          newItem["qty"] = 0;
        }
        return newItem
      })
    })
  }, [setProductionDeliveryDetails, getStockItem])

  const handleDeleteRow = id => {
    setProductionDeliveryDetails(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
  };

  return (
    <fieldset disabled={readOnly} className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border
                            border-gray-600 overflow-auto min-h-[100px]'>
      <legend className='sub-heading'>Sales Details</legend>
      <div className={`relative w-full overflow-y-auto p-1`}>
        <table className="table-data border border-gray-500 text-xs table-auto w-full">
          <thead className='bg-blue-200 border border-gray-500 top-0'>
            <tr className='border border-gray-500'>
              <th className="table-data w-2 text-center">S.no</th>
              <th className="table-data w-48">Del. no.</th>
              <th className="table-data w-48">Style</th>
              <th className="table-data w-48">Colors</th>
              <th className="table-data w-32">Size</th>
              <th className="table-data w-20">Uom</th>
              <th className="table-data w-20">Del. Qty</th>
              <th className="table-data w-20">A. Bill. Qty</th>
              <th className="table-data w-20">Bal. Qty</th>
              <th className="table-data w-20">Qty</th>
              <th className="table-data w-20">Price</th>
              <th className="table-data w-20">Tax</th>
              <th className="table-data w-20">Price With Tax</th>
              <th className="table-data w-20">Amount</th>
              {readOnly ?
                "" :
                <th className='w-20  bg-green-600 text-white'>
                  <div onClick={() => {
                    if (!supplierId) {
                      return toast.info("Please Select Supplier ...!")
                    }
                    setStockDetailsFillGrid(true)
                  }}
                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                    {PLUS}
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody className='overflow-y-auto table-data h-full w-full'>
            {productionDeliveryDetails.map((row, index) => (
              <FinishedGoodsSalesDeliveryItem index={index} row={row} handleDeleteRow={handleDeleteRow} handleInputChange={handleInputChange} readOnly={readOnly} getStockItem={getStockItem} supplierId={supplierId} />
            ))}
            {Array.from({ length: 8 - productionDeliveryDetails.length }).map(i =>
              <tr className='w-full font-bold h-6 border-gray-400 border'>
                <td className='table-data'>
                </td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data    "></td>
                <td className="table-data    "></td>
                <td className="table-data    "></td>
                <td className="table-data    "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                {!readOnly
                  &&
                  <td className="table-data"></td>
                }
              </tr>)
            }
          </tbody>
        </table>
      </div>
    </fieldset>
  )
}

export default FinishedGoodsSalesDetailsFromDelivery