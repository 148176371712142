import React from 'react'
import { priceWithTax } from '../../../Utils/helper';

const GridDetails = ({ poItems = [], taxDetails }) => {
  return (
    <div >
      <table className=" text-xs border  border-gray-500 table-auto w-full ">
        <thead className=' border border-gray-500 top-0 bg-green-200'>
          <tr className='h-8'>
            <th className="table-data text-center w-16">S.no</th>
            <th className="table-data ">Description</th>
            <th className="table-data ">Tax</th>
            <th className="table-data  w-16">Amount</th>
          </tr>
        </thead>
        <tbody>
          {poItems.map((value, index) => (
            <tr className='border  border-gray-500 w-full' key={index}>
              <td className='table-data text-center px-1 '>{index + 1}</td>
              <td className='table-data px-1  '>{value?.narration}</td>
              <td className='table-data text-right px-1  '>{value?.tax}</td>
              <td className='table-data text-right px-1  '>{value?.amount}</td>
            </tr>
          ))}
          <tr className='border  border-gray-500'>
            <th className='table-data text-center px-1  font-bold text-xs' colSpan={3}>TOTAL</th>
            <td className='px-1 h-8  text-right'>{parseFloat(taxDetails?.netAmount ? taxDetails?.netAmount : 0).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default GridDetails;