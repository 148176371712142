import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BR, BREAKDOWN_MASTER_API, BREAKDOWN_MASTER_APIEAKDOWN_MASTER_API } from "../../Api";


const BASE_URL = process.env.REACT_APP_SERVER_URL;

const BreakdownMasterApi = createApi({
    reducerPath: "BreakdownMaster",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["BreakdownMasterApi"],
    endpoints: (builder) => ({
        getBreakdownMaster: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: BREAKDOWN_MASTER_API + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: BREAKDOWN_MASTER_API,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["BreakdownMaster"],
        }),
        getBreakdownMasterById: builder.query({
            query: (id) => {
                return {
                    url: `${BREAKDOWN_MASTER_API}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["BreakdownMaster"],
        }),
        addBreakdownMaster: builder.mutation({
            query: (payload) => ({
                url: BREAKDOWN_MASTER_API,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["BreakdownMaster"],
        }),
        updateBreakdownMaster: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${BREAKDOWN_MASTER_API}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["BreakdownMaster"],
        }),
        deleteBreakdownMaster: builder.mutation({
            query: (id) => ({
                url: `${BREAKDOWN_MASTER_API}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["DesignationMaster"],
        }),
    }),
});

export const {
    useGetBreakdownMasterQuery,
    useGetBreakdownMasterByIdQuery,
    useAddBreakdownMasterMutation,
    useUpdateBreakdownMasterMutation,
    useDeleteBreakdownMasterMutation,


} = BreakdownMasterApi;

export default BreakdownMasterApi;
