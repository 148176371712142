import React, { useState } from 'react'
import { MultiSelectDropdown, DateInput } from '../../../Inputs'
import { multiSelectOption } from '../../../Utils/contructObject'

const Parameter = ({ startDate, setStartDate, endDate, setEndDate, filterParties, setFilterParties, allSuppliers, onClose }) => {
    const [localStartDate, setLocalStartDate] = useState(startDate)
    const [localEndDate, setLocalEndDate] = useState(endDate)
    const [localFilterParties, setLocalFilterParties] = useState(filterParties);

    function handleDone() {
        setStartDate(localStartDate);
        setEndDate(localEndDate);
        setFilterParties(localFilterParties);
        onClose();
    }

    function handleCancel() {
        setLocalStartDate("");
        setLocalEndDate("");
        setLocalFilterParties([]);
        onClose();
    }

    return (
        <div className='w-[900px]'>
            <div className='font-bold w-full text-center'>
                Parameters
            </div>
            <div className='grid items-center justify-center grid-cols-2 gap-x-16 gap-y-5' >
                <DateInput name={"From"} value={localStartDate} setValue={setLocalStartDate} inputClass={"w-[200px]"} />
                <DateInput name={"To"} value={localEndDate} setValue={setLocalEndDate} />
                <MultiSelectDropdown
                    inputClass={"w-[200px] absolute -z-50 right-[75px]"}
                    className='relative'
                    name={"Supplier"}
                    selected={localFilterParties}
                    setSelected={setLocalFilterParties}
                    options={multiSelectOption(allSuppliers, "aliasName", "id")}
                />
            </div>
            <div className='flex justify-end gap-4 mt-10'>
                <button onClick={handleDone} className='bg-lime-400 hover:bg-lime-600 hover:text-white p-1 px-3 text-sm rounded font-semibold transition'>
                    Done
                </button>
                <button onClick={handleCancel} className='bg-red-400 hover:bg-red-600 hover:text-white p-1 text-sm rounded font-semibold transition'>
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default Parameter
