import React, { useCallback, useEffect, useMemo } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { toast } from 'react-toastify';
import { useGetPcsStockQuery } from '../../../redux/ErpServices/StockServices';
import { getCommonParams } from '../../../Utils/helper';

const StockTransferDetailsForDelivery = ({ id, readOnly, gridDetails, setGridDetails,
  setStockDetailsFillGrid, storeId }) => {
  const { branchId } = getCommonParams()
  const handleInputChange = (value, index, field) => {
    const newBlend = structuredClone(gridDetails);
    newBlend[index][field] = value;
    setGridDetails(newBlend);
  };
  const { data } = useGetPcsStockQuery({ params: { storeId, isPacking: true, stockTransferFinishedGoodsId: id, branchId } })

  let stockData = useMemo(() => data?.data ? data.data : [], [data])

  const getStockItem = useCallback((checkItem, property) => {
    let item = stockData.find(item =>
      checkItem.styleId === item.styleId
      &&
      checkItem.colorId === item.colorId
      &&
      checkItem.uomId === item.uomId
      &&
      checkItem.prevProcessId === item.prevProcessId
      &&
      checkItem.sizeId === item.sizeId
    )
    if (!item) return ""
    return item[property]
  }, [stockData])

  useEffect(() => {
    setGridDetails(prev => {
      if (prev.length === 0) return prev
      return prev.map(item => {
        let newItem = structuredClone(item)
        const stockQty = getStockItem(item, "qty")
        if (parseFloat(stockQty) < parseFloat(item.qty)) {
          newItem["qty"] = 0;
        }
        return newItem
      })
    })
  }, [setGridDetails, getStockItem])

  const handleDeleteRow = id => {
    setGridDetails(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
  };
  return (
    <fieldset disabled={readOnly} className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border
                            border-gray-600 overflow-auto min-h-[100px]'>
      <legend className='sub-heading'>Sales Details</legend>
      <div className={`relative w-full overflow-y-auto p-1`}>
        <table className="table-data border border-gray-500 text-xs table-auto w-full">
          <thead className='bg-blue-200 border border-gray-500 top-0'>
            <tr className='border border-gray-500'>
              <th className="table-data w-2 text-center">S.no</th>
              <th className="table-data w-48">Style</th>
              <th className="table-data w-48">Colors</th>
              <th className="table-data w-32">Size</th>
              <th className="table-data w-20">Uom</th>
              <th className="table-data w-20">Stock Qty</th>
              <th className="table-data w-20">Qty</th>
              {readOnly ?
                "" :
                <th className='w-20  bg-green-600 text-white'>
                  <div onClick={() => {
                    if (!storeId) {
                      return toast.info("Please Select Store ...!")
                    }
                    setStockDetailsFillGrid(true)
                  }}
                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                    {PLUS}
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody className='overflow-y-auto table-data h-full w-full'>
            {gridDetails.map((row, index) => (
              <tr key={index} className="w-full table-row">
                <td className='table-data'>{index + 1}</td>
                <td className='table-data '>
                  {getStockItem(row, "styleName")}
                </td>
                <td className='table-data '>
                  {getStockItem(row, "colorName")}
                </td>
                <td className='table-data'>
                  {getStockItem(row, "sizeName")}
                </td>
                <td className='table-data'>
                  {getStockItem(row, "uomName")}
                </td>
                <td className='table-data text-right'>
                  {getStockItem(row, "qty")}
                </td>
                <td className='table-data'>
                  <input type="number"
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "qty") } }}
                    value={(!row.qty) ? 0 : row.qty}
                    min={0}
                    autoFocus={index === 0}
                    onChange={(e) => {
                      if (parseFloat(e.target.value) > parseFloat(getStockItem(row, "qty"))) {
                        toast.info("Delivery Qty Cannot be more than Stock Qty", { position: "top-center" })
                        return
                      }
                      handleInputChange(e.target.value, index, "qty")
                    }}
                    className="text-right rounded py-1 w-full px-1 table-data-input"
                    inputMode='decimal'
                    onBlur={(e) =>
                      handleInputChange(parseFloat(e.target.value).toFixed(2), index, "qty")
                    } />
                </td>
                {readOnly
                  ?
                  ""
                  :
                  <td className='table-data w-20'>
                    <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer'>
                      {DELETE}
                    </div>
                  </td>
                }
              </tr>
            ))}
            {Array.from({ length: 8 - gridDetails.length }).map(i =>
              <tr className='w-full font-bold h-6 border-gray-400 border'>
                <td className='table-data'>
                </td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data    "></td>
                <td className="table-data    "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                {!readOnly
                  &&
                  <td className="table-data"></td>
                }
              </tr>)
            }
          </tbody>
        </table>
      </div>
    </fieldset>
  )
}

export default StockTransferDetailsForDelivery