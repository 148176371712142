import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../../Inputs';
import { dropDownListObject, multiSelectOption } from '../../Utils/contructObject';

const LedgerTypeDropdown = ({ multiSelect = true, withoutLabel = true, readOnly, name, selected, setSelected }) => {
    const data = [
        {
            id: "Supplier",
            name: "Purchase"
        },
        {
            id: "Customer",
            name: "Sales"
        }
    ]
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'>
                    <option value="">Select</option>
                    {(data).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(data, "name", "id")} />
                        :
                        <DropdownInput readOnly={readOnly} name={name} value={selected} setValue={setSelected}
                            options={dropDownListObject(data, "name", "id")} />
                    }
                </>
            }
        </>
    )
}

export default LedgerTypeDropdown
