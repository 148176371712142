import { faEraser, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';
import { pieceMovement } from '../../../Utils/DropdownData';

export default function DataTable({
  setOperationBulletInDet,
  operationBulletInDet,
  readOnly,
  operationGrpData,
  operationData,
}) {
  console.log(operationBulletInDet, 'OperationBulletInDet');

  const handleCheckboxChange = (index) => {
    setOperationBulletInDet((prev) =>
      prev.map((item, id) =>
        id === index ? { ...item, isActive: !item.isActive } : item
      )
    );
  };

  function isSelectAll() {
    return operationBulletInDet.every((item) => item.isActive);
  }

  const handleSelectAll = () => {
    setOperationBulletInDet((prev) =>
      prev.map((item) => ({ ...item, isActive: !isSelectAll() }))
    );
  };

  const calculateTargetPerHour = (samTime) => {
    if (samTime > 0) {
      return (60 / samTime);
    }
    return '';
  };

  const handleChange = (index, field, value) => {
    const newValue = field === 'samTime' ? value : value;

    setOperationBulletInDet((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: newValue };

      if (field === 'samTime') {
        updated[index].targetPerHour = calculateTargetPerHour(parseFloat(newValue));
      }

      return updated;
    });
  };

  const onDelete = (index) => {
    setOperationBulletInDet((prev) => prev.filter((_, i) => i !== index));
  };

  const handleProcessGroupChange = (index, field, value) => {
    const selectedGroup = operationGrpData.find(
      (group) => group.id === parseInt(value)
    );

    if (selectedGroup) {
      const newOperations = selectedGroup.operationGroupDetails.map((detail) => {
        const operation = operationData.find(
          (op) => op.id === detail.operationNameId
        );

        return {
          id: `${detail.operationNameId}`,
          operationGroupNameId: selectedGroup.id,
          operationName: operation ? operation.name : '',
          operationNameId: detail.operationNameId,
          operationSeq: '',
          samTime: '',
          targetPerHour: '',
          pieceMovement: '',
          isActive: false,
        };
      });

      setOperationBulletInDet((prev) => {
        const updatedGrid = [...prev];
        updatedGrid.splice(index, 1, ...newOperations);
        return updatedGrid;
      });
    }
  };

  const selectedProcess = operationBulletInDet.map(item => parseInt(item.operationGroupNameId));

  const handleClear = () => {
    setOperationBulletInDet([]);
  };

  return (
    <div>
      <fieldset className="frame border-gray-300 rounded-lg p-1">
        <legend className="sub-heading p-1">Allocate Operation</legend>
        <button onClick={handleClear} className='flex w-full justify-end '>
          <FontAwesomeIcon className='text-lg text-red-600' icon={faEraser} />
        </button>

        <div className="grid grid-cols-1 ">
          <table className="w-auto h-full">
            <thead>
              <tr className="font-semibold text-[14px] border border-gray-300">
                <td className="text-center border border-gray-300 w-[5%]">S no</td>
                <td className="border border-gray-300 w-[10%]">
                  <div className="flex items-center justify-center ">
                    <input
                      type="checkbox"
                      checked={isSelectAll()}
                      onChange={handleSelectAll}
                      className="mr-2"
                    />
                    Active
                  </div>
                </td>
                <td className="text-center border border-gray-300 p-1">Process Group</td>
                <td className="text-center border border-gray-300">Operation Name</td>
                <td className="text-center border border-gray-300 w-[8%]">Operation Seq</td>
                <td className="text-center border border-gray-300 w-[8%]">SAM Time</td>
                <td className="text-center border border-gray-300 w-[8%]">Target/Hour</td>
                <td className="text-center border border-gray-300">Movement</td>
                <td className="text-center border border-gray-300 w-[8%]">Delete</td>
              </tr>
            </thead>
            <tbody className="border border-gray-300">
              {operationBulletInDet?.map((field, index) => (
                <tr key={field.id} className="border border-gray-300 text-[12px]">
                  <td>{index + 1}</td>
                  <td className="border border-gray-300 p-1 text-center">
                    <input
                      type="checkbox"
                      checked={field.isActive}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={readOnly}
                    />
                  </td>
                  <td className="border border-gray-300">
                    <select
                      className="input-field border border-gray-300 rounded text-black"
                      value={field.operationGroupNameId}
                      onChange={(e) => handleProcessGroupChange(index, "operationGroupNameId", e.target.value)}
                      disabled={readOnly || field.operationName}
                    >
                      <option value="">Select</option>
                      {operationGrpData?.map((val) => (
                        <option key={val.id} value={val.id} disabled={selectedProcess.includes(val.id)}>
                          {val.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="border border-gray-300">
                    {field.operationName}
                  </td>
                  <td className="border border-gray-300">
                    <input
                      disabled={readOnly}
                      className="input-field focus:outline-none border-gray-500 border rounded"
                      value={field.operationSeq}
                      onChange={(e) => handleChange(index, 'operationSeq', e.target.value)}
                      readOnly={readOnly}
                    />
                  </td>
                  <td className="border border-gray-300">
                    <input
                      type='number'
                      disabled={readOnly}
                      className="input-field focus:outline-none border-gray-500 border rounded"
                      value={field.samTime}
                      onChange={(e) => handleChange(index, 'samTime', e.target.value)}
                      readOnly={readOnly}
                    />
                  </td>
                  <td className="border border-gray-300">
                    <input
                      disabled={readOnly}
                      className="input-field focus:outline-none border-gray-500 border rounded"
                      value={field.targetPerHour}
                      readOnly={true}
                    />
                  </td>
                  <select
                    className="input-field border border-gray-300 rounded text-black"
                    value={field.pieceMovement}
                    onChange={(e) => handleChange(index, "pieceMovement", e.target.value)}
                    disabled={readOnly}
                  >
                    <option value="">Select</option>
                    {pieceMovement?.map((val) => (
                      <option key={val.value} value={val.value}>
                        {val.show}
                      </option>
                    ))}
                  </select>
                  <td onClick={() => onDelete(index)} className="text-center border-gray-300">
                    <FontAwesomeIcon icon={faTrashCan} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </fieldset>
    </div>
  );
}
