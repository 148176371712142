import React, { useEffect } from 'react'
import { Loader } from '../../../Basic/components'
import { DELETE } from '../../../icons'
import { getDiscountAmount, substract } from '../../../Utils/helper'
import { discountTypes } from '../../../Utils/DropdownData';
import { toast } from 'react-toastify'
import { useGetProcessDeliveryProgramItemsQuery } from '../../../redux/ErpServices/ProcessDeliveryServices';
import secureLocalStorage from 'react-secure-storage';
import _ from 'lodash';

const FabricdeliveryItem = ({ index, handleInputChange, readOnly, removeItem, item, billEntryId, supplierId, processId }) => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const { data, isLoading: isdeliveryItemsLoading, isFetching: isdeliveryItemsFetching } = useGetProcessDeliveryProgramItemsQuery({
        params: {
            branchId, supplierId, processId, billEntryId
        }
    })
    const deliveryItem = (data?.data ? data.data : []).find(i => parseInt(i.id) === parseInt(item.processDeliveryProgramDetailsId) )
    useEffect(() => {
        if (!data?.data) return
        handleInputChange(deliveryItem.processCost, index, "price")
        let balanceQty = substract(parseFloat(deliveryItem?.alreadyInwardedQty), parseFloat(deliveryItem?.alreadyBillQty))
        if((parseFloat(item.qty) > parseFloat(balanceQty)) ){
            handleInputChange(0, index, "qty");
        }
    }, [data, isdeliveryItemsLoading, isdeliveryItemsFetching, deliveryItem])
    
    if (isdeliveryItemsLoading || isdeliveryItemsFetching || !data?.data) return <Loader />
    const delQty = deliveryItem?.qty ? deliveryItem?.qty : 0
    const alreadyInwardedQty = deliveryItem.alreadyInwardedQty
    const alreadyBilledQty = deliveryItem.alreadyBillQty;
    const balanceQty = substract(alreadyInwardedQty, alreadyBilledQty);
    const processCost = deliveryItem.processCost
    const billAmount = processCost * item.qty;
    const discountAmount = getDiscountAmount(item.discountType, item.discountValue, billAmount);
    const taxableAmount = billAmount - discountAmount 
    const taxPercent = item?.taxPercent ? item.taxPercent : 0;
    const taxAmount = taxableAmount * taxPercent / 100;  
    const amount = taxableAmount + taxAmount; 
    return (
        <tr key={item.deliveryItemId} className='table-row'>
            <td className='text-left  table-data'>{index + 1}</td>
            <td className='text-left table-data'>{deliveryItem.ProcessDelivery.docId}</td>
            <td className='text-left table-data'>{deliveryItem.Fabric.aliasName}</td>
            <td className='text-left table-data'>{deliveryItem.Color.name}</td>
            <td className='text-left  table-data'>{deliveryItem.Design.name}</td>
            <td className='text-right  table-data'>{deliveryItem.Gauge.name}</td>
            <td className='text-right  table-data'>{deliveryItem.LoopLength.name}</td>
            <td className='text-left  table-data'>{deliveryItem.Gsm.name}</td>
            <td className='text-right  table-data'>{deliveryItem.KDia.name}</td>
            <td className='text-right  table-data'>{deliveryItem.FDia.name}</td>
            <td className='text-left  table-data'>{deliveryItem.Uom.name}</td>
            <td className='text-right  table-data'>{delQty}</td>
            <td className='text-right  table-data'>{alreadyInwardedQty}</td>
            <td className='text-right  table-data'>{alreadyBilledQty}</td>
            <td className='text-right  table-data'>{balanceQty}</td>
            <td className='   table-data text-right'>
                <input
                    onKeyDown={e => {
                        if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                        if (e.altKey) { e.preventDefault() }
                    }}
                    min={"0"}
                    type="number"
                    className="text-right rounded   w-full py-1 table-data-input"
                    autoFocus={index === 0}
                    value={item.qty}
                    disabled={readOnly}
                    onChange={(event) => {
                        if (event.target.value < 0) return
                        if (parseFloat(event.target.value) > parseFloat(balanceQty)) {
                            toast.info("Bill Qty  Cannot be more than Balance Qty", { position: "top-center" });
                            return
                        }
                        if (!event.target.value) {
                            handleInputChange(0, index, "qty");
                            return
                        }
                        handleInputChange(event.target.value, index, "qty");
                    }}

                    onBlur={(e) => {
                        if (!e.target.value) {
                            handleInputChange(0.000, index, "qty");
                            return
                        }
                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                    }}
                />
            </td>
            <td className='   table-data text-right'>
                {(processCost).toFixed(2)}
            </td>
            <td className='text-right  table-data'>{billAmount}</td>
            <td className='   table-data text-right'>
                <select name='type' disabled={readOnly} className='text-center rounded   w-full py-1 table-data-input'
                    value={item.discountType}
                    onChange={(event) => {
                        if (event.target.value < 0) return
                        if (!event.target.value) {
                            handleInputChange(0, index, "discountType");
                            return
                        }
                        handleInputChange(event.target.value, index, "discountType");
                    }}
                >
                    <option hidden>
                    </option>
                    {discountTypes.map((option, index) => <option key={index} value={option.value} >
                        {option.show}
                    </option>)}
                </select>
            </td>
            <td className='table-data text-right'>
                <input
                    onKeyDown={e => {
                        if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                        if (e.altKey) { e.preventDefault() }
                    }}
                    min={"0"}
                    type="number"
                    className="text-right rounded   w-full py-1 table-data-input"
                    value={item.discountValue}
                    disabled={readOnly}
                    onChange={(event) => {
                        if (event.target.value < 0) return
                        if (!event.target.value) {
                            handleInputChange(0, index, "discountValue");
                            return
                        }
                        handleInputChange(event.target.value, index, "discountValue");
                    }}

                    onBlur={(e) => {
                        if (!e.target.value) {
                            handleInputChange(0.000, index, "discountValue");
                            return
                        }
                        handleInputChange(parseFloat(e.target.value), index, "discountValue")
                    }}
                />
            </td>
            <td className='text-right  table-data'>{taxableAmount}</td>
            <td className='text-right  table-data'>
                <input
                    type="text"
                    className="text-right rounded   w-full py-1 table-data-input"
                    value={item.taxPercent}
                    disabled={readOnly}
                    onChange={(event) => {
                        handleInputChange(event.target.value, index, "taxPercent", true);
                    }}
                />
            </td>
            <td className='text-right  table-data'>{amount}</td>
            <td className='text-right  table-data'>
                <input
                    type="text"
                    className="text-right rounded   w-full py-1 table-data-input"
                    value={item.notes}
                    disabled={readOnly}
                    onChange={(event) => {
                        handleInputChange(event.target.value, index, "notes", true);
                    }}
                />
            </td>
            {!readOnly &&
                <td className='table-data w-12'>
                    <div tabIndex={-1} onClick={() => removeItem(item.processDeliveryProgramDetailsId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                        {DELETE}
                    </div>
                </td>
            }
        </tr>
    )
}

export default FabricdeliveryItem
