import React from 'react'
import { View, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import TaxSplitup from '../PrintFormatUtils/TaxSplitup';
import numToText from 'num-to-text';

export const YarnFormat = ({ taxGridData, gridDetails, isSupplierOutside, igstAmount, sgstAmount, cgstAmount, netAmount, grossAmount, roundOffAmount }) => {
    const row = [5, 30, 10, 15, 5, 10, 10, 10, 15];

    return (
        <>
            <View style={{ ...tw("w-full flex flex-row border-b border-gray-500 border-l text-center items-center  "), fontFamily: "Times-Bold" }}>
                <Text style={tw(`w-[${row[0]}%] p-1 border-r border-gray-500`)}>
                    S.no
                </Text>
                <Text style={tw(`w-[${row[1]}%] p-1 border-r border-gray-500`)}>
                    Yarn
                </Text>
                <Text style={tw(`w-[${row[2]}%] p-1 border-r border-gray-500`)}>
                    Hsn
                </Text>
                <Text style={tw(`w-[${row[3]}%] p-1 border-r border-gray-500`)}>
                    Color
                </Text>
                <Text style={tw(`w-[${row[4]}%] p-1 border-r border-gray-500`)}>
                    Uom
                </Text>
                <Text style={tw(`w-[${row[5]}%] p-1 border-r border-gray-500`)}>
                    Lot No.
                </Text>
                <Text style={{ ...tw(`w-[${row[6]}%] border-r text-center p-1 border-gray-500`) }}>
                    Qty
                </Text>
                <Text style={tw(`w-[${row[7]}%] border-r text-center p-1 border-gray-500`)}>
                    Price
                </Text>
                <Text style={tw(`w-[${row[8]}%] border-r text-center p-1 border-gray-500`)}>
                    Amount
                </Text>
            </View>
            {
                gridDetails.map((item, index) => (
                    <View key={index} style={tw(`w-full flex flex-row border-l border-gray-500 ${(index + 1) === gridDetails.length ? "border-b" : ""}`)} wrap={false}>
                        <Text style={tw(`w-[${row[0]}%] p-1 border-r border-gray-500 text-center`)}>
                            {index + 1}
                        </Text>
                        <Text style={tw(`w-[${row[1]}%] p-1 border-r border-gray-500  break-words`)}>
                            {item?.Yarn?.aliasName}
                        </Text>
                        <Text style={tw(`w-[${row[2]}%] p-1 border-r text-center border-gray-500`)}>
                            {item?.Yarn?.hsn}
                        </Text>
                        <Text style={tw(`w-[${row[3]}%] p-1 border-r border-gray-500`)}>
                            {item?.Color?.name}
                        </Text>
                        <Text style={tw(`w-[${row[4]}%] p-1 border-r border-gray-500 text-center`)}>
                            {item?.Uom?.name}
                        </Text>
                        <Text style={tw(`w-[${row[5]}%] p-1 border-r border-gray-500 text-center`)}>
                            {item?.lotNo}
                        </Text>
                        <Text style={{ ...tw(`w-[${row[6]}%] border-r text-center p-1 border-gray-500`) }}>
                            {parseFloat(item?.qty || 0)}
                        </Text>
                        <Text style={tw(`w-[${row[7]}%] border-r text-right p-1 border-gray-500`)}>
                            {parseFloat(item?.price).toFixed(2)}
                        </Text>
                        <Text style={tw(`w-[${row[8]}%] border-r text-right p-1 border-gray-500`)}>
                            {(parseFloat(item?.qty) * parseFloat(item?.price)).toFixed(2)}
                        </Text>
                    </View>
                ))
            }
            {
                Array.from({ length: 15 - (gridDetails.length) }).map((_, i) =>
                    <View key={i} style={tw('w-full flex flex-row border-l border-gray-500 h-[20px]')} wrap={false}>
                        <Text style={tw(`w-[${row[0]}%] p-1 border-r border-gray-500 text-center`)}>
                        </Text>
                        <Text style={tw(`w-[${row[1]}%] p-1 border-r border-gray-500`)}>
                        </Text>
                        <Text style={tw(`w-[${row[2]}%] p-1 border-r border-gray-500`)}>
                        </Text>
                        <Text style={tw(`w-[${row[3]}%] p-1 border-r border-gray-500`)}>
                        </Text>
                        <Text style={tw(`w-[${row[4]}%] p-1 border-r border-gray-500 text-center`)}>
                        </Text>
                        <Text style={tw(`w-[${row[5]}%] p-1 border-gray-500 text-center`)}>
                        </Text>
                        <Text style={{ ...tw(`w-[${row[6]}%] border-l border-r text-center p-1 border-gray-500`) }}>
                        </Text>
                        <Text style={tw(`w-[${row[7]}%] border-r text-right p-1 border-gray-500`)}>
                        </Text>
                        <Text style={tw(`w-[${row[8]}%] border-r text-right p-1 border-gray-500`)}>
                        </Text>
                    </View>
                )
            }
            <TaxSplitup taxGridData={taxGridData} row={row} numberInWords={netAmount ? numToText(netAmount || 0, 'in') : ""} grossAmount={grossAmount} roundOffAmount={roundOffAmount} isSupplierOutside={isSupplierOutside} sgstAmount={sgstAmount} cgstAmount={cgstAmount} igstAmount={igstAmount} netAmount={netAmount} />
        </>
    )
}
