import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetFabricMasterQuery,
    useGetFabricMasterByIdQuery,
    useAddFabricMasterMutation,
    useUpdateFabricMasterMutation,
    useDeleteFabricMasterMutation,
} from "../../../redux/ErpServices/FabricMasterServices";
import { useGetYarnBlendMasterQuery } from "../../../redux/ErpServices/YarnBlendMasterServices";
import { useGetFabricTypeMasterQuery } from "../../../redux/ErpServices/FabricTypeMasterServices";
import YarnBlendDetails from "../YarnMaster/YarnBlendDetails";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { LongTextInput, DropdownInput, LongDisabledInput, CheckBox } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
const MODEL = "Fabric Master";


export default function Form() {
    const [form, setForm] = useState(false);
    const [readOnly, setReadOnly] = useState(false);

    const [id, setId] = useState("");
    const [aliasName, setAliasName] = useState("");
    const [yarnBlendDetails, setYarnBlendDetails] = useState("");
    const [fabricTypeId, setFabricTypeId] = useState("");
    const [hsn, setHsn] = useState("");
    const [active, setActive] = useState(true);
    const [organic, setOrganic] = useState(false)


    const [searchValue, setSearchValue] = useState("");

    const childRecord = useRef(0);

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const userId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userId"
    )
    const params = {
        companyId
    };

    const { data: YarnBlendList } =
        useGetYarnBlendMasterQuery({ params });

    const { data: FabricTypeList } =
        useGetFabricTypeMasterQuery({ params });


    const { data: allData, isLoading, isFetching } = useGetFabricMasterQuery({ params, searchParams: searchValue });


    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetFabricMasterByIdQuery(id, { skip: !id });

    const [addData] = useAddFabricMasterMutation();
    const [updateData] = useUpdateFabricMasterMutation();
    const [removeData] = useDeleteFabricMasterMutation();

    const syncFormWithDb = useCallback((data) => {
        if (id) setReadOnly(true);
        setAliasName(data?.aliasName ? data?.aliasName : "");
        setYarnBlendDetails(data?.FabricOnYarnBlend ? data?.FabricOnYarnBlend : [{ yarnBlendId: "", percentage: "" }, { yarnBlendId: "", percentage: "" }, { yarnBlendId: "", percentage: "" }, { yarnBlendId: "", percentage: "" }]);
        setFabricTypeId(data?.fabricTypeId ? data?.fabricTypeId : "");
        setHsn(data?.hsn ? data?.hsn : "");
        setActive(id ? (data?.active ? data.active : false) : true);
        setOrganic(data?.organic ? data.organic : false);
    }, [id]);

    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        aliasName,
        yarnBlendDetails: yarnBlendDetails ? yarnBlendDetails.filter(item => item.yarnBlendId) : undefined, fabricTypeId, hsn,
        active, organic, companyId, id, userId
    }

    const validatePercentage = () => {
        const yarnBlendPercentage = yarnBlendDetails.filter(blend => blend.yarnBlendId).reduce((accumulator, currentValue) => {
            return accumulator + parseInt(currentValue.percentage)
        }, 0);
        return yarnBlendPercentage === 100
    }

    function findName(arr, id) {
        if (!arr) return ""
        let data = arr.find(item => parseInt(item.id) === parseInt(id))
        return data ? data.name : ""
    }

    const calculateYarnName = () => {
        let fabricType = findName(FabricTypeList?.data, fabricTypeId)

        let yarnBlend = yarnBlendDetails ?
            yarnBlendDetails?.filter(blend => blend.yarnBlendId && blend.percentage).map(blend => `${parseInt(blend.percentage)}%${findName(YarnBlendList?.data, blend.yarnBlendId)}`).join(' ') : "";

        if (!fabricType) return ""
        return `( ${yarnBlend} )/ ${fabricType}`
    }

    useEffect(() => {
        if (id) return
        setAliasName(calculateYarnName())
    }, [calculateYarnName()])


    const validateData = (data) => {
        return data.aliasName && data.fabricTypeId &&
            data.hsn
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData;
            if (text === "Updated") {
                returnData = await callback({ id, body: data }).unwrap();
            } else {
                returnData = await callback(data).unwrap();
            }
            setId(returnData.data.id)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };


    const saveData = () => {

        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", { position: "top-center" })
            return
        }
        if (!validatePercentage()) {
            toast.info("Yarn Blend equal to 100...!", { position: "top-center" })
            return
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    }

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                onNew();
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        setReadOnly(false);
        // syncFormWithDb(undefined)
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }
    const tableHeaders = ["Alias Name", "Status"]
    const tableDataNames = ['dataObj.aliasName', 'dataObj.active ? ACTIVE : INACTIVE']


    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >

            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />
                <div className="flex-1 grid md:grid-cols-2">
                    <div className="grid md:grid-cols-2 border-l w-[880px] h-[570px] overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-2 border-l'>
                            <div className='mr-1 md:ml-5'>
                                <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-5 md:w-[650px] border border-gray-600 md:pb-5 flex h-[280px] overflow-auto'>
                                    <legend className='sub-heading'>Fabric Info</legend>
                                    <div className='flex flex-col justify-start gap-3 flex-1'>
                                        <div className="grid grid-cols-3">
                                            <DropdownInput name="Fabric Type" options={dropDownListObject(id ? FabricTypeList.data : FabricTypeList.data.filter(item => item.active), "name", "id")} value={fabricTypeId} setValue={(value) => { setFabricTypeId(value); }} readOnly={readOnly} required={true} disabled={(childRecord.current > 0)} />
                                            <CheckBox name="Organic" readOnly={readOnly} value={organic} setValue={setOrganic} />
                                            <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                                        </div>
                                        <YarnBlendDetails id={id} params={params} yarnBlend={yarnBlendDetails} setYarnBlend={setYarnBlendDetails} readOnly={readOnly} />
                                    </div>
                                </fieldset>
                                <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg border border-gray-600 my-1 w-[650px] md:pb-5 flex'>
                                    <legend className='sub-heading'>Fabric Details</legend>
                                    <div className='flex flex-col justify-start gap-3 p-3 flex-1'>
                                        <LongTextInput name="Fabric Name" readOnly className={'focus:outline-none cursor-not-allowed md:col-span-2 h-6 w-[610px] border border-gray-500 rounded'} type="text" value={calculateYarnName()} disabled={(childRecord.current > 0)} />
                                        <div className="flex">
                                            <LongTextInput name="Alias Name" className={'focus:outline-none md:col-span-2 h-6 w-[450px] border border-gray-500 rounded'} type="text" value={aliasName} setValue={setAliasName} readOnly={readOnly} required={true} disabled={(childRecord.current > 0)} />
                                            <LongTextInput name="HSN Code" className={'focus:outline-none md:col-span-2 h-6 w-[150px] border border-gray-500 rounded'} type="text" value={hsn} setValue={setHsn} readOnly={readOnly} required={true} disabled={(childRecord.current > 0)} />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="frame overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}
