import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";

import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox, TimeInput } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { useAddShiftMasterMutation, useDeleteShiftMasterMutation, useGetShiftMasterByIdQuery, useGetShiftMasterQuery, useUpdateShiftMasterMutation } from "../../../redux/ErpServices/Shiftmaster";
import moment from "moment";

const MODEL = "Shift Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [accessory, setAccessory] = useState(false)
    const [active, setActive] = useState(true);
    const [shiftStartTime, setShiftStartTime] = useState("");
    const [mrgBreakTime, setMrgBreakTime] = useState("");
    const [lunchTime, setLunchTime] = useState("");
    const [shiftEndTime, setshiftEndTime] = useState("");
    const [aftBreakTime, setAftBreakTime] = useState("")
    const [searchValue, setSearchValue] = useState("");
    const [shiftDuration, setShiftDuration] = useState(null);
    const childRecord = useRef(0);


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };
    const { data: allData, isLoading, isFetching } = useGetShiftMasterQuery({ params, searchParams: searchValue });

    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetShiftMasterByIdQuery(id, { skip: !id });
    // console.log(data, 'singledata');



    const [addData] = useAddShiftMasterMutation();
    const [updateData] = useUpdateShiftMasterMutation();
    const [removeData] = useDeleteShiftMasterMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setName(data?.name ? data.name : "");
            setShiftStartTime(data?.shiftStartTime ? moment(data.shiftStartTime).format('HH:mm:ss') : '');
            setMrgBreakTime(data?.mrgBreakTime ? moment(data.mrgBreakTime).format('HH:mm:ss') : ''
            );
            setLunchTime(data?.lunchTime ? moment(data.lunchTime).format('HH:mm:ss') : '');
            setshiftEndTime(data?.shiftEndTime ? moment(data.shiftEndTime).format('HH:mm:ss') : '');
            setAftBreakTime(data?.aftBreakTime ? moment(data.aftBreakTime).format('HH:mm:ss') : '');
            setShiftDuration(data?.shiftDuration ? data?.shiftDuration : '')
            setActive(id ? (data?.active ? data.active : false) : true);
        },
        [id]
    );

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);


    function convertToDateTime(timeString) {

        if (!timeString) {
            return null;
        }


        const dateTime = moment(timeString, ['HH:mm:ss', 'HH:mm'], true);


        if (!dateTime.isValid()) {
            console.error('Invalid time format after parsing:', timeString);
            return null; // or throw an error if you prefer
        }

        return dateTime.toISOString(); // Convert to ISO 8601 format
    }

    const data = {
        id,
        name,
        shiftStartTime: convertToDateTime(shiftStartTime),
        mrgBreakTime: convertToDateTime(mrgBreakTime),
        lunchTime: convertToDateTime(lunchTime),
        aftBreakTime: convertToDateTime(aftBreakTime),
        shiftEndTime: convertToDateTime(shiftEndTime),
        shiftDuration: parseInt(shiftDuration),
        active,
        companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId"),
    };

    console.log(data, 'singleData');

    const validateData = (data) => {
        if (data.name) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId("")
            syncFormWithDb(undefined)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    const tableHeaders = [
        "Name", "Status"
    ]
    const tableDataNames = ["dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 grid md:grid-cols-2 border overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-2 border overflow-auto'>
                            <div className='mr-1 md:ml-2'>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Shift Info</legend>
                                    <div className='grid grid-cols-3 my-2 form'>

                                        <TextInput name="Shift" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <TimeInput
                                            name="StartTime"
                                            type="time"
                                            value={shiftStartTime}
                                            setValue={setShiftStartTime}
                                            required={true}
                                            readOnly={readOnly}
                                            disabled={(childRecord.current > 0)}
                                        />
                                        <TimeInput
                                            name="mrgBreakTime"
                                            type="time"
                                            value={mrgBreakTime}
                                            setValue={setMrgBreakTime}
                                            required={true}
                                            readOnly={readOnly}
                                            disabled={(childRecord.current > 0)}
                                        />

                                        <TimeInput
                                            name="lunchTime"
                                            type="time"
                                            value={lunchTime}
                                            setValue={setLunchTime}
                                            required={true}
                                            readOnly={readOnly}
                                            disabled={(childRecord.current > 0)}
                                        />
                                        <TimeInput
                                            name="aftBreakTime"
                                            type="time"
                                            value={aftBreakTime}
                                            setValue={setAftBreakTime}
                                            required={true}
                                            readOnly={readOnly}
                                            disabled={(childRecord.current > 0)}
                                        />
                                        <TimeInput
                                            name="EndTime"
                                            type="time"
                                            value={shiftEndTime}
                                            setValue={setshiftEndTime}
                                            required={true}
                                            readOnly={readOnly}
                                            disabled={(childRecord.current > 0)}
                                        />
                                        <TextInput name="ShiftName" type="number" value={shiftDuration} setValue={setShiftDuration} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
