import React, { useEffect, useState } from 'react'
import { useGetPoItemByIdQuery } from '../../../redux/ErpServices/PoServices'
import { Loader } from '../../../Basic/components'
import { DELETE, VIEW } from '../../../icons'
import { substract, sumArray } from '../../../Utils/helper'
import Modal from '../../../UiComponents/Modal'
import { FabricLotGrid } from './LotGrid'
import { toast } from 'react-toastify'

const FabricPoItem = ({ index, handleInputChange, readOnly, removeItem, item, purchaseInwardId, handleInputChangeLotNo, addNewLotNo, removeLotNo }) => {
    const [lotGrid, setLotGrid] = useState(false)
    const { data, isLoading, isFetching } = useGetPoItemByIdQuery({ id: item.poItemsId, purchaseInwardId }, { skip: !item.poItemsId })
    useEffect(() => {
        if (purchaseInwardId) return
        if (isLoading || isFetching) return
        if (data?.data) {
            handleInputChange([{ lotNo: "", qty: "0.000", noOfRolls: 0 }], index, "lotDetails", 0)
        }
    }, [isFetching, isLoading, data, purchaseInwardId])
    if (isLoading || isFetching) return <Loader />
    const poItem = data.data
    let poQty = parseFloat(poItem.qty).toFixed(3)
    let cancelQty = poItem.alreadyCancelData?._sum.qty ? parseFloat(poItem.alreadyCancelData._sum.qty).toFixed(3) : "0.000";
    let alreadyInwardedQty = poItem.alreadyInwardedData?._sum?.qty ? parseFloat(poItem.alreadyInwardedData._sum.qty).toFixed(3) : "0.000";
    let alreadyReturnedQty = poItem.alreadyReturnedData?._sum?.qty ? parseFloat(poItem.alreadyReturnedData._sum.qty).toFixed(3) : "0.000";
    let balanceQty = substract(substract(poQty, cancelQty), substract(alreadyInwardedQty, alreadyReturnedQty)).toFixed(3)
    let lotNoArr = item?.lotDetails ? item.lotDetails.map(item => item.lotNo) : []
    let isLotNoUnique = new Set(lotNoArr).size === lotNoArr.length
    function onClose() {
        if (!isLotNoUnique) {
            toast.info("Lot No Should be Unique", { position: "top-center" })
            return
        }
        setLotGrid(false)
    }
    return (
        <>
            <Modal widthClass={"max-h-[600px] overflow-auto"} onClose={onClose} isOpen={lotGrid}>
                <FabricLotGrid
                    readOnly={readOnly}
                    onClose={onClose}
                    addNewLotNo={addNewLotNo}
                    removeLotNo={removeLotNo}
                    handleInputChangeLotNo={handleInputChangeLotNo}
                    index={index} lotDetails={item?.lotDetails ? item?.lotDetails : []} balanceQty={balanceQty} />
            </Modal>
            <tr key={item.poItemId} className='table-row'>
                <td className='text-left px-1  table-data'>{index + 1}</td>
                <td className='text-left px-1 table-data'>{poItem.Po.docId}</td>
                <td className='text-left px-1 table-data'>{poItem.Fabric.aliasName}</td>
                <td className='text-left px-1 table-data'>{poItem.Color.name}</td>
                <td className='text-left px-1  table-data'>{poItem.Design.name}</td>
                <td className='text-right px-1  table-data'>{poItem.Gauge.name}</td>
                <td className='text-right px-1  table-data'>{poItem.LoopLength.name}</td>
                <td className='text-left px-1  table-data'>{poItem.Gsm.name}</td>
                <td className='text-right px-1  table-data'>{poItem.KDia.name}</td>
                <td className='text-right px-1  table-data'>{poItem.FDia.name}</td>
                <td className='text-left px-1  table-data'>{poItem.Uom.name}</td>
                <td className='text-right px-1 w-20 table-data'>{poQty}</td>
                <td className='text-right px-1  table-data'>{cancelQty}</td>
                <td className='text-right px-1  table-data'>{alreadyInwardedQty}</td>
                <td className='text-right px-1  table-data'>{alreadyReturnedQty}</td>
                <td className='text-right px-1  table-data'>{balanceQty}</td>
                <td className='text-center table-data'>
                    <button onClick={() => setLotGrid(true)} className='w-full'>
                        {VIEW}
                    </button>
                </td>
                <td className='text-right px-1  table-data'>
                    <input
                        type="number"
                        className="text-right rounded py-1  px-1 w-full table-data-input"
                        value={sumArray(item?.lotDetails ? item?.lotDetails : [], "noOfRolls")}
                        disabled={true}
                        onChange={(event) => {
                            if (event.target.value < 0) return
                            if (!event.target.value) {
                                handleInputChange(0, index, "noOfRolls");
                                return
                            }
                            handleInputChange(event.target.value, index, "noOfRolls", balanceQty);
                        }}
                        onKeyDown={e => {
                            if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                            if (e.key === "Delete") { handleInputChange("0", index, "noOfRolls") }
                        }}
                        min={"0"}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                handleInputChange(0.000, index, "noOfRolls", balanceQty);
                                return
                            }
                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "noOfRolls", balanceQty)
                        }}
                    />
                </td>
                <td className='text-left px-1  table-data'>
                    <input
                        type="number"
                        className="text-right rounded py-1  px-1 w-full table-data-input"
                        value={sumArray(item?.lotDetails ? item?.lotDetails : [], "qty")}
                        disabled={true}
                        onChange={(event) => {
                            if (event.target.value < 0) return
                            if (!event.target.value) {
                                handleInputChange(0, index, "qty");
                                return
                            }
                            handleInputChange(event.target.value, index, "qty", balanceQty);
                        }}
                        onKeyDown={e => {
                            if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                        }}
                        min={"0"}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                handleInputChange(0.000, index, "qty", balanceQty);
                                return
                            }
                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty", balanceQty)
                        }}
                    />
                </td>
                <td className='text-right px-1  table-data'>{parseFloat(poItem.price).toFixed(2)}</td>
                <td className='text-right   table-data'>{(parseFloat(poItem.price) * parseFloat(sumArray(item?.lotDetails ? item?.lotDetails : [], "qty"))).toFixed(2)}</td>
                {!readOnly &&
                    <td className='table-data w-12'>
                        <div tabIndex={-1} onClick={() => removeItem(item.poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                            {DELETE}
                        </div>
                    </td>
                }
            </tr>
        </>
    )
}

export default FabricPoItem
