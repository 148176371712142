import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetFinishedGoodsOpeningStockQuery,
  useGetFinishedGoodsOpeningStockByIdQuery,
  useAddFinishedGoodsOpeningStockMutation,
  useUpdateFinishedGoodsOpeningStockMutation,
  useDeleteFinishedGoodsOpeningStockMutation,
} from "../../../redux/ErpServices/finishedGoodsOpeningStockServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DropdownInput, DateInput, DisabledInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { findFromList, getCommonParams, getDateFromDateTime, isGridDatasValid } from "../../../Utils/helper";
import moment from "moment";
import { Loader } from "../../../Basic/components";

import {
  useGetLocationMasterQuery,
} from "../../../redux/ErpServices/LocationMasterServices";
import {
  useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import { useGetStyleMasterQuery } from "../../../redux/ErpServices/StyleMasterServices";
import Modal from "../../../UiComponents/Modal";
import Report from "./Report";
import FinishedGoodsOpeningStockDetails from "./FinishedGoodsOpeningStockDetails";

const MODEL = "Finished Goods Opening Stock";

export default function Form() {
  const dispatch = useDispatch()

  const today = new Date()
  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [docId, setDocId] = useState("");
  const [finishedGoodsOpeningStockDetails, setFinishedGoodsOpeningStockDetails] = useState([])
  const [storeId, setStoreId] = useState("")
  const [delDate, setDelDate] = useState(getDateFromDateTime(today))
  const [styleId, setStyleId] = useState("");

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const childRecord = useRef(0);
  const { branchId, companyId, finYearId, userId } = getCommonParams()


  const params = {
    companyId
  };
  const { data: locationData, isLoading: isLocationLoading, isFetching: isLocationFetching } = useGetLocationMasterQuery({ params: { branchId }, searchParams: searchValue });
  const { data: branchList } = useGetBranchQuery({ params: { companyId } });

  const { data: allData, isLoading, isFetching } = useGetFinishedGoodsOpeningStockQuery({ params: { branchId, finYearId }, searchParams: searchValue });

  const { data: styleList, isLoading: isStyleLoading, isFetching: isStyleFetching } =
    useGetStyleMasterQuery({ params, searchParams: searchValue });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetFinishedGoodsOpeningStockByIdQuery({ id }, { skip: !id });

  const [addData] = useAddFinishedGoodsOpeningStockMutation();
  const [updateData] = useUpdateFinishedGoodsOpeningStockMutation();
  const [removeData] = useDeleteFinishedGoodsOpeningStockMutation();

  const getNextDocId = useCallback(() => {
    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setDelDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    if (data?.docId) {
      setDocId(data.docId);
    }
    setLocationId(data?.storeId ? findFromList(data?.storeId, (locationData?.data ? locationData.data : []), "locationId") : "")
    setStoreId(data?.storeId ? data?.storeId : "");
    setFinishedGoodsOpeningStockDetails(data?.FinishedGoodsOpeningStockDetails ? data?.FinishedGoodsOpeningStockDetails : [])
    setStyleId(data?.styleId ? data.styleId : "");
  }, [id, locationData]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    branchId,
    storeId,
    styleId,
    finishedGoodsOpeningStockDetails: finishedGoodsOpeningStockDetails.filter(i => i?.colorId),
    id, userId, finYearId
  }

  const validateData = (data) => {
    return data.storeId && data.styleId && data.finishedGoodsOpeningStockDetails.length > 0
      && isGridDatasValid(data.finishedGoodsOpeningStockDetails, false, ["qty", "colorId", "sizeId", "uomId"])
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 0) {
        toast.success(text + "Successfully");
        setId("")
        syncFormWithDb(undefined)
      } else {
        toast.error(returnData.message);
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });

    } catch (error) {
      console.log("handle", error);
    }
  };


  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
    getNextDocId();
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLocationLoading || isLocationFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  if (isLocationFetching || isLocationLoading || isStyleLoading || isStyleFetching) return <Loader />
  const storeOptions = locationData ?
    locationData.data.filter(item => parseInt(item.locationId) === parseInt(locationId)) :
    [];
  return (
    <>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <Report
          heading={MODEL}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          allData={allData}
          tableWidth="100%"
          setForm={setForm}
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
      >
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}
            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            openReport={() => setFormReport(true)}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 overflow-auto'>
                    <legend className='sub-heading'>Finished Goods</legend>
                    <div className='flex flex-col justify-center items-start flex-1 w-full'>
                      <div className="grid grid-cols-4 w-full">
                        <DisabledInput name={"Delivery No"} value={docId} />
                        <DateInput name="Del. Date" value={delDate} type={"date"} required={true} disabled />
                        <DropdownInput
                          name="Style"
                          options={dropDownListObject(id ? (styleList ? styleList.data : []) : (styleList ? styleList.data.filter(item => item.active) : []), "sku", "id")}
                          value={styleId}
                          setValue={setStyleId}
                          required={true}
                          readOnly={id || readOnly}
                        />
                        <DropdownInput name="Location"
                          options={branchList ? (dropDownListObject(id ? branchList.data : branchList.data.filter(item => item.active), "branchName", "id")) : []}
                          value={locationId}
                          setValue={(value) => { setLocationId(value); setStoreId("") }}
                          required={true} readOnly={id || readOnly} />
                        <DropdownInput name="Store"
                          options={dropDownListObject(id ? storeOptions : storeOptions.filter(item => item.active), "storeName", "id")}
                          value={storeId} setValue={setStoreId} required={true} readOnly={id || readOnly} />
                      </div>
                    </div>
                  </fieldset>
                  {styleId &&
                    <FinishedGoodsOpeningStockDetails id={id} readOnly={readOnly}
                      finishedGoodsOpeningStockDetails={finishedGoodsOpeningStockDetails} setFinishedGoodsOpeningStockDetails={setFinishedGoodsOpeningStockDetails}
                      styleId={styleId} storeId={storeId} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}