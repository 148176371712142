import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import baseQuery from "../baseQuery";
import { OPERATION_GROUP_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const operationGroupMaster = createApi({
    reducerPath: "operationGroupMaster",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["OperationGroupMaster"],
    endpoints: (builder) => ({
        getOperationGroupMast: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: OPERATION_GROUP_API + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: OPERATION_GROUP_API,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["OperationGroupMaster"],
        }),
        getOperationGroupMastById: builder.query({
            query: (id) => {
                return {
                    url: `${OPERATION_GROUP_API}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["OperationGroupMaster"],
        }),
        addOperationGroupMast: builder.mutation({
            query: (payload) => ({
                url: OPERATION_GROUP_API,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["OperationGroupMaster"],
        }),
        updateOperationGroupMast: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${OPERATION_GROUP_API}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["OperationGroupMaster"],
        }),
        deleteOperationGroupMast: builder.mutation({
            query: (id) => ({
                url: `${OPERATION_GROUP_API}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["OperationGroupMaster"],
        }),
    }),
});

export const {
    useGetOperationGroupMastQuery,
    useGetOperationGroupMastByIdQuery,
    useAddOperationGroupMastMutation,
    useUpdateOperationGroupMastMutation,
    useDeleteOperationGroupMastMutation,

} = operationGroupMaster;

export default operationGroupMaster;
