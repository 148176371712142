import FabricAddress from "./FabricAddress"
import FabricColorProgram from "./FabricColorProgram"
import RawMaterialFabric from "./RawMaterialFabric"
import QRCode from "react-qr-code";



const MODEL = "FABRIC DELIVERY TO PROCESS"; 


export default function Form({ innerRef }) {


    const From = {
        name: "SPIKE CREATIONS - GARMENT",
        address: "NO. 702/3, RAJEEV GANDHI NAGAR, CHANDRAPURAM EAST, DHARAPURAM ROAD, 641604-TIRUPUR, TAMIL NADU, INDIA",
        gstno: "GST NO :33AEKFS0879M12F",
        panno: "PAN NO :AEKFS0879M"
    }
    const To = {
        name: "KONGOOR TEXTILES PROCESS",
        address: "177/1-C, PATCHANKATTU PALAYAM, ARULPURAM (PO), PALLADAMROAD, TIRUPUR-641605, TAMIL NADU(33), INDIA",
        gstno: "GST NO :33AADFK5062D1ZS",
        panno: "PAN NO :AADFK5062D"

    }

    const FabricColor = [{
        fabric: "INTERLOCK( 100%COTTON )",
        design: "SOLID",
        color: "BLACK(19-4006 TCX)",
        fdia: "62INCH",
        kdia: "34T",
        gsm: 210,
        gg: 24,
        ll: 30.5,
        uom: "KGS",
        roll: 16,
        progQty: 219.769,
        total:219.769
    }]


    const RawMetirial = [{
        fabricDGGLKF: "INTERLOCK( 100%COTTON ) / SOLID / 210 / 24 / 30.5 / 34T / 62INCH",
        counts: "",
        color: "GREY",
        uom: "KGS",
        rolls: 16,
        issqty: 219.769,
        total:219.769,
        

    }]
    const dcNo = "SPK/23-24/DFPI-000004";
    const process = "DYEING";
    const date = "08-07-2023";
    const jobNo = "SPK/23-24/DFPJ-000002";

    const invoice = "SPIKE CREATIONS - GARMENT";
    const vehicle = "0"
    return (
        <div className="h-full overflow-auto text-xs" ref={innerRef}>
            
            <div className="p-5">
                <div className="border border-gray-500  text-xs">

                    <div>
                    <h1 className="text-center font-bold text-lg p-2">FABRIC DELIVERY TO PROCESS</h1>
                        <FabricAddress from={From} to={To} />
                        <div>
                        <div className="grid grid-rows-2 grid-flow-col gap-x-10 mt-1 font-bold p-5">
                            <div  >
                                DC NO : {dcNo}
                            </div>
                            <div className="">
                                PROCESS : {process}
                            </div>
                            <div class>
                                DATE : {date}

                            </div>
                            <div className="">
                                JOB NO: {jobNo}
                            </div>
                            <div className="fixed top-50  right-20">
                            <QRCode value='YN/CTN/32S/COM' size={40} />
                            </div>
                        </div>
                       
                        </div>
                       
                        
                        <FabricColorProgram fabriccolor={FabricColor} />
                        <br />
                        <h2 className="font-bold px-5">KNITTING DELIVERY DETAILS -(HSN CODE-998821)</h2><br />
                        <RawMaterialFabric rawmeterial={RawMetirial} /><br />
                        <div className="mt-44">
                        <div className="grid grid-rows-2 grid-flow-col gap-4 mt-2 p-5 font-bold border border-gray-500 w-full">
                            <div>INVOICE : {invoice}</div>
                            <div>VEHICLE NO : {vehicle}</div>
                            {/* <div>FOLLOWER :</div> */}
                            <div>REMARKS :</div>
                        </div>
                        </div>
                       

                    </div>
                    <div className="text-right mt-2 px-32">For SPIKE CREATIONS-GARMENT</div>
                    <br />

                    <div className="flex gap-40 font-bold mt-10">
                        <div>Prepared By</div>
                        <div>Verified By</div>
                        <div>Received By</div>
                        <div>Approved By</div>
                    </div>


                </div>


            </div>


        </div>



    )

}



