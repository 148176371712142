import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DESTINATION_MASTER_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const DestinationMasterApi = createApi({
    reducerPath: "DestinationMasterMast",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["DestinationMaster"],
    endpoints: (builder) => ({
        getDestinationMaster: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: DESTINATION_MASTER_API + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: DESTINATION_MASTER_API,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["State"],
        }),
        getDestinationMasterById: builder.query({
            query: (id) => {
                return {
                    url: `${DESTINATION_MASTER_API}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["State"],
        }),
        addDestinationMaster: builder.mutation({
            query: (payload) => ({
                url: DESTINATION_MASTER_API,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["State"],
        }),
        updateDestinationMaster: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${DESTINATION_MASTER_API}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["State"],
        }),
        deleteDestinationMaster: builder.mutation({
            query: (id) => ({
                url: `${DESTINATION_MASTER_API}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["State"],
        }),
    }),
});

export const {
    useGetDestinationMasterQuery,
    useGetDestinationMasterByIdQuery,
    useAddDestinationMasterMutation,
    useUpdateDestinationMasterMutation,
    useDeleteDestinationMasterMutation,


} = DestinationMasterApi;

export default DestinationMasterApi;
