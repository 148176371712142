import React from 'react'

const Address = ({ fromAddress, toAddress }) => {
  return (
    <div>
      <div className=''>
        <table className="text-xs  w-full p-2 table-auto border border-gray-500">
          <thead className='text-left p-1 ml-1'>
            <tr className=''>
              <th className=' font-bold w-1/2 table-data text-sm'>FROM : </th>
              <th className=' font-bold text-sm '>TO :</th>
            </tr>
          </thead>
          <tbody>
            <tr className=' text-xs'>
              <td className='p-1 table-data'>
                <h2 className='font-bold text-sm text-green-700'>{fromAddress.branchName}</h2>
                <div> {fromAddress.address} </div>
                <div className=''>Mobile NO :{fromAddress.contactMobile}</div>
                <div className=''>PAN NO :{fromAddress.panNo}</div>
                <div className=''>GST NO :{fromAddress.gstNo}</div>
                <div className=''> EMAIL :{fromAddress.contactEmail}</div>
              </td >
              <div className='mt-0'>
                <td className=''>
                  <h2 className='font-bold text-sm text-green-700'>{toAddress.branchName}</h2>
                  <div> {toAddress.address} </div>
                  <div className=''>Mobile NO :{toAddress.contactMobile}</div>
                  <div className=''>PAN NO :{toAddress.panNo}</div>
                  <div className=''>GST NO :{toAddress.gstNo}</div>
                  <div className=''> EMAIL :{toAddress.contactEmail}</div>
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  )
}


export default Address