import React, { useState } from 'react'
import { useGetPoItemByIdQuery } from '../../../redux/ErpServices/PoServices'
import { Loader } from '../../../Basic/components'
import { substract } from '../../../Utils/helper'
import Modal from '../../../UiComponents/Modal'
import FabricPoItemInwardReport from './FabricPoItemInwardReport'
import FabricItemReturnReport from './FabricPoItemReturnReport'
import FabricItemCancelReport from './FabricPoItemCancelReport'

const FabricPoItem = ({ index, id, dataObj }) => {
    const [inwardReport, setInwardReport] = useState(false)
    const [returnReport, setReturnReport] = useState(false)
    const [cancelReport, setCancelReport] = useState(false)
    const { data, isLoading, isFetching } = useGetPoItemByIdQuery({ id }, { skip: !id })
    if (isLoading || isFetching) return <Loader />
    const poItem = data.data
    let poQty = parseFloat(poItem.qty).toFixed(3)
    let cancelQty = poItem.alreadyCancelData?._sum.qty ? parseFloat(poItem.alreadyCancelData._sum.qty).toFixed(3) : "0.000";
    let alreadyInwardedQty = poItem.alreadyInwardedData?._sum?.qty ? parseFloat(poItem.alreadyInwardedData._sum.qty).toFixed(3) : "0.000";
    let alreadyInwardedRolls = poItem.alreadyInwardedData?._sum?.noOfRolls ? parseFloat(poItem.alreadyInwardedData._sum.noOfRolls).toFixed(3) : "0.000";
    let alreadyReturnedQty = poItem.alreadyReturnedData?._sum?.qty ? parseFloat(poItem.alreadyReturnedData._sum.qty).toFixed(3) : "0.000";
    let alreadyReturnedRolls = poItem.alreadyReturnedData?._sum?.noOfRolls ? parseFloat(poItem.alreadyReturnedData._sum.noOfRolls).toFixed(3) : "0.000";
    let balanceQty = substract(substract(poQty, cancelQty), substract(alreadyInwardedQty, alreadyReturnedQty)).toFixed(3)
    let alreadyBillQty = poItem?.alreadyBillData?._sum?.qty ? poItem?.alreadyBillData._sum.qty : 0;
    let balBillQty = substract(poQty, alreadyBillQty);
    balBillQty = balBillQty > 0 ? balBillQty : 0
    return (
        <>
            <Modal isOpen={inwardReport} onClose={() => setInwardReport(false)} >
                <FabricPoItemInwardReport dataObj={dataObj} poItem={poItem}
                    inwardItems={poItem.PoInwardReturnItems.filter(item => item.PurchaseInwardOrReturn.inwardOrReturn === "PurchaseInward")} />
            </Modal>
            <Modal isOpen={returnReport} onClose={() => setReturnReport(false)}>
                <FabricItemReturnReport dataObj={dataObj} poItem={poItem}
                    inwardItems={poItem.PoInwardReturnItems.filter(item => item.PurchaseInwardOrReturn.inwardOrReturn === "PurchaseReturn")} />
            </Modal>
            <Modal isOpen={cancelReport} onClose={() => setCancelReport(false)}>
                <FabricItemCancelReport dataObj={dataObj} poItem={poItem}
                    inwardItems={poItem.PoInwardReturnItems.filter(item => item.PurchaseInwardOrReturn.inwardOrReturn === "PurchaseCancel")} />
            </Modal>
            <tr key={id} className='table-row'>
                <td className='text-left px-1  table-data'>{index + 1}</td>
                <td className='text-left px-1 table-data'>{poItem.Fabric.aliasName}</td>
                <td className='text-left px-1 table-data'>{poItem.Color.name}</td>
                <td className='text-left px-1  table-data'>{poItem.Design.name}</td>
                <td className='text-right px-1  table-data'>{poItem.Gauge.name}</td>
                <td className='text-right px-1  table-data'>{poItem.LoopLength.name}</td>
                <td className='text-left px-1  table-data'>{poItem.Gsm.name}</td>
                <td className='text-right px-1  table-data'>{poItem.KDia.name}</td>
                <td className='text-right px-1  table-data'>{poItem.FDia.name}</td>
                <td className='text-left px-1  table-data'>{poItem.Uom.name}</td>
                <td className='text-right px-1 w-20 table-data'>{poQty}</td>
                <td className='text-right px-1  table-data' onClick={() => setCancelReport(true)}>{cancelQty}</td>
                <td className='text-right px-1  table-data' onClick={() => setInwardReport(true)}>{alreadyInwardedQty}</td>
                <td className='text-right px-1  table-data' onClick={() => setInwardReport(true)}>{alreadyInwardedRolls}</td>
                <td className='text-right px-1  table-data' onClick={() => setReturnReport(true)}>{alreadyReturnedQty}</td>
                <td className='text-right px-1  table-data' onClick={() => setReturnReport(true)}>{alreadyReturnedRolls}</td>
                <td className='text-right px-1  table-data'>{balanceQty}</td>
                <td className='text-right   table-data'>{alreadyBillQty}</td>
                <td className='text-right   table-data'>{balBillQty}</td>
            </tr>
        </>
    )
}

export default FabricPoItem
