import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
  useGetPurchaseInwardOrReturnQuery,
  useGetPurchaseInwardOrReturnByIdQuery,
  useAddPurchaseInwardOrReturnMutation,
  useUpdatePurchaseInwardOrReturnMutation,
  useDeletePurchaseInwardOrReturnMutation,
} from "../../../redux/ErpServices/PurchaseInwardOrReturnServices";
import { useGetTaxTemplateQuery } from '../../../redux/ErpServices/TaxTemplateServices';

import {
  useGetPoQuery
} from "../../../redux/ErpServices/PoServices"
import { useGetPartyQuery, useGetPartyByIdQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { LongDropdownInput, LongDisabledInput, DropdownInput, TextInput, DisabledInput, DateInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { inwardTypeOptions, poTypes, purchasePrPi } from '../../../Utils/DropdownData';
import YarnInwardItems from "./YarnInwardItems";
import { useDispatch } from "react-redux";
import Modal from "../../../UiComponents/Modal";
import PoItemsSelection from "./PoItemsSelection";
import { findFromList, getDateFromDateTime } from "../../../Utils/helper";
import YarnReturnItems from "./YarnReturnItems";
import FabricReturnItems from "./FabricReturnItems";
import AccessoryInwardItems from "./AccessoryInwardItems";
import FabricInwardItems from "./FabricInwardItems";
import AccessoryReturnItems from "./AccessoryReturnItems";
import YarnDirectItems from "./YarnDirectItems";
import FabricDirectItems from "./FabricDirectItems";
import AccessoryDirectItems from "./AccessoryDirectItems";
import moment from "moment";
import {
  useGetLocationMasterQuery,
} from "../../../redux/ErpServices/LocationMasterServices";
import {
  useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import { Loader } from "../../../Basic/components";
import InwardReturnFormReport from "./InwardReturnFormReport";

const MODEL = "Purchase Inward / Return";


export default function Form() {

  const dispatch = useDispatch()

  const today = new Date()
  const [inwardItemSelection, setInwardItemSelection] = useState(false);
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [docId, setDocId] = useState("");
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [poType, setPoType] = useState("GreyYarn");
  const [inwardType, setInwardType] = useState("AgainstPo")
  const [taxTemplateId, setTaxTemplateId] = useState("");

  const [locationId, setLocationId] = useState('');

  const [storeId, setStoreId] = useState("")

  const [dcNo, setDcNo] = useState("")
  const [dcDate, setDcDate] = useState("")

  const [inwardOrReturn, setInwardOrReturn] = useState("PurchaseInward");
  const [supplierId, setSupplierId] = useState("");
  const [inwardItems, setInwardItems] = useState([]);

  const [finalInwardItems, setFinalInwardItems] = useState([]);

  const [active, setActive] = useState(true)

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )
  const companyId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  )
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )

  const { data: supplierList } =
    useGetPartyQuery({ params: { companyId } });

  const { data: supplierDetails } =
    useGetPartyByIdQuery(supplierId, { skip: !supplierId });

  const { data: taxTypeList } =
    useGetTaxTemplateQuery({ params: { companyId, active: true } });

  const { data: locationData } = useGetLocationMasterQuery({ params: { branchId }, searchParams: searchValue });

  const { data: allData, isLoading, isFetching } = useGetPurchaseInwardOrReturnQuery({ params: { branchId, inwardOrReturn : inwardType === "DirectInward" ? "DirectInward" : inwardOrReturn  }, searchParams: searchValue });

  const { data: poList, isLoading: poListLoading, isFetching: poListFetching } = useGetPoQuery({ params: { storeId, branchId }, searchParams: searchValue });

  const getNextDocId = useCallback(() => {
    if (id) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetPurchaseInwardOrReturnByIdQuery(id, { skip: !id });


  function isSupplierOutside() {
    if (supplierDetails) {
      return supplierDetails?.data?.City?.state?.name !== "TAMIL NADU"
    }
    return false
  }
  const [addData] = useAddPurchaseInwardOrReturnMutation();
  const [updateData] = useUpdatePurchaseInwardOrReturnMutation();
  const [removeData] = useDeletePurchaseInwardOrReturnMutation();


  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    setPoType(data?.poType ? data.poType : "GreyYarn");
    setDcNo(data?.dcNo ? data.dcNo : "");
    setDcDate(data?.dcDate ? getDateFromDateTime(data.dcDate) : "");
    setPoType(data?.poType ? data.poType : "GreyYarn");
    setInwardOrReturn(data?.inwardOrReturn ? (data.inwardOrReturn.includes("Inward") ? "PurchaseInward" : "PurchaseReturn") : "PurchaseInward");
    setStoreId(data?.storeId ? data.storeId : "");
    if(data?.docId){
      setDocId(data?.docId)
    }
    setLocationId(data?.storeId ? (
      locationData.data.find(item => parseInt(item.id) === parseInt(data.storeId))
        ?
        locationData.data.find(item => parseInt(item.id) === parseInt(data.storeId)).locationId
        :
        ""
    ) : "")
    setInwardType(data?.inwardOrReturn ? ((data.inwardOrReturn === "DirectInward") ? "DirectInward" : "AgainstPo") : "AgainstPo")
    if (data?.inwardOrReturn && data.inwardOrReturn === "DirectInward") {
      setFinalInwardItems(structuredClone(data.poInwardReturnItems))
    } else if (data?.poInwardReturnItems) {
      setInwardItems(structuredClone(data.poInwardReturnItems))
    }
    if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setActive(id ? (data?.active ? data.active : false) : true);
  }, [id]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const { data: branchList, isLoading: isBranchLoading, isFetching: isBranchFetching } = useGetBranchQuery({ params: { companyId } });

  const data = {
    inwardOrReturn: (inwardType === "DirectInward") ? inwardType : inwardOrReturn,
    dcDate, dcNo,
    poType, supplierId,
    storeId,
    branchId, id, userId,
    poInwardReturnItems: inwardType === "DirectInward" ?
      finalInwardItems.filter(item => item.yarnId || item.fabricId || item.accessoryId).map(item => { item["inwardQty"] = item["qty"]; return item })
      :
      finalInwardItems
  }

  const validateData = (data) => {
    return data.poType && data.supplierId && data.inwardOrReturn && data.storeId
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback({ id, body: data }).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      toast.success(text + "Successfully");
      setId(returnData.data.id)
      dispatch({
        type: `po/invalidateTags`,
        payload: ['po'],
      });
    } catch (error) {
      console.log("handle", error);
    }
  };
  const prefix = inwardOrReturn.includes("Inward") ? "GRN" : "RET";

  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined)
  };

  function onDataClick(id) {
    setId(id);
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']


  function removeItem(poNo, poItemId) {
    let newItems = structuredClone(inwardItems);
    newItems = newItems.filter(item => !((parseInt(item.poNo) === parseInt(poNo)) && (item.poItemId === poItemId)))
    setInwardItems(newItems);
  }


  function findPoItem(poNo, poItemId) {
    if (!poList) return {}
    let po = poList.data.find(p => parseInt(p.id) === parseInt(poNo));
    if (!po) return {}
    let poItem = po.poItems.find(i => i.poItemId === poItemId)
    if (!poItem) return {}
    return poItem
  }


  function isItemInPoAdded(poNo, poItemId, list) {
    let item = list.find(item => ((parseInt(item.poNo) === parseInt(poNo)) && (item.poItemId === poItemId)))
    if (!item) return false
    return true
  }

  function changeOnlyNeededPo(finalInwardItems) {
    let prevFinalItems = structuredClone(finalInwardItems);
    let toKeepItems = prevFinalItems.filter(item => isItemInPoAdded(item.poNo, item.poItemId, inwardItems))
    let newItems = inwardItems.filter(item => !isItemInPoAdded(item.poNo, item.poItemId, finalInwardItems))
    let alreadyInwardedQtyItem = {}

    if (inwardOrReturn === "PurchaseInward") {
      newItems = newItems.map((item) => {
        if (item.alreadyInwardedQty !== undefined) {
          alreadyInwardedQtyItem = { alreadyInwardedQty: item.alreadyInwardedQty }
        }
        return { ...findPoItem(item.poNo, item.poItemId), ...alreadyInwardedQtyItem,poDocId: findFromList(item.poNo,poList.data, "docId"), inwardQty: item?.inwardQty ? item.inwardQty : "0.000", poNo: item.poNo, poItemId: item.poItemId, stockId: item.stockId, id: item.id }
      })
    } else {
      newItems = newItems.map((item) => { return { ...findPoItem(item.poNo, item.poItemId),poDocId: findFromList(item.poNo,poList.data, "docId"), returnQty: item?.returnQty ? item.returnQty : "0.000", poNo: item.poNo, poItemId: item.poItemId, stockId: item.stockId, id: item.id } })
    }
    return [...toKeepItems, ...newItems]
  }


  useEffect(() => {
    if (id) return
    setInwardItems([]);
  }, [poType, supplierId, inwardOrReturn, inwardType, id])


  useEffect(() => {
    setInwardType("AgainstPo")
  }, [inwardOrReturn])

  useEffect(() => {
    if (inwardType === "DirectInward") {
      return
    }
    setFinalInwardItems(prev => changeOnlyNeededPo(prev));
  }, [inwardItems, setFinalInwardItems])

  useEffect(() => {
    if (inwardType === "DirectInward") {
      return
    }
    setFinalInwardItems(changeOnlyNeededPo([]));
  }, [id, setFinalInwardItems, inwardItems])

  if (!branchList || !locationData) return <Loader />

  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          poListLoading || poListFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  const allSuppliers = supplierList ? supplierList.data : []

  function filterSupplier() {
    let finalSupplier = []
    if (poType.toLowerCase().includes("yarn")) {
      finalSupplier = allSuppliers.filter(s => s.yarn)
    } else if (poType.toLowerCase().includes("fabric")) {
      finalSupplier = allSuppliers.filter(s => s.fabric)
    } else {
      finalSupplier = allSuppliers.filter(s => s.PartyOnAccessoryItems.length > 0)
    }
    return finalSupplier
  }
  let supplierListBasedOnSupply = filterSupplier()

  const storeOptions = locationData ?
    locationData.data.filter(item => parseInt(item.locationId) === parseInt(locationId)) :
    [];
  function openSelection() {
    if (!inwardOrReturn || !supplierId || !poType) {
      toast.info("Please Select Inward/Return , Po type and Suppplier", { position: "top-center" })
      return
    }
    setInwardItemSelection(true)
  }

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
    >
      <Modal isOpen={inwardItemSelection} onClose={() => setInwardItemSelection(false)} widthClass={"p-10"}>
        <PoItemsSelection storeId={storeId} inwardOrReturn={inwardOrReturn} setInwardItemSelection={setInwardItemSelection}
          transtype={poType}
          po={poList ? poList.data : []} supplierId={supplierId} inwardItems={inwardItems}
          setInwardItems={setInwardItems} />
      </Modal>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <InwardReturnFormReport
          heading={MODEL}
          loading={
            isLoading || isFetching
          }
          inwardOrReturn={inwardOrReturn}
          tableWidth="100%"
          setForm={setForm}
          data={allData?.data ? allData?.data : []}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          openReport={() => { setFormReport(true) }}
          deleteData={deleteData}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid grid-cols-1`}>
                  <div className={`col-span-1`}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 px-3 overflow-auto'>
                      <legend className='sub-heading'>Purchase Inward Return Info</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-4">
                          <DisabledInput name="Inward / Return No" value={docId} required={true} readOnly={readOnly} />
                          <LongDisabledInput name="Inward / Return Date" value={date} type={"date"} required={true} readOnly={readOnly} />
                          <DropdownInput
                            className={"w-[110px]"}
                            name="Inward/Return"
                            options={purchasePrPi}
                            value={inwardOrReturn}
                            setValue={setInwardOrReturn}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          <>
                            {(inwardOrReturn === "PurchaseInward") &&
                              <DropdownInput
                                className={"w-[110px]"}
                                name="Inward Type"
                                options={inwardTypeOptions}
                                value={inwardType}
                                setValue={setInwardType}
                                required={true}
                                readOnly={id || readOnly}
                              />
                            }
                          </>
                          <div className="col-span-2">
                            <LongDropdownInput name="Supplier" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                          </div>
                          <DropdownInput
                            className={"w-[110px]"}
                            name="Po Type"
                            options={poTypes}
                            value={poType}
                            setValue={setPoType}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          {(inwardType === "DirectInward") &&
                            <DropdownInput className={"w-40"} name="Tax Type" options={dropDownListObject(taxTypeList ? taxTypeList.data : [], "name", "id")} value={taxTemplateId} setValue={setTaxTemplateId} required={true} readOnly={id || readOnly} />
                          }
                          <TextInput name={"Dc No."} value={dcNo} setValue={setDcNo} readOnly={readOnly} />
                          <DateInput name={"Dc Date."} value={dcDate} setValue={setDcDate} type={"date"} readOnly={readOnly} />
                          <DropdownInput name="Location"
                            options={branchList ? (dropDownListObject(id ? branchList.data : branchList.data.filter(item => item.active), "branchName", "id")) : []}
                            value={locationId}
                            setValue={(value) => { setLocationId(value); setStoreId("") }}
                            required={true} readOnly={id || readOnly} />
                          <DropdownInput name="Store"
                            options={dropDownListObject(id ? storeOptions : storeOptions.filter(item => item.active), "storeName", "id")}
                            value={storeId} setValue={setStoreId} required={true} readOnly={id || readOnly} />
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 border border-gray-600 md:pb-5 flex h-[360px] w-full overflow-auto'>
                      <legend className='sub-heading'>Purchase Details</legend>
                      {
                        (inwardType === "DirectInward")
                          ?
                          <>
                            {poType.toLowerCase().includes("yarn")
                              ?
                              <YarnDirectItems id={id} transType={poType} taxTypeId={taxTemplateId} poItems={finalInwardItems} setPoItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                              :
                              (poType.toLowerCase().includes("fabric"))
                                ?
                                <FabricDirectItems id={id} transType={poType} taxTypeId={taxTemplateId} poItems={finalInwardItems} setPoItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                                :
                                <AccessoryDirectItems id={id} transType={poType} taxTypeId={taxTemplateId} poItems={finalInwardItems} setPoItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                            }
                          </>
                          :
                          <>
                            {inwardOrReturn === "PurchaseInward"
                              ?
                              <>
                                {
                                  poType.toLowerCase().includes("yarn")
                                    ?
                                    <YarnInwardItems prefix={prefix} openSelection={openSelection} removeItem={removeItem} transType={poType} finalInwardItems={finalInwardItems} setFinalInwardItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                                    :
                                    poType.toLowerCase().includes("fabric")
                                      ?
                                      <FabricInwardItems prefix={prefix} openSelection={openSelection} removeItem={removeItem} transType={poType} finalInwardItems={finalInwardItems} setFinalInwardItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                                      :
                                      <AccessoryInwardItems prefix={prefix} openSelection={openSelection} removeItem={removeItem} transType={poType} finalInwardItems={finalInwardItems} setFinalInwardItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                                }
                              </>
                              :
                              <>
                                {
                                  poType.toLowerCase().includes("yarn")
                                    ?
                                    <YarnReturnItems prefix={prefix} openSelection={openSelection} removeItem={removeItem} transType={poType} finalInwardItems={finalInwardItems} setFinalInwardItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                                    :
                                    (
                                      poType.toLowerCase().includes("fabric")
                                        ?
                                        <FabricReturnItems prefix={prefix} openSelection={openSelection} removeItem={removeItem} transType={poType} finalInwardItems={finalInwardItems} setFinalInwardItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                                        :
                                        <AccessoryReturnItems prefix={prefix} openSelection={openSelection} removeItem={removeItem} transType={poType} finalInwardItems={finalInwardItems} setFinalInwardItems={setFinalInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                                    )
                                }
                              </>
                            }
                          </>
                      }
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}