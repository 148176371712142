import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IOT_DEVICE_MAST } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const IOTDeviceMastApi = createApi({
    reducerPath: "iotDevice",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["IOTDevice"],
    endpoints: (builder) => ({
        getIOTDevice: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: IOT_DEVICE_MAST + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: IOT_DEVICE_MAST,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["State"],
        }),
        getIOTDeviceById: builder.query({
            query: (id) => {
                return {
                    url: `${IOT_DEVICE_MAST}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["State"],
        }),
        addIOTDevice: builder.mutation({
            query: (payload) => ({
                url: IOT_DEVICE_MAST,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["State"],
        }),
        updateIOTDevice: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${IOT_DEVICE_MAST}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["State"],
        }),
        deleteIOTDevice: builder.mutation({
            query: (id) => ({
                url: `${IOT_DEVICE_MAST}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["State"],
        }),
    }),
});

export const {
    useGetIOTDeviceQuery,
    useGetIOTDeviceByIdQuery,
    useAddIOTDeviceMutation,
    useUpdateIOTDeviceMutation,
    useDeleteIOTDeviceMutation,


} = IOTDeviceMastApi;

export default IOTDeviceMastApi;
