// src/components/LedgerReport.js

import React, { useState } from 'react';
import { useGetLedgerQuery } from '../../../redux/ErpServices/Ledger.Services';
import { convertUnderScoreToSpace, findFromList, getDateFromDateTimeToDisplay, getEntryType, params } from '../../../Utils/helper';
import { Loader } from '../../../Basic/components';
import { DateInput, DropdownInput } from '../../../Inputs';
import { dropDownListObject } from '../../../Utils/contructObject';
import { useGetPartyQuery } from '../../../redux/ErpServices/PartyMasterServices';
import commaNumber from 'comma-number';
import { GenerateButton, PrintButtonOnly } from '../../../Buttons';
import Modal from '../../../UiComponents/Modal';
import LedgerReportPrintFormat from './LedgerReportPrintFormat';
import { PDFViewer } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import moment from 'moment';

const LedgerReport = () => {
    const { companyId } = params
    const [partyId, setPartyId] = useState("");
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [pdfOpen, setPdfOpen] = useState(false);

    const { data, isLoading, isFetching, refetch } = useGetLedgerQuery({ params: { partyId, startDate, endDate, ledgerType: "Customer" } }, { skip: !partyId || !startDate || !endDate })

    const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
        useGetPartyQuery({ params: { companyId, active: true } });

    const [currentIndex, setCurrentIndex] = useState("");

    const allSuppliers = supplierList ? supplierList.data : []


    if (isLoading || isFetching || isSupplierFetching || isSupplierLoading) return <Loader />


    // Sample ledger data (replace with your actual data)
    const ledgerData = data?.data ? data.data : []

    // Calculate the total credit and debit amounts
    const totalCredit = ledgerData.filter(item => item.creditOrDebit === "Credit").reduce((total, entry) => total + Math.abs(entry.amount), 0);
    const totalDebit = ledgerData.filter(item => item.creditOrDebit === "Debit").reduce((total, entry) => total + Math.abs(entry.amount), 0);
    const balance = ((totalDebit - totalCredit) + (data?.openingBalance ? data?.openingBalance : 0))
    return (
        <>
            <Modal isOpen={pdfOpen} onClose={() => { setPdfOpen(false) }} widthClass={"w-[90%] h-[90%]"}>
                <PDFViewer style={tw("w-full h-full")}>
                    <LedgerReportPrintFormat data={data} partyName={findFromList(partyId, supplierList?.data || [], "aliasName")} startDate={getDateFromDateTimeToDisplay(startDate)} endDate={getDateFromDateTimeToDisplay(endDate)} />
                </PDFViewer>
            </Modal>
            <div className=" w-full p-10 bg-gray-50">
                <div className=''>
                    <div className='flex justify-between'>
                        <h1 className="text-2xl font-semibold mb-4 ">Sales Ledger Report</h1>
                        <GenerateButton name='Refresh' onClick={refetch} />
                        <PrintButtonOnly onClick={() => { setPdfOpen(true) }} />
                    </div>
                    <div className='grid grid-cols-3 gap-x-16'>
                        <DropdownInput name="Supplier"
                            options={dropDownListObject(allSuppliers, "aliasName", "id")}
                            value={partyId} setValue={setPartyId} required={true} />
                        <DateInput name={"From"} value={startDate} setValue={setStartDate} />
                        <DateInput name={"To"} value={endDate} setValue={setEndDate} />
                    </div>
                </div>
                {(startDate && endDate && partyId) &&
                    <div className='overflow-auto max-h-[500px]'>
                        <table className="table-auto relative w-full bg-white bg-opacity-90 shadow-md rounded-lg text-xs">
                            <thead className='sticky top-0 bg-gray-50'>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th className='px-4 py-1 text-sm border-2 border-black'>
                                        Opening Balance
                                    </th>
                                    <th className='text-right  px-4 py-1 border-l border-2 border-black'>
                                        {data?.openingBalance ? commaNumber(data?.openingBalance?.toFixed(2)) : "0.00"}
                                    </th>
                                </tr>
                                <tr>
                                    <th className="border px-4 py-1 w-10">S.No.</th>
                                    <th className="border px-4 py-1">Date</th>
                                    <th className="border px-4 py-1">Bill No</th>
                                    <th className="border px-4 py-1">Payment</th>
                                    {/* <th className="border px-4 py-1 w-72">Details</th> */}
                                    <th className="border px-4 py-1">Debit</th>
                                    <th className="border px-4 py-1">Credit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {partyId &&
                                    ledgerData.map((entry, index) => (
                                        <tr key={index} className='cursor-pointer'
                                            onClick={() => {
                                                if (currentIndex === index) {
                                                    setCurrentIndex("")
                                                } else {
                                                    setCurrentIndex(index)
                                                }
                                            }}>
                                            <td className="border px-4 py-1">{index + 1}</td>
                                            <td className="border px-4 py-1">{getDateFromDateTimeToDisplay(entry.partyBillDate)}</td>
                                            <td className="border px-4 py-1">{entry.partyBillNo}</td>
                                            <td className="border px-4 py-1">
                                                {/* {getEntryType((entry?.EntryType ? entry?.EntryType : "").split('_').join(' '), findFromList(partyId, supplierList?.data || [], "aliasName"))} */}
                                                {/* {(entry?.PayOut?.paymentMode || convertUnderScoreToSpace(entry?.EntryType)).toUpperCase()} */}
                                                {(entry?.PayOut?.paymentMode || "").toUpperCase()}
                                            </td>
                                            {/* <td className={`border  px-4 py-1 text-center `}>
                                                <div className='w-full flex justify-between'>
                                                    <span>
                                                    {(entry.EntryType === "Credit" || entry.EntryType === "Debit") ? entry.EntryType :
                                                        entry.EntryType === "PayOut" ? (entry?.PayOut?.paymentType ? (entry?.PayOut?.paymentType).toUpperCase() : "") : "BILL"}
                                                </span>
                                                {(entry.EntryType === "Credit" || entry.EntryType === "Debit") ? <></>
                                                    :
                                                    <span>
                                                        {
                                                            (currentIndex === index)
                                                                ?
                                                                <svg data-accordion-icon class="w-3 h-3 rotate-360 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                                                </svg>
                                                                :
                                                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                                                                </svg>
                                                        }
                                                    </span>
                                                }
                                                </div>
                                                <div >
                                                    {entry.EntryType === "PayOut" ?
                                                        <>
                                                            {(entry?.PayOut.paymentType === "AgainstBill"
                                                                ? entry?.PayOut?.PayOutItems.map(item =>
                                                                    <div key={item.id} className='my-2 font-normal '>
                                                                        <table className='table-fixed w-full'>
                                                                            <tr className='border'>
                                                                                <td className='border text-xs text-center' >
                                                                                    {item.BillEntry.docId}
                                                                                </td>
                                                                                <td className='border text-xs text-right'>
                                                                                    {commaNumber(item.amount.toFixed(2))}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>)
                                                                :
                                                                <>
                                                                    {entry?.PayOut.AdvanceAdjustMent.map((adv, index) =>
                                                                        <div key={index} className=''>
                                                                            {adv.AdvanceAdjustDetails.map(item =>
                                                                                <div key={item.id} className='my-2 font-normal'>
                                                                                    <table className='table-fixed w-full '>
                                                                                        <tr className='border'>
                                                                                            <td className='border text-xs'>
                                                                                                {item.BillEntry.docId}
                                                                                            </td>
                                                                                            <td className='border text-xs text-right'>
                                                                                                {commaNumber(item.amount.toFixed(2))}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </div>

                                                                            )}
                                                                        </div>

                                                                    )
                                                                    }
                                                                </>
                                                            )}
                                                        </>
                                                        :
                                                        <div className='border'>
                                                            {entry?.BillEntry ? entry?.BillEntry.docId : ""}
                                                        </div>
                                                    }
                                                </div>
                                            </td> */}
                                            <td className="border px-4 py-1 text-right text-red-600">{entry.creditOrDebit === "Debit" ? commaNumber(Math.abs(entry.amount).toFixed(2)) : ""}</td>
                                            <td className="border px-4 py-1 text-right text-green-600">{entry.creditOrDebit === "Credit" ? commaNumber(Math.abs(entry.amount).toFixed(2)) : ""}</td>
                                        </tr>
                                    ))
                                }
                                <tr className='font-bold'>
                                    <td className=" px-4 py-1"></td>
                                    <td className=" px-4 py-1"></td>
                                    <td className=" px-4 py-1"></td>
                                    <td className="px-4 py-1 text-sm text-center">Totals</td>
                                    <td className="border px-4 py-1 text-right text-red-600">{commaNumber(Math.abs(totalDebit).toFixed(2))}</td>
                                    <td className="border px-4 py-1 text-right text-green-600">{commaNumber(Math.abs(totalCredit).toFixed(2))}</td>
                                </tr>
                                <tr className='font-bold sticky bottom-0 bg-gray-50 text-sm'>
                                    <td className="px-4 py-1"></td>
                                    <td className="px-4 py-1"></td>
                                    <td className="px-4 py-1"></td>
                                    <td className="px-4 py-1"></td>
                                    <td colSpan={1} className={`mt-2 text-center  border-2 border-black`}>
                                        Closing Balance
                                    </td>
                                    <td className={`px-4 py-1 text-right   border-2 border-black`}>{commaNumber(balance.toFixed(2))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                {/* <div className='my-3 text-blue-700 text-sm text-right'>
                    Remarks : "-" in Amount Indicates Our Payment is Pending.
                </div> */}
            </div>
        </>
    );
};

export default LedgerReport;
