import React, { useState } from 'react'
import { getItemFullNameFromShortCode, substract } from '../../../Utils/helper'
import Modal from '../../../UiComponents/Modal';
import RawMaterialItem from './RawMaterialItem';
import { VIEW } from '../../../icons';
import { exportFileToCsv } from '../../../Utils/excelHelper';
import { ExcelButton } from '../../../Buttons';
import YarnitemInwardReport from './YarnPoItemInwardReport';

const YarnProgramItem = ({ id, index, item, rawMaterialType, delivery, dataObj }) => {
    const [currentProgramItemOpen, setCurrentProgramItemOpen] = useState("");
    const [inwardBreakUp, setInwardBreakUp] = useState(false);
    let programQty = item.qty;
    let noOfBags = item.noOfBags;
    let weightPerBag = item.weightPerBag;
    let inwardQty = item.alreadyInwardedQty;
    let inwardBags = item.alreadyInwardedBags;
    let balanceQty = substract(programQty, inwardQty);
    let balanceBags = substract(noOfBags, inwardBags);
    balanceBags = balanceBags > 0 ? balanceBags : 0
    let alreadyBillQty = item.alreadyBillQty;
    let balBillQty = substract(inwardQty, item.alreadyBillQty)
    balBillQty = balBillQty > 0 ? balBillQty : 0
    function exportexcel() {
        let excelPoItems = item.rawMaterials.map(rawMaterial => {
            let qty = parseFloat(rawMaterial.qty).toFixed(3)
            let balanceQty = substract(rawMaterial.qty, rawMaterial.alreadyUsedQty).toFixed(3)
            const programType = getItemFullNameFromShortCode(delivery.Process.io[1])
            let newItem = {}
            if (programType === "GreyYarn" || programType === "DyedYarn") {
                newItem["Item"] = rawMaterial.Yarn.aliasName;
            } else if (programType === "GreyFabric" || programType === "DyedFabric") {
                newItem["Item"] = rawMaterial.Fabric.aliasName;
                newItem["Design"] = rawMaterial.Design.name;
                newItem["Gauge"] = rawMaterial.Gauge.name;
                newItem["K-Dia"] = rawMaterial.KDia.name;
                newItem["F-Dia"] = rawMaterial.FDia.name;
            }
            newItem["Uom"] = rawMaterial.Uom.name
            newItem["Color"] = rawMaterial.Color.name
            newItem["Delivery Qty"] = qty;
            newItem["Used Qty"] = rawMaterial.alreadyUsedQty;
            newItem["Balance Qty"] = balanceQty;
            return newItem
        })
        exportFileToCsv([{
            "Yarn": item.Yarn.aliasName,
            Color: item.Color.name,
            Uom: item.Uom.name,
            "Supplier": delivery?.supplier?.aliasName
        }, ...excelPoItems,], `${item.Yarn.aliasName}`);
    }
    return (
        <>
            <Modal isOpen={Number.isInteger(currentProgramItemOpen)} onClose={() => setCurrentProgramItemOpen("")}>
                <RawMaterialItem rawMaterialType={rawMaterialType} item={item} />
            </Modal>
            <Modal isOpen={inwardBreakUp} onClose={() => setInwardBreakUp(false)}>
                <YarnitemInwardReport dataObj={dataObj} rawMaterialType={rawMaterialType} item={item} inwardItems={item.ProcessInwardDetails} />
            </Modal>
            <tr key={id} className={`table-row ${(currentProgramItemOpen === index) ? "border-2 border-black" : ""}`}>
                <td className='text-center   table-data'>{index + 1}</td>
                <td className='text-left  table-data'>{item?.Yarn?.aliasName}</td>
                <td className='text-left   table-data'>{item?.Color?.name}</td>
                <td className='text-left   table-data'>{item?.Uom?.name}</td>
                <td className='text-right  table-data'>{noOfBags}</td>
                <td className='text-right  table-data'>{weightPerBag}</td>
                <td className='text-right  table-data'>{programQty}</td>
                <td className='text-right   table-data' onClick={() => setInwardBreakUp(true)}>{inwardQty}</td>
                <td className='text-right   table-data' onClick={() => setInwardBreakUp(true)}>{inwardBags}</td>
                <td className='text-right   table-data'>{balanceQty}</td>
                <td className='text-right   table-data'>{balanceBags}</td>
                <td className='text-right   table-data'>{alreadyBillQty}</td>
                <td className='text-right   table-data'>{balBillQty}</td>
                <td className='text-center   table-data' onClick={() => setCurrentProgramItemOpen(index)}>{VIEW}</td>
                <td className='py-1 text-center w-8'>
                    <ExcelButton onClick={(e) => {
                        e.stopPropagation();
                        exportexcel()
                    }} width={18} />
                </td>
            </tr>
        </>
    )
}

export default YarnProgramItem
