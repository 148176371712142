import React, { useCallback, useState } from 'react';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import { DELETE, PLUS, VIEW } from '../../../icons';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, substract, sumArray } from '../../../Utils/helper';
import Modal from '../../../UiComponents/Modal';
import { YarnLotGrid } from './LotGrid';
import { useGetYarnMasterQuery } from '../../../redux/ErpServices/YarnMasterServices';
import YarnProgramFillGrid from "./YarnProgramFillGrid"

const YarnProgram = ({ id, programDetails, setProgramDetails, readOnly, setCurrentProgramIndex, finishedGoodsType, inwardDetails, setInwardDetails }) => {
    const [fillGrid, setFillGrid] = useState(inwardDetails.length === 0);
    const [currentProgramLotDetailsIndex, setCurrentProgramLotDetailsIndex] = useState("");
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };
    const handleInputChange = (value, index, field) => {
        setInwardDetails((prev) => {
            const newBlend = structuredClone(prev);
            newBlend[index][field] = value;
            return newBlend
        });
    };
    const deleteItem = (id) => {
        setInwardDetails(prev => {
            return prev.filter(item => parseInt(item.processDeliveryProgramDetailsId) !== parseInt(id))
        })
    }

    const { data: yarnList } =
        useGetYarnMasterQuery({ params });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { ...params, active: true } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });

    function getTotals(field) {
        const total = inwardDetails.reduce((accumulator, current) => {
            return accumulator + current.lotDetails.reduce((acc, curr) => acc + parseFloat(curr[field] ? curr[field] : 0), 0)
        }, 0)
        return parseFloat(total)
    }

    const getProgramDetailsData = useCallback((processDeliveryProgramDetailsId, property) => {
        let program = programDetails.find(item => parseInt(item.id) === parseInt(processDeliveryProgramDetailsId));
        if (!program) return ""
        return program[property]
    }, [programDetails])

    function handleInputChangeLotNo(value, index, lotIndex, field) {
        setInwardDetails(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]["lotDetails"]) return inwardItems
            newBlend[index]["lotDetails"][lotIndex][field] = value;
            if (field !== "inwardQty") {
                newBlend[index]["lotDetails"][lotIndex]["inwardQty"] =
                    (parseFloat(newBlend[index]["lotDetails"][lotIndex]["inwardBags"]) * parseFloat(newBlend[index]["lotDetails"][lotIndex]["weightPerBag"])).toFixed(3)
            }
            return newBlend
        });
    }
    function addNewLotNo(index) {
        setInwardDetails(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]) return inwardItems
            if (newBlend[index]["lotDetails"]) {
                newBlend[index]["lotDetails"] = [
                    ...newBlend[index]["lotDetails"],
                    { lotNo: "", inwardQty: "0.000", noOfRolls: 0 }]
            } else {
                newBlend[index]["lotDetails"] = [{ lotNo: "", qty: "0.000", noOfRolls: 0 }]
            }
            return newBlend
        })
    }
    function removeLotNo(index, lotIndex) {
        setInwardDetails(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]["lotDetails"]) return inwardItems
            newBlend[index]["lotDetails"] = newBlend[index]["lotDetails"].filter((_, index) => index != lotIndex)
            return newBlend
        })
    }
    let balanceQty = substract(parseFloat(getProgramDetailsData(inwardDetails[currentProgramLotDetailsIndex]?.processDeliveryProgramDetailsId, "qty")),
        parseFloat(getProgramDetailsData(inwardDetails[currentProgramLotDetailsIndex]?.processDeliveryProgramDetailsId, "alreadyInwardedQty")))


    if (!yarnList || !colorList || !uomList) return <Loader />
    return (
        <>
            <Modal isOpen={fillGrid} >
                <YarnProgramFillGrid onDone={() => { setFillGrid(false) }} readOnly={readOnly} programDetails={programDetails} setProgramDetails={setProgramDetails}
                    inwardDetails={inwardDetails} setInwardDetails={setInwardDetails} />
            </Modal>
            <Modal isOpen={Number.isInteger(currentProgramLotDetailsIndex)} onClose={() => { setCurrentProgramLotDetailsIndex("") }}>
                <YarnLotGrid
                    readOnly={readOnly}
                    onClose={() => { setCurrentProgramLotDetailsIndex("") }}
                    addNewLotNo={addNewLotNo}
                    removeLotNo={removeLotNo}
                    handleInputChangeLotNo={handleInputChangeLotNo}
                    index={currentProgramLotDetailsIndex}
                    lotDetails={inwardDetails[currentProgramLotDetailsIndex]?.lotDetails ? inwardDetails[currentProgramLotDetailsIndex]?.lotDetails : []}
                    balanceQty={balanceQty} />
            </Modal>
            <div className={`w-full`}>
                <table className=" text-xs table-fixed w-full">
                    <thead className='bg-blue-200 top-0'>
                        <tr>
                            <th className="table-data w-10">S.no</th>
                            <th className="table-data  w-32">Items<span className="text-red-500">*</span></th>
                            <th className="table-data  w-32">Color<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">UOM<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Process Cost per Kg<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Program Qty<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">A. Inward Qty<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Bal. Qty<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Lot Details.<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Inward Qty<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Inward Bags<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">RawMaterial Details</th>
                            {!readOnly &&
                                <th className="table-data  w-5 text-green-600" onClick={() => { setFillGrid(true) }}> {PLUS} </th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardDetails.map((row, index) => (
                            <tr key={index} className="w-full table-row" >
                                <td className="table-data  ">
                                    {index + 1}
                                </td>
                                <td className='table-data'>
                                    {findFromList(getProgramDetailsData(row.processDeliveryProgramDetailsId, "yarnId"), yarnList.data, "aliasName")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(getProgramDetailsData(row.processDeliveryProgramDetailsId, "colorId"), colorList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(getProgramDetailsData(row.processDeliveryProgramDetailsId, "uomId"), uomList.data, "name")}
                                </td>
                                <td className='table-data text-right'>
                                    {row?.processCost ? row?.processCost : 0}
                                </td>
                                <td className='table-data text-right'>
                                    {parseFloat(getProgramDetailsData(row.processDeliveryProgramDetailsId, "qty")).toFixed(3)}
                                </td>
                                <td className='text-right table-data'>
                                    {parseFloat(getProgramDetailsData(row.processDeliveryProgramDetailsId, "alreadyInwardedQty")).toFixed(3)}
                                </td>
                                <td className='text-right table-data'>
                                    {substract(parseFloat(getProgramDetailsData(row.processDeliveryProgramDetailsId, "qty")), parseFloat(getProgramDetailsData(row.processDeliveryProgramDetailsId, "alreadyInwardedQty"))).toFixed(3)}
                                </td>
                                <td className='text-right table-data'>
                                    <button
                                        className=" rounded py-1 w-full"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                setCurrentProgramLotDetailsIndex(index);
                                            }
                                        }}
                                        onClick={() => setCurrentProgramLotDetailsIndex(index)}>
                                        {VIEW}
                                    </button>
                                </td>
                                <td className='text-right table-data'>
                                    {sumArray(row?.lotDetails ? row.lotDetails : [], "inwardQty").toFixed(3)}
                                </td>
                                <td className='text-right table-data'>
                                    {sumArray(row?.lotDetails ? row.lotDetails : [], "inwardBags").toFixed(3)}
                                </td>
                                <td className='table-data'>
                                    <button
                                        className=" rounded py-1 w-full"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                setCurrentProgramIndex(index);
                                            }
                                        }}
                                        onClick={() => setCurrentProgramIndex(index)}>
                                        {VIEW}
                                    </button>
                                </td>
                                {!readOnly
                                    &&
                                    <td className='table-data'>
                                        <button
                                            className=" rounded py-1 w-full"
                                            onClick={() => deleteItem(row.processDeliveryProgramDetailsId)}>
                                            {DELETE}
                                        </button>
                                    </td>
                                }
                            </tr>
                        ))}
                        {Array.from({ length: 3 - inwardDetails.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                <td className='table-data'>
                                </td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                {!readOnly &&
                                    <td className="table-data   "></td>
                                }
                            </tr>)
                        }
                        <tr className='bg-blue-200 w-full border border-gray-400 h-7 font-bold'>
                            <td className="table-data text-center" colSpan={9}>Total</td>
                            <td className="table-data text-right px-1 w-10">{getTotals("inwardQty").toFixed(3)}</td>
                            <td className="table-data text-right px-1 w-10">{getTotals("inwardRolls").toFixed(3)}</td>
                            <td className="table-data w-10"></td>
                            {!readOnly &&
                                <td className="table-data w-10"></td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </>

    )
}

export default YarnProgram