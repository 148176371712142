import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { TextInput, CheckBox, DropdownInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";

import { useGetIOTDeviceQuery } from "../../../redux/ErpServices/IOTDeviceMasterService";
import { useAddLineOperationMasterDataMutation, useDeleteLineOperationMasterDataMutation, useGetLineOperationMasterByIdQuery, useGetLineOperationMasterQuery, useUpdateLineOperationMasterDataMutation } from "../../../redux/ErpServices/LineAndOperationMasterService";
import { useGetOperationNameQuery } from "../../../redux/ErpServices/operationNameServices";


import DataTable from "./TemplateFillGrid";
import { GenerateButton } from "../../../Buttons";
import { useGetGroupMasterQuery } from "../../../redux/ErpServices/GroupMasterService";
import { useGetPartyCategoryMasterQuery } from "../../../redux/ErpServices/PartyCategoryMasterServices";
import { useGetStyleMasterQuery } from "../../../redux/ErpServices/StyleMasterServices";

import { useGetOperationGroupMastQuery } from "../../../redux/ErpServices/OperationGroupServices";
import { useAddLineSettingMutation, useDeleteLineSettingMutation, useGetLineSettingByIdQuery, useGetLineSettingQuery, useUpdateLineSettingMutation } from "../../../redux/ErpServices/LineSettingServices";
import { useGetOperationBulletInQuery } from "../../../redux/ErpServices/OperationBulletInService";
import { useGetSectionMasterQuery } from "../../../redux/ErpServices/SectionMaster";
const MODEL = "Line Setting";

export default function Form() {
    const [form, setForm] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [sectionmasterId, setSectionMasterId] = useState('')
    const [samTollerance, setSamTollerance] = useState('')
    const [lineOperationId, setLineOperationId] = useState('')
    const [active, setActive] = useState(true);
    const [employeeRFID, setEmployeeRFID] = useState("")
    const [name, setName] = useState("");
    const [lineSettingdet, setLineSettingdet] = useState([]);
    const [groupId, setGroupId] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [buyerName, setBuyerName] = useState("");
    const [styleId, setStyleId] = useState("");
    const childRecord = useRef(0);
    const dispatch = useDispatch();
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId",

        ),

    };
    const { data: operationGroupName, } = useGetOperationGroupMastQuery({ params });

    const { data: buyerData } =
        useGetPartyCategoryMasterQuery({ params: { ...params, active: true } });
    const { data: styleData } =
        useGetStyleMasterQuery({ params: { ...params, active: true } });
    const { data: lineOperationData } = useGetLineOperationMasterQuery({ params, searchParams: searchValue })

    const { data: operationNameData } = useGetOperationNameQuery();
    const skipData = !buyerName || !styleId;
    const { data: operationBulletInData } = useGetOperationBulletInQuery({ params: { ...params, styleId: styleId, buyerName: buyerName, groupId: groupId } }, { skip: skipData });
    const { data: groupData, isLoading: isCountryLoading, isFetching: isCountryFetching } =
        useGetGroupMasterQuery({ params });
    const { data: lineData, isLoading: isLineLoading, isFetching: isLineFetching } =
        useGetLineOperationMasterQuery({ params });
    const { data: allData, isLoading, isFetching } = useGetLineSettingQuery({ params, searchParams: searchValue });
    const { data: sectionData, isLoading: isSectionData, isFetching: isSectionDatafetch } =
        useGetSectionMasterQuery({});
    const {
        data: singleData,
        isFetching: isSingleFetching,
    } = useGetLineSettingByIdQuery(id, { skip: !id });


    const [addData] = useAddLineSettingMutation();
    const [updateData] = useUpdateLineSettingMutation();
    const [removeData] = useDeleteLineSettingMutation();

    const syncFormWithDb = useCallback((data) => {
        if (id) { setReadOnly(true); setActive(true) }

        setActive(id ? (data?.active ? data.active : false) : true);
        setName(data?.name ? data.name : "");
        setEmployeeRFID(data?.rfidId ? data?.rfidId : "")
        setGroupId(data?.
            gropMasterId

            ? data?.gropMasterId
            : '')
        setLineSettingdet(data?.LineSettingDet ? data?.LineSettingDet : [])
        setStyleId(data?.styleId ? data?.styleId : "")
        setBuyerName(data?.partyCategoryId ? data?.partyCategoryId : "")
        setSamTollerance(data?.samTollerance ? data?.samTollerance : '')
        setLineOperationId(data?.
            lineOperationId ? data?.
            lineOperationId : '')
        childRecord.current = data?.childRecord ? data?.childRecord : 0;
        setSectionMasterId(data?.sectionMasterId
            ? data?.sectionMasterId
            : '')
    }, [id]);

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, id, syncFormWithDb, singleData]);



    const data = {
        id,
        name,
        active,
        lineOperationId,
        lineSettingdet,
        groupId,
        buyerName,
        styleId,
        samTollerance,
        sectionmasterId
    };
    console.log(data, 'data');


    const validateData = (data) => {
        if (data.groupId) {
            return true;
        }
        return false;
    };

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId("")
            syncFormWithDb(undefined)
            toast.success(text + "Successfully");

        } catch (error) {
            console.log("handle")


        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                dispatch({
                    type: `lineOperation/invalidateTags`,
                    payload: ['lineOperation'],
                });
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setReadOnly(false);
        setForm(true);
        setSearchValue("");
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }
    const tableHeaders = [
        "Group Name", "Buyer", "Style", "Status"
    ]
    const tableDataNames = ["dataObj.lineOperation.name", "dataObj.PartyCategory.name", "dataObj.Style.name",
        'dataObj.active ? ACTIVE : INACTIVE']

    // function generateRows() {
    //     const isValidRow = (row) => row.isActive || row.iOTDeviceId || row.operationNameId;
    //     setLineSettingdet(prev => {
    //         let oldValidData = prev.filter(i => isValidRow(i));
    //         if (noOfProcess < oldValidData.length) {
    //             toast.warning("Cannot Reduce No of Machines when Data entered...!!! Please delete rows Manually", { position: "top-center" });
    //             return prev;
    //         }
    //         let newData = Array.from({ length: noOfProcess - oldValidData.length }).map(i => ({
    //             iOTDeviceId: "", operationNameId: "", isActive: false
    //         }));
    //         return [...oldValidData, ...newData]
    //     })
    // }

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme">
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                    childRecord={childRecord.current}
                />
                <div className="flex w-full grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="flex w-[75%] border overflow-auto">
                        <div className="mr-1 md:ml-2 h-[20%] w-full ">
                            <fieldset className="frame my-1">
                                <legend className="sub-heading">Line Info</legend>
                                <div className="grid grid-cols-4 my-2 items-center">
                                    <DropdownInput
                                        name="Line Name"
                                        options={dropDownListObject(id ? lineData?.data || [] : lineData?.data || [].filter(item => item.active), "name", "id")}
                                        value={lineOperationId}
                                        setValue={setLineOperationId}
                                        required={true}
                                        readOnly={readOnly}
                                    />
                                    <DropdownInput
                                        name="Group Name"
                                        options={dropDownListObject(id ? groupData?.data || [] : groupData?.data || [].filter(item => item.active), "name", "id")}
                                        value={groupId}
                                        setValue={setGroupId}
                                        required={true}
                                        readOnly={readOnly}
                                    />

                                    <DropdownInput name="Buyer" options={dropDownListObject((buyerData?.data ? buyerData?.data : []), "name", "id")} value={buyerName} setValue={setBuyerName} readOnly={readOnly} required={true} />
                                    <DropdownInput name="style" options={dropDownListObject((styleData?.data ? styleData?.data : []), "name", "id")} value={styleId} setValue={setStyleId} readOnly={readOnly} required={true} />

                                    <DropdownInput name="Section" options={dropDownListObject((sectionData?.data ? sectionData?.data : []), "name", "id")} value={sectionmasterId} setValue={(value) => { setSectionMasterId(value); }} readOnly={readOnly} required={true} />

                                    {/* <div className="flex items-center justify-start">
                                        <div className="w-[50%] flex">
                                            <TextInput
                                                name="No.of.Process"
                                                type="text"
                                                value={noOfProcess}
                                                setValue={setNoOfProcess}
                                                required={true}
                                                readOnly={readOnly}
                                                disabled={(childRecord.current > 0)}
                                            /> */}
                                    {/* <div className="w-[10%]" title="Generate">
                                                <GenerateButton name="" onClick={generateRows} /></div> */}
                                    {/* </div>
                                    </div> */}
                                    <CheckBox
                                        name="Active"
                                        readOnly={readOnly}
                                        value={active}
                                        setValue={setActive}
                                    />
                                </div>

                            </fieldset>
                            <div className='w-[100%] border-gray-400 h-[80%]'><DataTable
                                readOnly={readOnly}
                                operationData={operationNameData?.data || []}
                                operationBulletInData={operationBulletInData?.data || []}
                                lineSettingdet={lineSettingdet} setLineSettingdet={setLineSettingdet}
                                lineOperationData={lineOperationData?.data || []}

                            /></div>
                        </div>
                    </div>
                    <div className="frame hidden w-[25%] md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
