import React from 'react';
import FabricPoItem from './FabricPoItem';



const FabricInwardItems = ({ inwardItems, setInwardItems, readOnly, removeItem, billEntryId, supplierId, processId }) => {
    const handleInputChange = (value, index, field) => {
        setInwardItems(inwardItems => {
            if (!inwardItems[index]) return inwardItems
            const newBlend = structuredClone(inwardItems);
            newBlend[index][field] = value;
            return newBlend
        });
    };

    return (
        <>
            <div className={`relative w-full overflow-y-auto py-1`}>
                <table className="table-data text-xs table-auto border border-gray-500  w-full">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8'>
                            <th className='text-center w-8 table-data '>
                                S.no
                            </th>
                            <th className='text-center  table-data'>
                                Del.no
                            </th>
                            <th className=' table-data'>
                                Fabric Name
                            </th>
                            <th className=' table-data'>
                                Color
                            </th>
                            <th className=' table-data'>
                                Design
                            </th>
                            <th className=' table-data'>
                                Gauge
                            </th>
                            <th className=' table-data'>
                                LL
                            </th>
                            <th className=' table-data'>
                                Gsm
                            </th>
                            <th className=' table-data'>
                                K-Dia
                            </th>
                            <th className=' table-data'>
                                F-Dia
                            </th>
                            <th className=' table-data'>
                                Uom
                            </th>
                            <th className="table-data w-10">Prog. Qty</th>
                            <th className="table-data w-10">Inward Qty</th>
                            <th className="table-data  w-10">A.Bill.Qty</th>
                            <th className="table-data  w-10">Bal.Bill. Qty</th>
                            <th className="table-data  w-10">Bill.Qty</th>
                            <th className="table-data  w-10">Bill.Rate</th>
                            <th className="table-data  w-10">Bill.Amount</th>
                            <th className="table-data  w-10">Disc.Type</th>
                            <th className="table-data  w-10">Disc.Value</th>
                            <th className="table-data  w-10">Taxable Amount</th>
                            <th className="table-data  w-10">Tax</th>
                            <th className="table-data  w-10">Amount</th>
                            <th className="table-data  w-10">Notes</th>
                            {!readOnly &&
                                <th className='table-data table-data w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) =>
                            <FabricPoItem item={item} billEntryId={billEntryId} removeItem={removeItem} supplierId={supplierId} processId={processId}
                                readOnly={readOnly} key={index} index={index} handleInputChange={handleInputChange} />
                        )}
                        {Array.from({ length: 8 - inwardItems.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                {Array.from({ length: 24 }).map(i =>
                                    <td className="table-data   "></td>
                                )}
                                {!readOnly &&
                                    <td className="table-data w-10"></td>
                                }
                            </tr>)
                        }
                    </tbody>
                </table>
            </div >
        </>
    )
}

export default FabricInwardItems