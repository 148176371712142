import { DELETE, PLUS } from '../../../icons';
import { useGetAccessoryMasterQuery } from '../../../redux/ErpServices/AccessoryMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetSizeMasterQuery } from '../../../redux/ErpServices/SizeMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, isBetweenRange, substract } from '../../../Utils/helper';
import { toast } from 'react-toastify';
import PoNumber from '../PoNumber';


const AccessoryInwardItems = ({ finalInwardItems, setFinalInwardItems, readOnly, removeItem, openSelection }) => {
    const handleInputChange = (value, index, field) => {
        console.log("value", value, index, field)
        const newBlend = structuredClone(finalInwardItems);
        newBlend[index][field] = value;
        setFinalInwardItems(newBlend);
    };

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const { data: accessoryList } =
        useGetAccessoryMasterQuery({ params: { companyId } });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { companyId } });

    function findAccessoryItemName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(row => parseInt(row.id) === parseInt(id))
        return acc ? acc.accessoryItem.name : null
    }

    function findAccessoryGroupName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(row => parseInt(row.id) === parseInt(id))
        return acc ? acc.accessoryItem.AccessoryGroup.name : null
    }

    const { data: sizeList } =
        useGetSizeMasterQuery({ params: { companyId } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params: { companyId } });


    if (!accessoryList || !colorList || !uomList || !sizeList) return <Loader />
    return (
        <>
            <div className={`relative w-full overflow-auto py-1`}>
                <table className="border border-gray-500 text-xs table-fixed w-full  ">
                    <thead className='bg-blue-200 top-0 border border-gray-500'>
                        <tr className='h-8'>
                            <th className="table-data w-10  text-center">S.no</th>
                            <th className="table-data  w-32 text-center">Po.no</th>
                            <th className="table-data ">Accessory Name</th>
                            <th className="table-data ">Accessory Items</th>
                            <th className="table-data ">Accessory Group</th>
                            <th className="table-data ">Colors</th>
                            <th className="table-data ">Size</th>
                            <th className="table-data  ">UOM</th>
                            <th className="table-data  ">Po Qty</th>
                            <th className="table-data  ">Already Inwarded Qty</th>
                            <th className="table-data  ">Balance Qty</th>
                            <th className="table-data  ">Inward Qty</th>
                            <th className="table-data  ">Price</th>
                            <th className="table-data  ">Gross</th>
                            {!readOnly
                                &&
                                <th className='w-20'>
                                    <div onClick={openSelection}
                                        className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                                        {PLUS}
                                    </div>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full '>
                        {finalInwardItems.map((row, index) => (
                            <tr key={index} className="w-full table-row">
                                <td className=" text-left py-1 px-1 table-data">
                                    {index + 1}
                                </td>
                                <td className='text-left px-1 table-data'>
                                    {row.poDocId}
                                </td>
                                <td className='table-data text-left px-1 '>
                                    {findFromList(row.accessoryId, accessoryList.data, "aliasName")}
                                </td>
                                <td className='table-data  text-left px-1'>
                                    {findAccessoryItemName(row.accessoryId)}
                                </td>
                                <td className='table-data  text-left px-1'>
                                    {findAccessoryGroupName(row.accessoryId)}
                                </td>
                                <td className='table-data  text-left px-1'>
                                    {findFromList(row.colorId, colorList.data, "name")}
                                </td>
                                <td className='table-data text-right px-1'>
                                    {findFromList(row.sizeId, sizeList.data, "name")}
                                </td>
                                <td className='table-data text-right px-1'>
                                    {findFromList(row.uomId, uomList.data, "name")}
                                </td>
                                <td className=' text-right px-1 table-data '>
                                    {substract(row.qty, row.alreadyCancelQty).toFixed(3)}
                                </td>
                                <td className='table-data text-right px-1'>
                                    {substract(parseFloat(row.alreadyInwardedQty), parseFloat(row.alreadyReturnedQty)).toFixed(3)}
                                </td>
                                <td className='table-data text-right px-1'>
                                    {substract(substract(row.qty, row.alreadyCancelQty), (parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty))).toFixed(3)}
                                </td>
                                <td className='table-data text-right px-1'>
                                    <input
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "inwardQty") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1  px-1 w-full table-data-input"
                                        value={row.inwardQty}
                                        disabled={readOnly}
                                        onChange={(event) => {
                                            if (!event.target.value) {
                                                handleInputChange(0, index, "inwardQty");
                                                return
                                            }
                                            if (isBetweenRange(0, substract(substract(row.qty, row.alreadyCancelQty), (parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty))), event.target.value)) {
                                                handleInputChange(event.target.value.replace(/^0+/, ''), index, "inwardQty")
                                            } else {
                                                toast.info("Inward Qty Cannot be more than balance Qty", { position: 'top-center' })
                                            }
                                        }}
                                        onBlur={(e) => {

                                            if (!e.target.value) {
                                                handleInputChange(0.000, index, "inwardQty");
                                                return
                                            }
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "inwardQty")
                                        }

                                        }
                                    />
                                </td>
                                <td className='table-data text-right px-1'>
                                    {(row.price)}
                                </td>
                                <td className='table-data text-right px-1'>
                                    {parseFloat((row.price) * (row.inwardQty)).toFixed(2)}
                                </td>
                                {!readOnly &&
                                    <td className=' w-12'>
                                        <div tabIndex={-1} onClick={() => removeItem(row.poNo, row.poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                            {DELETE}
                                        </div>
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default AccessoryInwardItems
