import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SHIFT_MASTER } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const ShiftMasterApi = createApi({
    reducerPath: "ShiftMaster",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["ShiftMaster"],
    endpoints: (builder) => ({
        getShiftMaster: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: SHIFT_MASTER + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: SHIFT_MASTER,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["ShiftMaster"],
        }),
        getShiftMasterById: builder.query({
            query: (id) => {
                return {
                    url: `${SHIFT_MASTER}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["ShiftMaster"],
        }),
        addShiftMaster: builder.mutation({
            query: (payload) => ({
                url: SHIFT_MASTER,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["ShiftMaster"],
        }),
        updateShiftMaster: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${SHIFT_MASTER}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["ShiftMaster"],
        }),
        deleteShiftMaster: builder.mutation({
            query: (id) => ({
                url: `${SHIFT_MASTER}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["ShiftMaster"],
        }),
    }),
});

export const {
    useGetShiftMasterQuery,
    useGetShiftMasterByIdQuery,
    useAddShiftMasterMutation,
    useUpdateShiftMasterMutation,
    useDeleteShiftMasterMutation,
} = ShiftMasterApi;

export default ShiftMasterApi;
