import React, { useEffect, useState } from 'react'
import { useGetPoItemByIdQuery } from '../../../redux/ErpServices/PoServices'
import { Loader } from '../../../Basic/components'
import { DELETE, VIEW } from '../../../icons'
import { isGridDatasValid, substract, sumArray } from '../../../Utils/helper'
import Modal from '../../../UiComponents/Modal'
import { YarnLotGridWithStock } from './LotGridWithStock'

const YarnPoItem = ({ poItemId, index, handleInputChange, readOnly, qty, noOfBags, removeItem, purchaseInwardId,
    storeId, stockId, item, handleInputChangeLotNo }) => {
    const [lotGrid, setLotGrid] = useState(false)
    const { data, isLoading, isFetching } = useGetPoItemByIdQuery({ id: item.poItemsId, purchaseInwardId, storeId, stockId: item.stockId }, { skip: !(item?.poItemsId) })

    useEffect(() => {
        if (purchaseInwardId) return
        if (isLoading || isFetching || !data?.data) return
        handleInputChange(data.data.alreadyInwardReturnLotWiseData.map(item => {
            let newItem = structuredClone(item)
            newItem["weightPerBag"] = parseFloat(data.data.weightPerBag).toFixed(3)
            newItem["noOfBags"] = 0
            newItem["qty"] = parseFloat(0).toFixed(3)
            return newItem
        }), index, "lotDetails", 0)
    }, [isFetching, isLoading, data, purchaseInwardId])

    useEffect(() => {
        console.log(purchaseInwardId, "purchaseInwardId")
        if (!purchaseInwardId) return
        if (isLoading || isFetching || !data?.data) return
        handleInputChange(data.data.alreadyInwardReturnLotWiseData.map(alreadyItem => {
            let newItem = structuredClone(alreadyItem)
            let lotIndex = item?.lotDetails ? item?.lotDetails.findIndex(i => (i?.lotNo ? i?.lotNo : null) === (alreadyItem?.lotNo ? alreadyItem?.lotNo : null)) : -1
            if (lotIndex === -1) {
                newItem["weightPerBag"] = parseFloat(data.data.weightPerBag).toFixed(3)
            } else {
                newItem["noOfBags"] = item.lotDetails[lotIndex].noOfBags
                newItem["weightPerBag"] = parseFloat(item.lotDetails[lotIndex].weightPerBag).toFixed(3)
                newItem["qty"] = parseFloat(item.lotDetails[lotIndex].qty).toFixed(3)
            }
            console.log(item, "item")
            return newItem
        }), index, "lotDetails", 0)
    }, [isFetching, isLoading, data])

    if (isLoading || isFetching) return <Loader />
    const poItem = data.data
    let poQty = parseFloat(poItem.qty).toFixed(3)
    let cancelQty = poItem.alreadyCancelData?._sum.qty ? poItem.alreadyCancelData._sum.qty : "0.000";
    let alreadyInwardedQty = poItem.alreadyInwardedData?._sum?.qty ? parseFloat(poItem.alreadyInwardedData._sum.qty).toFixed(3) : "0.000";
    let alreadyReturnedQty = poItem.alreadyReturnedData?._sum?.qty ? parseFloat(poItem.alreadyReturnedData._sum.qty).toFixed(3) : "0.000";
    let balanceQty = substract(substract(poQty, cancelQty), substract(alreadyInwardedQty, alreadyReturnedQty)).toFixed(3)
    let poBags = poItem.noOfBags
    let cancelBags = poItem.alreadyCancelData?._sum.noOfBags ? poItem.alreadyCancelData._sum.noOfBags : 0.000;
    let alreadyInwardedBags = poItem.alreadyInwardedData?._sum?.noOfBags ? poItem.alreadyInwardedData._sum.noOfBags : 0.000;
    let alreadyReturnedBags = poItem.alreadyReturnedData?._sum?.noOfBags ? parseFloat(poItem.alreadyReturnedData._sum.noOfBags).toFixed(3) : "0.000";
    let balanceBags = substract(substract(poBags, cancelBags), substract(alreadyInwardedBags, alreadyReturnedBags))
    let stockBags = sumArray(data?.data?.alreadyInwardReturnLotWiseData ? data?.data?.alreadyInwardReturnLotWiseData : [], "stockBags")
    let stockQty = sumArray(data?.data?.alreadyInwardReturnLotWiseData ? data?.data?.alreadyInwardReturnLotWiseData : [], "stockQty")

    function onClose() {
        if (readOnly) { return setLotGrid(false) }
        let isAnyReturnDataHasValue = item.lotDetails.some((i) => {
            return parseFloat(i["qty"]) !== 0
        })
        // if (!isAnyReturnDataHasValue) {
        //     window.alert("All Lot Return Qty is Empty...!")
        //     return
        // }
        if (!isGridDatasValid(item?.lotDetails ? item.lotDetails : [], false, ["qty"])) {
            if (window.confirm("Some of Lot Return Qty is 0 (Zero). Do you want to Continue ?")) {
                setLotGrid(false)
                return
            }
            return
        }
        setLotGrid(false)
    }

    const allowedReturnQty = (alreadyInwardQty, alreadyReturnQty, stockQty) => {
        const allowedReturn = substract(alreadyInwardQty, alreadyReturnQty)
        return Math.min(stockQty, allowedReturn)
    }
    return (
        <>
            <Modal widthClass={"max-h-[600px] overflow-auto"} onClose={onClose} isOpen={lotGrid}>
                <YarnLotGridWithStock
                    allowedReturnQty={allowedReturnQty}
                    readOnly={readOnly}
                    onClose={onClose}
                    handleInputChangeLotNo={handleInputChangeLotNo}
                    index={index} lotDetails={item?.lotDetails ? item?.lotDetails : []} balanceQty={balanceQty} balanceBags={balanceBags} />
            </Modal>
            <tr key={poItemId} className='table-row'>
                <td className='text-left px-1  table-data'>{index + 1}</td>
                <td className='text-left px-1 w-44 table-data'>{poItem?.Po?.docId}</td>
                <td className='text-left px-1 w-28  table-data'>{poItem?.Yarn?.aliasName}</td>
                <td className='text-left px-1  table-data'>{poItem?.Color?.name}</td>
                <td className='text-left px-1  table-data'>{poItem?.Uom?.name}</td>
                <td className='text-right px-1 table-data'>{stockQty}</td>
                <td className='text-right px-1 table-data'>{stockBags}</td>
                <td className='text-right px-1 table-data'>{alreadyInwardedQty}</td>
                <td className='text-right px-1 table-data'>{alreadyInwardedBags}</td>
                <td className='text-right px-1 table-data'>{alreadyReturnedQty}</td>
                <td className='text-right px-1 table-data'>{alreadyReturnedBags}</td>
                <td className='text-right px-1  table-data'>{allowedReturnQty(alreadyInwardedQty, alreadyReturnedQty, stockQty).toFixed(3)}</td>
                <td className='text-right px-1  table-data'>{allowedReturnQty(alreadyInwardedBags, alreadyReturnedBags, stockBags)}</td>
                <td className='text-center table-data'>
                    <button onClick={() => setLotGrid(true)} className='w-full'>
                        {VIEW}
                    </button>
                </td>
                <td className='text-left px-1  table-data'>
                    <input
                        type="number"
                        className="text-right rounded py-1  px-1 w-full table-data-input"
                        value={sumArray(item?.lotDetails ? item?.lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0) : [], "noOfBags")}
                        disabled={true}
                        onChange={(event) => {
                            if (!event.target.value) {
                                handleInputChange(0, index, "noOfBags");
                                return
                            }
                            handleInputChange(event.target.value, index, "noOfBags");
                        }}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                handleInputChange(0.000, index, "noOfBags");
                                return
                            }
                        }}
                    />
                </td>
                <td className='text-left px-1  table-data'>
                    <input
                        type="number"
                        className="text-right rounded py-1  px-1 w-full table-data-input"
                        value={sumArray(item?.lotDetails ? item?.lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0) : [], "qty")}
                        disabled={true}
                        onChange={(event) => {
                            if (!event.target.value) {
                                handleInputChange(0, index, "qty");
                                return
                            }
                            handleInputChange(event.target.value, index, "qty");
                        }}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                handleInputChange(0.000, index, "qty");
                                return
                            }
                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                        }}
                    />
                </td>
                <td className='text-right px-1  table-data'>{poItem.price}</td>
                <td className='text-right   table-data'>{(parseFloat(poItem.price) * parseFloat(sumArray(item?.lotDetails ? item?.lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0) : [], "qty"))).toFixed(2)}</td>
                {!readOnly &&
                    <td className='table-data w-12'>
                        <div tabIndex={-1} onClick={() => removeItem(poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                            {DELETE}
                        </div>
                    </td>
                }
            </tr>
        </>
    )
}

export default YarnPoItem
