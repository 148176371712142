import React, { useRef } from 'react'
import { Document, Page, View, PDFViewer, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import Header from '../PrintFormatUtils/Header';
import { getCommonParams, getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useGetBranchByIdQuery } from '../../../redux/services/BranchMasterService';
import { useGetCompanyByIdQuery } from '../../../redux/services/CompanyMasterService';
import BankDetails from '../PrintFormatUtils/BankDetails';
import Signature from '../PrintFormatUtils/Signature';
import { useGetRawMaterialSalesByIdQuery } from '../../../redux/ErpServices/RawMaterialSalesServices';
import { YarnFormat } from './YarnFormat';
import { FabricFormat } from './FabricFormat';
import { RAIN_DOT_WEBSITE_LINK } from '../../../Constants';
import QRCode from "qrcode";
import useTaxDetailsWithTaxSplitUp from '../../../CustomHooks/TaxHookDetails/useTaxDetailsWithTaxSplitUp';
import PageNumberFooter from '../PrintFormatUtils/PageNumberFooter';


const PrintFormatRawMaterials = ({ id, isSupplierOutside, poType }) => {
    const qrCode = useRef()
    QRCode.toDataURL(RAIN_DOT_WEBSITE_LINK, function (err, url) {
        qrCode.current = url;
    })
    const { branchId, companyId } = getCommonParams();
    const { data: branchData } = useGetBranchByIdQuery(branchId, { skip: !branchId });

    const { data: companyData } = useGetCompanyByIdQuery(companyId, { skip: !companyId })

    const { data } = useGetRawMaterialSalesByIdQuery({ id }, { skip: !id });

    const nonGridData = data?.data;

    const to = nonGridData?.Supplier;

    const docId = nonGridData?.docId;
    const docDate = getDateFromDateTimeToDisplay(nonGridData?.createdAt);

    const gridDetails = nonGridData?.RawMaterialsSalesDetails || [];
    // const gridDetails = Array.from({ length: 50 }).map(i => nonGridData?.RawMaterialsSalesDetails ? nonGridData?.RawMaterialsSalesDetails[0] : {})
    const { taxGridData, igstAmount, sgstAmount, cgstAmount, netAmount, grossAmount, roundOffAmount } = useTaxDetailsWithTaxSplitUp({ taxTemplateId: nonGridData?.taxTemplateId, items: gridDetails.map(i => ({ ...i, tax: i.taxPercent })), discountType: nonGridData?.discountType, discountValue: nonGridData?.discountValue, isSupplierOutside })
    const pagesName = ["Original", "Duplicate", "Triplicate"]
    return (
        <>
            <PDFViewer style={tw("h-full w-full border")} key={data} >
                <Document width={500} height={300} style={tw("w-full")} >
                    {pagesName.map(page =>
                        <Page key={page} size="A4" style={{ fontFamily: "Times-Roman", border: 1, ...tw("relative p-2 pb-[150px] flex flex-col   ") }}>
                            <View fixed>
                                <Header invoiceType={nonGridData?.invoiceType} page={page} eWayBillNo={nonGridData?.eWayBillNo} poNo={nonGridData?.poNo} qrCode={qrCode.current} branchData={branchData} to={to} docId={docId} docDate={docDate} />
                            </View>
                            <View style={tw("text-xs border-b border-gray-500 flex flex-col w-full ")}>
                                {poType.toLowerCase().includes("yarn") ?
                                    <YarnFormat gridDetails={gridDetails} taxGridData={taxGridData} isSupplierOutside={isSupplierOutside}
                                        igstAmount={igstAmount} sgstAmount={sgstAmount} cgstAmount={cgstAmount} netAmount={netAmount} grossAmount={grossAmount} roundOffAmount={roundOffAmount}
                                    />
                                    :
                                    <FabricFormat gridDetails={gridDetails} taxGridData={taxGridData} isSupplierOutside={isSupplierOutside}
                                        igstAmount={igstAmount} sgstAmount={sgstAmount} cgstAmount={cgstAmount} netAmount={netAmount} grossAmount={grossAmount} roundOffAmount={roundOffAmount}
                                    />
                                }
                            </View>
                            <View style={tw("flex-1 border-x border-gray-500 ")}>

                            </View>
                            <View fixed style={tw("absolute left-2 bottom-5 w-full flex items-center text-sm w-full")}>
                                <View style={tw("w-full")} render={({ subPageNumber, subPageTotalPages }) => (subPageNumber === subPageTotalPages) ?
                                    <View style={tw("w-full border-x border-gray-500 ")}>
                                        <BankDetails bankData={companyData?.data} />
                                        <Signature />
                                    </View>
                                    : <>
                                    </>} />
                                <PageNumberFooter />
                            </View>
                        </Page>
                    )}
                </Document>
            </PDFViewer>
        </>
    )
}

export default PrintFormatRawMaterials
