import React from "react";
import img from '../../../../src/assets/pin.png'
const AppFooter = () => {
  return (
    <div className=" flex justify-end items-end">
      <img src={img} alt="" className="h-12 w-12" />
    </div>
  );
};

export default AppFooter;
