import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PROCESS_DELIVERY_RETURN_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const ProcessDeliveryReturnApi = createApi({
  reducerPath: "ProcessDeliveryReturn",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["ProcessDeliveryReturn"],
  endpoints: (builder) => ({
    getProcessDeliveryReturn: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: PROCESS_DELIVERY_RETURN_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: PROCESS_DELIVERY_RETURN_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["ProcessDeliveryReturn"],
    }),
    getProcessDeliveryReturnById: builder.query({
      query: (id) => {
        return {
          url: `${PROCESS_DELIVERY_RETURN_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["ProcessDeliveryReturn"],
    }),
    addProcessDeliveryReturn: builder.mutation({
      query: (payload) => ({
        url: PROCESS_DELIVERY_RETURN_API,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["ProcessDeliveryReturn"],
    }),
    updateProcessDeliveryReturn: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `${PROCESS_DELIVERY_RETURN_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ProcessDeliveryReturn"],
    }),
    deleteProcessDeliveryReturn: builder.mutation({
      query: (id) => ({
        url: `${PROCESS_DELIVERY_RETURN_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProcessDeliveryReturn"],
    }),
  }),
});

export const {
  useGetProcessDeliveryReturnQuery,
  useGetProcessDeliveryReturnByIdQuery,
  useAddProcessDeliveryReturnMutation,
  useUpdateProcessDeliveryReturnMutation,
  useDeleteProcessDeliveryReturnMutation,
} = ProcessDeliveryReturnApi;

export default ProcessDeliveryReturnApi;
