import React from 'react'
import { Document, Page, View, PDFViewer, Text, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import logo from "../../../assets/RainDot.png"
import { getCommonParams, getDateFromDateTimeToDisplay, priceWithTax } from '../../../Utils/helper';
import { useGetFinishedGoodsSalesByIdQuery } from '../../../redux/ErpServices/finishedGoodsSalesServices';
import useTaxDetailsHook from '../../../CustomHooks/TaxHookDetails';
import { useGetBranchByIdQuery } from '../../../redux/services/BranchMasterService';
import { DottedLine } from '../PrintFormatUtils/DottedLine';
const numberToText = require('number-to-text')
require('number-to-text/converters/en-us');

const PrintFormaViewetail = ({ isSupplierOutside, id }) => {
    const { branchId } = getCommonParams();

    const { data } = useGetFinishedGoodsSalesByIdQuery({ id }, { skip: !id });

    const nonGridData = data?.data;

    const docId = nonGridData?.docId;
    const docDate = getDateFromDateTimeToDisplay(nonGridData?.createdAt);

    const gridDetails = nonGridData?.FinishedGoodsSalesDetails || [];

    const { data: branchData } = useGetBranchByIdQuery(branchId)

    const branchDetails = branchData?.data ? branchData?.data : {}

    const { igstAmount, sgstAmount, cgstAmount, netAmount, grossAmount, roundOffAmount, overAllDiscountAmount } =
        useTaxDetailsHook({ poItems: gridDetails.map(i => ({ ...i, taxPercent: i.tax })), taxTypeId: nonGridData?.taxTemplateId, discountType: nonGridData?.discountType, discountValue: nonGridData?.discountValue, isSupplierOutside })

    return (
        <PDFViewer style={tw("h-full w-full")} key={data}>
            <Document width={500} height={300} >
                <Page size="A5" style={{ fontFamily: "Times-Roman", ...tw("relative pb-[50px] p-2 text-xs") }}>
                    <View style={tw("flex flex-row items-center justify-between w-full")}>
                        <Image src={logo} style={{ width: '50px', height: '50px' }} />
                        <View style={{ ...tw("text-center text-xl flex flex-col items-center"), fontFamily: "Times-Bold" }}>
                            <Text >
                                P.S. TEX
                            </Text>
                            <Text >
                                CASH RECEIPT
                            </Text>
                        </View>
                        <Text></Text>
                    </View>
                    <View style={tw("flex flex-row justify-between text-sm mt-3")}>
                        <Text >
                            Inv.no : {docId}
                        </Text>
                        <Text >
                            Date: {docDate}
                        </Text>
                    </View>
                    <DottedLine />
                    <View style={{ ...tw("w-full flex flex-row text-center text-sm mt-3"), fontFamily: "Times-Bold" }}>
                        <Text style={tw("w-[5%] p-1 ")}>
                            S.no
                        </Text>
                        <Text style={tw("w-[65%] p-1 ")}>
                            Description
                        </Text>
                        <Text style={{ ...tw("w-[10%] text-right") }}>
                            Qty
                        </Text>
                        <Text style={tw("w-[20%] text-right")}>
                            Amount
                        </Text>
                    </View>
                    <View style={tw("mb-3 text-sm")}>
                        {gridDetails.map((value, index) => (
                            <View style={tw(' w-full flex flex-row text-center')} key={index}>
                                <Text style={tw('text-center w-[5%]')}>{index + 1} </Text>
                                <Text style={tw('w-[65%]')}>
                                    {value.Style?.name}/ {value.Color?.name}/{value.Size?.name}
                                </Text>
                                <Text style={tw('text-right w-[10%]')}>{parseFloat(value.qty).toFixed(0)}</Text>
                                <Text style={tw('text-right w-[20%]')}>
                                    {(priceWithTax(value.price, value.tax) * parseFloat(value.qty)).toFixed(2)}
                                </Text>
                            </View>
                        ))}
                    </View>
                    <DottedLine />
                    <View style={tw("flex flex-row justify-between text-sm mt-5")}>
                        <Text>
                            Sub Total
                        </Text>
                        <Text>
                            {parseFloat(grossAmount).toFixed(2)}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row justify-between text-sm")}>
                        <Text>
                            Discount
                        </Text>
                        <Text>
                            {parseFloat(overAllDiscountAmount).toFixed(2)}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row justify-between text-sm")}>
                        <Text>
                            Round Off
                        </Text>
                        <Text>
                            {parseFloat(roundOffAmount).toFixed(2)}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row justify-between text-sm")}>
                        <Text>
                            Net Amount
                        </Text>
                        <Text>
                            {parseFloat(netAmount).toFixed(2)}
                        </Text>
                    </View>
                    <View style={tw('w-full border border-gray-600 text-center text-xl justify-center p-1')}>
                        <Text style={{ fontFamily: "Times-Bold" }}>Bill. Amt: {parseFloat(netAmount).toFixed(2)}</Text>
                        <Text >(Inclusive of all taxes)</Text>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default PrintFormaViewetail