import React, { useEffect, useState } from 'react'
import { useGetPoByIdQuery } from '../../../redux/ErpServices/PoServices';
import FabricPoItem from './FabricPoItem';

const FabricPoItemSelection = ({ id, dataObj }) => {
    const [poItems, setPoItems] = useState([])
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetPoByIdQuery(id, { skip: !id });

    useEffect(() => {
        if (!singleData?.data?.PoItems) return
        setPoItems(singleData?.data?.PoItems ? singleData.data?.PoItems : []);
    }, [singleData, isSingleFetching, isSingleLoading])

    return (
        <div className='border bg-gray-200'>
            <table className='table-auto w-full text-xs border border-gray-500 '>
                <thead className=' border-b border-gray-500'>
                    <tr className='bg-blue-200 border border-gray-500 sticky top-10 '>
                        <th className="table-data  w-2 text-center">S.no</th>
                        <th className="table-data w-28">Items</th>
                        <th className='w-10 table-data'>
                            Color
                        </th>
                        <th className='w-16 table-data'>
                            Design
                        </th>
                        <th className='w-12 table-data'>
                            Gauge
                        </th>
                        <th className='w-12 table-data'>
                            LL
                        </th>
                        <th className='w-12 table-data'>
                            Gsm
                        </th>
                        <th className='w-12 table-data'>
                            K-Dia
                        </th>
                        <th className='w-12 table-data'>
                            F-Dia
                        </th>
                        <th className='w-12 table-data'>
                            Uom
                        </th>
                        <th className="table-data w-10">Po. qty</th>
                        <th className="table-data  w-10"> Can. qty</th>
                        <th className="table-data  w-10"> In qty</th>
                        <th className="table-data  w-10"> In Rolls</th>
                        <th className="table-data  w-10"> Rtn qty</th>
                        <th className="table-data  w-10"> Rtn Rolls</th>
                        <th className="table-data  w-10">Bal. qty</th>
                        <th className="table-data  w-10">Bill Qty</th>
                        <th className="table-data  w-10">Bal Bill Qty</th>
                    </tr>
                </thead>
                <tbody>
                    {poItems.map((item, index) =>
                        <FabricPoItem key={item.id} id={item.id} index={index} dataObj={dataObj} />
                    )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default FabricPoItemSelection