import React, { useEffect, useState } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { toast } from "react-toastify"
import { Loader } from '../../../Basic/components';
import { VIEW } from '../../../icons';
import Modal from '../../../UiComponents/Modal';
import TaxDetailsFullTemplate from '../TaxDetailsCompleteTemplate';
import secureLocalStorage from "react-secure-storage";
import { useGetLoopLengthQuery } from '../../../redux/ErpServices/LoopLengthMasterServices';


const FabricDirectItems = ({ id, transType, poItems, setPoItems, readOnly, isSupplierOutside, taxTypeId }) => {
    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const params = {
        companyId
    };

    const [currentSelectedIndex, setCurrentSelectedIndex] = useState("")

    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(poItems);
        newBlend[index][field] = value;
        if (field === "fabricId") {
            newBlend[index]["taxPercent"] = findYarnTax(value)
        }
        setPoItems(newBlend);
    };

    const Mandatory = (e, value) => {
        console.log("valueFabric", value);
        if (!value) {
            toast.info("Please fill the required field", { position: "top-center" });
            e.target.focus();
        }
    }

    useEffect(() => {
        if (poItems.length !== 0) return
        setPoItems(Array.from({ length: 8 }, i => {
            return { fabricId: "", qty: "", colorId: "", uomId: "", gaugeId: "", designId: "", gsmId: "", loopLengthId: "", kDiaId: "", fDiaId: "", qty: "", price: "", discountType: "Percentage" }
        }))
    }, [transType, setPoItems, poItems])


    const addRow = () => {
        if (poItems.length >= fabricList.data.length) {
            toast.info("No More Items", { position: 'top-center' })
            return
        }
        const newRow = { fabricId: "", qty: "", colorId: "", uomId: "", gaugeId: "", designId: "", gsmId: "", loopLengthId: "", kDiaId: "", fDiaId: "", qty: "", price: "" };
        setPoItems([...poItems, newRow]);
    };
    const handleDeleteRow = id => {
        setPoItems(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
    };


    const { data: fabricList } =
        useGetFabricMasterQuery({ params });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });

    const { data: gaugeList } =
        useGetGaugeQuery({ params });

    const { data: designList } =
        useGetdesignQuery({ params });

    const { data: gsmList } =
        useGetgsmQuery({ params });

    const { data: loopLengthList } =
        useGetLoopLengthQuery({ params });

    const { data: diaList } =
        useGetDiaQuery({ params });

    function findIdInYarnBlend(id) {
        return poItems ? poItems.find(blend => parseInt(blend.fabricId) === parseInt(id)) : false
    }
    function findYarnTax(id) {
        if (!fabricList) return 0
        let yarnItem = fabricList.data.find(item => parseInt(item.id) === parseInt(id))
        return yarnItem ? yarnItem.taxPercent : 0
    }

    if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList) return <Loader />

    return (
        <>

            <Modal isOpen={Number.isInteger(currentSelectedIndex)} onClose={() => setCurrentSelectedIndex("")}>
                <TaxDetailsFullTemplate setCurrentSelectedIndex={setCurrentSelectedIndex} taxTypeId={taxTypeId} currentIndex={currentSelectedIndex} poItems={poItems} handleInputChange={handleInputChange} isSupplierOutside={isSupplierOutside} />
            </Modal>
            <div className={`relative w-full overflow-y-auto p-1`}>
                <table className="border border-gray-500 text-xs table-auto w-full">
                    <thead className='bg-blue-200 top-0 border border-gray-500 '>
                        <tr>
                            <th className="table-data  w-2 text-center">S.no</th>
                            <th className="table-data ">Items<span className="text-red-500">*</span></th>
                            <th className="table-data ">Colors<span className="text-red-500">*</span></th>
                            <th className="table-data  w-32">Design<span className="text-red-500">*</span></th>
                            <th className="table-data  w-20">Gauge<span className="text-red-500">*</span></th>
                            <th className="table-data  w-20">LL<span className="text-red-500">*</span></th>
                            <th className="table-data  w-20">GSM<span className="text-red-500">*</span></th>
                            <th className="table-data  w-20">K Dia<span className="text-red-500">*</span></th>
                            <th className="table-data  w-20">F Dia<span className="text-red-500">*</span></th>
                            <th className="table-data  w-20">UOM<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Quantity<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Price<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Gross</th>
                            <th className="table-data  w-16">View Tax</th>
                            <th>
                                {readOnly ?
                                    "" :
                                    <div onClick={addRow}
                                        className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                                        {PLUS}
                                    </div>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {poItems.map((row, index) => (
                            <tr key={index} className="w-full table-row">
                                <td className="table-data text-left px-1 w-2">
                                    {index + 1}
                                </td>
                                <td className='table-data text-left px-1'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "fabricId") } }}
                                        disabled={readOnly} className='text-left w-full rounded py-1 table-data-input' value={row.fabricId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "fabricId")}
                                        onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "fabricId")
                                            Mandatory(e, (e.target.value));
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? fabricList.data : fabricList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id} >
                                                {blend.aliasName}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-left px-1'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "colorId") } }}
                                        disabled={readOnly} className='text-left w-full rounded py-1 table-data-input' value={row.colorId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "colorId")}
                                        onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "colorId")
                                            Mandatory(e, (e.target.value));
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? colorList.data : colorList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-left px-1'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "designId") } }}
                                        disabled={readOnly} className='text-left w-32 rounded py-1 table-data-input' value={row.designId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "designId")}
                                        onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "designId")
                                            Mandatory(e, (e.target.value));
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? designList.data : designList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-right px-1'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "gaugeId") } }}
                                        disabled={readOnly} className='text-left w-20 rounded py-1 table-data-input' value={row.gaugeId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "gaugeId")}
                                        onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "gaugeId")
                                            Mandatory(e, (e.target.value));
                                        }
                                        }
                                    >
                                        <option hidden>

                                        </option>
                                        {(id ? gaugeList.data : gaugeList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-right px-1'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "loopLengthId") } }}
                                        disabled={readOnly} className='text-left w-20 rounded py-1 table-data-input' value={row.loopLengthId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "loopLengthId")}
                                        onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "loopLengthId")
                                            Mandatory(e, (e.target.value));
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? loopLengthList.data : loopLengthList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-right px-1'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "gsmId") } }}
                                        disabled={readOnly} className='text-left w-20 rounded py-1 table-data-input' value={row.gsmId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "gsmId")}
                                        onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "gsmId")
                                            Mandatory(e, (e.target.value));
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? gsmList.data : gsmList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-right px-1'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "kDiaId") } }}
                                        disabled={readOnly} className='text-left w-20 rounded py-1 table-data-input' value={row.kDiaId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "kDiaId")}
                                        onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "kDiaId")
                                            Mandatory(e, (e.target.value));
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? diaList.data : diaList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-right px-1'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "fDiaId") } }}
                                        disabled={readOnly} className='text-left w-20 rounded py-1 table-data-input' value={row.fDiaId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "fDiaId")}
                                        onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "fDiaId")
                                            Mandatory(e, (e.target.value));
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? diaList.data : diaList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-left px-1'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "uomId") } }}
                                        disabled={readOnly} className='text-left w-20 rounded py-1 table-data-input' value={row.uomId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "uomId")}
                                        onBlur={(e) => {
                                            handleInputChange(e.target.value, index, "uomId")
                                            Mandatory(e, (e.target.value));
                                        }
                                        }>
                                        <option hidden>
                                        </option>
                                        {(id ? uomList.data : uomList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data text-right px-1'>
                                    <input

                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "qty") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        value={(!row.qty) ? 0 : row.qty}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "qty")
                                        }
                                        onBlur={(e) => {

                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                                            Mandatory(e,parseFloat (e.target.value));
                                        }
                                        }
                                    />
                                </td>
                                <td className='table-data text-right px-1'>
                                    <input
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "price") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        value={(!row.price) ? 0 : row.price}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "price")
                                        }
                                        onBlur={(e) => {

                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "price")
                                            Mandatory(e,parseFloat (e.target.value));
                                        }
                                        }
                                    />
                                </td>
                                <td className='table-data text-right px-1'>
                                    <input
                                        className="text-right rounded py-1 px-1 w-16 table-data-input"
                                        onFocus={(e) => e.target.select()}
                                        value={(!row.qty || !row.price) ? 0 : (parseFloat(row.qty) * parseFloat(row.price))}
                                        disabled={true}
                                    />
                                </td>
                                <td className='text-center px-1 w-16'>
                                    <button
                                        className="text-center rounded py-1 w-full"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                setCurrentSelectedIndex(index);
                                            }
                                        }}
                                        onClick={() => {
                                            if (!taxTypeId) return toast.info("Please select Tax Type", { position: "top-center" });
                                            setCurrentSelectedIndex(index)
                                        }
                                        }
                                    >
                                        {VIEW}
                                    </button>
                                </td>
                                <td className=''>
                                    {readOnly
                                        ?
                                        ""
                                        :
                                        <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                            {DELETE}
                                        </div>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default FabricDirectItems