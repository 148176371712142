import React from 'react'
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { useEffect } from 'react';
import { useGetStyleMasterByIdQuery } from '../../../redux/ErpServices/StyleMasterServices';
import { DELETE, PLUS } from '../../../icons';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';

const FinishedGoodsOpeningStockDetails = ({ id, readOnly, finishedGoodsOpeningStockDetails, setFinishedGoodsOpeningStockDetails, styleId }) => {
  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };
  const { data: processList } = useGetProcessQuery({ params: { ...params, isPcsStage: true, isCutting: false } });


  const { data: uomList } =
    useGetUnitOfMeasurementMasterQuery({ params: { ...params, isCutting: true } });

  const { data: styleDetails } =
    useGetStyleMasterByIdQuery(styleId, { skip: !styleId });

  const handleInputChange = (value, index, field) => {
    const newBlend = structuredClone(finishedGoodsOpeningStockDetails);
    newBlend[index][field] = value;
    setFinishedGoodsOpeningStockDetails(newBlend);
  };

  useEffect(() => {
    if (finishedGoodsOpeningStockDetails.length >= 15) return
    setFinishedGoodsOpeningStockDetails(prev => {
      let newArray = Array.from({ length: 15 - prev.length }, i => {
        return { colorId: "", sizeId: "", uomId: "", qty: "0.000", prevProcessId: "" }
      })
      return [...prev, ...newArray]
    }
    )
  }, [setFinishedGoodsOpeningStockDetails, finishedGoodsOpeningStockDetails])

  const addRow = () => {
    const newRow = { prevProcessId: "", colorId: "", sizeId: "", uomId: "", qty: "0.000" };
    setFinishedGoodsOpeningStockDetails([...finishedGoodsOpeningStockDetails, newRow]);
  };
  const handleDeleteRow = id => {
    setFinishedGoodsOpeningStockDetails(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
  };
  if (!styleDetails || !uomList) return <Loader />
  return (
    <fieldset disabled={readOnly} className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border
                            border-gray-600 overflow-auto min-h-[220px]'>
      <legend className='sub-heading'>Finished Goods Opening Stock Details</legend>
      <div className={`relative w-full overflow-y-auto p-1`}>
        <table className="table-data border border-gray-500 text-xs table-auto w-full">
          <thead className='bg-blue-200 border border-gray-500 top-0'>
            <tr className='border border-gray-500'>
              <th className="table-data w-2 text-center">S.no</th>
              <th className="table-data w-48">Prev. Process</th>
              <th className="table-data w-48">Colors</th>
              <th className="table-data w-32">Size</th>
              <th className="table-data w-20">Uom</th>
              <th className="table-data w-20">Qty</th>
              {readOnly ?
                "" :
                <th className='w-20  bg-green-600 text-white'>
                  <div onClick={addRow}
                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                    {PLUS}
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody className='overflow-y-auto table-data h-full w-full'>
            {finishedGoodsOpeningStockDetails.map((row, index) => (
              <tr key={index} className="w-full table-row">
                <td className='table-data'>{index + 1}</td>
                <td className='table-data'>
                  <select
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "prevProcessId") } }}
                    tabIndex={"0"} disabled={readOnly} className='text-left w-full rounded py-1 table-data-input'
                    value={row.prevProcessId}
                    onChange={(e) => handleInputChange(e.target.value, index, "prevProcessId")}
                    onBlur={(e) => {
                      handleInputChange((e.target.value), index, "prevProcessId")
                    }
                    }
                  >
                    <option value={""}>
                    </option>
                    {(id ? processList.data : processList.data.filter(item => item.active)).map((blend) =>
                      <option value={blend.id} key={blend.id}>
                        {blend.name}
                      </option>)}
                  </select>
                </td>
                <td className='table-data '>
                  <select
                    value={row.colorId}
                    onChange={(e) => handleInputChange(e.target.value, index, "colorId")}
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "colorId") } }}
                    className='text-left w-full rounded py-1 table-data-input'>
                    <option hidden value={""}>
                    </option>
                    {styleDetails.data.StyleOnColor.map((item, index) => (
                      <option value={item.colorId}>
                        {item.Color.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td className='table-data'>
                  <select
                    value={row.sizeId}
                    onChange={(e) => handleInputChange(e.target.value, index, "sizeId")}
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "sizeId") } }}
                    className='text-left w-full rounded py-1 table-data-input'>
                    <option hidden>
                    </option>
                    {(styleDetails?.data?.SizeTemplate?.SizeTemplateOnSize ? styleDetails?.data?.SizeTemplate?.SizeTemplateOnSize : []).map((value, index) => (
                      <option value={value.sizeId}>
                        {value?.Size?.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td className='table-data'>
                  <select
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "uomId") } }}
                    value={row.uomId} onChange={(e) => handleInputChange(e.target.value, index, "uomId")}
                    disabled={readOnly} className='text-left w-full rounded py-1 table-data-input' >
                    <option hidden>
                    </option>
                    {uomList.data.map((blend) =>
                      <option value={blend.id} key={blend.id}>
                        {blend.name}
                      </option>
                    )}
                  </select></td>
                <td className='table-data'>
                  <input type="number" min={0}
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "qty") } }}
                    value={(!row.qty) ? 0 : row.qty}
                    onChange={(e) => { if (e.target.value >= 0) handleInputChange(e.target.value, index, "qty") }}
                    className="text-right rounded py-1 w-full px-1 table-data-input"
                    inputMode='decimal'
                    onBlur={(e) =>
                      handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                    } />
                </td>
                {readOnly
                  ?
                  ""
                  :
                  <td className='table-data w-20'>
                    <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer'>
                      {DELETE}
                    </div>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </fieldset>
  )
}

export default FinishedGoodsOpeningStockDetails