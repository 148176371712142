import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BATCH_MASTER_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const BatchMasterApi = createApi({
    reducerPath: "batchMaster",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["BatchMasterApi"],
    endpoints: (builder) => ({
        getBatch: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: BATCH_MASTER_API + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: BATCH_MASTER_API,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["BatchMaster"],
        }),
        getBatchById: builder.query({
            query: (id) => {
                return {
                    url: `${BATCH_MASTER_API}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["BatchMaster"],
        }),
        addBatch: builder.mutation({
            query: (payload) => ({
                url: BATCH_MASTER_API,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["BatchMaster"],
        }),
        updateBatch: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${BATCH_MASTER_API}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["BatchMaster"],
        }),
        deleteBatch: builder.mutation({
            query: (id) => ({
                url: `${BATCH_MASTER_API}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["BatchMaster"],
        }),
    }),
});

export const {
    useGetBatchQuery,
    useGetBatchByIdQuery,
    useAddBatchMutation,
    useUpdateBatchMutation,
    useDeleteBatchMutation,


} = BatchMasterApi;

export default BatchMasterApi;
