import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
  useGetCuttingOrderQuery,
  useGetCuttingOrderByIdQuery,
  useAddCuttingOrderMutation,
  useUpdateCuttingOrderMutation,
  useDeleteCuttingOrderMutation,
} from "../../../redux/ErpServices/CuttingOrderServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { LongDropdownInput, DropdownInput, DisabledInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { getCommonParams, getDateFromDateTime, isGridDatasValid } from "../../../Utils/helper";
import moment from "moment";
import { Loader } from "../../../Basic/components";
import _ from "lodash";

import PrintFormatYarnDeliveryToKnitting from "../PrintFormat-YarnDeliveryToKnitting"
import { useGetStyleMasterQuery } from "../../../redux/ErpServices/StyleMasterServices";
import Modal from "../../../UiComponents/Modal";
import CuttingDetails from "./CuttingDetails";
import CuttingOrderFormReport from "./CuttingOrderFormReport";
import Consolidation from "../ProcessDelivery/Consolidation";


const MODEL = "Cutting Order";


export default function Form() {

  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const handlePrint = () => { }


  const dispatch = useDispatch()

  const today = new Date()
  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [cuttingDetails, setCuttingOrderDetails] = useState([])
  const [partyId, setSupplierId] = useState("");
  const [docDate, setDocDate] = useState(getDateFromDateTime(today))
  const [docId, setDocId] = useState("")

  const [remarks, setRemarks] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [vehicleNo, setVehicleNo] = useState("");

  const [styleId, setStyleId] = useState("");

  const [active, setActive] = useState(true)

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);


  const { branchId, companyId, finYearId, userId } = getCommonParams()


  const params = {
    companyId, finYearId
  }

  const { data: styleList, isLoading: isStyleLoading, isFetching: isStyleFetching } = useGetStyleMasterQuery({ params, searchParams: searchValue });

  const { data: supplierList } =
    useGetPartyQuery({ params });
  const { data: allData, isLoading, isFetching } = useGetCuttingOrderQuery({ params: { branchId, finYearId }, searchParams: searchValue });



  const getNextDocId = useCallback(() => {
    if (id) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetCuttingOrderByIdQuery({ id }, { skip: !id });



  const [addData] = useAddCuttingOrderMutation();
  const [updateData] = useUpdateCuttingOrderMutation();
  const [removeData] = useDeleteCuttingOrderMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setDocDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    if (data?.docId) {
      setDocId(data.docId);
    }
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");

    setCuttingOrderDetails(data?.CuttingOrderDetails ? data?.CuttingOrderDetails : [])
    setSupplierId(data?.partyId ? data?.partyId : "");
    setStyleId(data?.styleId ? data.styleId : "");
    setActive(id ? (data?.active ? data.active : false) : true);
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");
    setRemarks(data?.remarks ? data?.remarks : "");
    setSpecialInstructions(data?.specialInstructions ? data?.specialInstructions : "")
  }, [id]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    partyId,
    branchId,
    styleId,
    id, userId,
    vehicleNo,
    specialInstructions,
    remarks,
    cuttingOrderDetails: cuttingDetails.filter(d => d.colorId)
    , finYearId
  }
  const validateData = (data) => {
    let cuttingOrderValidationFields = ["colorId", "sizeId", "uomId", "orderQty", "cuttingPrice"]
    return data.partyId && data.styleId && data.vehicleNo
      && data.cuttingOrderDetails.length !== 0
      && isGridDatasValid(data.cuttingOrderDetails, false, cuttingOrderValidationFields)
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      toast.success(text + "Successfully");
      setId("")
      syncFormWithDb(undefined)
      dispatch({
        type: `po/invalidateTags`,
        payload: ['po'],
      });
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });
    } catch (error) {
      console.log("handle", error);
    }
  };


  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
    getNextDocId();
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const getIssuedQty = (stockItem) => {
    const issueDetails = cuttingDetails.map(item => item.rawMaterials).flat()
    const stockItemIssuedDetails = issueDetails.filter(item => _.isEqual(stockItem.itemDetails, item.itemDetails))
    const totalQty = stockItemIssuedDetails.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.issueQty ? currentValue?.issueQty : 0))
    }, 0)
    return totalQty
  }
  const getTotalIssuedQty = () => {
    const totalQty = cuttingDetails.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.orderQty ? currentValue?.orderQty : 0))
    }, 0)
    return totalQty
  }

  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLoading || isFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  if (isStyleLoading || isStyleFetching || isLoading || isFetching) return <Loader />

  return (
    <>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <CuttingOrderFormReport
          heading={MODEL}
          loading={
            isLoading || isFetching
          }
          setForm={setForm}
          data={allData?.data ? allData?.data : []}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }}
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <div onKeyDown={handleKeyDown} className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
        <div className="hidden">
          <PrintFormatYarnDeliveryToKnitting innerRef={componentRef} />
        </div>
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}

            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            // onPrint={id ? handlePrint : null}
            openReport={() => setFormReport(true)}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <div className={`grid"} h-full relative overflow-auto`}>
                    <div className={``}>
                      <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                        <legend className='sub-heading'>Delivery Info</legend>
                        <div className='flex flex-col justify-center items-start flex-1 w-full'>
                          <div className="grid grid-cols-5">
                            <DisabledInput name={"Doc ID."} value={`${docId}`} />
                            <DisabledInput name="DOC Date" value={docDate} type={"date"} required={true} disabled />
                            <DropdownInput
                              clear
                              name="Style"
                              options={dropDownListObject(id ? (styleList ? styleList.data : []) : (styleList ? styleList.data.filter(item => item.active) : []), "sku", "id")}
                              value={styleId}
                              setValue={setStyleId}
                              required={true}
                              readOnly={id || readOnly}
                            />
                            <div className="col-span-2">
                              <LongDropdownInput name="Supplier" options={supplierList ? (dropDownListObject(id ? supplierList.data : supplierList.data.filter(item => item.active), "aliasName", "id")) : []} value={partyId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                            </div>
                          </div>
                        </div>
                      </fieldset>{console.log(cuttingDetails, "cuttingDetails")}
                      {styleId
                        &&
                        <CuttingDetails id={id} styleId={styleId} cuttingDetails={cuttingDetails} setCuttingDetails={setCuttingOrderDetails} styleList={styleList.data} readOnly={readOnly} sizeTemplateId={styleList.data.map((value) => value.sizeTemplateId)} />
                      }
                    </div>
                    <Consolidation totalQty={getTotalIssuedQty()} vehicleNo={vehicleNo} setVehicleNo={setVehicleNo} remarks={remarks} setRemarks={setRemarks}
                      specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions} />
                    <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                      <FormReport
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setId={setId}
                        tableHeaders={tableHeaders}
                        tableDataNames={tableDataNames}
                        data={allData?.data ? allData?.data : []}
                        loading={
                          isLoading || isFetching
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}