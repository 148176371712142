import React, { useState } from 'react';
import { Loader } from '../../../Basic/components';
import { useGetPartyByIdQuery } from '../../../redux/ErpServices/PartyMasterServices';
import BillEntrySelectionGrid from './BillEntrySelectionGrid';

const BillEntrySelection = ({ supplierId, setAdvanceAdjustmentItems, advanceAdjustmentItems, setSelectBillItems }) => {
    const [localItems, setLocalItems] = useState(advanceAdjustmentItems.map(item => parseInt(item.billEntryId)));

    const { data: supplierData, isLoading: supplierLoading, isFetching: supplierFetching } =
        useGetPartyByIdQuery(supplierId, { skip: !supplierId });

    if (supplierFetching || supplierLoading) return <Loader />


    function addItem(id) {
        setLocalItems(localItems => {
            let newItems = structuredClone(localItems);
            newItems.push(id);
            return newItems
        });
    }
    function removeItem(id) {
        setLocalItems(localItems => {
            let newItems = structuredClone(localItems);
            newItems = newItems.filter(item => parseInt(item) !== parseInt(id))
            return newItems
        });
    }


    function handleChange(id) {
        if (isItemAdded(id)) {
            removeItem(id)
        } else {
            addItem(id)
        }
    }
    function isItemAdded(id) {
        return localItems.findIndex(item => parseInt(item) === parseInt(id)) !== -1
    }

    function handleSelectAllChange(value, poItems) {
        if (value) {
            poItems.forEach(item => addItem(item.id))
        } else {
            poItems.forEach(item => removeItem(item.id))
        }
    }

    function getSelectAll(poItems) {
        return poItems.every(item => isItemAdded(item.id))
    }

    function handleDone() {
        setAdvanceAdjustmentItems(prevInwardItems => {
            let oldInwardItems = prevInwardItems.filter(item => isItemAdded(item.billEntryId))
            let newInwardItems = localItems.filter(item => {
                return prevInwardItems.findIndex(prevItem => parseInt(prevItem.billEntryId) === parseInt(item)) === -1
            })
            return [...oldInwardItems, ...newInwardItems.map(i => { return { billEntryId: i } })]
        });
        setSelectBillItems(false);
    }

    function handleCancel() {
        setLocalItems([]);
        setSelectBillItems(false);
    }

    return (
        <div className=''>
            <div className='h-[520px] overflow-auto'>
                <div className='flex justify-between text-center bg-blue-200 rounded-b-md mb-7 sticky top-0 '>
                    <div className='p-2 rounded-lg flex items-center gap-5'>
                        <label className='text-xs font-semibold'>Supplier</label>
                        <input className='text-xs h-6 rounded border border-gray-500 bg-white' value={supplierData?.data ? supplierData.data.aliasName : ""} disabled={true} />
                    </div>
                </div>
                <div>
                    <BillEntrySelectionGrid getSelectAll={getSelectAll} handleSelectAllChange={handleSelectAllChange} handleChange={handleChange} supplierId={supplierId} isItemAdded={isItemAdded} />
                    <div className='flex item-end justify-end gap-4 mt-3'>
                        <button onClick={handleDone} className='bg-lime-400 hover:bg-lime-600 hover:text-white p-1 px-3 text-sm rounded font-semibold transition'>
                            Done
                        </button>
                        <button onClick={handleCancel} className='bg-red-400 hover:bg-red-600 hover:text-white p-1 text-sm rounded font-semibold transition'>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillEntrySelection