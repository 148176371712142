import { toast } from 'react-toastify';
import AccessoryPoItem from './AccessoryPoItem';


const AccessoryCancelItems = ({ inwardItems, setInwardItems,  readOnly, removeItem ,purchaseInwardId}) => {

    const handleInputChange = (value, index, field, balanceQty) => {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            newBlend[index][field] = value
            if (field === "qty") {
                if (parseFloat(balanceQty) < parseFloat(value)) {
                    toast.info("Cancel Qty Can not be more than balance Qty", { position: 'top-center' })
                    return inwardItems
                }
            }
            return newBlend
        });
    };

    
    return (
        <>
            <div className={`relative w-full overflow-auto py-1`}>
                <table className="border border-gray-500 text-xs table-auto w-full  ">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8 '>
                            <th className="table-data   text-center">S.no</th>
                            <th className="table-data    text-center">Po.no</th>
                            <th className="table-data  ">Acc. Name</th>
                            <th className="table-data  ">Acc. Items</th>
                            <th className="table-data  ">Acc. Group</th>
                            <th className="table-data  ">Colors</th>
                            <th className="table-data  ">Size</th>
                            <th className="table-data   ">UOM</th>
                            <th className="table-data   ">Po Qty</th>
                            <th className="table-data   ">A.Can Qty</th>
                            <th className="table-data   ">A.In. Qty</th>
                            <th className="table-data   ">A.Rtn. Qty</th>
                            <th className="table-data   ">Bal.Qty</th>
                            <th className="table-data   ">Can. Qty<span className="text-red-500">*</span></th>
                            <th className="table-data   ">Price</th>
                            {/* <th className="table-data   ">Gross</th> */}
                            {!readOnly &&
                                <th className='table-data border  w-12'>Del</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) => <AccessoryPoItem purchaseInwardId={purchaseInwardId} removeItem={removeItem} readOnly={readOnly} key={item.poItemsId} qty={item.qty} poItemId={item.poItemsId} index={index} handleInputChange={handleInputChange} />)}
                        {Array.from({ length: 8 - inwardItems.length }).map(i =>
                             <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                             {Array.from({ length: 15}).map(i =>
                                 <td className="table-data   "></td>
                             )}
                             {!readOnly &&
                                 <td className="table-data w-10"></td>
                             }
                         </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default AccessoryCancelItems
