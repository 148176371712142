import React from 'react';
import { useGetYarnMasterQuery } from '../../../redux/ErpServices/YarnMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, substract } from '../../../Utils/helper';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetLoopLengthQuery } from '../../../redux/ErpServices/LoopLengthMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';

const FabricFillGrid = ({ rawMaterials, setRawMaterials, readOnly, rawMaterialsFillGrid, onDone }) => {
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const addrow = (row) => {
        setRawMaterials(prev => {
            let newInwardDetails = structuredClone(prev);
            newInwardDetails.push({ rawMaterialsId: row.id, stockPrice: row.stockPrice, consumptionQty: 0, lossQty: 0 })
            return newInwardDetails
        })
    }
    const deleterow = (id) => {
        setRawMaterials(prev => {
            return prev.filter(row => parseInt(row.rawMaterialsId) !== parseInt(id))
        })
    }

    const isItemSelected = (id) => {
        let foundIndex = rawMaterials.findIndex(row => parseInt(row.rawMaterialsId) === parseInt(id))
        return foundIndex !== -1
    }

    const handleChangeInwardRawMaterialDetails = (row) => {
        if (isItemSelected(row.id)) {
            deleterow(row.id)
        } else {
            addrow(row)
        }
    }

    const { data: fabricList } =
        useGetFabricMasterQuery({ params });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });

    const { data: gaugeList } =
        useGetGaugeQuery({ params });

    const { data: designList } =
        useGetdesignQuery({ params });

    const { data: gsmList } =
        useGetgsmQuery({ params });

    const { data: loopLengthList } =
        useGetLoopLengthQuery({ params });

    const { data: diaList } =
        useGetDiaQuery({ params });


    const { data: processList, isLoading: isProcessLoading, isFetching: isProcessFetching } = useGetProcessQuery({ params });


    function getTotals(field) {
        const total = rawMaterials.reduce((accumulator, current) => {
            return accumulator + parseFloat(current[field] ? current[field] : 0)
        }, 0)
        return parseFloat(total)
    }
    if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList) return <Loader />
    return (
        <>
            <div className={`relative w-full overflow-y-auto`}>
                <div className='flex justify-between mb-2'>
                    <h1 className='text-center mx-auto font-bold'>Raw Materials</h1>
                    <button className='text-center font-bold bg-blue-400 text-gray-100 p-1 rounded-lg' onClick={onDone}>DONE</button>
                </div>
                <table className=" text-xs table-fixed  w-full">
                    <thead className='bg-blue-200 top-0'>
                        <tr className=''>
                            <th className=' table-data  w-7'>
                            </th>
                            <th className=' table-data  w-7'>
                                S.no
                            </th>
                            <th className="table-data  w-32">Fabric<span className="text-red-500">*</span></th>
                            <th className="table-data  w-32">Color<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Design<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Gauge<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">LL<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">GSM<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">K Dia<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">F Dia<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">UOM<span className="text-red-500">*</span></th>
                            <th className='table-data  w-16'>
                                Lot No.
                            </th>
                            <th className="table-data  w-20">
                                Prev. Process
                            </th>
                            <th className="table-data  w-20">
                                Delivery qty
                            </th>
                            <th className="table-data  w-20">
                                A. Used qty
                            </th>
                            <th className="table-data  w-20">
                                Bal. qty
                            </th>
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {rawMaterialsFillGrid.map((row, index) => (
                            <tr key={index} className="w-full table-row" onClick={() => {
                                handleChangeInwardRawMaterialDetails(row)
                            }}>
                                <td className="table-data  w-2  py-1 text-center">
                                    <input type='checkbox' checked={isItemSelected(row.id)} />
                                </td>
                                <td className="table-data  w-2  py-1 text-center">
                                    {index + 1}
                                </td>
                                <td className='table-data'>
                                    {findFromList(row.fabricId, fabricList.data, "aliasName")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(row.colorId, colorList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(row.designId, designList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(row.gaugeId, gaugeList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(row.loopLengthId, loopLengthList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(row.gsmId, gsmList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(row.kDiaId, diaList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(row.fDiaId, diaList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(row.uomId, uomList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {row.lotNo}
                                </td>
                                <td className=' px-1 table-data  '>
                                    {findFromList(row.processId, processList.data, "name")}
                                </td>
                                <td className='table-data text-right'>
                                    {((!row.qty) ? 0 : row.qty).toFixed(3)}
                                </td>
                                <td className='table-data text-right'>
                                    {((!row.alreadyUsedQty) ? "0.000" : parseFloat(row.alreadyUsedQty).toFixed(3))}
                                </td>
                                <td className="table-data text-right">
                                    {substract(row.qty, row.alreadyUsedQty).toFixed(3)}
                                </td>
                            </tr>
                        ))}
                        {Array.from({ length: 5 - rawMaterialsFillGrid.length }).map(i =>
                            <tr className='w-full font-bold h-8 border-gray-400 border table-row'>
                                <td className='table-data'>
                                </td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                            </tr>)
                        }
                        {/* <tr className='bg-blue-200 w-full border border-gray-400 h-7 font-bold'>
                            <td className='table-data'>
                            </td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   ">Total</td>
                            <td className="table-data    text-right">{getTotals("consumptionQty").toFixed(3)}</td>
                            <td className="table-data    text-right">{getTotals("lossQty").toFixed(3)}</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default FabricFillGrid