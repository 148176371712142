import React from 'react'
import { toast } from 'react-toastify';
import { DELETE, VIEW } from '../../../icons';

const FabricConsumptionItem = ({ item, id, cuttingOrderId, index, handleInputChange, readOnly, setCuttingReceiptFabricConsumptionDetails,
    cuttingReceiptFabricConsumptionFillData }) => {

    let cuttingDeliveryItem = cuttingReceiptFabricConsumptionFillData.find(i => parseInt(i.id) === parseInt(item.cuttingDeliveryDetailsId))

    const delQty = cuttingDeliveryItem?.delQty ? cuttingDeliveryItem?.delQty : 0 
    const alreadyConsumedQty = cuttingDeliveryItem?.alreadyUsedQty ? cuttingDeliveryItem?.alreadyUsedQty : 0
    const alreadyReturnExcessQty = cuttingDeliveryItem?.alreadyReturnExcessQty ? cuttingDeliveryItem?.alreadyReturnExcessQty : 0
    
    
    const balanceQty = delQty - alreadyConsumedQty - alreadyReturnExcessQty;

    const deleteItem = () => {
        setCuttingReceiptFabricConsumptionDetails(prev => {
            return prev.filter(i => parseInt(i.cuttingDeliveryDetailsId) !== parseInt(item.cuttingDeliveryDetailsId))
        })
    }

    return (
        <tr key={index} className="w-full table-row">
            <td className='text-left px-1 table-data shadow-xl '>
                {index + 1}
            </td>
            <td className='text-left px-1 table-data shadow-xl '>
                {cuttingDeliveryItem?.docId}
            </td>
            <td className='text-left px-1 table-data shadow-xl'>
                {cuttingDeliveryItem?.Fabric.aliasName}
            </td>
            <td className='text-left  table-data shadow-xl'>
                {cuttingDeliveryItem?.Color.name}
            </td>
            <td className='text-left px-1 table-data shadow-xl'>
                {cuttingDeliveryItem?.Design.name}
            </td>
            <td className='text-right px-1 table-data shadow-xl'>
                {cuttingDeliveryItem?.Gauge.name}
            </td>
            <td className='text-right px-1 table-data shadow-xl'>
                {cuttingDeliveryItem?.LoopLength.name}
            </td>
            <td className='text-right px-1 table-data shadow-xl'>
                {cuttingDeliveryItem?.Gsm.name}
            </td>
            <td className='text-right px-1 table-data shadow-xl'>
                {cuttingDeliveryItem?.KDia.name}
            </td>
            <td className='text-right px-1 table-data shadow-xl'>
                {cuttingDeliveryItem?.FDia.name}
            </td>
            <td className='text-right px-1 table-data shadow-xl'>
                {cuttingDeliveryItem?.Uom.name}
            </td>
            <td className=' px-1 table-data shadow-xl text-right'>
                {delQty}
            </td>
            <td className=' table-data '>
                {alreadyConsumedQty}
            </td>
            <td className=' table-data '>
                {alreadyReturnExcessQty}
            </td>
            <td className='  table-data '>
                {balanceQty}
            </td>
          
            <td className=' table-data '>
                <input
                    type="number"
                    onFocus={(e) => e.target.select()}
                    className="text-right rounded py-1 px-1 w-full table-data-input"
                    value={(!item.returnExcessRolls) ? 0 : item.returnExcessRolls}
                    readOnly={readOnly}
                    onChange={(e) => {
                        if (e.target.value > balanceQty) {
                            toast.info("Cons. Qty Cannot be more than Bal. Qty ", { position: 'top-center' });
                            return
                        }
                        handleInputChange(e.target.value, index, "returnExcessRolls")
                    }
                    }
                    onBlur={(e) =>
                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "returnExcessRolls")
                    }
                />
            </td>
            <td className=' table-data '>
                <input
                    type="number"
                    onFocus={(e) => e.target.select()}
                    className="text-right rounded py-1 px-1 w-full table-data-input"
                    value={(!item.returnExcessQty) ? 0 : item.returnExcessQty}
                    readOnly={readOnly}
                    onChange={(e) => {
                        if (e.target.value > balanceQty) {
                            toast.info("Cons. Qty Cannot be more than Bal. Qty ", { position: 'top-center' });
                            return
                        }
                        handleInputChange(e.target.value, index, "returnExcessQty")
                    }
                    }
                    onBlur={(e) =>
                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "returnExcessQty")
                    }
                />
            </td>
            {!readOnly &&
                <td className='table-data '>
                    <button className='w-full' onClick={deleteItem}>
                        {DELETE}
                    </button>
                </td>
            }
        </tr>
    )
}

export default FabricConsumptionItem