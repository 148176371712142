import React, { useEffect, useState } from 'react'
import { useGetPcsStockQuery } from '../../../redux/ErpServices/StockServices';
import { Loader } from '../../../Basic/components';
import { getCommonParams, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { showEntries } from '../../../Utils/DropdownData';

const FinishedGoodsSalesDetailsFillGrid = ({ productionDeliveryDetails, setProductionDeliveryDetails, setFillGrid, storeId, id }) => {
    const [localProductionDeliveryDetails, setLocaProductionDeliveryDetails] = useState(productionDeliveryDetails);

    const { branchId } = getCommonParams()
    const [searchStyleNo, setSearchStyleNo] = useState("");


    function handleDone() {
        setProductionDeliveryDetails(localProductionDeliveryDetails);
        setFillGrid(false);
    }

    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const { data, isFetching, isLoading } = useGetPcsStockQuery({
        params: {
            storeId, isPacking: true,
            branchId,
            pagination: true,
            dataPerPage, pageNumber: currentPageNumber, finishedGoodsSalesId: id, searchStyleNo
        }
    })
    useEffect(() => {
        if (data?.totalCount) {
            setTotalCount(data?.totalCount)
        }
    }, [data, isLoading, isFetching])
    if (!data?.data || isFetching || isLoading) return <Loader />

    function addItem(item) {
        setLocaProductionDeliveryDetails(localInwardItems => {
            let newItems = structuredClone(localInwardItems);
            newItems.push(item);
            return newItems
        });
    }
    function removeItem(removeItem) {
        setLocaProductionDeliveryDetails(localInwardItems => {
            return localInwardItems.filter(item =>
                !(removeItem.styleId === item.styleId
                    &&
                    removeItem.prevProcessId === item.prevProcessId
                    &&
                    removeItem.sizeId === item.sizeId
                    &&
                    removeItem.storeId === item.storeId
                    &&
                    removeItem.colorId === item.colorId
                )
            )
        });
    }

    function isItemChecked(checkItem) {
        let item = localProductionDeliveryDetails.find(item =>
            checkItem.styleId === item.styleId
            &&
            checkItem.prevProcessId === item.prevProcessId
            &&
            checkItem.sizeId === item.sizeId
            &&
            checkItem.storeId === item.storeId
            &&
            checkItem.colorId === item.colorId
        )
        if (!item) return false
        return true
    }


    function handleCheckBoxChange(value, item) {
        if (value) {
            addItem(item)
        } else {
            removeItem(item)
        }
    }

    function handleSelectAllChange(value) {
        if (value) {
            (data?.data ? data.data : []).forEach(item => addItem(item))
        } else {
            (data?.data ? data.data : []).forEach(item => removeItem(item))
        }
    }

    function getSelectAll() {
        return (data?.data ? data.data : []).every(item => isItemChecked(item))
    }


    return (
        <div>
            <div className={`bg-gray-200 z-50 overflow-auto w-[1000px]`}>
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10 text-xs"> Stock Items</div>
                    <div className=" sub-heading justify-center md:justify-start items-center">
                        <label className="text-white text-xs rounded-md m-1  border-none">Show Entries</label>
                        <select value={dataPerPage}
                            onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                            {showEntries.map((option) => <option value={option.value} >{option.show}</option>)}
                        </select>
                    </div>
                </div>
                <table className="border border-gray-500 w-full text-xs text-start">
                    <thead className="border border-gray-500">
                        <tr>
                            <th className='w-8 p-5'>
                                Mark All
                                <input type="checkbox" className='w-full' onChange={(e) => handleSelectAllChange(e.target.checked)}
                                    checked={getSelectAll()}
                                />
                            </th>
                            <th className="w-20 border border-gray-500">S.no</th>
                            <th className="border w-20 border-gray-500 ">
                                <div className='grid h-full items-center justify-center'>
                                    <span>
                                        Style
                                    </span>
                                    <input type="text" autoFocus className='w-[90%] border border-gray-600' value={searchStyleNo} onChange={(e) => setSearchStyleNo((e.target.value).toUpperCase())} />
                                </div>
                            </th>
                            <th className="border border-gray-500">Color</th>
                            <th className="border border-gray-500">Size</th>
                            <th className="border border-gray-500">Prev. Process</th>
                            <th className="border border-gray-500">Stock Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.data.map((item, index) =>
                            <tr key={index} className='table-row' onClick={() => {
                                handleCheckBoxChange(!isItemChecked(item), { ...item, qty: 0 })
                            }}>
                                <td className='table-data'>
                                    <input type="checkbox" className='w-full table-data-input'
                                        checked={isItemChecked(item)} />
                                </td>
                                <td>{index + 1}</td>
                                <td>{item.styleName}</td>
                                <td className='text-center'>{item?.colorName}</td>
                                <td className='text-center'>{item.sizeName}</td>
                                <td className='text-center'>{item.processName}</td>
                                <td className='text-right'>{item.qty}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div>
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                    pageCount={Math.ceil(totalCount / dataPerPage)}
                    marginPagesDisplayed={1}
                    onPageChange={(e) => {
                        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
                    }}
                    containerClassName={"flex justify-center mt-10 gap-5 items-center w-full"}
                    pageClassName={"border custom-circle text-center"}
                    disabledClassName={"p-1 bg-gray-200"}
                    previousLinkClassName={"border p-1 text-center"}
                    nextLinkClassName={"border p-1"}
                    activeClassName={"bg-blue-900 text-white px-2"} />
            </div>
            <div className='flex justify-end'>
                <button className='p-1 bg-blue-400 rounded-lg' onClick={handleDone}>Done</button>
            </div>
        </div>

    )
}

export default FinishedGoodsSalesDetailsFillGrid;