import React from 'react';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, substract } from '../../../Utils/helper';
import { useGetYarnMasterQuery } from '../../../redux/ErpServices/YarnMasterServices';

const YarnProgramFillGrid = ({ id, programDetails, setProgramDetails, readOnly, setCurrentProgramIndex,
  finishedGoodsType, inwardDetails, setInwardDetails, onDone }) => {
  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };

  const addItem = (id, processCost) => {
    setInwardDetails(prev => {
      let newInwardDetails = structuredClone(prev);
      newInwardDetails.push({
        processDeliveryProgramDetailsId: id, inwardQty: 0, inwardBags: 0, weightPerBag: 0, processCost,
        lotDetails: [{ lotNo: "", inwardRolls: 0, inwardQty: 0, inwardBags: 0, weightPerBag: 0 }]
      })
      return newInwardDetails
    })
  }
  const deleteItem = (id) => {
    setInwardDetails(prev => {
      return prev.filter(item => parseInt(item.processDeliveryProgramDetailsId) !== parseInt(id))
    })
  }

  const isItemSelected = (id) => {
    let foundIndex = inwardDetails.findIndex(item => parseInt(item.processDeliveryProgramDetailsId) === parseInt(id))
    return foundIndex !== -1
  }

  const handleChangeInwardProgramDetails = (id, processCost) => {
    if (isItemSelected(id)) {
      deleteItem(id)
    } else {
      addItem(id, processCost)
    }
  }


  const { data: yarnList } =
    useGetYarnMasterQuery({ params });

  const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
    useGetColorMasterQuery({ params: { ...params, active: true } });

  const { data: uomList } =
    useGetUnitOfMeasurementMasterQuery({ params });


  function getTotals(field) {
    const total = programDetails.reduce((accumulator, current) => {
      return accumulator + parseFloat(current[field] ? current[field] : 0)
    }, 0)
    return parseFloat(total)
  }

  if (!yarnList || !colorList || !uomList) return <Loader />
  return (
    <>
      <div className={`w-full `}>
        <div className='flex justify-between mb-2'>
          <h1 className='text-center mx-auto font-bold'>Program Materials</h1>
          <button className='text-center font-bold bg-blue-400 text-gray-100 p-1 rounded-lg' onClick={onDone}>DONE</button>
        </div>
        <table className=" text-xs table-fixed w-full">
          <thead className='bg-blue-200 top-0'>
            <tr>
              <th className="table-data w-5"></th>
              <th className="table-data w-5">S.no</th>
              <th className="table-data  w-32">Items<span className="text-red-500">*</span></th>
              <th className="table-data  w-32">Color<span className="text-red-500">*</span></th>
              <th className="table-data  w-16">UOM<span className="text-red-500">*</span></th>
              <th className="table-data  w-16">Process Cost per Kg<span className="text-red-500">*</span></th>
              <th className="table-data  w-16">Program Qty<span className="text-red-500">*</span></th>
              <th className="table-data  w-16">A. Inward Qty<span className="text-red-500">*</span></th>
              <th className="table-data  w-16">Bal. Qty<span className="text-red-500">*</span></th>
            </tr>
          </thead>
          <tbody className='overflow-y-auto  h-full w-full'>
            {programDetails.map((row, index) => (
              <tr key={index} className="w-full table-row" onClick={() => { handleChangeInwardProgramDetails(row.id, row.processCost) }} >
                <td className="table-data flex justify-items-center items-center ">
                  <input type='checkbox' checked={isItemSelected(row.id)} />
                </td>
                <td className="table-data  ">
                  {index + 1}
                </td>
                <td className='table-data'>
                  {findFromList(row.yarnId, yarnList.data, "aliasName")}
                </td>
                <td className='table-data'>
                  {findFromList(row.colorId, colorList.data, "name")}
                </td>
                <td className='table-data'>
                  {findFromList(row.uomId, uomList.data, "name")}
                </td>
                <td className='table-data text-right'>
                  {row?.processCost ? row?.processCost : 0}
                </td>
                <td className='table-data text-right'>
                  {(!row.qty) ? 0 : row.qty}
                </td>
                <td className='text-right table-data'>
                  {parseFloat(row.alreadyInwardedQty).toFixed(3)}
                </td>
                <td className='text-right table-data'>
                  {substract(row.qty, row?.alreadyInwardedQty ? row?.alreadyInwardedQty : 0).toFixed(3)}
                </td>

              </tr>
            ))}
            {Array.from({ length: 5 - programDetails.length }).map(i =>
              <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                <td className='table-data'>
                </td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
              </tr>)
            }
          </tbody>
        </table>
      </div>
    </>

  )
}

export default YarnProgramFillGrid