import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SEASON_MASTER } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const seasonMasterMasterApi = createApi({
    reducerPath: "SeasonMasterMaster",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["SeasonMaster"],
    endpoints: (builder) => ({
        getSeasonMaster: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: SEASON_MASTER + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: SEASON_MASTER,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["SeasonMaster"],
        }),
        getSeasonMasterById: builder.query({
            query: (id) => {
                return {
                    url: `${SEASON_MASTER}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["SeasonMaster"],
        }),
        addSeasonMaster: builder.mutation({
            query: (payload) => ({
                url: SEASON_MASTER,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["SeasonMaster"],
        }),
        updateSeasonMaster: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${SEASON_MASTER}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["SeasonMaster"],
        }),
        deleteSeasonMaster: builder.mutation({
            query: (id) => ({
                url: `${SEASON_MASTER}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["SeasonMaster"],
        }),
    }),
});

export const {
    useGetSeasonMasterQuery,
    useGetSeasonMasterByIdQuery,
    useAddSeasonMasterMutation,
    useUpdateSeasonMasterMutation,
    useDeleteSeasonMasterMutation,
} = seasonMasterMasterApi;

export default seasonMasterMasterApi;
