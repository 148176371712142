import React, { useEffect } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetYarnMasterQuery } from '../../../redux/ErpServices/YarnMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, isBetweenRange, substract } from '../../../Utils/helper';
import {
    useGetPoQuery,
} from "../../../redux/ErpServices/PoServices";
import { toast } from 'react-toastify';


const YarnInwardItems = ({ finalInwardItems, setFinalInwardItems, readOnly, removeItem, openSelection, prefix }) => {
    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(finalInwardItems);
        newBlend[index][field] = value;
        if (field !== "inwardQty" && newBlend[index]["noOfBags"] && newBlend[index]["weightPerBag"]) {
            let tempInwardQty = (parseFloat(newBlend[index]["noOfBags"]) * parseFloat(newBlend[index]["weightPerBag"])).toFixed(3)
            newBlend[index]["inwardQty"] = tempInwardQty
        }
        setFinalInwardItems(newBlend);
    };

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )

    const { data: poList, isLoading: poListLoading, isFetching: poListFetching } = useGetPoQuery({ params:{branchId} });

    const { data: yarnList } =
        useGetYarnMasterQuery({ params: { companyId } });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { companyId } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params: { companyId } });

    if (!yarnList || !colorList || !uomList || !poList) return <Loader />
    return (
        <>
            <div className={`w-full overflow-y-auto py-1`}>
                <table className="border border-gray-500 text-xs table-auto w-full">
                    <thead className='bg-blue-200 top-0 border-b border-gray-500'>
                        <tr className='h-8'>
                            <th className="table-data  w-2 text-center">S.no</th>
                            <th className="table-data  w-2 text-center">P.no</th>
                            <th className="table-data ">Items</th>
                            <th className="table-data ">Colors</th>
                            <th className="table-data  ">UOM</th>
                            <th className="table-data  ">Po. Qty</th>
                            <th className="table-data  ">Already Inwarded Qty</th>
                            <th className="table-data  ">Balance Qty</th>
                            <th className="table-data  w-16">No. of Bags<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Weight Per Bag<span className="text-red-500">*</span></th>
                            <th className="table-data  ">Inward Qty</th>
                            <th className="table-data  ">Price</th>
                            <th className="table-data  ">Gross</th>
                            {!readOnly
                                &&
                                <th className='w-20'>
                                    <div onClick={openSelection}
                                        className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                                        {PLUS}
                                    </div>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {finalInwardItems.map((row, index) => (
                            <tr key={index} className="w-full table-row">
                                <td className="table-data text-left px-1 py-1">
                                    {index + 1}
                                </td>
                                <td className="table-data text-left px-1 py-1">
                                    {row.poDocId}
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="text"
                                        onFocus={(e) => e.target.select()}
                                        className="text-left rounded py-1  px-1 table-data-input"
                                        value={findFromList(row.yarnId, yarnList.data, "aliasName")}
                                        disabled={true}
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="text"
                                        onFocus={(e) => e.target.select()}
                                        className="text-left rounded py-1  px-1 table-data-input"
                                        value={findFromList(row.colorId, colorList.data, "name")}
                                        disabled={true}
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="text"
                                        onFocus={(e) => e.target.select()}
                                        className="text-left rounded py-1  px-1 table-data-input"
                                        value={findFromList(row.uomId, uomList.data, "name")}
                                        disabled={true}
                                    />
                                </td>
                                <td className='table-data text-right px-1'>
                                    {substract(row.qty, row.alreadyCancelQty).toFixed(3)}
                                </td>
                                <td className='table-data text-right px-1'>
                                    {(parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty)).toFixed(3)}
                                </td>
                                <td className='table-data text-right px-1'>
                                    {substract(substract(row.qty, row.alreadyCancelQty), (parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty))).toFixed(3)}
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0", index, "noOfBags") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-16 px-1 table-data-input"
                                        value={(!row.noOfBags) ? 0 : row.noOfBags}
                                        disabled={readOnly}
                                        inputMode='decimal'
                                        onChange={(e) => {
                                            if (!e.target.value) {
                                                handleInputChange(0, index, "noOfBags");
                                                return
                                            }
                                            let tempInwardQty = parseFloat(row?.weightPerBag ? row.weightPerBag : 0) * parseFloat(e.target.value)
                                            if (isBetweenRange(0, substract(substract(row.qty, row.alreadyCancelQty), (parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty))), tempInwardQty)) {
                                                handleInputChange(e.target.value.replace(/^0+/, ''), index, "noOfBags")
                                            } else {
                                                toast.info("Inward Qty Cannot be more than balance Qty", { position: 'top-center' })
                                            }
                                        }
                                        }
                                        onBlur={(e) =>
                                            handleInputChange(parseFloat(e.target.value), index, "noOfBags")
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        onFocus={(e) => e.target.select()}
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "weightPerBag") } }}
                                        className="text-right rounded py-1 w-16 px-1 table-data-input"
                                        value={(!row.weightPerBag) ? 0 : row.weightPerBag}
                                        disabled={readOnly}
                                        inputMode='decimal'
                                        onChange={(e) => {
                                            if (!e.target.value) {
                                                handleInputChange(0, index, "weightPerBag");
                                                return
                                            }
                                            let tempInwardQty = parseFloat(row?.noOfBags ? row.noOfBags : 0) * parseFloat(e.target.value)
                                            if (isBetweenRange(0, substract(substract(row.qty, row.alreadyCancelQty), (parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty))), tempInwardQty)) {
                                                handleInputChange(e.target.value.replace(/^0+/, ''), index, "weightPerBag")
                                            } else {
                                                toast.info("Inward Qty Cannot be more than balance Qty", { position: 'top-center' })
                                            }
                                        }
                                        }
                                        onBlur={(e) =>
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "weightPerBag")
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "inwardQty") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 px-1 w-full table-data-input"
                                        value={row.inwardQty}
                                        disabled={readOnly}
                                        onChange={(event) => {
                                            if (!event.target.value) {
                                                handleInputChange(0, index, "inwardQty");
                                                return
                                            }
                                            if (isBetweenRange(0, substract(substract(row.qty, row.alreadyCancelQty), (parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty))), event.target.value)) {
                                                handleInputChange(event.target.value.replace(/^0+/, ''), index, "inwardQty")
                                            } else {
                                                toast.info("Inward Qty Cannot be more than balance Qty", { position: 'top-center' })
                                            }
                                        }}
                                        onBlur={(e) => {
                                            if (!e.target.value) {
                                                handleInputChange(0.000, index, "inwardQty");
                                                return
                                            }
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "inwardQty")
                                        }}
                                    />
                                </td>
                                <td className='table-data text-right px-1'>
                                    {row.price}
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="number"
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 px-1 table-data-input"
                                        value={(row.price * row.inwardQty).toFixed(2)}
                                        disabled={true}
                                    />
                                </td>
                                {!readOnly &&
                                    <td className=' w-12'>
                                        <div tabIndex={-1} onClick={() => removeItem(row.poNo, row.poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                            {DELETE}
                                        </div>
                                    </td>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default YarnInwardItems