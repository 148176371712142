import React from 'react'
import {
    View, Text
} from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import { ACC_HOLDER_NAME } from '../../../Constants';
const BankDetails = ({ bankData }) => {
    return (
        <View style={{ ...tw("border-b border-gray-500"), fontFamily: "Times-Bold" }} wrap={false}>
            <Text style={tw("text-sm text-center")}>Bank Details</Text>
            <View style={{ ...tw("text-xs h-full w-full border-t border-gray-500 h-[40px] flex flex-row") }}>
                <View style={tw("w-1/2 h-full border-r border-gray-500")}>
                    <View style={tw("border-b border-gray-500 h-1/2 flex flex-row w-full items-center")}>
                        <Text style={tw("w-4/12 pl-5 text-right")}>
                            Name:
                        </Text>
                        <Text style={tw("w-7/12 text-left")}>
                            &nbsp;{ACC_HOLDER_NAME}
                        </Text>
                    </View>
                    <View style={tw("h-1/2 flex flex-row w-full items-center")}>
                        <Text style={tw("w-4/12 pl-5 text-right")}>
                            Bank&nbsp;:
                        </Text>
                        <Text style={tw("w-7/12 text-left pl-1")}>
                            &nbsp;{bankData?.bankName}
                        </Text>
                    </View>
                </View>
                <View style={tw("w-1/2 h-full border-gray-500")}>
                    <View style={tw("border-b border-gray-500 h-1/2 flex flex-row w-full items-center")}>
                        <Text style={tw("w-4/12 pl-5 text-right")}>
                            Acc.No &nbsp;:
                        </Text>
                        <Text style={tw("w-7/12 text-left")}>
                            &nbsp;{bankData?.accNo}
                        </Text>
                    </View>
                    <View style={tw("h-1/2 flex flex-row w-full items-center")}>
                        <Text style={tw("w-4/12 pl-5 text-right")}>
                            IFSC &nbsp;&&nbsp;Branch&nbsp;:
                        </Text>
                        <Text style={tw("w-7/12 text-left pl-1")}>
                            &nbsp;{bankData?.ifscCode}&nbsp;&&nbsp;{bankData?.branchName}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default BankDetails