import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetOnlineSalesReturnQuery,
  useGetOnlineSalesReturnByIdQuery,
  useAddOnlineSalesReturnMutation,
  useUpdateOnlineSalesReturnMutation,
  useDeleteOnlineSalesReturnMutation,
} from "../../../redux/ErpServices/onlineSalesReturnServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DropdownInput, DateInput, DisabledInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { getCommonParams, getDateFromDateTime, isGridDatasValid } from "../../../Utils/helper";
import moment from "moment";
import { Loader } from "../../../Basic/components";

import {
  useGetLocationMasterQuery,
} from "../../../redux/ErpServices/LocationMasterServices";
import {
  useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import Modal from "../../../UiComponents/Modal";

import OnlineSalesItemsFillGrid from "./OnlineSalesItemsFillGrid";
import OnlineSalesDeliveryDetails from "./OnlineSalesReturnDetails";
import Consolidation from "../Consolidation";
import OnlineSalesReport from "./OnlineSalesReturnReport";
import { useReactToPrint } from "@etsoo/reactprint";

const MODEL = "ONLINE SALES RETURN";

export default function Form() {

  const componentRef = useRef();


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "-",
    pageStyle: ``

  })

  const dispatch = useDispatch()

  const today = new Date()
  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [docId, setDocId] = useState("");
  const [stockDetailsFillGrid, setStockDetailsFillGrid] = useState(false);
  const [onlineSalesReturnDetails, setOnlineSalesReturnDetails] = useState([])
  const [storeId, setStoreId] = useState("")

  const [delDate, setDelDate] = useState(getDateFromDateTime(today))

  const [remarks, setRemarks] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [vehicleNo, setVehicleNo] = useState("");
  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const childRecord = useRef(0);

  const { branchId, companyId, finYearId, userId } = getCommonParams()

  const { data: locationData, isLoading: isLocationLoading, isFetching: isLocationFetching } = useGetLocationMasterQuery({ params: { branchId }, searchParams: searchValue });
  const { data: branchList } = useGetBranchQuery({ params: { companyId } });

  const { data: allData, isLoading, isFetching } = useGetOnlineSalesReturnQuery({ params: { branchId, finYearId }, searchParams: searchValue });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetOnlineSalesReturnByIdQuery({ id }, { skip: !id });

  const [addData] = useAddOnlineSalesReturnMutation();
  const [updateData] = useUpdateOnlineSalesReturnMutation();
  const [removeData] = useDeleteOnlineSalesReturnMutation();

  const getNextDocId = useCallback(() => {
    if (id) return
    if (isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setDelDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    if (data?.docId) {
      setDocId(data.docId);
    }
    setLocationId(data?.Store?.locationId ? data?.Store?.locationId : "")
    setStoreId(data?.storeId ? data?.storeId : "");
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");
    setOnlineSalesReturnDetails(data?.OnlineSalesReturnDetails ? data?.OnlineSalesReturnDetails : [])
    setSpecialInstructions(data?.specialInstructions ? data?.specialInstructions : "")
    setRemarks(data?.remarks ? data?.remarks : "")
  }, [id]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    branchId,
    storeId,
    vehicleNo,
    specialInstructions,
    remarks,
    onlineSalesReturnDetails,
    id, userId, finYearId
  }

  const validateData = (data) => {
    return data.storeId
      && data.onlineSalesReturnDetails.length > 0
      && isGridDatasValid(data.onlineSalesReturnDetails, false, ["qty"])
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 0) {
        toast.success(text + "Successfully");
        onNew()
      } else {
        toast.error(returnData.message);
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });

    } catch (error) {
      console.log("handle", error);
    }
  };
  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
    getNextDocId();
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const getTotalIssuedQty = () => {
    const totalQty = onlineSalesReturnDetails.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.qty ? currentValue?.qty : 0))
    }, 0)
    return totalQty
  }

  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLocationLoading || isLocationFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  if (isLocationFetching || isLocationLoading) return <Loader />
  const storeOptions = locationData ?
    locationData.data.filter(item => parseInt(item.locationId) === parseInt(locationId)) :
    [];
  return (
    <>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <OnlineSalesReport
          heading={MODEL}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          allData={allData}
          tableWidth="100%"
          setForm={setForm}
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <Modal isOpen={stockDetailsFillGrid} onClose={() => { setStockDetailsFillGrid(false) }} widthClass={"bg-gray-300"}>
        <OnlineSalesItemsFillGrid
          id={id}
          storeId={storeId}
          setFillGrid={setStockDetailsFillGrid} productionDeliveryDetails={onlineSalesReturnDetails}
          setProductionDeliveryDetails={setOnlineSalesReturnDetails} />
      </Modal>
      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
      >
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}
            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            onPrint={id ? handlePrint : null}
            openReport={() => setFormReport(true)}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <div >
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                      <legend className='sub-heading'>Sales</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-4">
                          <DisabledInput name={"Delivery No"} value={docId} />
                          <DateInput name="Del. Date" value={delDate} type={"date"} required={true} disabled />
                          <DropdownInput name="Location"
                            options={branchList ? (dropDownListObject(id ? branchList.data : branchList.data.filter(item => item.active), "branchName", "id")) : []}
                            value={locationId}
                            setValue={(value) => { setLocationId(value); setStoreId("") }}
                            required={true} readOnly={id || readOnly} />
                          <DropdownInput name="Store"
                            options={dropDownListObject(id ? storeOptions : storeOptions.filter(item => item.active), "storeName", "id")}
                            value={storeId} setValue={setStoreId} required={true} readOnly={id || readOnly} />
                        </div>
                      </div>
                    </fieldset>
                    <OnlineSalesDeliveryDetails id={id} readOnly={readOnly} setStockDetailsFillGrid={setStockDetailsFillGrid}
                      productionDeliveryDetails={onlineSalesReturnDetails} setProductionDeliveryDetails={setOnlineSalesReturnDetails}
                      storeId={storeId} />
                    <Consolidation vehicleNo={vehicleNo} setVehicleNo={setVehicleNo}
                      specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions}
                      remarks={remarks} setRemarks={setRemarks} totalQty={getTotalIssuedQty()} readOnly={readOnly} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}