import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MACHINE_MASTER_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const MachineMastApi = createApi({
    reducerPath: "machineMast",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["MachieMast"],
    endpoints: (builder) => ({
        getMachine: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: MACHINE_MASTER_API + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: MACHINE_MASTER_API,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["State"],
        }),
        getMachineById: builder.query({
            query: (id) => {
                return {
                    url: `${MACHINE_MASTER_API}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["State"],
        }),
        addMachine: builder.mutation({
            query: (payload) => ({
                url: MACHINE_MASTER_API,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["State"],
        }),
        updateMachine: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${MACHINE_MASTER_API}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["State"],
        }),
        deleteMachine: builder.mutation({
            query: (id) => ({
                url: `${MACHINE_MASTER_API}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["State"],
        }),
    }),
});

export const {
    useGetMachineQuery,
    useGetMachineByIdQuery,
    useAddMachineMutation,
    useUpdateMachineMutation,
    useDeleteMachineMutation,


} = MachineMastApi;

export default MachineMastApi;
