import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import baseQuery from "../baseQuery";
import { ORDER_ENTRY } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const orderEntryApi = createApi({
    reducerPath: "OrderEntry",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["OrderEntry"],
    endpoints: (builder) => ({
        getOrderEntry: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: ORDER_ENTRY + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: ORDER_ENTRY,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["OrderEntry"],
        }),
        getOrderEntryById: builder.query({
            query: (id) => {
                return {
                    url: `${ORDER_ENTRY}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["OrderEntry"],
        }),
        addOrderEntry: builder.mutation({
            query: (payload) => ({
                url: ORDER_ENTRY,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["OrderEntry"],
        }),
        updateOrderEntry: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${ORDER_ENTRY}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["OrderEntry"],
        }),
        deleteOrderEntry: builder.mutation({
            query: (id) => ({
                url: `${ORDER_ENTRY}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["OrderEntry"],
        }),
    }),
});

export const {
    useGetOrderEntryQuery,
    useGetOrderEntryByIdQuery,
    useAddOrderEntryMutation,
    useUpdateOrderEntryMutation,
    useDeleteOrderEntryMutation,

} = orderEntryApi;

export default orderEntryApi;
