import React from 'react'
import RainDot from "../../../assets/RainDot.png"
import { getCommonParams, getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import useTaxDetailsHook from '../../../CustomHooks/TaxHookDetails';
import { useGetFinishedGoodsSalesByIdQuery } from '../../../redux/ErpServices/finishedGoodsSalesServices';
import SalesOrder from './SalesOrder';
import { useGetBranchByIdQuery } from '../../../redux/services/BranchMasterService';
const numberToText = require('number-to-text')
require('number-to-text/converters/en-in');

export default function Form({ finishedGoodsSalesDetails, innerRef, id, discountType, discountValue, taxTemplateId }) {
  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetFinishedGoodsSalesByIdQuery({ id }, { skip: !id });

  const { branchId } = getCommonParams()
  const { data: branchData } = useGetBranchByIdQuery(branchId)

  const branchDetails = branchData?.data ? branchData?.data : {}

  const { ...taxDetails } = useTaxDetailsHook({
    poItems: finishedGoodsSalesDetails.map(i => ({ ...i, taxPercent: i.tax })), taxTypeId: taxTemplateId,
    discountType: discountType, discountValue: discountValue
  })

  if (!singleData || isSingleFetching || isSingleLoading || !taxDetails) return null

  return (
    <div className=" w-[300px] flex flex-col justify-between border-2 m-0 border-black small-print" id='poPrint' ref={innerRef}>
      <div className='flex items-center justify-center'>
        <img src={RainDot} alt='logo' className="h-6 w-6" />
        <h1 className='font-bold text-sm '>{branchDetails.branchName}</h1>
      </div>
      <div className='text-center mt-2 border border-gray-500 p-3 text-xs'>
        <h1 >{branchDetails.address}</h1>
        <h1 >{branchDetails.mobile}</h1>
        <h1 >PAN: {branchDetails.panNo} </h1>
        <h1>GST: {branchDetails.gstNo}</h1>
        <h1>MOBILE: {branchDetails.contactMobile}</h1>
      </div>
      <div className=' text-center grid  text-xs justify-between mt-1 pl-2'>
        <div className='grid grid-cols-4 text-start'>
          <span className="font-bold col-span-1">Bill.No</span>
          <span>:</span>
          <span className='col-span-2'> {singleData?.data?.docId} </span>
        </div>
        <div className='grid grid-cols-4 text-start'>
          <span className="font-bold col-span-1">Bill.Date</span>
          <span>:</span>
          <span className='col-span-2'>{getDateFromDateTimeToDisplay(singleData?.data?.createdAt)}</span>
        </div>
        <div className='grid grid-cols-4 text-start'>
          <span className="font-bold col-span-1">Cus.Name</span>
          <span>:</span>
          <span className='col-span-2'>{singleData?.data?.customerName ? singleData?.data?.customerName : ""}</span>
        </div>
        <div className='grid grid-cols-4 text-start'>
          <span className="font-bold col-span-1">Cus.Mobile</span>
          <span>:</span>
          <span className='col-span-2'>{singleData?.data?.customerMobile ? singleData?.data?.customerMobile : ""}</span>
        </div>
      </div>
      <SalesOrder finishedGoodsSalesDetails={finishedGoodsSalesDetails} singleData={singleData?.data} taxDetails={taxDetails} />
      <div className='w-full border-x border-b border-gray-600 grid justify-center p-1'>
        <div className='text-sm font-bold'>    Net. Amount: {parseFloat(taxDetails?.netAmount).toFixed(2)}</div>
        <div className='text-xs'>(Inclusive of all taxes)</div>
      </div>
      <h1 className=' text-xs pl-0.5 text-start'><span className="font-bold">Amount in words:</span> Rs.{numberToText.convertToText(taxDetails?.netAmount ? taxDetails?.netAmount : 0, { language: "en-in", separator: "" })} Only
      </h1>
    </div >
  )
}

