import React from 'react'

const SalesOrder = ({ finishedGoodsSalesDetails = [] }) => {
  return (
    <div className=' mt-8 '>
      <table className=" text-xs border  border-gray-500 table-auto w-full p-1">
        <thead className='  top-0 bg-green-200'>
          <tr className='h-4 pr-1'>
            <th className="text-center text-xs ">S.no</th>
            <th className=" text-xs  ">Process</th>
            <th className=" text-xs  ">Qty</th>
            <th className=" text-xs  ">Uom</th>
            <th className="  text-xs ">Rate</th>
            <th className=" text-xs ">Amt.</th>
          </tr>
        </thead>
        <tbody>
          {finishedGoodsSalesDetails.map((value, index) => (
            <tr className='border  border-gray-500 w-full text-xs ' key={index}>
              <td className=' text-xs  text-center px-1 table-data'>{index + 1} </td>
              <td className=' text-xs px-1 table-data '>
                {value?.Process?.name}
              </td>
              <td className=' text-xs  px-1 table-data text-right'>
                {value?.qty ? value.qty : ""}
              </td>
              <td className=' text-xs px-1 table-data '>
                {value?.Uom?.name}
              </td>
              <td className='px-1  text-xs table-data text-right '>
                {((value?.price ? value?.price : 0))}
              </td>
              <td className=' px-1 text-xs table-data text-right'>
                {((value?.price ? value?.price : 0) * (value?.qty ? value?.qty : 0))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SalesOrder;