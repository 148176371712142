import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetPrintingJobWorkQuery,
  useGetPrintingJobWorkByIdQuery,
  useAddPrintingJobWorkMutation,
  useUpdatePrintingJobWorkMutation,
  useDeletePrintingJobWorkMutation,
} from "../../../redux/ErpServices/PrintingJobWorkServices";
import { useGetPartyByIdQuery, useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { DropdownInput, DateInput, DisabledInput, TextInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { getCommonParams, getDateFromDateTime } from "../../../Utils/helper";
import moment from "moment";
import Consolidation from "../Consolidation";
import Modal from "../../../UiComponents/Modal";
import Report from "./JobWorkReport";
import { useReactToPrint } from "@etsoo/reactprint";
import Summary from "./Summary";
import { useGetTaxTemplateQuery } from "../../../redux/ErpServices/TaxTemplateServices";
import useTaxDetailsHook from "../../../CustomHooks/TaxHookDetails";
import JobWorkGridDetails from "./JobWorkGridDetails";
import PrintFormatPrintingJobWork from "../PrintFormat-PrintingJobWork";

const MODEL = "Printing Job Work";


export default function PrintingJobWork() {

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "-",
    pageStyle: ` `
  });


  const dispatch = useDispatch()

  const today = new Date()
  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [docId, setDocId] = useState("");
  const [taxSummary, setTaxSummary] = useState(false);
  const [taxTemplateId, setTaxTemplateId] = useState("");


  const [supplierId, setSupplierId] = useState("");

  const [autoDeliveryDate, setAutoDeliveryDate] = useState(getDateFromDateTime(today));
  // const [delDate, setDelDate] = useState("")
  const [orderDate, setOrderDate] = useState(getDateFromDateTime(today))
  const [supplierDcNo, setSupplierDcNo] = useState("")

  const [gridDetails, setGridDetails] = useState([]);


  const [remarks, setRemarks] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [vehicleNo, setVehicleNo] = useState("");


  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);
  const [discountType, setDiscountType] = useState("Percentage");
  const [discountValue, setDiscountValue] = useState(0);

  const [payMode, setPayMode] = useState("Cash")

  const { branchId, companyId, finYearId, userId } = getCommonParams()
  const params = {
    companyId
  };

  const { data: supplierList } =
    useGetPartyQuery({ params });
  const { data: allData, isLoading, isFetching } = useGetPrintingJobWorkQuery({
    params: {
      branchId, finYearId
    }, searchParams: searchValue
  });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetPrintingJobWorkByIdQuery({ id }, { skip: !id });

  const getNextDocId = useCallback(() => {
    if (id) return
    if (isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])


  const [addData] = useAddPrintingJobWorkMutation();
  const [updateData] = useUpdatePrintingJobWorkMutation();
  const [removeData] = useDeletePrintingJobWorkMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setAutoDeliveryDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    setAutoDeliveryDate(data?.orderDate ? moment.utc(data?.orderDate).format("YYYY-MM-DD") : moment.utc(new Date()).format("YYYY-MM-DD"));
    setGridDetails(data?.PrintingJobWorkDet ? data?.PrintingJobWorkDet : [])
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setSupplierDcNo(data?.supplierDc ? data?.supplierDc : "");
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");
    setRemarks(data?.remarks ? data?.remarks : "");
    setTaxTemplateId(data?.taxTemplateId ? data?.taxTemplateId : "");
    setSpecialInstructions(data?.specialInstructions ? data?.specialInstructions : "")
    setPayMode(data?.payMode ? data?.payMode : "Cash")
    setDiscountType(data?.discountType ? data?.discountType : "Percentage");
    setDiscountValue(data?.discountValue ? data?.discountValue : "0");
    if (data?.docId) {
      setDocId(data?.docId)
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);
  const { ...taxDetails } = useTaxDetailsHook({
    poItems: gridDetails.map(i => ({ ...i, taxPercent: i.tax })), taxTypeId: taxTemplateId,
    discountType: discountType, discountValue: discountValue
  })

  const data = {
    supplierId,
    branchId,
    vehicleNo,
    specialInstructions,
    remarks,
    id, userId,
    taxTemplateId,
    payMode,
    discountType,
    discountValue,
    orderDate,
    supplierDc: supplierDcNo,
    finYearId,
    netBillValue: taxDetails?.netAmount ? taxDetails?.netAmount : 0,
    gridDetails: gridDetails.filter(i => i.processId)
  }
  const validateData = (data) => {
    return true
  }
  const { data: taxTypeList } =
    useGetTaxTemplateQuery({ params: { ...params } });

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 0) {
        toast.success(text + "Successfully");
        onNew();
      } else {
        toast.error(returnData.message);
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });
    } catch (error) {
      console.log("handle", error);
    }
  };


  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const getTotalIssuedQty = () => {
    const totalQty = gridDetails?.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.qty ? currentValue?.qty : 0))
    }, 0)
    return totalQty
  }
  const { data: supplierDetails } =
    useGetPartyByIdQuery(supplierId, { skip: !supplierId });

  function isSupplierOutside() {
    if (supplierDetails) {
      return supplierDetails?.data?.City?.state?.name !== "TAMIL NADU"
    }
    return false
  }


  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        setForm={setForm}
        data={allData?.data ? allData?.data : []}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  const allSuppliers = supplierList ? supplierList.data : []
  return (
    <>
      <Modal isOpen={taxSummary} onClose={() => setTaxSummary(false)} widthClass={"p-10"}>
        <Summary
          discountType={discountType}
          setDiscountType={setDiscountType}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          poItems={gridDetails.filter(i => i.processId).map(i => ({ ...i, taxPercent: i.tax }))} taxTypeId={taxTemplateId} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
      </Modal>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <Report
          heading={MODEL}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
        />
      </Modal>
      <div className="hidden">
        <PrintFormatPrintingJobWork innerRef={componentRef} id={id} />
      </div>
      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
      >
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}
            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            onPrint={id ? handlePrint : null}
            openReport={() => setFormReport(true)}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"} h-full relative overflow-auto`}>
                    <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>
                      <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                        <legend className='sub-heading'>Info</legend>
                        <div className='flex flex-col justify-center items-start flex-1 w-full'>
                          <div className="grid grid-cols-4">
                            <DisabledInput name={"Dc. No."} value={docId} />
                            <DateInput name="Delivery Date" value={autoDeliveryDate} type={"date"} required={true} disabled />
                            <DateInput name="Order Date" value={orderDate} required={true} setValue={setOrderDate} />
                            <TextInput name="Supplier Dc" value={supplierDcNo} setValue={setSupplierDcNo} required={true} />
                            <DropdownInput name="Supplier" options={dropDownListObject(allSuppliers, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                            <DropdownInput name="Tax Type" options={dropDownListObject(taxTypeList ? taxTypeList.data : [], "name", "id")} value={taxTemplateId} setValue={setTaxTemplateId} required={true} readOnly={readOnly} />
                          </div>
                        </div>
                      </fieldset>
                      <JobWorkGridDetails gridItems={gridDetails} setGridItems={setGridDetails} />
                      <div className="flex">
                        <button className="text-sm bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition p-1 ml-5 rounded"
                          onClick={() => {
                            if (!taxTemplateId) {
                              toast.info("Please Select Tax Template !", { position: "top-center" })
                              return
                            }
                            setTaxSummary(true)
                          }}>
                          View Summary
                        </button>
                        <Consolidation
                          isRawMaterialSales
                          netAmount={taxDetails?.netAmount ? taxDetails?.netAmount : 0}
                          totalQty={getTotalIssuedQty()} readOnly={readOnly} vehicleNo={vehicleNo} setVehicleNo={setVehicleNo} remarks={remarks} setRemarks={setRemarks}
                          specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions} />
                      </div>
                    </div>
                    <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                      <FormReport
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setId={setId}
                        tableHeaders={tableHeaders}
                        tableDataNames={tableDataNames}
                        data={allData?.data ? allData?.data : []}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}