import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { MultiSelectDropdown } from '../../../Inputs';

const OrderEntryDetails = ({ singleSizeData, styleId, orderEntryDet, setOrderEntryDet, colorList }) => {
    const [readOnly, setReadOnly] = useState(false);
    const [selectedColors, setSelectedColors] = useState({});

    const colorOptions = colorList?.map(color => ({
        label: color.name,
        value: color.id
    }));
    console.log(orderEntryDet, 'orderEntryDet');

    const handleQtyChange = (e, colorLabel, styleLabel, sizeName) => {
        const newQty = e.target.value;

        setOrderEntryDet((prevOrderEntryDet) => {
            const updatedOrderEntryDet = prevOrderEntryDet?.map((entry) => {
                if (entry.colorLabel === colorLabel && entry.styleLabel === styleLabel && entry.sizeName === sizeName) {
                    return { ...entry, quantity: newQty };
                }
                return entry;
            });

            if (!updatedOrderEntryDet.some(entry => entry.colorLabel === colorLabel && entry.styleLabel === styleLabel && entry.sizeName === sizeName)) {
                updatedOrderEntryDet.push({ colorLabel, quantity: newQty, styleLabel, sizeName });
            }

            return updatedOrderEntryDet;
        });
    };

    const getTotalForColorSize = (colorLabel, sizeName) => {
        return orderEntryDet
            .filter((entry) => entry.colorLabel === colorLabel && entry.sizeName === sizeName)
            .reduce((sum, entry) => sum + (parseInt(entry.quantity) || 0), 0);
    };

    const getGrandTotalForStyle = (styleLabel) => {
        return Object.keys(selectedColors).reduce((total, colorLabel) => {
            return total + singleSizeData.SizeTemplateOnSize.map(size => size.Size.name).reduce((sizeTotal, sizeName) => {
                const colorSizeTotal = orderEntryDet
                    .filter((entry) => entry.styleLabel === styleLabel && entry.colorLabel === colorLabel && entry.sizeName === sizeName)
                    .reduce((sum, entry) => sum + (parseInt(entry.quantity) || 0), 0);
                return sizeTotal + colorSizeTotal;
            }, 0);
        }, 0);
    };

    const addRowsForSelectedColors = () => {
        setOrderEntryDet((prevOrderEntryDet) => {
            const existingEntries = prevOrderEntryDet.filter(entry =>
                styleId?.some(style => entry.styleLabel === style.label)
            );

            const newEntries = styleId?.flatMap(style =>
                (selectedColors[style.label] || []).flatMap(color =>
                    singleSizeData.SizeTemplateOnSize.map(size =>
                    ({
                        colorLabel: color.label,
                        quantity: "0",
                        styleLabel: style.label,
                        sizeName: size.Size.name
                    })
                    )
                )
            );

            const updatedEntries = [
                ...existingEntries,
                ...newEntries.filter(newEntry =>
                    !existingEntries.some(existingEntry =>
                        existingEntry.colorLabel === newEntry.colorLabel &&
                        existingEntry.styleLabel === newEntry.styleLabel &&
                        existingEntry.sizeName === newEntry.sizeName
                    )
                )
            ];

            return updatedEntries;
        });
    };

    useEffect(() => {
        addRowsForSelectedColors();
    }, [selectedColors, styleId]);

    const getCellColor = (quantity) => {
        if (quantity >= 50) return 'bg-green-500'; // Green background
        if (quantity < 40) return 'bg-red-500';   // Red background
        return 'bg-white'; // Default background
    };

    return (
        <div className='w-[50%] frame border-gray-300 rounded-lg p-1'>
            <legend className="sub-heading p-1">Size & colour Wise Quantity</legend>
            {styleId?.map((style, index) => (
                <fieldset key={index} className="">
                    <div className='w-[15rem]'>
                        <MultiSelectDropdown
                            name={"Colors"}
                            setSelected={(colors) => setSelectedColors(prev => ({ ...prev, [style.label]: colors }))}
                            selected={selectedColors[style.label] || []}
                            options={colorOptions || []}
                            readOnly={readOnly}
                        />
                    </div>
                    <table className="border p-4 w-full">
                        <thead>
                            <tr className='border border-gray-300'>
                                <th className='border border-gray-300'>Color</th>
                                {singleSizeData?.SizeTemplateOnSize.map(item => (
                                    <th key={item.Size.name} className='border border-gray-300'>{item.Size.name}</th>
                                ))}
                                <th className='border border-gray-300'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(selectedColors[style.label] || []).map((color) => {
                                const quantitiesBySize = singleSizeData.SizeTemplateOnSize.map((size) => {
                                    const sizeName = size.Size.name;
                                    const entry = orderEntryDet.find(entry =>
                                        entry.colorLabel === color.label &&
                                        entry.styleLabel === style.label &&
                                        entry.sizeName === sizeName
                                    );
                                    return entry?.quantity || 0;
                                });
                                const total = quantitiesBySize.reduce((sum, qty) => sum + parseInt(qty, 10), 0);

                                return (
                                    <tr key={color.label} className='border border-gray-300'>
                                        <td className='border border-gray-300 text-[12px] font-semibold'>{color.label}</td>
                                        {quantitiesBySize.map((quantity, index) => (
                                            <td key={index} className={`border border-gray-300 `}>
                                                <input
                                                    type='number'
                                                    className='text-right w-20 focus:outline-none border-gray-500 border rounded'
                                                    readOnly={readOnly}
                                                    value={quantity}
                                                    onChange={(e) => handleQtyChange(e, color.label, style.label, singleSizeData.SizeTemplateOnSize[index].Size.name)}
                                                />
                                            </td>
                                        ))}
                                        <td className='border border-gray-300'>{total}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="flex justify-end gap-20 mt-2">
                        <label htmlFor="grand-total" className="mr-5">Grand Total:</label>
                        <p id="grand-total">{getGrandTotalForStyle(style.label)}</p>
                    </div>
                </fieldset>
            ))}
        </div>
    );
};

export default OrderEntryDetails;
