import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
  useGetBillEntryQuery,
  useGetBillEntryByIdQuery,
  useAddBillEntryMutation,
  useUpdateBillEntryMutation,
  useDeleteBillEntryMutation,
} from "../../../redux/ErpServices/BillEntryServices";

import { useGetPartyQuery, useGetPartyByIdQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DropdownInput, TextInput, DisabledInput } from "../../../Inputs";
import { dropDownListObject, } from '../../../Utils/contructObject';

import { useDispatch } from "react-redux";
import Modal from "../../../UiComponents/Modal";
import ProcessDeliveryProgramItemsSelection from "./ProcessDeliveryProgramItemsSelection";
import { findFromList, getCommonParams, getDateFromDateTime, isGridDatasValid } from "../../../Utils/helper";

import YarnInwardItems from "./YarnInwardItems";
import FabricInwardItems from "./FabricInwardItems";

import moment from "moment";
import ProcessBillEntryReport from "./ProcessBillEntryReport";
import { Loader } from "../../../Basic/components";
import { useGetBranchQuery } from "../../../redux/services/BranchMasterService";
import { useGetTaxTemplateQuery } from "../../../redux/ErpServices/TaxTemplateServices";
import BillSummary from "./BillEntrySummary";
import useTaxDetailsHook from "../../../CustomHooks/TaxHookDetails";
import { useGetProcessQuery } from "../../../redux/ErpServices/processMasterServices";

const MODEL = "Process Bill Entry";


export default function Form() {
  const dispatch = useDispatch()

  const today = new Date()
  const [inwardItemSelection, setInwardItemSelection] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [id, setId] = useState("");
  const [date, setDate] = useState(getDateFromDateTime(today));
  const [processId, setProcessId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [docId, setDocId] = useState("");

  const [summary, setSummary] = useState(false)

  const [inwardItems, setInwardItems] = useState([]);

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [discountType, setDiscountType] = useState("Percentage");
  const [discountValue, setDiscountValue] = useState(0);

  const [partyBillNo, setPartyBillNo] = useState("")
  const [partyBillDate, setPartyBillDate] = useState("")
  const [netBillValue, setNetBillValue] = useState("")
  const [taxTemplateId, setTaxTemplateId] = useState("");


  const childRecord = useRef(0);

  const { branchId, companyId, finYearId, userId } = getCommonParams()


  const params = {
    branchId, companyId
  };
  const { data: supplierList } =
    useGetPartyQuery({ params: { companyId, active: true } });

  const { data: supplierDetails } =
    useGetPartyByIdQuery(supplierId, { skip: !supplierId });

  const { data: taxTypeList } =
    useGetTaxTemplateQuery({ params: { ...params } });

  const { data: allData, isLoading, isFetching } = useGetBillEntryQuery({ params: { branchId, finYearId, isProcessBillEntry: true } });

  const { data: processList } = useGetProcessQuery({ params });

  const { isLoading: isTaxHookDetailsLoading, ...taxDetails } = useTaxDetailsHook({ poItems: inwardItems, taxTypeId: taxTemplateId, discountType, discountValue })

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetBillEntryByIdQuery({ id }, { skip: !id });

  function isSupplierOutside() {
    if (supplierDetails) {
      return supplierDetails?.data?.City?.state?.name !== "TAMIL NADU"
    }
    return false
  }

  const [addData] = useAddBillEntryMutation();
  const [updateData] = useUpdateBillEntryMutation();
  const [removeData] = useDeleteBillEntryMutation();


  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.docId) {
      setDocId(data?.docId)
    }
    setDiscountType(data?.discountType ? data?.discountType : "Percentage")
    setDiscountValue(data?.discountValue ? data?.discountValue : "0")
    setInwardItems(data?.BillEntryItems ? structuredClone(data.BillEntryItems) : [])
    if (data?.createdAt) setDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    setPartyBillNo(data?.partyBillNo ? data?.partyBillNo : "")
    setPartyBillDate(data?.partyBillDate ? moment.utc(data?.partyBillDate).format("YYYY-MM-DD") : "")
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setTaxTemplateId(data?.taxTemplateId ? data.taxTemplateId : "")
    setNetBillValue(data?.netBillValue ? data.netBillValue : "")
    setProcessId(data?.processId ? data.processId : '')
  }, [id]);

  const getNextDocId = useCallback(() => {
    if (id) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, id])

  useEffect(getNextDocId, [getNextDocId])

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    supplierId,
    branchId, id, userId,
    partyBillNo, partyBillDate,
    netBillValue, taxTemplateId,
    billEntryItems: inwardItems,
    discountType, discountValue,
    isProcessBillEntry: true,
    processId, finYearId
  }

  const validateData = (data) => {
    let mandatoryFields = ["qty"];
    return data.supplierId && data.partyBillDate && data.partyBillNo
      && isGridDatasValid(data.billEntryItems, false, mandatoryFields)
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback({ id, body: data }).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 1) {
        toast.error(returnData.message)
      } else {
        toast.success(text + "Successfully");
        setId("")
        syncFormWithDb(undefined)
      }
      dispatch({
        type: `Ledger/invalidateTags`,
        payload: ['Ledger'],
      });
      dispatch({
        type: `po/invalidateTags`,
        payload: ['po'],
      });
      dispatch({
        type: `DirectInwardOrReturn/invalidateTags`,
        payload: ['DirectInwardOrReturn'],
      });
      dispatch({
        type: `ProcessDelivery/invalidateTags`,
        payload: ['ProcessDelivery'],
      });
    } catch (error) {
      console.log("handle", error);
    }
  };

  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (parseFloat(taxDetails.netAmount) !== parseFloat(netBillValue)) {
      toast.info("Net Bill Value Not Matching Net Amount...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `DirectInwardOrReturn/invalidateTags`,
          payload: ['DirectInwardOrReturn'],
        });
        dispatch({
          type: `Ledger/invalidateTags`,
          payload: ['Ledger'],
        });
        onNew();
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined)
  };



  function removeItem(id) {
    setInwardItems(localInwardItems => {
      let newItems = structuredClone(localInwardItems);
      newItems = newItems.filter(item => !((parseInt(item.processDeliveryProgramDetailsId) === parseInt(id))))
      return newItems
    });
  }

  const { data: branchList } = useGetBranchQuery({ params: { companyId } });

  const allSuppliers = supplierList ? supplierList.data : []

  function filterSupplier() {
    if (!processId) return []
    const isProcessInSupplier = (supplierProcessList) => supplierProcessList.includes(parseInt(processId))
    let finalSupplier = allSuppliers.filter(s => (!(s?.PartyOnProcess)) ? false : isProcessInSupplier(s.PartyOnProcess.map(item => parseInt(item.processId))))
    return finalSupplier
  }
  let supplierListBasedOnSupply = filterSupplier()

  if (!branchList?.data) return <Loader />

  const transtype = processId ? (findFromList(processId, processList.data, "io")).toString().split("_").at(1) : ""

  const processData = processList?.data ? processList.data : []

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme"
    >
      <Modal isOpen={summary} onClose={() => setSummary(false)} widthClass={"p-10"}>
        <BillSummary
          discountType={discountType}
          setDiscountType={setDiscountType}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          poItems={inwardItems} taxTypeId={taxTemplateId} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
      </Modal>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"h-[70%]"}>
        <ProcessBillEntryReport
          heading={MODEL}
          loading={
            isLoading || isFetching
          }

          allData={allData}
          tableWidth="100%"
          data={allData?.data}

          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <Modal isOpen={inwardItemSelection} onClose={() => setInwardItemSelection(false)} widthClass={"w-[95%] h-[90%] py-10"}>
        <ProcessDeliveryProgramItemsSelection setInwardItemSelection={setInwardItemSelection}
          transtype={transtype}
          processData={processData}
          supplierId={supplierId}
          processId={processId}
          inwardItems={inwardItems}
          setInwardItems={setInwardItems} />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          openReport={() => { setFormReport(true); }}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid">
            <div className='col-span-3 grid'>
              <div className='mr-1'>
                <div className={`grid`}>
                  <div className={``}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 h-[140px] px-3'>
                      <legend className='sub-heading'>Purchase Inward Info</legend>
                      <div className="flex justify-end relative top-0 right-0">
                      </div>
                      <div className='grid grid-cols-4 items-start flex-1 w-full'>
                        <DisabledInput name="Doc. ID" value={docId} required={true} readOnly={readOnly} />
                        <DisabledInput name="Doc 
                           Date" value={date} type={"date"} required={true} readOnly={readOnly} />
                        <DropdownInput
                          className={"w-[110px]"}
                          name="Process"
                          options={dropDownListObject(id ? processData : processData.filter(item => item.active), "name", "id")}
                          value={processId}
                          setValue={setProcessId}
                          required={true}
                          readOnly={id || readOnly}
                        />
                        <DropdownInput name="Supplier" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                        <TextInput name={"PartyBill.No"} required value={partyBillNo} setValue={setPartyBillNo} readOnly={readOnly} />
                        <TextInput name={"PartyBill.Date."} required value={partyBillDate} setValue={setPartyBillDate} type={"date"} readOnly={readOnly} />
                        <TextInput name={"NetBillValue"} value={netBillValue} setValue={setNetBillValue} readOnly={readOnly} required />
                        <DropdownInput name="Tax Type" options={dropDownListObject(taxTypeList ? taxTypeList.data : [], "name", "id")} value={taxTemplateId} setValue={setTaxTemplateId} required={true} readOnly={readOnly} />
                        {!readOnly &&
                          <div className="">
                            <button className="p-1.5 text-xs bg-lime-400 rounded hover:bg-lime-600 font-semibold transition hover:text-white"
                              onClick={() => {
                                if (!supplierId || !processId) {
                                  toast.info("Please Select Inward/Return , Po type and Suppplier", { position: "top-center" })
                                  return
                                }
                                setInwardItemSelection(true)
                              }}
                            >Select Items</button>
                          </div>
                        }
                      </div>
                    </fieldset>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 border border-gray-600 md:pb-5 flex h-[360px] w-full '>
                      <legend className='sub-heading'>Bill Entry Details</legend>
                      {(transtype === "GY" || transtype === "DY") ?
                        <YarnInwardItems billEntryId={id} removeItem={removeItem} supplierId={supplierId} processId={processId}
                          transType={transtype} inwardItems={inwardItems} setInwardItems={setInwardItems}
                          readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                        :
                        <FabricInwardItems removeItem={removeItem} transType={transtype} billEntryId={id} supplierId={supplierId} processId={processId}
                          inwardItems={inwardItems} setInwardItems={setInwardItems} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
                      }
                    </fieldset>
                  </div>
                  <div>
                    <button className="text-sm bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition p-1 ml-5 rounded"
                      onClick={() => {
                        if (!taxTemplateId) {
                          toast.info("Please Select Tax Template !", { position: "top-center" })
                          return
                        }
                        setSummary(true)
                      }}>
                      View Summary
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



