import React, { useEffect, useState, useRef, useCallback } from 'react';
import secureLocalStorage from 'react-secure-storage';

import FormHeader from '../../../Basic/components/FormHeader';
import FormReport from '../../../Basic/components/FormReportTemplate';
import { toast } from 'react-toastify';
import { TextInput, CheckBox, TextArea } from '../../../Inputs';
import ReportTemplate from '../../../Basic/components/ReportTemplate';
import DataTable from './TemplateFillGrid';
import { TICK_ICON } from '../../../icons';
import { useAddOperationGroupMastMutation, useDeleteOperationGroupMastMutation, useGetOperationGroupMastByIdQuery, useGetOperationGroupMastQuery, useUpdateOperationGroupMastMutation } from '../../../redux/ErpServices/OperationGroupServices';
import { useGetOperationNameQuery } from '../../../redux/ErpServices/operationNameServices';

const MODEL = 'Operation Group Master';

export default function Form() {
    const [form, setForm] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [active, setActive] = useState(true);
    const [operationGroupDetails, setOperationGroupDetails] = useState([]);
    const [formula, setFormula] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const childRecord = useRef(0);
    const [isFormulaEditing, setIsFormulaEditing] = useState(null);

    const params = {
        companyId: secureLocalStorage.getItem(sessionStorage.getItem('sessionId') + 'userCompanyId'),
        branchId: secureLocalStorage.getItem(sessionStorage.getItem('sessionId') + 'currentBranchId'),
    };
    const { data: fielddata } = useGetOperationNameQuery({ params });
    const { data: allData, isLoading, isFetching } = useGetOperationGroupMastQuery({ params, searchParams: searchValue });
    const { data: singleData, isFetching: isSingleFetching, isLoading: isSingleLoading } = useGetOperationGroupMastByIdQuery(id, { skip: !id });

    const [addData] = useAddOperationGroupMastMutation();
    const [updateData] = useUpdateOperationGroupMastMutation();
    const [removeData] = useDeleteOperationGroupMastMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setName(data?.name || '');
            setActive(data?.active ?? true);
            setOperationGroupDetails(data?.operationGroupDetails || []);
            setFormula(data?.formula || '');
        }, [id]);

    useEffect(() => {
        if (singleData) {
            syncFormWithDb(singleData?.data);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id,
        name,
        branchId: secureLocalStorage.getItem(sessionStorage.getItem('sessionId') + 'currentBranchId'),
        active,
        operationGroupDetails: operationGroupDetails,
    };

    const validateData = (data) => data.name;

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            await callback(data).unwrap();
            setId('');
            syncFormWithDb(undefined);
            toast.success(`${text} Successfully`);
        } catch (error) {
            console.error('handleSubmitCustom error:', error);
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info('Please fill all required fields...!', {
                position: 'top-center',
            });
            return;
        }
        if (window.confirm('Are you sure save the details ...?')) {
            if (id) {
                handleSubmitCustom(updateData, data, 'Updated');
            } else {
                handleSubmitCustom(addData, data, 'Added');
            }
        }
    };

    const deleteData = async () => {
        if (id && window.confirm('Are you sure to delete...?')) {
            try {
                await removeData(id);
                setId('');
                toast.success('Deleted Successfully');
            } catch (error) {
                toast.error('Something went wrong');
            }
        }
    };

    const handleKeyDown = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId('');
        setForm(true);
        setSearchValue('');
        syncFormWithDb(undefined);
        setReadOnly(false);
    };

    const onDataClick = (id) => {
        setId(id);
        setForm(true);
    };

    const tableHeaders = ['Name', 'Status'];
    const tableDataNames = ['dataObj.name', 'dataObj.active ? ACTIVE : INACTIVE'];

    if (!form) {
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={isLoading || isFetching}
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );
    }

    return (
        <div onKeyDown={handleKeyDown} className="md:items-start md:justify-items-center grid h-full bg-theme">
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue('');
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 ">
                        <div className="mr-1 md:ml-2">
                            <div className="flex my-2 border border-gray-400 gap-8 form">
                                <TextInput
                                    name="Name"
                                    type="text"
                                    value={name}
                                    setValue={setName}
                                    required={true}
                                    readOnly={readOnly}
                                    disabled={childRecord.current > 0}
                                />

                                <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                            </div>
                            <div className='w-[50%] border-gray-400'><DataTable
                                readOnly={readOnly}
                                data={fielddata?.data || []}
                                setFormula={setFormula}
                                operationGroupDetails={operationGroupDetails} setOperationGroupDetails={setOperationGroupDetails}
                            /></div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={isLoading || isFetching}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
