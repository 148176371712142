import React, { useState } from 'react';
import secureLocalStorage from "react-secure-storage";
import { useGetStockQuery } from '../../../redux/ErpServices/StockServices';
import { EMPTY_ICON, REFRESH_ICON } from "../../../icons";

import moment from 'moment';
import Modal from '../../../UiComponents/Modal';
import ParameterButton from '../../ReusableComponents/ParameterButton';
import Parameter from './Parameter';

const StockItems = () => {
  const [poType, setPoType] = useState("")
  const [storeId, setStoreId] = useState("")
  const [locationId, setLocationId] = useState('');
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [parameter, setParameter] = useState(false);

  const branchId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "currentBranchId"
  )

  const { data: stockData, refetch
  } = useGetStockQuery({ params: { branchId, stockReport: true, storeId, itemType: poType, toDate: endDate } },
    { skip: !(endDate && poType && storeId) });


  let stockList = stockData ? stockData.data : []


  const nemericFields = ["Opening_Stock", "In_Qty", "Out_Qty", "Closing_Stock", "No_Of_Rolls", "Qty", "No_Of_Bags"];
  return (
    <>
      <Modal isOpen={parameter} onClose={() => {

        setParameter(false)
      }
      } >
        <Parameter
          poType={poType} setPoType={setPoType} locationId={locationId} setLocationId={setLocationId}
          storeId={storeId} setStoreId={setStoreId} endDate={endDate} setEndDate={setEndDate} onClose={() => setParameter(false)}
        />
      </Modal>
      <div className=''>
        <div className='w-full h-full  p-2'>
          <div className='flex items-center justify-between page-heading p-2 font-bold'>
            <h1 className=''>Stock Report as On Date</h1>
            <div className='flex gap-5'>
              <ParameterButton onClick={() => setParameter(true)} />
              <button onClick={refetch}>
                Refresh {REFRESH_ICON}
              </button>
            </div>
          </div>

          <div>
            {
              stockList.length !== 0 ?
                <table className='w-full border border-gray-500 text-xs'>
                  <thead>
                    <tr className='bg-blue-200 border border-gray-500 sticky top-10'>
                      {Object.keys(stockList[0]).map((heading, i) =>
                        <th key={i}>
                          {heading.replace(/_+/g, ' ')}
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {stockList.map((data, i) =>
                      <tr key={i} className='py-2 w-full table-row'>
                        {Object.keys(data).map((heading, i) =>
                          <td key={i} className={`${nemericFields.includes(heading) ? "text-right" : "text-left"}`}>
                            {data[heading]}
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
                :
                <div className="flex justify-center items-center text-blue-900  text-3xl sm:mt-52">
                  <p>{EMPTY_ICON} No Data Found...! </p>
                </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default StockItems