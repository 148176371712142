import React, { useEffect, useState } from 'react'
import { useGetPoItemByIdQuery } from '../../../redux/ErpServices/PoServices'
import { Loader } from '../../../Basic/components'
import { DELETE, VIEW } from '../../../icons'
import { isGridDatasValid, sumArray } from '../../../Utils/helper'
import { FabricLotGridWithStock } from './LotGridWithStock'
import Modal from '../../../UiComponents/Modal'

const FabricPoItem = ({ poItemId, noOfRolls, index, handleInputChange, readOnly, qty, removeItem, purchaseInwardId, stockId, item,
    handleInputChangeLotNo }) => {
    const [lotGrid, setLotGrid] = useState(false)

    const { data, isLoading, isFetching } = useGetPoItemByIdQuery({ id: poItemId, purchaseInwardId, stockId }, { skip: !poItemId })

    const allowedReturnQty = (alreadyInwardQty, alreadyReturnQty, stockQty) => {
        const allowedReturn = (alreadyInwardQty - alreadyReturnQty)
        return Math.min(stockQty, allowedReturn)
    }

    useEffect(() => {
        if (purchaseInwardId) return
        if (isLoading || isFetching || !data?.data) return
        handleInputChange(data.data.alreadyInwardReturnLotWiseData.map(item => {
            let newItem = structuredClone(item)
            newItem["noOfRolls"] = 0
            newItem["qty"] = parseFloat(0).toFixed(3)
            return newItem
        }), index, "lotDetails", 0)
    }, [isFetching, isLoading, data, index, purchaseInwardId])

    useEffect(() => {
        if (!purchaseInwardId) return
        if (isLoading || isFetching || !data?.data) return
        handleInputChange(data.data.alreadyInwardReturnLotWiseData.map(alreadyItem => {
            let newItem = structuredClone(alreadyItem)
            let lotIndex = item?.lotDetails ? item?.lotDetails.findIndex(i => i.lotNo === alreadyItem.lotNo) : -1
            if (lotIndex !== -1) {
                newItem["noOfRolls"] = item.lotDetails[lotIndex].noOfRolls
                newItem["qty"] = parseFloat(item.lotDetails[lotIndex].qty).toFixed(3)
            }
            return newItem
        }), index, "lotDetails", 0)
    }, [isFetching, isLoading, data])

    if (isLoading || isFetching) return <Loader />
    const poItem = data.data
    let alreadyInwardedQty = poItem.alreadyInwardedData?._sum?.qty ? parseFloat(poItem.alreadyInwardedData._sum.qty).toFixed(3) : "0.000";
    let alreadyInwardedRolls = poItem.alreadyInwardedData?._sum?.noOfRolls ? poItem.alreadyInwardedData._sum.noOfRolls : 0;
    let alreadyReturnedQty = poItem.alreadyReturnedData?._sum?.qty ? parseFloat(poItem.alreadyReturnedData._sum.qty).toFixed(3) : "0.000";
    let alreadyReturnedRolls = poItem.alreadyReturnedData?._sum?.noOfRolls ? parseFloat(poItem.alreadyReturnedData._sum.noOfRolls).toFixed(3) : "0.000";
    let stockQty = sumArray(data?.data?.alreadyInwardReturnLotWiseData ? data?.data?.alreadyInwardReturnLotWiseData : [], "stockQty")
    let stockRolls = sumArray(data?.data?.alreadyInwardReturnLotWiseData ? data?.data?.alreadyInwardReturnLotWiseData : [], "stockRolls")

    function onClose() {
        if (readOnly) { return setLotGrid(false) }
        let isAnyReturnDataHasValue = item.lotDetails.some((i) => {
            return parseFloat(i["qty"]) !== 0
        })
        if (!isAnyReturnDataHasValue) {
            window.alert("All Lot Return Qty is Empty...!")
            return
        }
        if (!isGridDatasValid(item?.lotDetails ? item.lotDetails : [], false, ["qty"])) {
            if (window.confirm("Some of Lot Return Qty is 0 (Zero). Do you want to Continue ?")) {
                setLotGrid(false)
                return
            }
            return
        }
        setLotGrid(false)
    }

    return (
        <>
            <Modal widthClass={"max-h-[600px] overflow-auto"} onClose={onClose} isOpen={lotGrid}>
                <FabricLotGridWithStock
                    allowedReturnQty={allowedReturnQty}
                    readOnly={readOnly}
                    onClose={onClose}
                    handleInputChangeLotNo={handleInputChangeLotNo}
                    index={index} lotDetails={item?.lotDetails ? item?.lotDetails : []} />
            </Modal>
            <tr key={poItemId} className='table-row'>
                <td className='text-left px-1  table-data'>{index + 1}</td>
                <td className='text-left px-1 w-48  table-data'>{poItem?.Po?.docId}</td>
                <td className='text-left px-1 w-36 table-data'>{poItem?.Fabric?.aliasName}</td>
                <td className='text-left px-1 w-36 table-data'>{poItem?.Color?.name}</td>
                <td className='text-left px-1  table-data'>{poItem?.Design?.name}</td>
                <td className='text-right px-1  table-data'>{poItem?.Gauge?.name}</td>
                <td className='text-right px-1  table-data'>{poItem?.LoopLength?.name}</td>
                <td className='text-left px-1  table-data'>{poItem?.Gsm?.name}</td>
                <td className='text-right px-1  table-data'>{poItem?.KDia?.name}</td>
                <td className='text-right px-1  table-data'>{poItem?.FDia?.name}</td>
                <td className='text-left px-1  table-data'>{poItem?.Uom?.name}</td>
                <td className='text-right px-1 w-20 table-data'>{stockRolls}</td>
                <td className='text-right px-1 w-20 table-data'>{stockQty}</td>
                <td className='text-right px-1 w-20 table-data'>{alreadyInwardedRolls}</td>
                <td className='text-right px-1 w-20 table-data'>{alreadyInwardedQty}</td>
                <td className='text-right px-1 w-20 table-data'>{alreadyReturnedRolls}</td>
                <td className='text-right px-1 w-20 table-data'>{alreadyReturnedQty}</td>
                <td className='text-right px-1  table-data'>{allowedReturnQty(alreadyInwardedRolls, alreadyReturnedRolls, stockRolls)}</td>
                <td className='text-right px-1  table-data'>{allowedReturnQty(alreadyInwardedQty, alreadyReturnedQty, stockQty)}</td>
                <td className='text-center table-data'>
                    <button onClick={() => setLotGrid(true)} className='w-full'>
                        {VIEW}
                    </button>
                </td>
                <td className='text-right px-1  table-data'>
                    <input
                        type="number"
                        className="text-right rounded py-1  px-1 w-full table-data-input"
                        value={sumArray(item?.lotDetails ? item?.lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0) : [], "noOfRolls")}
                        disabled={true}
                        onChange={(event) => {
                            if (!event.target.value) {
                                handleInputChange(0, index, "noOfRolls");
                                return
                            }
                            handleInputChange(event.target.value, index, "noOfRolls");
                        }}

                    />
                </td>
                <td className='text-left px-1  table-data'>
                    <input
                        type="number"
                        className="text-right rounded py-1  px-1 w-full table-data-input"
                        value={parseFloat(sumArray(item?.lotDetails ? item?.lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0) : [], "qty")).toFixed(3)}
                        disabled={true}
                        onChange={(event) => {
                            if (!event.target.value) {
                                handleInputChange(0, index, "qty");
                                return
                            }
                            handleInputChange(event.target.value, index, "qty");
                        }}
                    />
                </td>
                <td className='text-right px-1  table-data'>{parseFloat(poItem.price).toFixed(2)}</td>
                <td className='text-right   table-data'>{(parseFloat(poItem.price) * parseFloat(sumArray(item?.lotDetails ? item?.lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0) : [], "qty"))).toFixed(2)}</td>
                {!readOnly &&
                    <td className='table-data w-12'>
                        <div tabIndex={-1} onClick={() => removeItem(poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                            {DELETE}
                        </div>
                    </td>
                }
            </tr>
        </>
    )
}

export default FabricPoItem
