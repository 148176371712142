import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FINISHED_GOODS_OPENING_STOCK_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const FinishedGoodsOpeningStockApi = createApi({
  reducerPath: "FinishedGoodsOpeningStock",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ["FinishedGoodsOpeningStock"],
  endpoints: (builder) => ({
    getFinishedGoodsOpeningStock: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: FINISHED_GOODS_OPENING_STOCK_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: FINISHED_GOODS_OPENING_STOCK_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["FinishedGoodsOpeningStock"],
    }),
    getFinishedGoodsOpeningStockById: builder.query({
      query: ({ id }) => {
        return {
          url: `${FINISHED_GOODS_OPENING_STOCK_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["FinishedGoodsOpeningStock"],
    }),
    addFinishedGoodsOpeningStock: builder.mutation({
      query: (payload) => ({
        url: FINISHED_GOODS_OPENING_STOCK_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["FinishedGoodsOpeningStock"],
    }),
    updateFinishedGoodsOpeningStock: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${FINISHED_GOODS_OPENING_STOCK_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["FinishedGoodsOpeningStock"],
    }),
    deleteFinishedGoodsOpeningStock: builder.mutation({
      query: (id) => ({
        url: `${FINISHED_GOODS_OPENING_STOCK_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FinishedGoodsOpeningStock"],
    }),
  }),
});

export const {
  useGetFinishedGoodsOpeningStockQuery,
  useGetFinishedGoodsOpeningStockByIdQuery,
  useAddFinishedGoodsOpeningStockMutation,
  useUpdateFinishedGoodsOpeningStockMutation,
  useDeleteFinishedGoodsOpeningStockMutation,
} = FinishedGoodsOpeningStockApi;

export default FinishedGoodsOpeningStockApi;
