import React, { useState } from 'react';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from "react-secure-storage";
import { useGetPartyQuery } from '../../../redux/ErpServices/PartyMasterServices';
import { findFromList } from '../../../Utils/helper';
import YarnPoItemSelection from './YarnPoItemSelection';
import FabricPoItemSelection from './FabricPoItemSelection';

const ProcessDeliveryProgramItemsSelection = ({ transtype, processId, supplierId, setInwardItems, inwardItems, setInwardItemSelection, processData }) => {
    const [localInwardItems, setLocalInwardItems] = useState(inwardItems.map(prevItem => { return { id: prevItem.processDeliveryProgramDetailsId } }));

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const { data: supplierList, isLoading: supplierLoading, isFetching: supplierFetching } =
        useGetPartyQuery({ params: { companyId, active: true } });

    if (supplierFetching || supplierLoading) return <Loader />

    function addItem(id) {
        setLocalInwardItems(localInwardItems => {
            let newItems = structuredClone(localInwardItems);
            newItems.push({ id });
            return newItems
        });
    }
    function removeItem(id) {
        setLocalInwardItems(localInwardItems => {
            let newItems = structuredClone(localInwardItems);
            newItems = newItems.filter(item => !((parseInt(item.id) === parseInt(id))))
            return newItems
        });
    }


    function handleChange(id) {
        if (isItemAdded(id)) {
            removeItem(id)
        } else {
            addItem(id)
        }
    }
    function isItemAdded(id) {
        return localInwardItems.findIndex(item => parseInt(item.id) === parseInt(id)) !== -1
    }

    function handleSelectAllChange(value, poItems) {
        if (value) {
            poItems.forEach(item => addItem(item.id))
        } else {
            poItems.forEach(item => removeItem(item.id))
        }
    }

    function getSelectAll(poItems) {
        return poItems.every(item => isItemAdded(item.id, item?.poId ? true : false))
    }

    function handleDone() {
        setInwardItems(prevInwardItems => {
            let oldInwardItems = prevInwardItems.filter(item => isItemAdded(item.processDeliveryProgramDetailsId))
            let newInwardItems = localInwardItems.filter(item => {
                return prevInwardItems.findIndex(prevItem => (parseInt(prevItem.processDeliveryProgramDetailsId) === parseInt(item.id))) === -1
            })
            return [...oldInwardItems, ...newInwardItems.map(i => { return { processDeliveryProgramDetailsId: i.id, discountType: "Percentage", discountValue: "0", qty: "0.000", } })]
        });
        setInwardItemSelection(false);
    }

    function handleCancel() {
        setLocalInwardItems([]);
        setInwardItemSelection(false);
    }
    return (
        <>
            <div className='h-full w-full flex flex-col'>
                <div className='flex justify-between text-center bg-blue-200 rounded-b-md sticky top-0'>
                    <div className='p-2 rounded-lg flex items-center gap-5'>
                        <label className='text-xs font-semibold'>Process</label>
                        <input className='text-xs h-6 rounded border border-gray-500 bg-white' value={findFromList(processId, processData, "name")} disabled={true} />
                    </div>
                    <div className='p-2 rounded-lg flex items-center gap-5'>
                        <label className='text-xs font-semibold'>Supplier</label>
                        <input className='text-xs h-6 rounded border border-gray-500 bg-white' value={findFromList(supplierId, supplierList.data, "aliasName")} disabled={true} />
                    </div>
                </div>
                <div>
                    {
                        <>
                            {(transtype === "GY" || transtype === "DY") ?
                                <YarnPoItemSelection getSelectAll={getSelectAll} handleSelectAllChange={handleSelectAllChange} processId={processId} poType={transtype} handleChange={handleChange} supplierId={supplierId} isItemAdded={isItemAdded} />
                                :
                                <FabricPoItemSelection getSelectAll={getSelectAll} handleSelectAllChange={handleSelectAllChange} processId={processId} isItemAdded={isItemAdded} handleChange={handleChange} supplierId={supplierId} />
                            }
                        </>
                    }
                </div>
            </div>
            <div className='flex justify-end gap-4 mt-3'>
                <button onClick={handleDone} className='bg-lime-400 hover:bg-lime-600 hover:text-white p-1 px-3 text-sm rounded font-semibold transition'>
                    Done
                </button>
                <button onClick={handleCancel} className='bg-red-400 hover:bg-red-600 hover:text-white p-1 text-sm rounded font-semibold transition'>
                    Cancel
                </button>
            </div>
        </>
    )
}

export default ProcessDeliveryProgramItemsSelection