import React from 'react'
import { getCommonParams } from '../../Utils/helper'
import { DropdownInput } from '../../Inputs'
import { dropDownListObject } from '../../Utils/contructObject'
import { useGetTaxTemplateQuery } from '../../redux/ErpServices/TaxTemplateServices'

const TaxTemplateDropdown = ({ taxTemplateId, setTaxTemplateId, id, readOnly }) => {
    const { companyId } = getCommonParams()
    const { data: taxTemplateData } = useGetTaxTemplateQuery({ params: { companyId } })
    const taxTemplateList = taxTemplateData?.data ? taxTemplateData?.data : [];
    return (
        <DropdownInput name="Tax Template" options={dropDownListObject(id ? taxTemplateList : taxTemplateList.filter(item => item.active), "name", "id")} value={taxTemplateId} setValue={setTaxTemplateId} required={true} readOnly={readOnly} />
    )
}

export default TaxTemplateDropdown
