import React from 'react'

const StyleDetails = ({ styleWiseDetails, currentSelectedStyle, setStyleWiseDetails, readonly }) => {
    const styleDetails = styleWiseDetails.find(item => parseInt(item.styleId) === parseInt(currentSelectedStyle))
    let sizeTemplate = styleDetails?.SizeDetails ? styleDetails?.SizeDetails : [];
    function handleInputChange(index, field, value) {
        setStyleWiseDetails(prev => {
            let newPrev = structuredClone(prev);
            let styleIndex = prev.findIndex(i => parseInt(i.styleId) === parseInt(currentSelectedStyle))
            newPrev[styleIndex]['SizeDetails'][index][field] = value;
            return newPrev;
        })
    }
    return (
        <div className='border border-gray-600 w-[300px]'>
            <div className='flex justify-center border-b border-gray-600'>
                <span className='font-semibold'>Style:</span>
                <span>{styleDetails?.Style?.sku}</span>
            </div>
            <table className='table-fixed w-full'>
                <thead>
                    <tr className='table-row'>
                        <th className='table-data'>
                            Size
                        </th>
                        <th className='table-data'>
                            Price
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sizeTemplate.map((size, sizeIndex) =>
                        <tr key={size} className='table-row'>
                            <td className='table-data'>
                                {size?.Size?.name}
                            </td>
                            <td className='table-data'>
                                <input type="number" value={size?.price ? size?.price : 0} onChange={(e) => handleInputChange(sizeIndex, 'price', e.target.value)} disabled={readonly} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default StyleDetails
