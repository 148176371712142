import React from 'react'
import { isBetweenRange, substract } from '../../../Utils/helper'
import { toast } from 'react-toastify';
import { DELETE } from '../../../icons';

const CuttingReceiptInwardDetailItem = ({ item, index, id, cuttingReceiptInwardDetails, setCuttingReceiptInwardDetails, cuttingReceiptInwardDetailsFillData, readOnly }) => {
    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(cuttingReceiptInwardDetails);
        newBlend[index][field] = value;
        setCuttingReceiptInwardDetails(newBlend);
    };
    const cuttingDeliveryItem = cuttingReceiptInwardDetailsFillData.find(i => parseInt(i.id) === parseInt(item.cuttingOrderDetailsId))
    const orderQty = cuttingDeliveryItem?.orderQty
    const alreadyReceivedQty = cuttingDeliveryItem?.alreadyReceivedQty ? cuttingDeliveryItem?.alreadyReceivedQty : 0;
    const balQty = substract(orderQty, alreadyReceivedQty);
    const deleteItem = () => {
        setCuttingReceiptInwardDetails(prev => {
            return prev.filter(i => parseInt(i.cuttingOrderDetailsId) !== parseInt(item.cuttingOrderDetailsId))
        })
    }
    return (
        <tr key={index} className='py-2 table-row'>
            <td className='table-data   '>
                {index + 1}
            </td>
            <td className='table-data'>
                {cuttingDeliveryItem?.Portion?.name}
            </td>
            <td className='table-data'>
                {cuttingDeliveryItem?.Color?.name}
            </td>
            <td className=' table-data text-left px-1'>
                {cuttingDeliveryItem?.Size?.name}
            </td>
            <td className='  table-data text-left'>
                {cuttingDeliveryItem?.UOM?.name}
            </td>
            <td className='  table-data text-right px-1'>
                {orderQty}
            </td>
            <td className='  table-data text-right px-1'>
                {alreadyReceivedQty}
            </td>
            <td className='  table-data text-right px-1'>
                {balQty}
            </td>
            <td className='  table-data text-right px-1'>
                {cuttingDeliveryItem?.cuttingPrice}
            </td>
            <td className='table-data'>
                <input
                    type="number"
                    onFocus={(e) => e.target.select()}
                    className="text-right rounded py-1 px-1 w-full table-data-input"

                    value={(!item.receivedQty) ? 0 : item.receivedQty}

                    onChange={(e) => {
                        // if (e.target.value > balQty) {
                        //     toast.info("Inward Qty Cannot be more than balance Qty", { position: 'top-center' })
                        //     return
                        // }
                        handleInputChange(e.target.value, index, "receivedQty")
                    }}
                    onBlur={(e) =>
                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "receivedQty")
                    }
                />
            </td>
            {!readOnly
                &&
                <td className='table-data '>
                    <button className='w-full' onClick={deleteItem}>
                        {DELETE}
                    </button>
                </td>
            }
        </tr>
    )
}

export default CuttingReceiptInwardDetailItem