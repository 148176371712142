import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
  useGetRawMaterialSalesQuery,
  useGetRawMaterialSalesByIdQuery,
  useAddRawMaterialSalesMutation,
  useUpdateRawMaterialSalesMutation,
  useDeleteRawMaterialSalesMutation,
} from "../../../redux/ErpServices/RawMaterialSalesServices";
import {
  useGetProcessQuery,
} from "../../../redux/ErpServices/processMasterServices";
import { useGetPartyByIdQuery, useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { LongDropdownInput, DropdownInput, DateInput, DisabledInput, TextInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { findFromList, getCommonParams, getDateFromDateTime, getItemFullNameFromShortCode, isGridDatasValid } from "../../../Utils/helper";
import moment from "moment";
import { Loader } from "../../../Basic/components";
import RawMaterial from "../RawMaterialSalesRawMaterial";
import Consolidation from "../Consolidation";

import {
  useGetLocationMasterQuery,
} from "../../../redux/ErpServices/LocationMasterServices";
import {
  useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import Modal from "../../../UiComponents/Modal";
import RawMaterialSalesReport from "./RawMaterialSalesReport";
import { invoiceTypes, paymentModes, poTypes } from "../../../Utils/DropdownData";
import Summary from "./Summary";
import { useGetTaxTemplateQuery } from "../../../redux/ErpServices/TaxTemplateServices";
import useTaxDetailsHook from "../../../CustomHooks/TaxHookDetails";
import PrintFormatRawMaterials from "./PrintFormat";

const MODEL = "Raw Material Sales";


export default function Form() {



  const dispatch = useDispatch()

  const [print, setPrint] = useState(false);
  const today = new Date()
  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [processId, setProcessId] = useState("");
  const [locationId, setLocationId] = useState('');
  const [docId, setDocId] = useState("");
  const [taxSummary, setTaxSummary] = useState(false);
  const [taxTemplateId, setTaxTemplateId] = useState("");
  const [eWayBillNo, setEWayBillNo] = useState("");
  const [poNo, setPoNo] = useState("")

  const handlePrint = () => setPrint(true);

  const [poType, setPoType] = useState("GreyYarn")

  const [storeId, setStoreId] = useState("")

  const [supplierId, setSupplierId] = useState("");

  const [autoDeliveryDate, setAutoDeliveryDate] = useState(getDateFromDateTime(today));
  const [delDate, setDelDate] = useState("")

  const [remarks, setRemarks] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [vehicleNo, setVehicleNo] = useState("");

  const [rawMaterials, setRawMaterials] = useState([])

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);
  const [discountType, setDiscountType] = useState("Percentage");
  const [discountValue, setDiscountValue] = useState(0);

  const [invoiceType, setInvoiceType] = useState("Credit")

  const { branchId, companyId, finYearId, userId } = getCommonParams()


  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };
  const { data: processList, isLoading: isProcessLoading, isFetching: isProcessFetching } = useGetProcessQuery({ params: { ...params, isPcsStage: false } });
  const { data: locationData, isLoading: isLocationLoading, isFetching: isLocationFetching } = useGetLocationMasterQuery({ params: { branchId }, searchParams: searchValue });
  const { data: branchList } = useGetBranchQuery({ params: { companyId } });

  const { data: supplierList } =
    useGetPartyQuery({ params });
  const { data: allData, isLoading, isFetching } = useGetRawMaterialSalesQuery({
    params: {
      branchId, processId, finYearId
    }, searchParams: searchValue
  });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetRawMaterialSalesByIdQuery({ id }, { skip: !id });

  const getNextDocId = useCallback(() => {
    if (id) return
    if (isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])


  const [addData] = useAddRawMaterialSalesMutation();
  const [updateData] = useUpdateRawMaterialSalesMutation();
  const [removeData] = useDeleteRawMaterialSalesMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setAutoDeliveryDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    setDelDate(data?.delDate ? moment.utc(data?.delDate).format("YYYY-MM-DD") : "");
    setPoType(data?.poType ? data?.poType : "GreyYarn");
    setProcessId(data?.processId ? data?.processId : "");
    setRawMaterials(data?.RawMaterialsSalesDetails ? data?.RawMaterialsSalesDetails : [])
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setLocationId(data?.storeId ? findFromList(data?.storeId, (locationData?.data ? locationData.data : []), "locationId") : "")
    setStoreId(data?.storeId ? data?.storeId : "");
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");
    setRemarks(data?.remarks ? data?.remarks : "");
    setTaxTemplateId(data?.taxTemplateId ? data?.taxTemplateId : "");
    setSpecialInstructions(data?.specialInstructions ? data?.specialInstructions : "")
    setInvoiceType(data?.invoiceType ? data?.invoiceType : "Credit")
    setDiscountType(data?.discountType ? data?.discountType : "Percentage");
    setDiscountValue(data?.discountValue ? data?.discountValue : "0");
    setEWayBillNo(data?.eWayBillNo || "");
    setPoNo(data?.poNo || "");
    if (data?.docId) {
      setDocId(data?.docId)
    }
  }, [id, locationData]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);
  const { ...taxDetails } = useTaxDetailsHook({
    poItems: rawMaterials, taxTypeId: taxTemplateId,
    discountType: discountType, discountValue: discountValue
  })

  const data = {
    supplierId,
    branchId,
    storeId,
    processId,
    vehicleNo,
    specialInstructions,
    remarks,
    poType,
    id, userId,
    taxTemplateId,
    invoiceType,
    discountType,
    discountValue,
    rawMaterialDetails: rawMaterials,
    netBillValue: taxDetails?.netAmount ? taxDetails?.netAmount : 0,
    finYearId,
    eWayBillNo,
    poNo
  }
  const validateData = (data) => {
    console.log(data, "Data")
    let rawMaterialMandatoryDetails = ["qty", "taxPercent", "price"]
    if (poType === "GreyYarn" || poType === "DyedYarn") {
      rawMaterialMandatoryDetails = [...rawMaterialMandatoryDetails, "noOfBags", "weightPerBag"];
    } else if (poType === "GreyFabric" || poType === "DyedFabric") {
      rawMaterialMandatoryDetails = [...rawMaterialMandatoryDetails, "noOfRolls"];
    }
    return data.poType && data.supplierId && data.storeId && data.vehicleNo && data.taxTemplateId
      && data.rawMaterialDetails.length !== 0
      && isGridDatasValid(data.rawMaterialDetails, false, rawMaterialMandatoryDetails)
  }
  const { data: taxTypeList } =
    useGetTaxTemplateQuery({ params: { ...params } });

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 0) {
        toast.success(text + "Successfully");
        setId("")
        syncFormWithDb(undefined)
      } else {
        toast.error(returnData.message);
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });
    } catch (error) {
      console.log("handle", error);
    }
  };


  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const getIssuedQty = (stockItem) => {
    return 0
  }
  const getTotalIssuedQty = () => {
    const totalQty = rawMaterials?.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.qty ? currentValue?.qty : 0))
    }, 0)
    return totalQty
  }
  const { data: supplierDetails } =
    useGetPartyByIdQuery(supplierId, { skip: !supplierId });

  function isSupplierOutside() {
    if (supplierDetails) {
      return supplierDetails?.data?.City?.state?.name !== "TAMIL NADU"
    }
    return false
  }


  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLocationLoading || isLocationFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  const allSuppliers = supplierList ? supplierList.data : []


  if (isProcessLoading || isProcessFetching || isLocationFetching || isLocationLoading) return <Loader />

  const storeOptions = locationData ?
    locationData.data.filter(item => parseInt(item.locationId) === parseInt(locationId)) :
    [];

  const processOptions = id ? processList.data : processList.data.filter(item => item.active).filter(i =>
    getItemFullNameFromShortCode(i.io.split("_")[1]) === poType
  )

  return (
    <>
      <Modal isOpen={taxSummary} onClose={() => setTaxSummary(false)} widthClass={"p-10"}>
        <Summary
          discountType={discountType}
          setDiscountType={setDiscountType}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          poItems={rawMaterials} taxTypeId={taxTemplateId} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
      </Modal>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <RawMaterialSalesReport
          heading={MODEL}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          allData={allData}
          tableWidth="100%"
          setForm={setForm}
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <Modal isOpen={print} onClose={() => { setPrint(false) }} widthClass={"bg-gray-300 w-[90%] h-[90%]"} >
        <PrintFormatRawMaterials id={id} poType={poType} isSupplierOutside={isSupplierOutside()} />
      </Modal>
      {/* <div className="hidden">
        <PrintFormatRawMaterialSales isSupplierOutside={isSupplierOutside()} poType={poType} remarks={remarks} rawMaterials={rawMaterials.filter(item => item.yarnId || item.fabricId)} innerRef={componentRef} id={id ? id : ""} discountType={discountType} discountValue={discountValue} taxTemplateId={taxTemplateId} />
      </div> */}
      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
      >
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}
            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            onPrint={id ? handlePrint : null}
            openReport={() => setFormReport(true)}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"} h-full relative overflow-auto`}>
                    <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>
                      <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                        <legend className='sub-heading'>Delivery Info</legend>
                        <div className='flex flex-col justify-center items-start flex-1 w-full'>
                          <div className="grid grid-cols-4">
                            <DisabledInput name={"Dc. No."} value={docId} />
                            <DateInput name="Delivery Date" value={autoDeliveryDate} type={"date"} required={true} disabled />
                            <DropdownInput
                              className={"w-[110px]"}
                              name="Po Type"
                              options={poTypes}
                              value={poType}
                              setValue={setPoType}
                              required={true}
                              readOnly={id || readOnly}
                            />
                            <DropdownInput
                              className={"w-[110px]"}
                              name="From Process"
                              options={dropDownListObject(processOptions, "name", "id")}
                              value={processId}
                              setValue={setProcessId}
                              required={true}
                              readOnly={id || readOnly}
                            />
                            <div className="col-span-2">
                              <LongDropdownInput name="Supplier" options={dropDownListObject(allSuppliers, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                            </div>
                            <DropdownInput name="Tax Type" options={dropDownListObject(taxTypeList ? taxTypeList.data : [], "name", "id")} value={taxTemplateId} setValue={setTaxTemplateId} required={true} readOnly={readOnly} />
                            {/* <TextInput name={"User Del. Date"} value={delDate} setValue={setDelDate} type={"date"} readOnly={readOnly} />
                            <TextInput name={"Due. Date"} value={dueDate} setValue={setDueDate} type={"date"} readOnly={readOnly} /> */}
                            <DropdownInput name="Location"
                              options={branchList ? (dropDownListObject(id ? (branchList?.data ? branchList?.data : []) : (branchList?.data ? branchList?.data : []).filter(item => item.active), "branchName", "id")) : []}
                              value={locationId}
                              setValue={(value) => { setLocationId(value); setStoreId("") }}
                              required={true} readOnly={id || readOnly} />
                            <DropdownInput name="Store"
                              options={dropDownListObject(id ? storeOptions : storeOptions.filter(item => item.active), "storeName", "id")}
                              value={storeId} setValue={setStoreId} required={true} readOnly={id || readOnly} />
                            <DropdownInput name="Invoice Type" options={invoiceTypes}
                              value={invoiceType} setValue={setInvoiceType} required={true} readOnly={readOnly} />
                            <TextInput name={"E-Way Bill No"} value={eWayBillNo} setValue={setEWayBillNo} readOnly={readOnly} />
                            <TextInput name={"Purchase Order No"} value={poNo} setValue={setPoNo} readOnly={readOnly} />
                          </div>
                        </div>
                      </fieldset>
                      <RawMaterial readOnly={readOnly}
                        storeId={storeId} getIssuedQty={getIssuedQty}
                        rawMaterials={rawMaterials} setRawMaterials={setRawMaterials}
                        rawMaterialType={poType} id={id}
                      />
                      <div className="flex">
                        <button className="text-sm bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition p-1 ml-5 rounded"
                          onClick={() => {
                            if (!taxTemplateId) {
                              toast.info("Please Select Tax Template !", { position: "top-center" })
                              return
                            }
                            setTaxSummary(true)
                          }}>
                          View Summary
                        </button>
                        <Consolidation
                          isRawMaterialSales
                          netAmount={taxDetails?.netAmount ? taxDetails?.netAmount : 0}
                          totalQty={getTotalIssuedQty()} readOnly={readOnly} vehicleNo={vehicleNo} setVehicleNo={setVehicleNo} remarks={remarks} setRemarks={setRemarks}
                          specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions} />
                      </div>
                    </div>
                    <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                      <FormReport
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setId={setId}
                        tableHeaders={tableHeaders}
                        tableDataNames={tableDataNames}
                        data={allData?.data ? allData?.data : []}
                        loading={
                          isLocationLoading || isLocationFetching
                        }
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}