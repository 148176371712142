import React, { useState } from "react";
import { AppHeader, AppFooter } from "../../components";
import Modal from "../../../UiComponents/Modal";
import { BranchAndFinyearForm, LogoutConfirm } from "../../components";
import ActiveTabList from "../../components/ActiveTabList";
import secureLocalStorage from "react-secure-storage";
import SuperAdminHeader from "../../components/SuperAdminHeader";
import { useGetAccessoryGroupMasterQuery } from "../../../redux/ErpServices/AccessoryGroupMasterServices";
import { getCommonParams } from "../../../Utils/helper";
import { useGetFabricMasterQuery } from "../../../redux/ErpServices/FabricMasterServices";
import { useGetAccessoryMasterQuery } from "../../../redux/ErpServices/AccessoryMasterServices";
import { useGetYarnMasterQuery } from "../../../redux/ErpServices/YarnMasterServices";
import { useGetColorMasterQuery } from "../../../redux/ErpServices/ColorMasterServices";
import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";
import { useGetdesignQuery } from "../../../redux/ErpServices/DesignMasterServices";
import { useGetGaugeQuery } from "../../../redux/ErpServices/GaugeMasterServices";
import { useGetLoopLengthQuery } from "../../../redux/ErpServices/LoopLengthMasterServices";
import { useGetgsmQuery } from "../../../redux/ErpServices/GsmMasterServices";
import { useGetDiaQuery } from "../../../redux/ErpServices/DiaMasterServices";
import { useGetSizeMasterQuery } from "../../../redux/ErpServices/SizeMasterServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import { useGetPaytermMasterQuery } from "../../../redux/ErpServices/PayTermMasterServices";
import { useGetTermsAndConditionsQuery } from "../../../redux/services/TermsAndConditionsService";
import { useGetBranchQuery } from "../../../redux/services/BranchMasterService";

const Home = () => {
  const [isGlobalOpen, setIsGlobalOpen] = useState(false);
  const [logout, setLogout] = useState(false);
  const isSuperAdmin = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "superAdmin"
  );
  const { companyId } = getCommonParams()
  // useGetYarnMasterQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetColorMasterQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetUnitOfMeasurementMasterQuery({ params: { companyId } }, { skip: isSuperAdmin })
  // useGetFabricMasterQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetdesignQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetGaugeQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetLoopLengthQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetgsmQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetDiaQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetAccessoryMasterQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetAccessoryGroupMasterQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetSizeMasterQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetPartyQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetPaytermMasterQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetTermsAndConditionsQuery({ params: { companyId } }, { skip: isSuperAdmin });
  // useGetBranchQuery({ params: { companyId } }, { skip: isSuperAdmin });
  return (
    <div className="h-full flex flex-col justify-between">
      <div className="h-[88%]">  <Modal
        isOpen={isGlobalOpen}
        onClose={() => {
          setIsGlobalOpen(false);
        }}
        widthClass={""}
      >
        <BranchAndFinyearForm setIsGlobalOpen={setIsGlobalOpen} />
      </Modal>
        <Modal
          isOpen={logout}
          onClose={() => {
            setLogout(false);
          }}
          widthClass={""}
        >
          <LogoutConfirm setLogout={setLogout} />
        </Modal>
        <div className="flex flex-col h-full">
          <div>
            {isSuperAdmin ? (
              <SuperAdminHeader
                setIsGlobalOpen={setIsGlobalOpen}
                setLogout={setLogout}
              />
            ) : (
              <AppHeader setIsGlobalOpen={setIsGlobalOpen} setLogout={setLogout} />
            )}
          </div>
          <div className="flex-1">
            <ActiveTabList />
          </div>
        </div></div>
      <div className="h-[12%] flex items-end justify-end"><AppFooter /></div>
    </div>
  );
};
export default Home;
