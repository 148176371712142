import React from 'react'
import { sumArray } from '../../../Utils/helper';
import { toast } from 'react-toastify';

export function YarnLotGridWithStock({ isDirect, lotDetails, handleInputChangeLotNo, index, readOnly, onClose, allowedReturnQty }) {
    return (
        <table className='table-fixed w-[1000px]'>
            <thead className='border text-sm'>
                <th className='table-data text-center'>Lot No</th>
                <th className='table-data text-center'>Inward Bags</th>
                <th className='table-data text-center'>Inward Qty</th>
                <th className='table-data text-center'>A. Returned Bags</th>
                <th className='table-data text-center'>A. Returned Qty</th>
                <th className='table-data text-center'>Stock Bags</th>
                <th className='table-data text-center'>Stock Qty</th>
                <th className='table-data text-center'>Allowed Rtn. Bags</th>
                <th className='table-data text-center'>Allowed Rtn. Qty</th>
                <th className='table-data  text-center'>No. Of Bags</th>
                <th className='table-data  text-center'>Wg/Per</th>
                <th className='table-data text-center'>Qty</th>
            </thead>
            <tbody>
                {lotDetails.map((item, lotIndex) =>
                    <tr className='table-row' key={lotIndex}>
                        <td className='text-center table-data'>
                            {item.lotNo}
                        </td>
                        <td className='text-center table-data'>
                            {item.inwardBags}
                        </td>
                        <td className='text-center table-data'>
                            {parseFloat(item.inwardQty).toFixed(3)}
                        </td>
                        <td className='text-center table-data'>
                            {item.returnBags}
                        </td>
                        <td className='text-center table-data'>
                            {parseFloat(item.returnQty).toFixed(3)}
                        </td>
                        <td className='text-center table-data'>
                            {item.stockBags}
                        </td>
                        <td className='text-center table-data'>
                            {parseFloat(item.stockQty).toFixed(3)}
                        </td>
                        <td className='text-center table-data'>
                            {allowedReturnQty(item.inwardBags, item.returnBags, item.stockBags)}
                        </td>
                        <td className='text-center table-data'>
                            {allowedReturnQty(item.inwardQty, item.returnQty, item.stockQty)}
                        </td>
                        <td className='text-center table-data'>
                            <input
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo(0, index, lotIndex, "noOfBags"); }
                                }}
                                min={"0"}
                                type="number"
                                className="text-right rounded py-1   w-full table-data-input"
                                value={item.noOfBags}
                                disabled={readOnly}
                                onChange={(event) => {
                                    if (!event.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "noOfBags");
                                        return
                                    }
                                    let value = event.target.value;
                                    let allowedReturnBags = allowedReturnQty(item.inwardBags, item.returnBags, item.stockBags)
                                    if (parseFloat(value) > parseFloat(allowedReturnBags)) {
                                        toast.info("Inward Bags Can not be more than Allowed Return Bags", { position: 'top-center' })
                                        return
                                    }
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "noOfBags");
                                }}
                            />
                        </td>
                        <td className='text-center table-data'>
                            {item.weightPerBag}
                        </td>
                        <td className='text-center table-data'>
                            <input
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo(0, index, lotIndex, "qty"); }
                                }}
                                min={"0"}
                                type="number"
                                className="text-right rounded py-1  w-full table-data-input"
                                value={item.qty}
                                disabled={readOnly}
                                onChange={(event) => {
                                    if (!event.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "qty");
                                        return
                                    }
                                    let value = event.target.value;
                                    let allowedReturn = allowedReturnQty(item.inwardQty, item.returnQty, item.stockQty)
                                    if (parseFloat(value) > parseFloat(allowedReturn)) {
                                        toast.info("Inward Qty Can not be more than Allowed Return Qty", { position: 'top-center' })
                                        return
                                    }
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "qty");
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "qty")
                                        return
                                    }
                                    handleInputChangeLotNo(e.target.value, index, lotIndex, "qty")
                                }}
                            />
                        </td>
                    </tr>
                )}
                <tr className='table-row'>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td className='text-right'>{sumArray(lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0), "noOfBags")}</td>
                    <td></td>
                    <td className='text-right'>{(sumArray(lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0), "qty")).toFixed(3)}</td>
                </tr>
            </tbody>
        </table>
    )
}


export function FabricLotGridWithStock({ isDirect, lotDetails, handleInputChangeLotNo, index, readOnly, onClose, allowedReturnQty }) {
    return (
        <table className='table-fixed w-[1000px]'>
            <thead className='border text-sm'>
                <th className='table-data text-center'>Lot No</th>
                <th className='table-data text-center'>Inward Rolls</th>
                <th className='table-data text-center'>Inward Qty</th>
                <th className='table-data text-center'>A. Returned Rolls</th>
                <th className='table-data text-center'>A. Returned Qty</th>
                <th className='table-data text-center'>Stock Rolls</th>
                <th className='table-data text-center'>Stock Qty</th>
                <th className='table-data text-center'>Allowed Rtn. Rolls</th>
                <th className='table-data text-center'>Allowed Rtn. Qty</th>
                <th className='table-data  text-center'>No. Of Rolls</th>
                <th className='table-data text-center'>Qty</th>
            </thead>
            <tbody>
                {lotDetails.map((item, lotIndex) =>
                    <tr className='table-row' key={lotIndex}>
                        <td className='text-center table-data'>
                            {item.lotNo}
                        </td>
                        <td className='text-center table-data'>
                            {item.inwardRolls}
                        </td>
                        <td className='text-center table-data'>
                            {parseFloat(item.inwardQty).toFixed(3)}
                        </td>
                        <td className='text-center table-data'>
                            {item.returnRolls}
                        </td>
                        <td className='text-center table-data'>
                            {parseFloat(item.returnQty).toFixed(3)}
                        </td>
                        <td className='text-center table-data'>
                            {item.stockRolls}
                        </td>
                        <td className='text-center table-data'>
                            {parseFloat(item.stockQty).toFixed(3)}
                        </td>
                        <td className='text-center table-data'>
                            {allowedReturnQty(item.inwardRolls, item.returnRolls, item.stockRolls)}
                        </td>
                        <td className='text-center table-data'>
                            {parseFloat(allowedReturnQty(item.inwardQty, item.returnQty, item.stockQty)).toFixed(3)}
                        </td>
                        <td className='text-center table-data'>
                            <input
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo(0, index, lotIndex, "noOfRolls"); }
                                }}
                                min={"0"}
                                type="number"
                                className="text-right rounded py-1   w-full table-data-input"
                                value={item.noOfRolls}
                                disabled={readOnly}
                                onChange={(event) => {
                                    if (!event.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "noOfRolls");
                                        return
                                    }
                                    let value = event.target.value;
                                    let allowedReturnRolls = allowedReturnQty(item.inwardRolls, item.returnRolls, item.stockRolls)
                                    if (parseFloat(value) > parseFloat(allowedReturnRolls)) {
                                        toast.info("Inward Bags Can not be more than Allowed Return Bags", { position: 'top-center' })
                                        return
                                    }
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "noOfRolls");
                                }}
                            />
                        </td>
                        <td className='text-center table-data'>
                            <input
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo(0, index, lotIndex, "qty"); }
                                }}
                                min={"0"}
                                type="number"
                                className="text-right rounded py-1  w-full table-data-input"
                                value={item.qty}
                                disabled={readOnly}
                                onChange={(event) => {
                                    if (!event.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "qty");
                                        return
                                    }
                                    let value = event.target.value;
                                    let allowedReturn = allowedReturnQty(item.inwardQty, item.returnQty, item.stockQty)
                                    if (parseFloat(value) > parseFloat(allowedReturn)) {
                                        toast.info("Inward Qty Can not be more than Allowed Return Qty", { position: 'top-center' })
                                        return
                                    }
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "qty");
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "qty")
                                        return
                                    }
                                    handleInputChangeLotNo(parseFloat(e.target.value).toFixed(3), index, lotIndex, "qty")
                                }}
                            />
                        </td>
                    </tr>
                )}
                <tr className='table-row'>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td className='text-right'>{sumArray(lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0), "noOfRolls")}</td>
                    <td className='text-right'>{(sumArray(lotDetails.filter(item => item.qty && parseFloat(item.qty) !== 0), "qty")).toFixed(3)}</td>
                </tr>
            </tbody>
        </table>
    )
}