import React from 'react'
import Address from './Address';

import RainDot from "../../../assets/RainDot.png"
import QRCode from "react-qr-code";
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import SalesOrder from './SalesOrder';
import { useGetInterOfficeStockTransferFinishedGoodsByIdQuery } from '../../../redux/ErpServices/InterOfficeTransferFinishedGoodsServices';

export default function Form({ remarks, finishedGoodsSalesDetails, innerRef, id }) {

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetInterOfficeStockTransferFinishedGoodsByIdQuery({ id }, { skip: !id });

  if (!singleData || isSingleFetching || isSingleLoading) return null

  return (
    <div className="h-[950px] w-full flex flex-col justify-between border-2 m-0 border-black" id='poPrint' ref={innerRef}>
      <div>
        <div className='flex'>
          <div className='flex items-center justify-center'>
            <img src={RainDot} alt='logo' className="h-9 w-9" />
          </div>
          <div className='text-center p-1 text-md flex items-center justify-center font-bold text-green-700 w-full'> Inter Office Delivery
          </div>
        </div>
        <div className=''>
          <table className="text-xs border border-gray-500 w-full table-auto ">
            <tbody>
              <tr className='text-xs'>
                <td >Doc.Id: {singleData?.data?.docId}</td>
                <td> Del.Date : {getDateFromDateTimeToDisplay(singleData?.data?.createdAt)}</td>

                <td className='table-data px-14 py-1 w-1/6'>
                  <QRCode value={singleData?.data?.docId ? singleData?.data?.docId : ""} size={80} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Address fromAddress={singleData?.data?.Branch ? singleData?.data?.Branch : {}}
          toAddress={singleData?.data?.DeliveryBranch ? singleData?.data?.DeliveryBranch : {}} />
        <SalesOrder finishedGoodsSalesDetails={finishedGoodsSalesDetails} singleData={singleData?.data} />
      </div>
      <div className=' w-full'>
        <div className='w-full '>
          <div className='w-full border border-gray-600 flex items-center'>
          </div>
          <div className='w-full border border-gray-600 flex items-center'><h1 className='font-bold text-sm'>Remarks : </h1>
            <span className='text-xs'>
              {remarks}
            </span>
          </div>
        </div>
        <div className='mt-3'>
          <div className='text-sm text-right px-2 font-bold italic'>
            For {singleData?.data?.Branch?.branchName}
          </div>
          <div className='grid grid-rows-1 grid-flow-col p-2 font-bold text-xs mt-8 justify-around'>
            <h1>Prepared By</h1>
            <h1>Verified By</h1>
            <h1>Received By</h1>
            <h1>Approved By</h1>
          </div>
        </div>
      </div>

    </div>
  )
}

