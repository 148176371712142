import React from 'react'

const Address = ({ branchDetails, supplierDetails }) => {
  return (
    <div className=''>
      <table className="text-xs  w-full p-2 table-auto border border-gray-500">
        <thead className='text-left p-1 ml-1'>
          <tr className=''>
            <th className=' font-bold w-1/2 table-data text-sm'>FROM : </th>
            <th className=' font-bold text-sm '>TO :</th>
          </tr>
        </thead>
        <tbody>
          <tr className=' text-xs p-3'>
            <td className=''>
              <h2 className='font-bold text-sm text-green-700'>{branchDetails?.branchName}</h2>
              <div>{branchDetails?.address}</div>
              <div>Mobile No :{branchDetails?.contactMobile}</div>
              {branchDetails?.panNo &&
                <div className=''>PAN NO :{branchDetails?.panNo}</div>
              }
              {branchDetails?.gstNo &&
                <div className=''>GST No :{branchDetails?.gstNo}</div>
              }
              {branchDetails?.email &&
                <div className=''>EMAIL :{branchDetails?.email}</div>
              }
            </td>
            <td className='p-1 table-data'>
              <h2 className='font-bold text-sm text-green-700'>{supplierDetails.name}</h2>
              <div> {supplierDetails.address} </div>
              <div className=''>Mobile NO :{supplierDetails.contactMobile}</div>
              <div className=''>PAN NO :{supplierDetails.panNo}</div>
              <div className=''>GST NO :{supplierDetails.gstNo}</div>
              <div className=''> EMAIL :{supplierDetails.email}</div>
            </td >
          </tr>
        </tbody>
      </table>
    </div>
  )
}


export default Address