import React from 'react'
import { useGetPriceTemplateMasterQuery } from '../../redux/ErpServices/PriceTemplateMasterServices'
import { getCommonParams } from '../../Utils/helper'
import { DropdownInput } from '../../Inputs'
import { dropDownListObject } from '../../Utils/contructObject'

const PriceTemplateDropdown = ({ priceTemplateId, setPriceTemplateId, id, readOnly }) => {
    const { companyId } = getCommonParams()
    const { data: priceTemplateData } = useGetPriceTemplateMasterQuery({ params: { companyId } })
    const priceTemplateList = priceTemplateData?.data ? priceTemplateData?.data : [];
    return (
        <DropdownInput name="Price Template" options={dropDownListObject(id ? priceTemplateList : priceTemplateList.filter(item => item.active), "name", "id")} value={priceTemplateId} setValue={setPriceTemplateId} required={true} readOnly={readOnly} />
    )
}

export default PriceTemplateDropdown
