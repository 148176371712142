import { toast } from 'react-toastify';
import AccessoryPoItem from './AccessoryPoItem';

const AccessoryInwardItems = ({ inwardItems, setInwardItems, readOnly, removeItem, purchaseInwardId }) => {
    const handleInputChange = (value, index, field, balanceQty) => {
        const newBlend = structuredClone(inwardItems);
        newBlend[index][field] = value;
        if (field === "qty") {
            if (parseFloat(balanceQty) < parseFloat(value)) {
                toast.info("Inward Qty Can not be more than balance Qty", { position: 'top-center' })
                return
            }
        }
        setInwardItems(newBlend);
    };
    return (
        <>
            <div className={`relative w-full overflow-auto py-1`}>
                <table className="border border-gray-500 text-xs table-auto w-full  ">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8 '>
                            <th className="table-data w-5  text-center">S.no</th>
                            <th className="table-data  w-16 text-center">Po.no</th>
                            <th className="table-data  ">Accessory Name</th>
                            <th className="table-data  ">Accessory Items</th>
                            <th className="table-data  ">Accessory Group</th>
                            <th className="table-data  ">Colors</th>
                            <th className="table-data  ">Size</th>
                            <th className="table-data   ">UOM</th>
                            <th className="table-data  w-14">Po. Qty</th>
                            <th className="table-data  w-14"> Can. Qty</th>
                            <th className="table-data  w-14"> A. In Qty</th>
                            <th className="table-data  w-14"> A. Return Qty</th>
                            <th className="table-data  w-14">Bal. Qty</th>
                            <th className="table-data  w-14">In. Qty</th>
                            <th className="table-data  w-14">Po Price</th>
                            <th className="table-data  w-14">Gross</th>
                            {!readOnly &&
                                <th className='table-data border  w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) => <AccessoryPoItem item={item} purchaseInwardId={purchaseInwardId} removeItem={removeItem} readOnly={readOnly} key={item.poItemsId} index={index} handleInputChange={handleInputChange} />)}
                        {Array.from({ length: 8 - inwardItems.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                {Array.from({ length: 16 }).map(i =>
                                    <td className="table-data   "></td>
                                )}
                                {!readOnly &&
                                    <td className="table-data w-14"></td>
                                }
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default AccessoryInwardItems
