import React from 'react'
import { getDateFromDateTimeToDisplay, sumArray } from '../../../Utils/helper'
import { exportFileToCsv } from '../../../Utils/excelHelper'
import { ExcelButton } from '../../../Buttons'

const YarnitemInwardReport = ({ inwardItems = [], dataObj, poItem }) => {
  return (
    <div className='border bg-gray-200'>
      <table className='table-fixed text-xs border border-gray-500 '>
        <thead>
          <tr className='table-row'>
            <th className='w-32 table-data'>Po. No</th>
            <th>Yarn</th>
            <th className='w-20 table-data'>Color</th>
            <th className='w-20 table-data'>Uom</th>
          </tr>
        </thead>
        <tbody>
          <tr className='table-row'>
            <td className='table-data'>
              {dataObj.docId}
            </td>
            <td className='table-data'>
              {poItem.Yarn.aliasName}
            </td>
            <td className='table-data'>
              {poItem.Color.name}
            </td>
            <td className='table-data'>
              {poItem.Uom.name}
            </td>
            <td className='py-1 text-center w-8'>
              <ExcelButton onClick={() => {
                let excelData = inwardItems.map(item => {
                  let newItem = {};
                  newItem["Inward. No."] = item.PurchaseInwardOrReturn.docId;
                  newItem["Inward. Date"] = getDateFromDateTimeToDisplay(item.PurchaseInwardOrReturn.createdAt);
                  newItem["Dc. No"] = item.PurchaseInwardOrReturn.dcNo;
                  newItem["Dc. Date"] = getDateFromDateTimeToDisplay(item.PurchaseInwardOrReturn.dcDate);
                  newItem["Lot No"] = item.lotNo;
                  newItem["Bags"] = item.noOfBags;
                  newItem["Qty"] = item.qty;
                  return newItem
                })
                exportFileToCsv([
                  { "Po No.": dataObj.docId, "Yarn": poItem.Yarn.aliasName, "Color": poItem.Color.name, "Uom": poItem.Uom.name }
                  , ...excelData], `${dataObj.docId}-Inward`)
              }} width={18} />
            </td>
          </tr>
        </tbody>
      </table>
      <table className='table-auto w-full text-xs border border-gray-500 '>
        <thead className=' border-b border-gray-500'>
          <tr className='bg-blue-200 border border-gray-500 sticky top-10 '>
            <th className="table-data  w-2 text-center">S.no</th>
            <th className="table-data  w-2 text-center">Inward No.</th>
            <th className="table-data  w-20 text-center">Inward Date.</th>
            <th className="table-data  w-20 text-center">DC No.</th>
            <th className="table-data  w-20 text-center">Dc Date.</th>
            <th className="table-data  w-10">Lot No.</th>
            <th className="table-data  w-10">Inward Bags</th>
            <th className="table-data  w-10">Inward Qty</th>
          </tr>
        </thead>
        <tbody>
          {inwardItems.map((item, index) =>
            <tr key={item.id}>
              <td className='text-center   table-data'>{index + 1}</td>
              <td className='text-left  table-data'>{item.PurchaseInwardOrReturn.docId}</td>
              <td className='text-left  table-data'>{getDateFromDateTimeToDisplay(item.PurchaseInwardOrReturn.createdAt)}</td>
              <td className='text-left  table-data'>{item.PurchaseInwardOrReturn.dcNo}</td>
              <td className='text-left  table-data'>{getDateFromDateTimeToDisplay(item.PurchaseInwardOrReturn.dcDate)}</td>
              <td className='text-right  table-data'>{item.lotNo}</td>
              <td className='text-right  table-data'>{item.noOfBags}</td>
              <td className='text-right  table-data'>{item.qty}</td>
            </tr>
          )
          }
          <tr className='table-row'>
            <td colSpan={5} className='table-data'></td>
            <td>Totals</td>
            <td className='text-right  table-data'>{sumArray(inwardItems.filter(item => item.noOfBags), "noOfBags")}</td>
            <td className='text-right  table-data'>{sumArray(inwardItems, "qty")}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default YarnitemInwardReport
