import React from 'react'
import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { useGetLoopLengthQuery } from '../../../redux/ErpServices/LoopLengthMasterServices';
import { Loader } from '../../../Basic/components';
import { findFromList } from '../../../Utils/helper';
import secureLocalStorage from 'react-secure-storage';



const params = {
  companyId: secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userCompanyId"
  ),
};
const FabricRawMaterial = ({ fabricTotal, programDetails }) => {
  const { data: fabricList } =
    useGetFabricMasterQuery({ params });

  const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
    useGetColorMasterQuery({ params });

  const { data: uomList } =
    useGetUnitOfMeasurementMasterQuery({ params });

  const { data: gaugeList } =
    useGetGaugeQuery({ params });

  const { data: designList } =
    useGetdesignQuery({ params });

  const { data: gsmList } =
    useGetgsmQuery({ params });

  const { data: loopLengthList } =
    useGetLoopLengthQuery({ params });

  const { data: diaList } =
    useGetDiaQuery({ params });
  function getTotal() {
    return programDetails.reduce((t, p) =>
      t + (p.rawMaterials.reduce((a, c) => a + parseFloat(c.qty), 0)), 0)
  }
  if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList) return <Loader />
  return (
    <div>
      <div className='w-full mt-3  font-bold'>
        <h1>
          RAW MATERIAL
        </h1>
      </div>
      <table className="  border border-gray-500 table-auto w-full ">
        <thead className=' border border-gray-500 top-0'>
          <tr className='h-3'>
            <th className="table-data text-center w-16">S.no</th>
            <th className="table-data text-center">FABRIC</th>
            <th className="table-data ">DESIGN</th>
            <th className="table-data  ">GSM</th>
            <th className="table-data  ">GAUGE</th>
            <th className="table-data  "> LL</th>
            <th className="table-data  ">KDIA</th>
            <th className="table-data  w-16">FDIA</th>
            <th className="table-data  w-16">UOM</th>
            <th className="table-data  w-16">NO.OF.ROLLS</th>
            <th className="table-data  w-16">ISS.QTY</th>
          </tr>
        </thead>
        <tbody>
          {programDetails.map((value, valueIndex) =>
          (
            value.rawMaterials.map((item, itemIndex) =>
            (
              <tr className='border border-gray-500' key={itemIndex}>
                <td className='table-data text-center px-1 h-3 '>{itemIndex + 1} </td>
                <td className='table-data  px-1 h-3 '>{item.Fabric?.aliasName} </td>
                <td className='table-data  px-1 h-3 '>{item.Design?.name} </td>
                <td className='table-data text-right px-1 h-3 '>{item.Gsm?.name} </td>
                <td className='table-data text-right px-1 h-3'>{item.Gauge?.name} </td>
                <td className='table-data text-right px-1 h-3 '>{item.LoopLength?.name} </td>
                <td className='table-data text-right px-1 h-3 '>{item.KDia?.name}</td>
                <td className='table-data text-right px-1 h-3 '> {item.FDia?.name}</td>
                <td className='table-data text-right px-1 h-3 '>{item.Uom?.name} </td>
                <td className='table-data text-right px-1 h-3'>{item.noOfRolls} </td>
                <td className='table-data text-right px-1 h-3 '> {item.qty}</td>
              </tr>
            ))
          ))}
          <tr>
            <td className='table-data text-center  px-1 h-3' colSpan={10}>TOTAL</td>
            <td className=' px-1 h-3 text-right'>{getTotal().toFixed(3)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default FabricRawMaterial