import React from 'react';
import secureLocalStorage from 'react-secure-storage';
import { DELETE, PLUS } from '../../../icons';
import { toast } from 'react-toastify';
import { useGetPcsStockQuery } from '../../../redux/ErpServices/StockServices';

const ProductionDeliveryDetails = ({ id, readOnly, productionDeliveryDetails, setProductionDeliveryDetails, styleId, fromProcessId,
  setStockDetailsFillGrid, storeId }) => {
  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };
  const handleInputChange = (value, index, field) => {
    const newBlend = structuredClone(productionDeliveryDetails);
    newBlend[index][field] = value;
    setProductionDeliveryDetails(newBlend);
  };
  const { data } = useGetPcsStockQuery({ params: { storeId, prevProcessId: fromProcessId, styleId, productionDeliveryId: id } })

  let stockData = data?.data ? data.data : []

  function getStockItem(checkItem, property) {
    let item = stockData.find(item =>
      checkItem.styleId === item.styleId
      &&
      checkItem.portionId === item.portionId
      &&
      checkItem.colorId === item.colorId
      &&
      checkItem.uomId === item.uomId
      &&
      checkItem.prevProcessId === item.prevProcessId
      &&
      checkItem.sizeId === item.sizeId
    )
    if (!item) return ""
    return item[property]
    
  }

  const handleDeleteRow = id => {
    setProductionDeliveryDetails(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
  };
  return (
    <fieldset disabled={readOnly} className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border
                            border-gray-600 overflow-auto min-h-[100px]'>
      <legend className='sub-heading'>Production Delivery Details</legend>
      <div className={`relative w-full overflow-y-auto p-1`}>
        <table className="table-data border border-gray-500 text-xs table-auto w-full">
          <thead className='bg-blue-200 border border-gray-500 top-0'>
            <tr className='border border-gray-500'>
              <th className="table-data w-2 text-center">S.no</th>
              <th className="table-data w-48">Portion</th>
              <th className="table-data w-48">Colors</th>
              <th className="table-data w-32">Size</th>
              <th className="table-data w-20">Uom</th>
              <th className="table-data w-20">Stock Qty</th>
              <th className="table-data w-20">Del. Qty</th>
              <th className="table-data w-20">Process Cost</th>
              <th className="table-data w-20">Amount</th>
              {readOnly ?
                "" :
                <th className='w-20  bg-green-600 text-white'>
                  <div onClick={() => {
                    if (!storeId) {
                      return toast.info("Please Select Store ...!")
                    }
                    setStockDetailsFillGrid(true)
                  }}
                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                    {PLUS}
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody className='overflow-y-auto table-data h-full w-full'>
            {productionDeliveryDetails.map((row, index) => (
              <tr key={index} className="w-full table-row">
                <td className='table-data'>{index + 1}</td>
                <td className='table-data '>
                  {getStockItem(row, "portionName")}
                </td>
                <td className='table-data '>
                  {getStockItem(row, "colorName")}
                </td>
                <td className='table-data'>
                  {getStockItem(row, "sizeName")}
                </td>
                <td className='table-data'>
                  {getStockItem(row, "uomName")}
                </td>
                <td className='table-data text-right'>
                  {getStockItem(row, "qty")}
                </td>
                <td className='table-data'>
                  <input type="number"
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "delQty") } }}
                    value={(!row.delQty) ? 0 : row.delQty}
                    min={0}
                    onChange={(e) => {
                      if (parseFloat(e.target.value) > parseFloat(getStockItem(row, "qty"))) {
                        toast.info("Delivery Qty Cannot be more than Stock Qty", { position: "top-center" })
                        return
                      }
                      handleInputChange(e.target.value, index, "delQty")
                    }}
                    className="text-right rounded py-1 w-full px-1 table-data-input"
                    inputMode='decimal'
                    onBlur={(e) =>
                      handleInputChange(parseFloat(e.target.value).toFixed(2), index, "delQty")
                    } />
                </td>
                <td className='table-data'>
                  <input type="number"
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "processCost") } }}
                    value={(!row.processCost) ? 0 : row.processCost}
                    onChange={(e) => { handleInputChange(e.target.value, index, "processCost") }}
                    className="text-right rounded py-1 w-full px-1 table-data-input"
                    inputMode='decimal'
                    onBlur={(e) =>
                      handleInputChange(parseFloat(e.target.value).toFixed(2), index, "processCost")
                    } />
                </td>
                <td className='table-data'>
                  <input
                    type="number"
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "gross") } }}
                    onFocus={(e) => e.target.select()}
                    className="text-right w-full rounded py-1  px-1 table-data-input"
                    value={(!row.delQty || !row.processCost) ? 0 : (parseFloat(row.delQty) * parseFloat(row.processCost)).toFixed(2)}
                    disabled={true}
                    onBlur={(e) =>
                      handleInputChange(parseFloat(e.target.value).toFixed(2), index, "gross")
                    }
                  />
                </td>
                {readOnly
                  ?
                  ""
                  :
                  <td className='table-data w-20'>
                    <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer'>
                      {DELETE}
                    </div>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </fieldset>
  )
}

export default ProductionDeliveryDetails