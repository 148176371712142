

export default function FabricColorProgram({fabriccolor}){

    return(
        <div className="">
            <table className=" text-xs w-full  border border-gray-500">
            <thead className=' border border-gray-500 top-0'>
                        <tr className='h-8 '>
                            <th className="table-data w-16  text-center">S.no</th>
                            <th className="table-data w-16   text-center">Fabric</th>
                            <th className="table-data w-16 ">Design</th>
                            <th className="table-data w-16 ">Color</th>
                            <th className="table-data w-16 ">FDIA</th>
                            <th className="table-data w-16 ">KDIA</th>
                            <th className="table-data w-16 ">GSM</th>
                            <th className="table-data  w-16 ">GG</th>
                            <th className="table-data  w-16 ">LL</th>
                            <th className="table-data  w-16 ">UOM</th>
                            <th className="table-data  w-16 ">ROLL</th>
                            <th className="table-data  w-16 ">Prog Qty</th>
                            
                        </tr>
                    </thead >
                    <tbody>
                        {fabriccolor.map((value,index)=>(
                              <tr className="border border-gray-500" key={index}>
                              <td className="table-data text-left px-1 py-1 h-10 text-center">{index+1}</td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.fabric} </td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.design} </td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.color} </td>
                              <td className="table-data text-left px-1 py-1 text-center"> {value.fdia}</td>
                              <td className="table-data text-left px-1 py-1 text-center"> {value.kdia}</td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.gsm}</td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.gg}</td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.ll} </td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.uom} </td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.roll} </td>
                              <td className="table-data text-left px-1 py-1 text-right px-1"> {value.progQty}</td>
                              
                          </tr>

                        ))

                        }
                        <tr className="col-span-2 h-9">
                            
                            <th className="table-data text-left px-1 py-1 text-center" colSpan={10} >TOTAL</th><td className="text-center px-1 table-data">16</td><td className="text-right px-1">219.769</td>
                            
                            
                            
                        </tr>
                        
                        
                    </tbody>
                    
                
                    
            </table>
                  
        </div>
    )
}