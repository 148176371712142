import React from 'react'
import { PLUS } from '../../../icons';
import { Delete } from '../../../Buttons';
import { sumArray } from '../../../Utils/helper';
import { toast } from 'react-toastify';

export function YarnLotGrid({ isDirect, lotDetails, handleInputChangeLotNo, index, balanceQty, balanceBags, readOnly, addNewLotNo, removeLotNo, onClose }) {
    return (
        <table className='table-fixed w-[500px]'>
            <thead className='border text-sm'>
                <th className='table-data text-center'>Lot No</th>
                <th className='table-data  text-center'>No. Of Bags</th>
                <th className='table-data  text-center'>Wg/Per</th>
                <th className='table-data text-center'>Inward Qty</th>
                {!readOnly &&
                    <th className='table-data text-center'>
                        <button className='text-green-500' onClick={() => { addNewLotNo(index, lotDetails[0] ? lotDetails[0].weightPerBag : "0.000") }} >{PLUS}</button>
                    </th>
                }
            </thead>
            <tbody>
                {lotDetails.map((item, lotIndex) =>
                    <tr className='table-row' key={lotIndex}>
                        <td className='text-center table-data'>
                            <input
                                autoFocus={index === 0}
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo("", index, lotIndex, "lotNo", balanceQty); }
                                }}
                                type="text"
                                className="text-right rounded py-1   w-full table-data-input"
                                value={item.lotNo}
                                disabled={readOnly}
                                onChange={(event) => {
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "lotNo", balanceQty);
                                }}
                            />
                        </td>
                        <td className='text-center table-data'>
                            <input
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo(0, index, lotIndex, "inwardBags", balanceQty); }
                                }}
                                min={"0"}
                                type="number"
                                className="text-right rounded py-1   w-full table-data-input"
                                value={item.inwardBags}
                                disabled={readOnly}
                                onChange={(event) => {
                                    if (!event.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "inwardBags", balanceQty);
                                        return
                                    }
                                    let value = event.target.value;
                                    let totalBagsExcludeCurrent = sumArray(lotDetails.filter((_, index) => index !== lotIndex), "inwardBags")
                                    if (parseFloat(balanceBags) < (parseFloat(totalBagsExcludeCurrent) + parseFloat(value))) {
                                        toast.info("Inward Bags Can not be more than balance Bags", { position: 'top-center' })
                                        return
                                    }
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "inwardBags", balanceQty);
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "inwardBags", balanceQty)
                                        return
                                    }
                                    handleInputChangeLotNo(e.target.value, index, lotIndex, "inwardBags", balanceQty)
                                }}
                            />
                        </td>
                        <td className='text-center table-data'>
                            <input
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo(0, index, lotIndex, "weightPerBag", balanceQty); }
                                }}
                                min={"0"}
                                type="number"
                                className="text-right rounded py-1   w-full table-data-input"
                                value={item.weightPerBag}
                                disabled={readOnly}
                                onChange={(event) => {
                                    if (!event.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "weightPerBag", balanceQty);
                                        return
                                    }
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "weightPerBag", balanceQty);
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "weightPerBag", balanceQty)
                                        return
                                    }
                                    handleInputChangeLotNo(e.target.value, index, lotIndex, "weightPerBag", balanceQty)
                                }}
                            />
                        </td>
                        <td className='text-center table-data'>
                            <input
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo(0, index, lotIndex, "inwardQty", balanceQty); }
                                }}
                                min={"0"}
                                type="number"
                                className="text-right rounded py-1  w-full table-data-input"
                                value={item.inwardQty}
                                disabled={readOnly}
                                onChange={(event) => {
                                    if (!event.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "inwardQty", balanceQty);
                                        return
                                    }
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "inwardQty", balanceQty);
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "inwardQty", balanceQty)
                                        return
                                    }
                                    handleInputChangeLotNo(e.target.value, index, lotIndex, "inwardQty", balanceQty)
                                }}
                            />
                        </td>
                        {!readOnly &&
                            <th className='table-data text-center'>
                                <Delete onClick={() => { removeLotNo(index, lotIndex) }} />
                            </th>
                        }
                    </tr>
                )}
                <tr className='table-row'>
                    <td>Total</td>
                    <td className='text-right'>{sumArray(lotDetails, "inwardBags")}</td>
                    <td></td>
                    <td className='text-right'>{(sumArray(lotDetails, "inwardQty")).toFixed(3)}</td>
                    {!readOnly &&
                        <td></td>
                    }
                </tr>
                {isDirect
                    ?
                    <tr className='table-row'>
                        <td colSpan={3} ></td>
                        {!readOnly &&
                            <>
                                <td className='w-full'><button className='w-full bg-blue-500 text-white' onClick={onClose}>Done</button></td>
                            </>
                        }
                    </tr>
                    :
                    <tr className='table-row'>
                        <td>Balance</td>
                        <td className='text-right'>{balanceBags}</td>
                        <td></td>
                        <td className='text-right'>{balanceQty}</td>
                        {!readOnly &&
                            <>
                                <td className='w-full'><button className='w-full bg-blue-500 text-white' onClick={onClose}>Done</button></td>
                            </>
                        }
                    </tr>
                }
            </tbody>
        </table>
    )
}

export function FabricLotGrid({ lotDetails, handleInputChangeLotNo, index, readOnly, addNewLotNo, removeLotNo, onClose, balanceQty, isDirect }) {
    return (
        <table className='table-fixed w-[500px]'>
            <thead className='border text-sm'>
                <th className='table-data text-center'>Lot No</th>
                <th className='table-data  text-center'>Inward Rolls</th>
                <th className='table-data text-center'>Inward Qty</th>
                {!readOnly &&
                    <th className='table-data text-center'>
                        <button className='text-green-500' onClick={() => { addNewLotNo(index) }} >{PLUS}</button>
                    </th>
                }
            </thead>
            <tbody>
                {lotDetails.map((item, lotIndex) =>
                    <tr className='table-row' key={lotIndex}>
                        <td className='text-center table-data'>
                            <input
                                autoFocus={index === 0}
                                        
                                        onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo("", index, lotIndex, "lotNo", balanceQty); }
                                }}
                                type="text"
                                className="text-right rounded py-1   w-full table-data-input"
                                value={item.lotNo}
                                disabled={readOnly}
                                onChange={(event) => {
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "lotNo", balanceQty);
                                }}
                            />
                        </td>
                        <td className='text-center table-data'>
                            <input
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo(0, index, lotIndex, "inwardRolls", balanceQty); }
                                }}
                                min={"0"}
                                type="number"
                                className="text-right rounded py-1   w-full table-data-input"
                                value={item.inwardRolls}
                                disabled={readOnly}
                                onChange={(event) => {
                                    if (!event.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "inwardRolls", balanceQty);
                                        return
                                    }
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "inwardRolls", balanceQty);
                                }}
                            />
                        </td>
                        <td className='text-center table-data'>
                            <input
                                onKeyDown={e => {
                                    if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                    if (e.key === "Delete") { handleInputChangeLotNo(0, index, lotIndex, "inwardQty", balanceQty); }
                                }}
                                min={"0"}
                                type="number"
                                className="text-right rounded py-1  w-full table-data-input"
                                value={item.inwardQty}
                                disabled={readOnly}
                                onChange={(event) => {
                                    if (!event.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "inwardQty", balanceQty);
                                        return
                                    }
                                    handleInputChangeLotNo(event.target.value, index, lotIndex, "inwardQty", balanceQty);
                                }}
                                onBlur={(e) => {
                                    if (!e.target.value) {
                                        handleInputChangeLotNo(0, index, lotIndex, "inwardQty", balanceQty)
                                        return
                                    }
                                    handleInputChangeLotNo(e.target.value, index, lotIndex, "inwardQty", balanceQty)
                                }}
                            />
                        </td>
                        {!readOnly &&
                            <th className='table-data text-center'>
                                <Delete onClick={() => { removeLotNo(index, lotIndex) }} />
                            </th>
                        }
                    </tr>
                )}
                <tr className='table-row'>
                    <td>Total</td>
                    <td className='text-right'>{sumArray(lotDetails, "inwardRolls")}</td>
                    <td className='text-right'>{(sumArray(lotDetails, "inwardQty")).toFixed(3)}</td>
                    {!readOnly &&
                        <td></td>
                    }
                </tr>
                {isDirect
                    ?
                    <tr className='table-row'>
                        <td colSpan={3} ></td>
                        {!readOnly &&
                            <>
                                <td className='w-full'><button className='w-full bg-blue-500 text-white' onClick={onClose}>Done</button></td>
                            </>
                        }
                    </tr>
                    :
                    <tr className='table-row'>
                        <td>Balance</td>
                        <td></td>
                        <td className='text-right'>{balanceQty}</td>
                        {!readOnly &&
                            <>
                                <td className='w-full'><button className='w-full bg-blue-500 text-white'
                                 onClick={onClose}>Done</button></td>
                            </>
                        }
                    </tr>
                }
            </tbody>
        </table>
    )
}

