import React from 'react'
import { View, Text } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import TaxSplitup from '../PrintFormatUtils/TaxSplitup';
import numToText from 'num-to-text';

export const FabricFormat = ({ taxGridData, gridDetails, isSupplierOutside, igstAmount, sgstAmount, cgstAmount, netAmount, grossAmount, roundOffAmount }) => {
    const row = [5, 20, 5, 10, 10, 5, 5, 5, 7, 5, 10, 5, 5, 10];


    return (
        <View style={tw("w-full text-[9px]")}>
            <View style={{ ...tw("w-full flex flex-row border-b border-gray-500 border-l text-center items-center "), fontFamily: "Times-Bold" }}>
                <Text style={tw(`w-[${row[0]}%] p-1 border-r border-gray-500`)}>
                    S.no
                    {/* {row.reduce((a, c) => a + c, 0)} */}
                </Text>
                <Text style={tw(`w-[${row[1]}%] p-1 border-r border-gray-500`)}>
                    Fabric
                </Text>
                <Text style={tw(`w-[${row[2]}%] p-1 border-r border-gray-500`)}>
                    Hsn
                </Text>
                <Text style={tw(`w-[${row[3]}%] p-1 border-r border-gray-500`)}>
                    Color
                </Text>
                <Text style={tw(`w-[${row[4]}%] p-1 border-r border-gray-500`)}>
                    Design
                </Text>
                <Text style={tw(`w-[${row[5]}%] p-1 border-r border-gray-500`)}>
                    Gauge
                </Text>
                <Text style={tw(`w-[${row[6]}%] p-1 border-r border-gray-500`)}>
                    LL
                </Text>
                <Text style={tw(`w-[${row[7]}%] p-1 border-r border-gray-500`)}>
                    Gsm
                </Text>
                <Text style={tw(`w-[${row[8]}%] p-1 border-r border-gray-500`)}>
                    K/F-Dia
                </Text>
                <Text style={tw(`w-[${row[9]}%] p-1 border-r border-gray-500`)}>
                    Uom
                </Text>
                <Text style={tw(`w-[${row[10]}%] p-1 border-r border-gray-500`)}>
                    Lot No.
                </Text>
                <Text style={{ ...tw(`w-[${row[11]}%] p-1 border-r text-center  border-gray-500`) }}>
                    Qty
                </Text>
                <Text style={tw(`w-[${row[12]}%] border-r p-1 text-center  border-gray-500`)}>
                    Price
                </Text>
                <Text style={tw(`w-[${row[13]}%] border-r p-1 text-center  border-gray-500`)}>
                    Amt.
                </Text>
            </View>
            {
                gridDetails.map((item, index) => (
                    <View key={index} style={tw(`w-full flex flex-row border-l text-center border-gray-500 ${(index + 1) === gridDetails.length ? "border-b" : ""}`)} wrap={false}>
                        <Text style={tw(`w-[${row[0]}%]  border-r p-1 border-gray-500 text-center`)}>
                            {index + 1}
                        </Text>
                        <Text style={tw(`w-[${row[1]}%]  border-r p-1 border-gray-500 text-center break-words`)}>
                            {item?.Fabric?.aliasName}
                        </Text>
                        <Text style={tw(`w-[${row[2]}%]  border-r p-1 text-center border-gray-500`)}>
                            {item?.Fabric?.hsn}
                        </Text>
                        <Text style={tw(`w-[${row[3]}%]  border-r p-1 border-gray-500 `)}>
                            {item?.Color?.name}
                        </Text>
                        <Text style={tw(`w-[${row[4]}%]  border-r p-1 border-gray-500 `)}>
                            {item?.Design?.name}
                        </Text>
                        <Text style={tw(`w-[${row[5]}%]  border-r p-1 border-gray-500 `)}>
                            {item?.Gauge?.name}
                        </Text>
                        <Text style={tw(`w-[${row[6]}%]  border-r p-1 border-gray-500`)}>
                            {item?.LoopLength?.name}
                        </Text>
                        <Text style={tw(`w-[${row[7]}%]  border-r p-1 border-gray-500`)}>
                            {item?.Gsm?.name}
                        </Text>
                        <Text style={tw(`w-[${row[8]}%]  border-r p-1 border-gray-500`)}>
                            {item?.KDia?.name}/ {item?.FDia?.name}
                        </Text>
                        <Text style={tw(`w-[${row[9]}%]  border-r p-1 border-gray-500 text-center`)}>
                            {item?.Uom?.name}
                        </Text>
                        <Text style={tw(`w-[${row[10]}%]  border-r p-1 border-gray-500 text-center`)}>
                            {item?.lotNo}
                        </Text>
                        <Text style={{ ...tw(`w-[${row[11]}%] border-r p-1 text-center  border-gray-500`) }}>
                            {parseFloat(item?.qty || 0)}
                        </Text>
                        <Text style={tw(`w-[${row[12]}%] border-r text-right p-1 border-gray-500`)}>
                            {parseFloat(item?.price).toFixed(2)}
                        </Text>
                        <Text style={tw(`w-[${row[13]}%] border-r text-right p-1 border-gray-500`)}>
                            {(parseFloat(item?.qty) * parseFloat(item?.price)).toFixed(2)}
                        </Text>
                    </View>
                ))
            }
            {
                Array.from({ length: 15 - (gridDetails.length) }).map((_, i) =>
                    <View key={i} style={tw('w-full flex flex-row border-l border-gray-500 h-[20px]')} wrap={false}>
                        {row.map((_, index) =>
                            <Text key={index} style={tw(`w-[${row[index]}%]  border-r border-gray-500 `)}>
                            </Text>
                        )}
                    </View>
                )
            }
            <TaxSplitup taxGridData={taxGridData} row={row} numberInWords={netAmount ? numToText(netAmount || 0, 'in') : ""} grossAmount={grossAmount} roundOffAmount={roundOffAmount} isSupplierOutside={isSupplierOutside} sgstAmount={sgstAmount} cgstAmount={cgstAmount} igstAmount={igstAmount} netAmount={netAmount} />
        </View>
    )
}
