import React, { useEffect, useState } from 'react'
import { useGetAccessoryMasterQuery } from "../../../redux/ErpServices/AccessoryMasterServices";
import { useGetUnitOfMeasurementMasterQuery } from "../../../redux/ErpServices/UnitOfMeasurementServices";
import secureLocalStorage from 'react-secure-storage';
import { Loader } from '../../../Basic/components';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { findFromList, getDateFromDateTime, substract } from '../../../Utils/helper';
import { useGetSizeMasterQuery } from '../../../redux/ErpServices/SizeMasterServices';


const AccessoryPoItemSelection = ({ poItems, addItem, removeItem, isItemInPoAdded, storeId, inwardOrReturn }) => {
    const [localItems, setLocalItems] = useState([]);

    const [searchFilter, setSearchFilter] = useState([]);

    useEffect(() => {
        let fullList = []
        poItems.forEach((poItem) => {
            poItem.poItems.forEach((item) => {
                if (inwardOrReturn === "PurchaseInward" || inwardOrReturn === "PurchaseCancel") {
                    if ((item.alreadyInwardedQty + item.alreadyReturnedQty) !== substract(item.qty, item.alreadyCancelQty)) {
                        fullList.push({ ...item, poDocId: poItem.docId, poNo: poItem.id, poDate: poItem.createdAt, dueDate: poItem.dueDate, poItemId: item.poItemId })
                    }
                } else {
                    if (parseFloat(item.alreadyInwardedQty) !== 0) {
                        fullList.push({ ...item, poDocId: poItem.docId, poNo: poItem.id, poDate: poItem.createdAt, dueDate: poItem.dueDate, poItemId: item.poItemId })
                    }
                }
            })
        })
        setLocalItems(fullList)
    }, [poItems, setLocalItems, searchFilter])

    function filterRecords(localItems) {
        let newLocalItems = [...localItems]
        newLocalItems = newLocalItems.filter(item => {
            for (let filter of searchFilter) {
                let fieldName = filter.fieldName
                let searchValue = filter.searchValue
                let objTransformFunc = filter.objTransformFunc
                let itemObj = objTransformFunc(item[fieldName])
                if (!(`${itemObj}`.toLowerCase().includes(searchValue.toLowerCase()))) return false
            }
            return true
        })
        return newLocalItems
    }

    useEffect(() => {
        setLocalItems(prev => filterRecords(prev))
    }, [setLocalItems, searchFilter, poItems])


    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const { data: accessoryList, isLoading: accessoryListLoading, isFetching: accessoryListFetching } = useGetAccessoryMasterQuery({ companyId })
    const { data: uomList, isLoading: uomLoading, isFetching: uomFetching } = useGetUnitOfMeasurementMasterQuery({ companyId })
    const { data: colorList, isLoading: colorLoading, isFetching: colorFetching } = useGetColorMasterQuery({ companyId })
    const { data: sizeList, isLoading: sizeLoading, isFetching: sizeFetching } = useGetSizeMasterQuery({ companyId })

    function handleCheckBoxChange(poNo, value, poItemId) {
        console.log(poNo, value, poItemId, "addItems")
        if (value) {
            addItem(poNo, poItemId)
        } else {
            removeItem(poNo, poItemId)
        }
    }


    function handleSelectAllChange(value) {
        if (value) {
            localItems.forEach(item => addItem(item.poNo, item.poItemId))
        } else {
            localItems.forEach(item => removeItem(item.poNo, item.poItemId))
        }
    }

    function getSelectAll() {
        return localItems.every(item => isItemInPoAdded(item.poNo, item.poItemId))
    }


    function changeSearchFilter(field, value, objTransformFunc) {
        setSearchFilter(prev => {
            if (!value) {
                return prev.filter(item => item.fieldName !== field)
            }
            let newSearchFilter = [...prev]
            let index = newSearchFilter.findIndex(item => item.fieldName === field)
            if (index !== -1) {
                newSearchFilter[index]["searchValue"] = value
            } else {
                newSearchFilter.push({ fieldName: field, searchValue: value, objTransformFunc });
            }
            return newSearchFilter
        })
    }

    function findAccessoryItemName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
        return acc ? acc.accessoryItem.name : null
    }


    function findAccessoryGroupName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
        return acc ? acc.accessoryItem.AccessoryGroup.name : null
    }



    let count = 1;
    if (accessoryListLoading || accessoryListFetching || uomFetching || uomLoading || colorFetching || colorLoading || sizeLoading || sizeFetching) {
        return <Loader />

    }
    return (
        <div className='bg-gray-200'>
            <table className='table-auto w-full border border-gray-500 text-xs'>{console.log(accessoryList, "accessory list")}
                <thead className='border border-gray-500'>
                    <tr className='bg-blue-200 table-data py-2 sticky top-10'>
                        <th className='p-1'>
                        </th>
                        <th className='text-center table-data  p-1'>
                            S.no
                        </th>
                        <th className='text-center table-data p-1'>
                            Po.no
                        </th>
                        <th className='text-center table-data p-1'>
                            Po.Date
                        </th>
                        <th className='text-center table-data p-1'>
                            Due Date
                        </th>
                        <th className='table-data p-1 '>
                            Accessory Name
                        </th>
                        <th className='table-data p-1'>
                            Accessory Items
                        </th>
                        <th className='table-data p-1'>
                            Accessory Group
                        </th>
                        <th className='table-data p-1'>
                            Color
                        </th>
                        <th className='table-data p-1'>
                            Size
                        </th>
                        <th className='table-data p-1'>
                            Uom
                        </th>
                        <th className='table-data p-1'>
                            Po qty
                        </th>
                        <th className='table-data p-1'>
                            Already Inwarded qty
                        </th>
                        <th className='table-data'>
                            Balance qty
                        </th>
                        <th className='table-data p-1'>
                            Price
                        </th>
                        <th className='table-data p-1'>
                            Gross
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='py-2 h-5 sticky top-20 bg-gray-200 table-data'>{console.log(localItems, "localitems")}
                        <td className='text-center table-data '>
                            <input type="checkbox" onChange={(e) => handleSelectAllChange(e.target.checked)}
                                checked={getSelectAll()}
                            />
                        </td>

                        <td className='text-left table-data '>
                        </td>
                        <td className='text-left px-1  table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("poNo", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-left px-1  table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("poDate", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-left px-1   table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("dueDate", e.target.value, (item) => item)
                                }} />
                        </td>
                        <td className='text-left px-1   table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("accessoryId", e.target.value, (item) => findFromList(item, accessoryList.data, "aliasName"))
                                }} />
                        </td>

                        <td className='text-left px-1   table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("accessoryId", e.target.value, (item) => findAccessoryItemName(item))
                                }} />
                        </td>

                        <td className='text-left px-1   table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("accessoryId", e.target.value, (item) => findAccessoryGroupName(item))
                                }} />
                        </td>
                        <td className='text-left px-1   table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("colorId", e.target.value, (item) => findFromList(item, colorList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-right px-1  table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("sizeId", e.target.value, (item) => findFromList(item, sizeList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-left px-1 table-data '>
                            <input className='w-full p-2' type="text"
                                onChange={(e) => {
                                    changeSearchFilter("uomId", e.target.value, (item) => findFromList(item, uomList.data, "name"))
                                }}
                            />
                        </td>
                        <td className='text-center  table-data '>
                        </td>
                        <td className='text-center  table-data '>
                        </td>
                        <td className='text-center table-data '>
                        </td>
                        <td className='text-center table-data '>
                        </td>
                        <td className='text-center table-data '>
                        </td>
                    </tr>
                    {localItems.map((item, index) =>
                        <tr key={index} className='py-2 table-row'>
                            <td className='text-center p-3 table-data '>
                                <input type="checkbox" onChange={(e) =>
                                    handleCheckBoxChange(item.poNo, !isItemInPoAdded(item.poNo, item.poItemId), item.poItemId)}
                                    checked={isItemInPoAdded(item.poNo, item.poItemId)} />
                            </td>
                            <td className='text-left px-1  table-data '>
                                {count++}
                            </td>
                            <td className='text-left px-1 table-data'>
                                {item.poDocId}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {getDateFromDateTime(item.poDate)}
                            </td>
                            <td className='text-left px-1 table-data '>
                                {getDateFromDateTime(item.dueDate)}
                            </td>
                            <td className='text-left px-1 table-data'>
                                {findFromList(item.accessoryId, accessoryList.data, "aliasName")}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {findAccessoryItemName(item.accessoryId)}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {findAccessoryGroupName(item.accessoryId)}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {findFromList(item.colorId, colorList.data, "name")}
                            </td>
                            <td className='text-right px-1  table-data '>
                                {findFromList(item.sizeId, sizeList.data, "name")}
                            </td>
                            <td className='text-left px-1  table-data '>
                                {findFromList(item.uomId, uomList.data, "name")}
                            </td>
                            <td className='text-right px-1 table-data '>
                                {substract(item.qty, item.alreadyCancelQty).toFixed(3)}
                            </td>
                            <td className='text-right px-1 table-data '>
                                {(item.alreadyInwardedQty + item.alreadyReturnedQty).toFixed(3)}
                            </td>
                            <td className='text-right px-1 table-data '>
                                {substract(substract(item.qty, item.alreadyCancelQty), (item.alreadyInwardedQty + item.alreadyReturnedQty)).toFixed(3)}
                            </td>
                            <td className='text-right px-1  table-data '>
                                {item.price}
                            </td>
                            <td className='text-right px-1  table-data '>
                                {(parseFloat(item.price) * parseFloat(item.qty)).toFixed(2)}
                            </td>
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>

    )
}

export default AccessoryPoItemSelection;