import React from 'react'
import { useGetYarnMasterQuery } from '../../../redux/ErpServices/YarnMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';


const RawMaterialYarn = ({ programDetails }) => {

  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };
  const { data: yarnList } =
    useGetYarnMasterQuery({ params });

  const { data: colorList } =
    useGetColorMasterQuery({ params });

  const { data: uomList } =
    useGetUnitOfMeasurementMasterQuery({ params });

  function getTotal() {
    return programDetails.reduce((t, p) =>
      t + (p.rawMaterials.reduce((a, c) => a + parseFloat(c.qty), 0)), 0)
  }

  if (!yarnList || !colorList || !uomList) return <Loader />


  return (
    <div>
      <div className='w-full  font-bold mt-3'>
        <h1>
          YARN RAW MATERIAL
        </h1>
      </div>
      <table className="  border border-gray-500 table-auto w-full ">
        <thead className=' border border-gray-500 top-0'>
          <tr className='h-3'>
            <th className="table-data  w-16 text-center">S.no</th>
            <th className="table-data text-center">YARN DESCRIPTION</th>
            <th className="table-data ">COLOR</th>
            <th className="table-data ">UOM</th>
            <th className="table-data  ">NO.OF.ROLLS</th>
            <th className="table-data  ">ISS.QTY</th>
          </tr>
        </thead>
        <tbody>
          {programDetails.map((value) =>
          (
            value.rawMaterials.map((item, itemIndex) =>
            (
              <tr className=' border border-gray-500 top-0'>
                <td className='table-data text-center px-1 h-3'>{itemIndex + 1}</td>
                <td className='table-data  px-1 h-3 '>{item.Yarn?.aliasName}</td>
                <td className='table-data  px-1 h-3 '>{item.Color?.name}</td>
                <td className='table-data  px-1 h-3 '>{item.Uom?.name}</td>
                <td className='table-data text-right px-1 h-3 '>{item.noOfBags}</td>
                <td className='table-data text-right px-1 h-3 '>{item.qty}</td>
              </tr>
            ))
          ))}
          <tr className=' border border-gray-500 top-0'>
            <td className=' text-center  px-1 h-3 table-data' colSpan={5}>TOTAL</td>
            <td className=' px-1 h-3 text-right'>{getTotal().toFixed(3)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default RawMaterialYarn