import React from 'react'
import FabricConsumptionItem from './FabricConsumptionItem';
import { PLUS } from '../../../icons';


const FabricConsumptionDetails = ({ id, cuttingOrderId, cuttingReceiptFabricConsumptionDetails,
    setCuttingReceiptFabricConsumptionDetails,
    cuttingReceiptFabricConsumptionFillData,
    readOnly, setFillGrid }) => {
    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(cuttingReceiptFabricConsumptionDetails);
        newBlend[index][field] = value;
        setCuttingReceiptFabricConsumptionDetails(newBlend);
    };

    return (
        <>
            <div className={`relative w-full overflow-y-auto py-1`}>
                <table className="border border-gray-500 text-xs table-auto w-full">
                    <thead className='bg-blue-200 top-0'>
                        <tr className='h-8'>
                            <th className=' table-data '>
                                S.no
                            </th>
                            <th className=' table-data w-52'>
                                Del Dc.no
                            </th>
                            <th className='table-data w-80'>
                                Fabric Name
                            </th>
                            <th className=' table-data w-20'>
                                Color
                            </th>
                            <th className='table-data w-20'>
                                Design
                            </th>
                            <th className='table-data'>
                                Gauge
                            </th>
                            <th className='table-data'>
                                LL
                            </th>
                            <th className='table-data'>
                                Gsm
                            </th>
                            <th className='table-data'>
                                K-Dia
                            </th>
                            <th className='table-data'>
                                F-Dia
                            </th>
                            <th className='table-data'>
                                Uom
                            </th>
                            <th className='table-data '>
                                Del Qty
                            </th>
                            <th className='table-data'>
                                A.Cons.Qty
                            </th>
                            <th className='table-data'>
                                A.Rtn.Qty
                            </th>
                            <th className="table-data w-16">
                                Bal Qty
                            </th>
                            <th className="table-data w-24">
                                No. Of Rolls
                            </th>
                            <th className="table-data w-24">
                                Rtn. Qty
                            </th>
                            {!readOnly &&
                                <th className="table-data  w-5 text-green-600" onClick={() => { setFillGrid(true) }}> {PLUS} </th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto border border-gray-600 h-full w-full'>
                        {cuttingReceiptFabricConsumptionDetails.map((item, index) => (
                            <FabricConsumptionItem
                                cuttingReceiptFabricConsumptionDetails={cuttingReceiptFabricConsumptionDetails}
                                setCuttingReceiptFabricConsumptionDetails={setCuttingReceiptFabricConsumptionDetails}
                                cuttingReceiptFabricConsumptionFillData={cuttingReceiptFabricConsumptionFillData}
                                item={item} id={id} readOnly={readOnly} cuttingOrderId={cuttingOrderId} index={index}
                                handleInputChange={handleInputChange} />
                        ))}
                        {Array.from({ length: 2 - cuttingReceiptFabricConsumptionDetails.length }).map(i =>
                            <tr className='w-full font-bold h-6 border-gray-400 border table-row'>
                                <td className='table-data'>
                                </td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data"></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>

                            </tr>)
                        }
                    </tbody>
                </table>
            </div >
        </>
    )
}

export default FabricConsumptionDetails