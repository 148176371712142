import React, { useState } from 'react'
import { useGetFinishedGoodsSalesDeliveryQuery } from '../../../redux/ErpServices/finishedGoodsSalesDeliveryServices';
import { substract } from '../../../Utils/helper';

const FinishedGoodsSalesDetailsFillGridFromDelivery = ({ productionDeliveryDetails, setProductionDeliveryDetails, setFillGrid, supplierId, id }) => {
    const [localProductionDeliveryDetails, setLocaProductionDeliveryDetails] = useState(productionDeliveryDetails);

    function handleDone() {
        setProductionDeliveryDetails(localProductionDeliveryDetails);
        setFillGrid(false);
    }
    const { data } = useGetFinishedGoodsSalesDeliveryQuery({ params: { itemWise: true, supplierId, finishedGoodsSalesId: id } }, { skip: !supplierId })

    function addItem(item) {
        setLocaProductionDeliveryDetails(localInwardItems => {
            let newItems = structuredClone(localInwardItems);
            newItems.push({ ...item, finishedGoodsSalesDeliveryDetailsId: item.id });
            return newItems
        });
    }
    function removeItem(removeItem) {
        setLocaProductionDeliveryDetails(localInwardItems => {
            return localInwardItems.filter(item =>
                !(
                    removeItem.finishedGoodsSalesDeliveryDetailsId === item.finishedGoodsSalesDeliveryDetailsId
                )
            )
        });
    }

    function isItemChecked(checkItem) {
        let item = localProductionDeliveryDetails.find(item =>
            checkItem.finishedGoodsSalesDeliveryDetailsId === item.finishedGoodsSalesDeliveryDetailsId
        )
        if (!item) return false
        return true
    }


    function handleCheckBoxChange(value, item) {
        if (value) {
            addItem(item)
        } else {
            removeItem(item)
        }
    }

    const finishedGoodsDeliveryDetails = (data?.data ? data?.data.map(i => ({ ...i, finishedGoodsSalesDeliveryDetailsId: i.id })) : []);

    function handleSelectAllChange(value) {
        if (value) {
            finishedGoodsDeliveryDetails.forEach(item => addItem(item))
        } else {
            finishedGoodsDeliveryDetails.forEach(item => removeItem(item))
        }
    }

    function getSelectAll() {
        return finishedGoodsDeliveryDetails.every(item => isItemChecked(item))
    }

    return (
        <div>
            <div className={`bg-gray-200 z-50 overflow-auto w-[1000px]`}>
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10 text-xs"> Finished Goods Delivery Items</div>
                </div>
                <table className="border border-gray-500 w-full text-xs text-start">
                    <thead className="border border-gray-500">
                        <tr>
                            <th className='w-8 p-5'>
                                Mark All
                                <input type="checkbox" className='w-full' onChange={(e) => handleSelectAllChange(e.target.checked)}
                                    checked={getSelectAll()}
                                />
                            </th>
                            <th className="w-20 border border-gray-500">S.no</th>
                            <th className="border border-gray-500">Delivery No.</th>
                            <th className="border border-gray-500">Style</th>
                            <th className="border border-gray-500">Color</th>
                            <th className="border border-gray-500">Size</th>
                            <th className="border border-gray-500">Prev. Process</th>
                            <th className="border border-gray-500">Del. Qty</th>
                            <th className="border border-gray-500">A. Bill Qty</th>
                            <th className="border border-gray-500">Bal. Bill Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {finishedGoodsDeliveryDetails.map((item, index) =>
                            <tr key={index} className='table-row' onClick={() => {
                                handleCheckBoxChange(!isItemChecked({ ...item, finishedGoodsSalesDeliveryDetailsId: item.id }), { ...item, finishedGoodsSalesDeliveryDetailsId: item.id, qty: 0 })
                            }}>
                                <td className='table-data'>
                                    <input type="checkbox" className='w-full table-data-input'
                                        checked={isItemChecked({ ...item, finishedGoodsSalesDeliveryDetailsId: item.id })} />
                                </td>
                                <td>{index + 1}</td>
                                <td>{item?.FinishedGoodsSalesDelivery?.docId}</td>
                                <td>{item?.Style?.name}</td>
                                <td className='text-center'>{item?.Color?.name}</td>
                                <td className='text-center'>{item?.Size?.name}</td>
                                <td className='text-center'>{item?.PrevProcess?.name}</td>
                                <td className='text-right'>{item.qty}</td>
                                <td className='text-right'>{item.aBillQty}</td>
                                <td className='text-right'>{substract(item.qty, item.aBillQty)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='flex justify-end'>
                <button className='p-1 bg-blue-400 rounded-lg' onClick={handleDone}>Done</button>
            </div>
        </div>
    )
}

export default FinishedGoodsSalesDetailsFillGridFromDelivery;