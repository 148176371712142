import React from 'react'
import {
    View, Text
} from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
const Signature = () => {
    return (
        <View style={tw("w-full")}>
            <Text style={{ ...tw("text-right pr-3 pt-1 text-xs"), fontFamily: "Times-Bold" }}>FOR&nbsp;P.S&nbsp;TEX</Text>
            <View style={tw("w-full pb-[10px] pt-[25px] border-b border-gray-500")}>
                <View style={{ ...tw(" text-xs w-full flex flex-row justify-between px-3") }}>
                    <Text>
                        Prepared By
                    </Text>
                    <Text>
                        Verified By
                    </Text>
                    <Text>
                        Authorized By
                    </Text>
                </View>
            </View>
        </View>
    )
}

export default Signature