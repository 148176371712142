import React, { useEffect, useRef, useState } from 'react'
import Yarn from './Yarn'
import _ from 'lodash';
import Fabric from './Fabric';

const RawMaterial = ({ storeId, rawMaterialType, currentProgramIndex, inwardDetails, setInwardDetails,
  programDetails, setProgramDetails, getIssuedQty, isColorInStyle, readOnly, openSelectionFillGrid }) => {
  const prevRawMaterials = useRef(inwardDetails[currentProgramIndex]?.rawMaterials ? inwardDetails[currentProgramIndex]["rawMaterials"] : [])
  const [rawMaterials, setRawMaterials] = useState(prevRawMaterials.current)
  const [fillGrid, setFillGrid] = useState(false);

  useEffect(() => {
    setRawMaterials(prevRawMaterials.current)
  }, [currentProgramIndex, prevRawMaterials])


  useEffect(() => {
    setInwardDetails(prev => {
      let newPrograms = structuredClone(prev)
      newPrograms[currentProgramIndex]["rawMaterials"] = rawMaterials;
      return newPrograms
    })
  }, [rawMaterials, currentProgramIndex])


  function removeItem(removeItem) {
    setRawMaterials(localInwardItems => {
      let newItems = structuredClone(localInwardItems);
      newItems = newItems.filter(item => !(_.isEqual(item.itemDetails, removeItem.itemDetails)))
      return newItems
    });
  }

  useEffect(() => {
    if (rawMaterials.length === 0) {
      setFillGrid(true)
    } else {
      setFillGrid(false)
    }
  }, [rawMaterials])
  return (
    <>
      {rawMaterialType.includes("Y")
        ?
        <Yarn
          rawMaterialsFillGrid={programDetails[currentProgramIndex]?.rawMaterials ? programDetails[currentProgramIndex]["rawMaterials"] : []}
          readOnly={readOnly} removeItem={removeItem} setFillGrid={setFillGrid} getIssuedQty={getIssuedQty}
          rawMaterials={rawMaterials} setRawMaterials={setRawMaterials} />
        :
        <>
          {rawMaterialType.includes("F") ?
            <Fabric rawMaterialsFillGrid={programDetails[currentProgramIndex]?.rawMaterials ? programDetails[currentProgramIndex]["rawMaterials"] : []}
            readOnly={readOnly} removeItem={removeItem} setFillGrid={setFillGrid} getIssuedQty={getIssuedQty}
            rawMaterials={rawMaterials} setRawMaterials={setRawMaterials} />
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default RawMaterial