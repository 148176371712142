import React, { useEffect } from 'react'
import { Loader } from '../../../Basic/components'
import { DELETE, VIEW } from '../../../icons'
import { getDateFromDateTimeToDisplay, substract, sumArray } from '../../../Utils/helper'
import { toast } from 'react-toastify'
import { useGetBillEntryByIdQuery } from '../../../redux/ErpServices/BillEntryServices'

const BillEntryGridRowItem = ({ item, index, handleInputChange, readOnly, removeItem, advanceAdjustmentId }) => {
    const { data, isLoading, isFetching } = useGetBillEntryByIdQuery({ id: item.billEntryId, advanceAdjustmentId }, { skip: !(item?.billEntryId) })
    if (isLoading || isFetching) return <Loader />
    const billEntryItem = data.data
    let balanceAmount = substract(billEntryItem.netBillValue, billEntryItem.alreadyPaidAmount)
    return (
        <>
            <tr key={item.poItemsId} className='table-row'>
                <td className='text-center   table-data'>{index + 1}</td>
                <td className='text-left  table-data'>{billEntryItem.docId}</td>
                <td className='text-left  table-data'>{getDateFromDateTimeToDisplay(billEntryItem.createdAt)}</td>
                <td className='text-left  table-data'>{billEntryItem.partyBillNo}</td>
                <td className='text-right  table-data'>{billEntryItem.poType}</td>
                <td className='text-right  table-data'>{billEntryItem.supplier.aliasName}</td>
                <td className='text-right  table-data'>{parseFloat(billEntryItem.netBillValue).toFixed(2)}</td>
                <td className='text-right  table-data'>{parseFloat(billEntryItem.alreadyPaidAmount).toFixed(2)}</td>
                <td className='text-right  table-data'>{balanceAmount.toFixed(2)}</td>
                <td className='text-left table-data'>
                    <input
                        autoFocus={index === 0}
                        onKeyDown={e => {
                            if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                            if (e.key === "Delete") { handleInputChange("0", index, "noOfBags") }
                        }}
                        min={"0"}
                        type="number"
                        className="text-right rounded py-1   w-full table-data-input"
                        value={item.amount}
                        disabled={readOnly}
                        onChange={(event) => {
                            if (!event.target.value) {
                                handleInputChange(0, index, "amount");
                                return
                            }
                            if(event.target.value > balanceAmount){
                                toast.info("Paid Amount Cannot More than Balance Amount", {position:"top-center"})
                                return 
                            }
                            handleInputChange(event.target.value, index, "amount");
                        }}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                handleInputChange(0, index, "amount");
                                return
                            }
                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "amount")
                        }}
                    />
                </td>
                {!readOnly &&
                    <td className='table-data w-12'>
                        <div tabIndex={-1} onClick={() => removeItem(item.billEntryId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                            {DELETE}
                        </div>
                    </td>
                }
            </tr>
        </>
    )
}

export default BillEntryGridRowItem
