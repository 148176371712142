import React, { useCallback, useEffect, useMemo } from 'react';
import { PLUS } from '../../../icons';
import { toast } from 'react-toastify';
import { useGetPcsStockQuery } from '../../../redux/ErpServices/StockServices';
import { getCommonParams } from '../../../Utils/helper';
import FInishedGoodsSalesDetailItem from './FInishedGoodsSalesDetailItem';

const FinishedGoodsSalesDetails = ({ id, readOnly, productionDeliveryDetails, setProductionDeliveryDetails,
  setStockDetailsFillGrid, storeId, isWholeSale, supplierId }) => {
  const { branchId } = getCommonParams()
  const handleInputChange = useCallback((value, index, field) => {
    setProductionDeliveryDetails(productionDeliveryDetails => {
      const newBlend = structuredClone(productionDeliveryDetails);
      newBlend[index][field] = value;
      return newBlend
    });
  }, [setProductionDeliveryDetails]);
  const { data } = useGetPcsStockQuery({ params: { storeId, isPacking: true, finishedGoodsSalesId: id, branchId } })


  let stockData = useMemo(() => data?.data ? data.data : [], [data])

  const getStockItem = useCallback((checkItem, property) => {
    let item = stockData.find(item =>
      checkItem.styleId === item.styleId
      &&
      checkItem.colorId === item.colorId
      &&
      checkItem.uomId === item.uomId
      &&
      checkItem.prevProcessId === item.prevProcessId
      &&
      checkItem.sizeId === item.sizeId
    )
    if (!item) return ""
    return item[property]
  }, [stockData])

  useEffect(() => {
    setProductionDeliveryDetails(prev => {
      if (prev.length === 0) return prev
      return prev.map(item => {
        let newItem = structuredClone(item)
        const stockQty = getStockItem(item, "qty")
        if (parseFloat(stockQty) < parseFloat(item.qty)) {
          newItem["qty"] = 0;
        }
        return newItem
      })
    })
  }, [setProductionDeliveryDetails, getStockItem])

  const handleDeleteRow = id => {
    setProductionDeliveryDetails(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
  };
  return (
    <fieldset disabled={readOnly} className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border
                            border-gray-600 overflow-auto min-h-[100px]'>
      <legend className='sub-heading'>Sales Details</legend>
      <div className={`relative w-full overflow-y-auto p-1`}>
        <table className="table-data border border-gray-500 text-xs table-auto w-full">
          <thead className='bg-blue-200 border border-gray-500 top-0'>
            <tr className='border border-gray-500'>
              <th className="table-data w-2 text-center">S.no</th>
              <th className="table-data w-48">Style</th>
              <th className="table-data w-48">Colors</th>
              <th className="table-data w-32">Size</th>
              <th className="table-data w-20">Uom</th>
              <th className="table-data w-20">Stock Qty</th>
              <th className="table-data w-20">Qty</th>
              <th className="table-data w-20">Price</th>
              {
                isWholeSale &&
                <>
                  <th className="table-data w-20">Tax</th>
                  <th className="table-data w-20">Price With Tax</th>
                </>
              }
              <th className="table-data w-20">Amount</th>
              {readOnly ?
                "" :
                <th className='w-20  bg-green-600 text-white'>
                  <div onClick={() => {
                    if (!storeId) {
                      return toast.info("Please Select Store ...!")
                    }
                    setStockDetailsFillGrid(true)
                  }}
                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                    {PLUS}
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody className='overflow-y-auto table-data h-full w-full'>
            {productionDeliveryDetails.map((row, index) => (
              <FInishedGoodsSalesDetailItem index={index} row={row} handleDeleteRow={handleDeleteRow} handleInputChange={handleInputChange} readOnly={readOnly} getStockItem={getStockItem} isWholeSale={isWholeSale} supplierId={supplierId} />
            ))}
            {Array.from({ length: 8 - productionDeliveryDetails.length }).map(i =>
              <tr className='w-full font-bold h-6 border-gray-400 border'>
                <td className='table-data'>
                </td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data    "></td>
                <td className="table-data    "></td>
                <td className="table-data    "></td>
                <td className="table-data    "></td>
                {
                  isWholeSale &&
                  <>
                    <td className="table-data   "></td>
                    <td className="table-data   "></td>
                  </>}
                {!readOnly
                  &&
                  <td className="table-data"></td>
                }
              </tr>)
            }
          </tbody>
        </table>
      </div>
    </fieldset>
  )
}

export default FinishedGoodsSalesDetails