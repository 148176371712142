import React from 'react';
import YarnPoItem from './YarnPoItem';
import { toast } from 'react-toastify';
import { sumArray } from '../../../Utils/helper';

const YarnInwardItems = ({ inwardItems, setInwardItems, readOnly, removeItem, purchaseInwardId }) => {
    const handleInputChange = (value, index, field, balanceQty) => {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            newBlend[index][field] = value;
            if (field !== "qty" && newBlend[index]["noOfBags"] && newBlend[index]["weightPerBag"]) {
                let tempInwardQty = (parseFloat(newBlend[index]["noOfBags"]) * parseFloat(newBlend[index]["weightPerBag"])).toFixed(3)
                if (parseFloat(balanceQty) < parseFloat(tempInwardQty)) {
                    toast.info("Inward Qty Can not be more than balance Qty", { position: 'top-center' })
                    return inwardItems
                }
                newBlend[index]["qty"] = tempInwardQty
            }
            if (field === "qty") {
                if (parseFloat(balanceQty) < parseFloat(value)) {
                    toast.info("Inward Qty Can not be more than balance Qty", { position: 'top-center' })
                    return
                }
                let qty = parseInt(newBlend[index]["noOfBags"]) * parseFloat(newBlend[index]["weightPerBag"])
                let excessQty = parseInt(newBlend[index]["noOfBags"]) * 2
                if ((qty + excessQty) < parseFloat(value)) {
                    toast.info("Excess Qty Cannot be More than 2kg Per Bag", { position: 'top-center' })
                    return inwardItems
                }
            }
            return newBlend
        });
    };

    function handleInputChangeLotNo(value, index, lotIndex, field, balanceQty) {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]["lotDetails"]) return inwardItems
            newBlend[index]["lotDetails"][lotIndex][field] = value;
            let totalQtyExcludeCurrentIndex = sumArray(newBlend[index]["lotDetails"].filter((_, index) => index != lotIndex), "qty")
            if ((field === "weightPerBag" || field === "noOfBags") && newBlend[index]["lotDetails"][lotIndex]["noOfBags"] && newBlend[index]["lotDetails"][lotIndex]["weightPerBag"]) {
                let tempInwardQty = (parseFloat(newBlend[index]["lotDetails"][lotIndex]["noOfBags"]) * parseFloat(newBlend[index]["lotDetails"][lotIndex]["weightPerBag"])).toFixed(3)
                let currentOverAllQty = parseFloat(tempInwardQty) + parseFloat(totalQtyExcludeCurrentIndex)
                // if (parseFloat(balanceQty) < parseFloat(currentOverAllQty)) {
                //     toast.info("Inward Qty Can not be more than balance Qty", { position: 'top-center' })
                //     return inwardItems
                // }
                newBlend[index]["lotDetails"][lotIndex]["qty"] = tempInwardQty
            }
            if (field === "qty") {
                // let currentOverAllQty = parseFloat(value) + parseFloat(totalQtyExcludeCurrentIndex)
                // if (parseFloat(balanceQty) < parseFloat(currentOverAllQty)) {
                //     toast.info("Inward Qty Can not be more than balance Qty", { position: 'top-center' })
                //     return inwardItems
                // }
                let qty = parseInt(newBlend[index]["lotDetails"][lotIndex]["noOfBags"]) * parseFloat(newBlend[index]["lotDetails"][lotIndex]["weightPerBag"])
                let excessQty = parseInt(newBlend[index]["lotDetails"][lotIndex]["noOfBags"]) * 2
                if ((qty + excessQty) < parseFloat(value)) {
                    toast.info("Excess Qty Cannot be More than 2kg Per Bag", { position: 'top-center' })
                    return inwardItems
                }
            }
            return newBlend
        });
    }
    function addNewLotNo(index, weightPerBag) {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]) return inwardItems
            if (newBlend[index]["lotDetails"]) {
                newBlend[index]["lotDetails"] = [
                    ...newBlend[index]["lotDetails"],
                    { lotNo: "", qty: "0.000", noOfBags: 0, weightPerBag }]
            }else{
                newBlend[index]["lotDetails"] = [{ lotNo: "", qty: "0.000", noOfBags: 0, weightPerBag }]
            }
            return newBlend
        })
    }
    function removeLotNo(index, lotIndex) {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]["lotDetails"]) return inwardItems
            newBlend[index]["lotDetails"] = newBlend[index]["lotDetails"].filter((_, index) => index != lotIndex)
            return newBlend
        })
    }
    return (
        <>
            <div className={`w-full overflow-y-auto py-1`}>
                <table className=" text-xs border border-gray-500 table-auto w-full">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8'>
                            <th className="table-data  w-2 text-center">S.no</th>
                            <th className="table-data  w-10 text-center">P.no</th>
                            <th className="table-data w-28">Items</th>
                            <th className="table-data w-14">Colors</th>
                            <th className="table-data  w-14">UOM</th>
                            <th className="table-data w-10">Po. Qty</th>
                            <th className="table-data w-10">Po. Bags</th>
                            <th className="table-data  w-10"> Can. Qty</th>
                            <th className="table-data  w-10"> Can. Bags</th>
                            <th className="table-data  w-10"> A. In Qty</th>
                            <th className="table-data  w-10"> A. In Bags</th>
                            <th className="table-data  w-10"> A. Return Qty</th>
                            <th className="table-data  w-10"> A. Return Bags</th>
                            <th className="table-data  w-10">Bal. Qty</th>
                            <th className="table-data  w-10">Bal. Bags</th>
                            <th className="table-data  w-10">Lot Det.<span className="text-red-500">*</span></th>
                            <th className="table-data  w-10">Bag. cnt<span className="text-red-500">*</span></th>
                            {/* <th className="table-data  w-10">Wg./Bag<span className="text-red-500">*</span></th> */}
                            <th className="table-data  w-10">In. Qty</th>
                            <th className="table-data  w-10">Po Price</th>
                            <th className="table-data  w-10">Gross</th>
                            {!readOnly &&
                                <th className='table-data  w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) => <YarnPoItem removeLotNo={removeLotNo} addNewLotNo={addNewLotNo} handleInputChangeLotNo={handleInputChangeLotNo} item={item} purchaseInwardId={purchaseInwardId} removeItem={removeItem} readOnly={readOnly} key={item.poItemsId} index={index} handleInputChange={handleInputChange} />)}
                        {Array.from({ length: 7 - inwardItems.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                {Array.from({ length: 20 }).map(i =>
                                    <td className="table-data   "></td>
                                )}
                                {!readOnly &&
                                    <td className="table-data w-10"></td>
                                }
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default YarnInwardItems