import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import baseQuery from "../baseQuery";
import { OPERATION_BULLETIN } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const operationBulletIner = createApi({
    reducerPath: "operationBulletIner",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["OperationBulletIn"],
    endpoints: (builder) => ({
        getOperationBulletIn: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: OPERATION_BULLETIN + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: OPERATION_BULLETIN,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["OperationBulletIn"],
        }),
        getOperationBulletInById: builder.query({
            query: (id) => {
                return {
                    url: `${OPERATION_BULLETIN}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["OperationBulletIn"],
        }),
        addOperationBulletIn: builder.mutation({
            query: (payload) => ({
                url: OPERATION_BULLETIN,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["OperationBulletIn"],
        }),
        updateOperationBulletIn: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${OPERATION_BULLETIN}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["OperationBulletIn"],
        }),
        deleteOperationBulletIn: builder.mutation({
            query: (id) => ({
                url: `${OPERATION_BULLETIN}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["OperationBulletIn"],
        }),
    }),
});

export const {
    useGetOperationBulletInQuery,
    useGetOperationBulletInByIdQuery,
    useAddOperationBulletInMutation,
    useUpdateOperationBulletInMutation,
    useDeleteOperationBulletInMutation,

} = operationBulletIner;

export default operationBulletIner;
