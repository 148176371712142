

export default function RawMaterialFabric({rawmeterial}){

    return(
        <div>
            <table className="text-xs w-full  border border-gray-500">
            <thead className=' border border-gray-500 top-0'>
                        <tr className='h-8 '>
                            <th className="table-data w-16  text-center">S.no</th>
                            <th className="table-data w-16   text-center">Fabric/Design/Gsm/GG/LL/KDia/FDia</th>
                            <th className="table-data w-16 ">Counts</th>
                            <th className="table-data w-16 ">Color</th>
                            <th className="table-data w-16 ">Uom</th>
                            <th className="table-data w-16 ">Rolls</th>
                            <th className="table-data w-16 ">Iss Qty</th>   
                        </tr>
                    </thead>
                    <tbody>
                        {rawmeterial.map((value,index)=>(
                              <tr className="border border-gray-500" key={index}>
                              <td className="table-data text-left px-1 py-1 h-10 text-center">{index+1} </td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.fabricDGGLKF} </td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.counts}  </td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.color}  </td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.uom}  </td>
                              <td className="table-data text-left px-1 py-1 text-center">{value.rolls}  </td>
                              <td className="table-data text-left px-1 py-1 text-right px-1">{value.issqty}  </td>
       
                          </tr>
                        )
                             
                        )}
                        
                        <tr >
                            <th className="table-data text-left px-1 py-1 text-center" colSpan={5}>TOTAL </th><td className="text-center px-1 table-data">16</td><td className="text-right px-1">219.769</td>
                        </tr>
                       
                        
                        
                    </tbody>
                    
                
                    
            </table>
                  
        </div>
    )
}