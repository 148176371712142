import React from 'react'
import { substract } from '../../../Utils/helper'

const YarnRawMaterialItem = ({ item }) => {
    return (
        <table className='table-auto w-full text-xs border border-gray-500'>
            <thead>
                <tr className='bg-blue-200 border border-gray-500 sticky top-10 '>
                    <th className="table-data w-28">S.no</th>
                    <th className="table-data w-28">Yarn</th>
                    <th className="table-data w-14">Colors</th>
                    <th className="table-data  w-14">UOM</th>
                    <th className="table-data  w-14">Qty</th>
                    <th className="table-data  w-14">Already Used</th>
                    <th className="table-data  w-14">Bal. Qty</th>
                </tr>
            </thead>
            <tbody>
                {(item?.rawMaterials ? item?.rawMaterials : []).map(
                    (item, index) =>
                        <tr key={item.id} className={`table-row `}>
                            <td className='text-center   table-data'>{index + 1}</td>
                            <td className='text-left  table-data'>{item.Yarn.aliasName}</td>
                            <td className='text-left   table-data'>{item.Color.name}</td>
                            <td className='text-left   table-data'>{item.Uom.name}</td>
                            <td className='text-left   table-data'>{parseFloat(item?.qty ? item?.qty : 0).toFixed(3)}</td>
                            <td className='text-left   table-data'>{parseFloat(item?.alreadyUsedQty ? item?.alreadyUsedQty : 0).toFixed(3)}</td>
                            <td className='text-left   table-data'>{parseFloat(substract(item?.qty ? item?.qty : 0, item?.alreadyUsedQty ? item?.alreadyUsedQty : 0)).toFixed(3)}</td>
                        </tr>
                )}
            </tbody>
        </table>
    )
}

export default YarnRawMaterialItem
