import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';

export default function DataTable({
  setLineOperationDet,
  lineoperationDet,
  readOnly,
  deviceData,
  operationData,
}) {
  console.log(lineoperationDet, 'iot');

  const handleCheckboxChange = (index) => {
    setLineOperationDet(prev => {
      return prev.map((item, id) =>
        id === index
          ? { ...item, isActive: !item.isActive }
          : item
      );
    });
  };
  console.log(lineoperationDet, 'lineoperationDet');

  function isSelectAll() {
    return lineoperationDet.every(item => item.isActive);
  }

  const handleSelectAll = () => {
    if (isSelectAll()) {
      setLineOperationDet(prev => {
        return prev.map(item => ({
          ...item,
          isActive: false
        }));
      });
    } else {
      setLineOperationDet(prev => {
        return prev.map(item => ({
          ...item,
          isActive: true
        }));
      });
    }
  };

  const handleChange = (index, field, value) => {
    setLineOperationDet(prev => {
      return prev.map((item, id) =>
        id === index
          ? { ...item, [field]: value }
          : item
      );
    });
  };

  const onDelete = (index) => {
    setLineOperationDet(prev => prev.filter((id, i) => i !== index));
  };
  const selectedDeviceIds = lineoperationDet.map(item => parseInt(item.iOTDeviceId));
  return (
    <div>
      <fieldset className="frame border-gray-300 rounded-lg p-1">
        <legend className="sub-heading p-1">Operation List</legend>
        <div className="grid grid-cols-1 ">
          <table className="w-auto h-full">
            <thead>
              <tr className="font-semibold text-[14px] border border-gray-300">
                <td className="text-center  border border-gray-300 w-[5%]">S no</td>
                <td className="border border-gray-300 w-[10%]">
                  <div className="flex items-center justify-center ">
                    <input
                      type="checkbox"
                      checked={isSelectAll()}
                      onChange={handleSelectAll}
                      className="mr-2"
                    />
                    Active
                  </div>
                </td>
                <td className="text-center  border border-gray-300 w-[30%]">Device ID</td>
                <td className="text-center  border border-gray-300 w-[30%]">Operation Name</td>
                <td className="text-center  border border-gray-300 w-[5%]">Delete</td>
              </tr>
            </thead>
            <tbody className="border border-gray-300">
              {lineoperationDet?.map((field, index) => (
                <tr key={field.id} className="border border-gray-300 text-[12px]">
                  <td>{index + 1}</td>
                  <td className="border border-gray-300 p-1 text-center">
                    <input
                      type="checkbox"
                      checked={field.isActive || false}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={readOnly}
                    />
                  </td>
                  <td className='border border-gray-300'>
                    <select
                      className="input-field border border-gray-300 rounded text-black"
                      value={field.iOTDeviceId}
                      onChange={(e) => handleChange(index, 'iOTDeviceId', e.target.value)}
                      disabled={readOnly}
                    >
                      <option value="">Select</option>
                      {deviceData?.map((val, id) => (
                        <option key={val.id} value={val.id} disabled={selectedDeviceIds.includes(val.id)} className=''>
                          {console.log(val.id, 'val.id')}
                          {console.log(selectedDeviceIds, 'selected')}
                          {val.code}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className='border border-gray-300'>
                    <select
                      className="input-field border border-gray-300  rounded text-black"
                      value={field.operationNameId}
                      onChange={(e) => handleChange(index, 'operationNameId', e.target.value)}
                      disabled={readOnly}
                    >
                      <option value="">Select</option>
                      {operationData?.map((val, id) => (
                        <option key={val.id} value={val.id}>
                          {val.name}
                        </option>
                      ))}
                    </select>

                  </td>
                  <td onClick={() => onDelete(index)} className='text-center  border-gray-300'>
                    <FontAwesomeIcon icon={faTrashCan} />
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </fieldset>
    </div>
  );
}
