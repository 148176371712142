import React from 'react';
import { useGetYarnMasterQuery } from '../../../redux/ErpServices/YarnMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, substract } from '../../../Utils/helper';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';

const YarnFillGrid = ({ rawMaterials, setRawMaterials, readOnly, rawMaterialsFillGrid, onDone }) => {
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const addItem = (item) => {
        setRawMaterials(prev => {
            let newInwardDetails = structuredClone(prev);
            newInwardDetails.push({ rawMaterialsId: item.id, stockPrice: item.stockPrice, consumptionQty: 0, lossQty: 0 })
            return newInwardDetails
        })
    }
    const deleteItem = (id) => {
        setRawMaterials(prev => {
            return prev.filter(item => parseInt(item.rawMaterialsId) !== parseInt(id))
        })
    }

    const isItemSelected = (id) => {
        let foundIndex = rawMaterials.findIndex(item => parseInt(item.rawMaterialsId) === parseInt(id))
        return foundIndex !== -1
    }

    const handleChangeInwardRawMaterialDetails = (item) => {
        console.log(rawMaterialsFillGrid, "rawMaterialsFillGrid")
        if (isItemSelected(item.id)) {
            deleteItem(item.id)
        } else {
            addItem(item)
        }
    }


    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(rawMaterials);
        newBlend[index][field] = value;
        setRawMaterials(newBlend);
    };

    const { data: yarnList } =
        useGetYarnMasterQuery({ params });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { ...params, active: true } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });
    const { data: processList, isLoading: isProcessLoading, isFetching: isProcessFetching } = useGetProcessQuery({ params });

    function getTotals(field) {
        const total = rawMaterials.reduce((accumulator, current) => {
            return accumulator + parseFloat(current[field] ? current[field] : 0)
        }, 0)
        return parseFloat(total)
    }

    if (!yarnList || !colorList || !uomList || !rawMaterials || isProcessFetching || isProcessLoading) return <Loader />
    return (
        <>
            <div className={`relative w-full overflow-y-auto`}>
                <div className='flex justify-between mb-2'>
                    <h1 className='text-center mx-auto font-bold'>Raw Materials</h1>
                    <button className='text-center font-bold bg-blue-400 text-gray-100 p-1 rounded-lg' onClick={onDone}>DONE</button>
                </div>
                <table className=" text-xs table-fixed  w-full">
                    <thead className='bg-blue-200 top-0'>
                        <tr className=''>
                            <th className=' table-data  w-7'>
                            </th>
                            <th className=' table-data  w-7'>
                                S.no
                            </th>
                            <th className='table-data w-64'>
                                Yarn Name
                            </th>
                            <th className='table-data w-20'>
                                Color
                            </th>
                            <th className='table-data w-20'>
                                Uom
                            </th>
                            <th className='table-data  w-16'>
                                Lot No.
                            </th>
                            <th className="table-data  w-20">
                                Prev. Process
                            </th>
                            <th className="table-data  w-20">
                                Delivery qty
                            </th>
                            <th className="table-data  w-20">
                                A. Used qty
                            </th>
                            <th className="table-data  w-20">
                                Bal. qty
                            </th>
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {rawMaterialsFillGrid.map((item, index) => (
                            <tr key={index} className="w-full table-row" onClick={() => {
                                handleChangeInwardRawMaterialDetails(item)
                            }}>
                                <td className="table-data  w-2  py-1 text-center">
                                    <input type='checkbox' checked={isItemSelected(item.id)} />
                                </td>
                                <td className="table-data  w-2  py-1 text-center">
                                    {index + 1}
                                </td>
                                <td className='table-data '>
                                    {findFromList(item.yarnId, yarnList.data, "aliasName")}
                                </td>
                                <td className=' table-data '>
                                    {findFromList(item.colorId, colorList.data, "name")}
                                </td>
                                <td className=' table-data '>
                                    {findFromList(item.uomId, uomList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {item.lotNo}
                                </td>
                                <td className=' px-1 table-data  '>
                                    {findFromList(item.processId, processList.data, "name")}
                                </td>
                                <td className='table-data text-right'>
                                    {((!item.qty) ? 0 : item.qty).toFixed(3)}
                                </td>
                                <td className='table-data text-right'>
                                    {((!item.alreadyUsedQty) ? "0.000" : parseFloat(item.alreadyUsedQty).toFixed(3))}
                                </td>
                                <td className="table-data text-right">
                                    {substract(item.qty, item.alreadyUsedQty).toFixed(3)}
                                </td>
                            </tr>
                        ))}
                        {Array.from({ length: 5 - rawMaterialsFillGrid.length }).map(i =>
                            <tr className='w-full font-bold h-8 border-gray-400 border table-row'>
                                <td className='table-data'>
                                </td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                            </tr>)
                        }
                        {/* <tr className='bg-blue-200 w-full border border-gray-400 h-7 font-bold'>
                            <td className='table-data'>
                            </td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   ">Total</td>
                            <td className="table-data    text-right">{getTotals("consumptionQty").toFixed(3)}</td>
                            <td className="table-data    text-right">{getTotals("lossQty").toFixed(3)}</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default YarnFillGrid