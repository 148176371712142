import React from 'react'
import { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import FormHeader from "../FormHeader";
import FormReport from "../FormReportTemplate";
import { toast } from "react-toastify";
import { CheckBox, DropdownInput, TextArea } from "../../../Inputs";
import ReportTemplate from "../ReportTemplate";
import { useDispatch } from "react-redux";
import { poTypes, } from '../../../Utils/DropdownData';
import {
    useGetTermsAndConditionsQuery,
    useGetTermsAndConditionsByIdQuery,
    useAddTermsAndConditionsMutation,
    useUpdateTermsAndConditionsMutation,
    useDeleteTermsAndConditionsMutation,
} from "../../../redux/services/TermsAndConditionsService"

const MODEL = "Terms And Condition";


export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [active, setActive] = useState(true);
    const [isPurchaseOrder, setIsPurchaseOrder] = useState(true);
    const [itemType, setItemType] = useState("GreyYarn");
    const [description, setDescription] = useState("");


    const [searchValue, setSearchValue] = useState("");

    const childRecord = useRef(0);
    const dispatch = useDispatch();



    const userId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userId"
    )
    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };


    const { data: allData, isLoading, isFetching } = useGetTermsAndConditionsQuery({ params, searchParams: searchValue });


    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetTermsAndConditionsByIdQuery(id, { skip: !id });

    const [addData] = useAddTermsAndConditionsMutation();
    const [updateData] = useUpdateTermsAndConditionsMutation();
    const [removeData] = useDeleteTermsAndConditionsMutation();

    const data = {
        itemType, description, companyId, userId, active, id, isPurchaseOrder
    };

    const syncFormWithDb = useCallback((data) => {
        if (id) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
        setDescription(data?.description ? data?.description : "")
        setActive(id ? (data?.active ? data.active : false) : true);
        setIsPurchaseOrder(data?.isPurchaseOrder ? data.isPurchaseOrder : false)
        setItemType(data?.itemType ? data.itemType : "");
        childRecord.current = data?.childRecord ? data?.childRecord : 0;
    }, [id]);

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);


    const validateData = (data) => {
        if (data.itemType && data.description) {
            return true;
        }
        return false;
    };

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId("")
            syncFormWithDb(undefined)
            toast.success(text + "Successfully");


        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {

            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setReadOnly(false);
        setForm(true);
        setSearchValue("");
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }
    const tableHeaders = ["Item Type", "Description", "Status", "isPurchaseOrder"]
    const tableDataNames = ["dataObj.itemType", "dataObj.description", 'dataObj.active ? ACTIVE : INACTIVE', 'dataObj.isPurchaseOrder  ? ACTIVE : INACTIVE']
    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );
    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                    childRecord={childRecord.current}
                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 grid md:grid-cols-2 border overflow-auto">
                        <div className='col-span-3 grid md:grid-cols-2 border overflow-auto'>
                            <div className='mr-1 md:ml-2'>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Terms And Condition Info</legend>
                                    <div className='grid grid-cols-1 my-2'>
                                        <TextArea value={description} readOnly={readOnly} setValue={setDescription} name="Terms And Condition" rows="8" required={true} class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your Terms here..."></TextArea>
                                        <DropdownInput name="Po Type" options={poTypes} value={itemType} setValue={setItemType} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                                        <CheckBox name="isPurchaseOrder" readOnly={readOnly} value={isPurchaseOrder} setValue={setIsPurchaseOrder} />
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

