import React from 'react';
import BillEntryGridRowItem from './BillEntryGridRowItem';
import { sumArray } from '../../../Utils/helper';


const BillItems = ({ payOutItems, setPayOutItems, readOnly, advanceAdjustmentId }) => {
    const handleInputChange = (value, index, field) => {
        setPayOutItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            newBlend[index][field] = value;
            return newBlend
        });
    };
    function removeItem(billEntryId) {
        setPayOutItems(prevPayOutItems => {
            return prevPayOutItems.filter(item => parseInt(item.billEntryId) !== parseInt(billEntryId))
        })
    }
    return (
        <>
            <div className={`w-full overflow-y-auto py-1`}>
                <table className=" text-xs border border-gray-500 table-auto w-full">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8'>
                            <th className="table-data  w-2 text-center">S.no</th>
                            <th className="table-data  w-10 text-center">Doc.Id</th>
                            <th className="table-data w-28">Doc. Date</th>
                            <th className="table-data w-28">Party Bill No.</th>
                            <th className="table-data w-14">Item Type</th>
                            <th className="table-data  w-14">Supplier</th>
                            <th className="table-data w-10">Bill Amount</th>
                            <th className="table-data  w-10">A. Paid Amount</th>
                            <th className="table-data  w-10">Bal. Amount</th>
                            <th className="table-data  w-10">Paid Amount</th>
                            {!readOnly &&
                                <th className='table-data  w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {payOutItems.map((item, index) =>
                            <BillEntryGridRowItem item={item}
                                advanceAdjustmentId={advanceAdjustmentId} removeItem={removeItem} readOnly={readOnly} key={item.billEntryId}
                                index={index} handleInputChange={handleInputChange} />)}
                        {Array.from({ length: 7 - payOutItems.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                {Array.from({ length: 10 }).map(i =>
                                    <td className="table-data   "></td>
                                )}
                                {!readOnly &&
                                    <td className="table-data w-10"></td>
                                }
                            </tr>)
                        }
                        <tr className='table-row font-bold'>
                            <td className='text-center table-data' colSpan={9}>Total</td>
                            <td className='text-right'>{sumArray(payOutItems.filter(i => i.amount), "amount").toFixed(2)}</td>
                            {!readOnly &&
                                <td className='table-data w-12'>

                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default BillItems