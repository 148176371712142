import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetFinishedGoodsSalesQuery,
  useGetFinishedGoodsSalesByIdQuery,
  useAddFinishedGoodsSalesMutation,
  useUpdateFinishedGoodsSalesMutation,
  useDeleteFinishedGoodsSalesMutation,
} from "../../../redux/ErpServices/finishedGoodsSalesServices";
import { useGetPartyByIdQuery, useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DropdownInput, DateInput, DisabledInput, TextInput, CheckBox } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { findFromList, getCommonParams, getDateFromDateTime, isGridDatasValid } from "../../../Utils/helper";
import moment from "moment";
import { Loader } from "../../../Basic/components";

import {
  useGetLocationMasterQuery,
} from "../../../redux/ErpServices/LocationMasterServices";
import {
  useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import Modal from "../../../UiComponents/Modal";

import FinishedGoodsSalesDetailsFillGrid from "./FinishedGoodsSalesDetailsFillGrid";
import { finishedGoodsBillTypes, invoiceTypes, paymentModes, salesTypes } from "../../../Utils/DropdownData";
import FinishedGoodsSalesDetails from "./FinishedGoodsSalesDetails";
import Consolidation from "../Consolidation";
import FinishedGoodsReport from "./FinishedGoodsReport";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { useGetTaxTemplateQuery } from "../../../redux/ErpServices/TaxTemplateServices";
import Summary from "../RawMaterialSales/Summary";
import useTaxDetailsHook from "../../../CustomHooks/TaxHookDetails";
import FinishedGoodsSalesDetailsFromDelivery from "./FinishedGoodsSalesDetailsFromDelivery";
import FinishedGoodsSalesDetailsFillGridFromDelivery from "./FinishedGoodsSalesDetailsFillGridFromDelivery";
import PrintFormatWholeSale from "./PrintFormatWholeSale";
import PrintFormatRetail from "./PrintFormatRetail";

const MODEL = "FINISHED GOODS SALES";

export default function Form() {
  const dispatch = useDispatch()
  const today = new Date()
  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [docId, setDocId] = useState("");
  const [stockDetailsFillGrid, setStockDetailsFillGrid] = useState(false);
  const [finishedGoodsSalesDetails, setFinishedGoodsSalesDetails] = useState([])
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [isTaxBill, setIsTaxBill] = useState("");
  const [storeId, setStoreId] = useState("")
  const [print, setPrint] = useState(false);
  const [eWayBillNo, setEWayBillNo] = useState("");
  const [poNo, setPoNo] = useState("")

  const handlePrint = () => {
    setPrint(true)
  }
  const [supplierId, setSupplierId] = useState("");
  const [taxSummary, setTaxSummary] = useState(false);
  const [taxTemplateId, setTaxTemplateId] = useState("");

  const [delDate, setDelDate] = useState(getDateFromDateTime(today))

  const [remarks, setRemarks] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [vehicleNo, setVehicleNo] = useState("");
  const [discountType, setDiscountType] = useState("Percentage");
  const [discountValue, setDiscountValue] = useState(0);
  const [invoiceType, setInvoiceType] = useState("Credit")

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const childRecord = useRef(0);
  const [salesType, setSalesType] = useState("WHOLESALE");
  const [finishedGoodsBillType, setFinishedGoodsBillType] = useState("DIRECT");

  const { branchId, companyId, finYearId, userId } = getCommonParams()


  const params = {
    companyId
  };
  const { data: locationData, isLoading: isLocationLoading, isFetching: isLocationFetching } = useGetLocationMasterQuery({ params: { branchId }, searchParams: searchValue });
  const { data: branchList } = useGetBranchQuery({ params: { companyId } });

  const { data: allData, isLoading, isFetching } = useGetFinishedGoodsSalesQuery({ params: { branchId, isTaxBill, finYearId }, searchParams: searchValue });
  const { data: supplierList } =
    useGetPartyQuery({ params });

  const { data: taxTypeList } =
    useGetTaxTemplateQuery({ params: { ...params } });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetFinishedGoodsSalesByIdQuery({ id }, { skip: !id });

  const [addData] = useAddFinishedGoodsSalesMutation();
  const [updateData] = useUpdateFinishedGoodsSalesMutation();
  const [removeData] = useDeleteFinishedGoodsSalesMutation();

  const getNextDocId = useCallback(() => {
    if (id) return
    if (isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setDelDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    if (data?.docId) {
      setDocId(data.docId);
    }
    setSalesType(data?.salesType ? data?.salesType : "WHOLESALE")
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setLocationId(data?.storeId ? findFromList(data?.storeId, (locationData?.data ? locationData.data : []), "locationId") : "")
    setStoreId(data?.storeId ? data?.storeId : "");
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");
    setFinishedGoodsSalesDetails(data?.FinishedGoodsSalesDetails ? data?.FinishedGoodsSalesDetails : [])
    setSpecialInstructions(data?.specialInstructions ? data?.specialInstructions : "")
    setRemarks(data?.remarks ? data?.remarks : "")
    setCustomerName(data?.customerName ? data.customerName : "");
    setCustomerMobile(data?.customerMobile ? data.customerMobile : "");
    setFinishedGoodsBillType(data?.finishedGoodsBillType ? data.finishedGoodsBillType : "DIRECT");
    setIsTaxBill(data?.isTaxBill ? JSON.parse(data?.isTaxBill) : false)
    setTaxTemplateId(data?.taxTemplateId ? data.taxTemplateId : "")
    setInvoiceType(data?.invoiceType || "Credit")
    setDiscountType(data?.discountType ? data?.discountType : "Percentage");
    setDiscountValue(data?.discountValue ? data?.discountValue : "0");
    setEWayBillNo(data?.eWayBillNo || "");
    setPoNo(data?.poNo || "");
  }, [id, locationData]);

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);
  const { ...taxDetails } = useTaxDetailsHook({
    poItems: finishedGoodsSalesDetails.map(item => ({ ...item, taxPercent: item.tax })), taxTypeId: taxTemplateId,
    discountType: discountType, discountValue: discountValue
  })

  const data = {
    supplierId,
    branchId,
    storeId,
    salesType,
    vehicleNo,
    specialInstructions,
    remarks,
    isTaxBill,
    finishedGoodsSalesDetails,
    id, userId,
    customerName,
    customerMobile,
    finishedGoodsBillType,
    taxTemplateId,
    invoiceType,
    discountType,
    discountValue,
    netBillValue: taxDetails?.netAmount ? taxDetails?.netAmount : 0,
    finYearId,
    eWayBillNo,
    poNo
  }

  const isWholeSale = salesType === "WHOLESALE"
  const isDirect = finishedGoodsBillType === "DIRECT"

  const validateData = (data) => {
    return (isDirect ? (data.vehicleNo && data.storeId) : true)
      && data.finishedGoodsSalesDetails.length > 0
      && isGridDatasValid(data.finishedGoodsSalesDetails, false, ["qty", "price"])
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 0) {
        toast.success(text + "Successfully");
        onNew()
      } else {
        toast.error(returnData.message);
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });

    } catch (error) {
      console.log("handle", error);
    }
  };


  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
    getNextDocId();
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const getTotalIssuedQty = () => {
    const totalQty = finishedGoodsSalesDetails.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.qty ? currentValue?.qty : 0))
    }, 0)
    return totalQty
  }

  const { data: supplierDetails } =
    useGetPartyByIdQuery(supplierId, { skip: !supplierId });

  function isSupplierOutside() {
    console.log("supplierDetails", supplierDetails, supplierDetails?.data?.City?.state?.name !== "TAMIL NADU")
    if (supplierDetails) {
      return supplierDetails?.data?.City?.state?.name !== "TAMIL NADU"
    }
    return false
  }

  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLocationLoading || isLocationFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  if (isLocationFetching || isLocationLoading) return <Loader />
  const storeOptions = locationData ?
    locationData.data.filter(item => parseInt(item.locationId) === parseInt(locationId)) :
    [];
  return (
    <>
      <Modal isOpen={taxSummary} onClose={() => setTaxSummary(false)} widthClass={"p-10"}>
        <Summary
          discountType={discountType}
          setDiscountType={setDiscountType}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          poItems={finishedGoodsSalesDetails.map(item => ({ ...item, taxPercent: item.tax }))} taxTypeId={taxTemplateId} readOnly={readOnly} isSupplierOutside={isSupplierOutside()} />
      </Modal>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <FinishedGoodsReport
          heading={MODEL}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          allData={allData}
          tableWidth="100%"
          setForm={setForm}
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <Modal isOpen={stockDetailsFillGrid} onClose={() => { setStockDetailsFillGrid(false) }} widthClass={"bg-gray-300"}>
        {isDirect
          ?
          <FinishedGoodsSalesDetailsFillGrid
            id={id}
            storeId={storeId}
            setFillGrid={setStockDetailsFillGrid} productionDeliveryDetails={finishedGoodsSalesDetails}
            setProductionDeliveryDetails={setFinishedGoodsSalesDetails} />
          :
          <FinishedGoodsSalesDetailsFillGridFromDelivery
            id={id}
            setFillGrid={setStockDetailsFillGrid} productionDeliveryDetails={finishedGoodsSalesDetails}
            setProductionDeliveryDetails={setFinishedGoodsSalesDetails} supplierId={supplierId} />
        }
      </Modal>
      <Modal isOpen={print} onClose={() => { setPrint(false) }} widthClass={"bg-gray-300 w-[90%] h-[90%]"} >
        {singleData?.data?.salesType === "WHOLESALE" ?
          <PrintFormatWholeSale id={id} isSupplierOutside={isSupplierOutside()} />
          :
          <PrintFormatRetail id={id} isSupplierOutside={isSupplierOutside()} />
        }
      </Modal>
      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
      >
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}
            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            onPrint={id ? handlePrint : null}
            openReport={() => setFormReport(true)}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <div >
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                      <legend className='sub-heading'>Sales</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-4">
                          {id ?
                            <DisabledInput name={"Delivery No"} value={docId} />
                            :
                            <div className="flex w-full col-span">
                              <CheckBox name={""} value={isTaxBill} setValue={setIsTaxBill} readOnly={readOnly} />
                              <DisabledInput name={"Delivery No"} value={docId} />
                            </div>
                          }
                          <DateInput name="Del. Date" value={delDate} type={"date"} required={true} disabled />
                          <DropdownInput
                            name="Direct / Against Delivery"
                            options={finishedGoodsBillTypes}
                            value={finishedGoodsBillType}
                            setValue={setFinishedGoodsBillType}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          {isDirect
                            ?
                            <>
                              <DropdownInput
                                name="WHOLE SALE / RETAIL"
                                options={salesTypes}
                                value={salesType}
                                setValue={setSalesType}
                                required={true}
                                readOnly={id || readOnly}
                              />
                              {isWholeSale ?
                                <DropdownInput name="Supplier" options={supplierList ? (dropDownListObject(id ? supplierList.data : supplierList.data.filter(item => item.active), "aliasName", "id")) : []} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                                :
                                <>
                                  <TextInput name={"Customer name"} value={customerName} setValue={setCustomerName} readOnly={readOnly} />
                                  <TextInput name={"Mobile"} value={customerMobile} setValue={setCustomerMobile} readOnly={readOnly} />
                                </>
                              }
                              <DropdownInput name="Location"
                                options={branchList ? (dropDownListObject(id ? branchList.data : branchList.data.filter(item => item.active), "branchName", "id")) : []}
                                value={locationId}
                                setValue={(value) => { setLocationId(value); setStoreId("") }}
                                required={true} readOnly={id || readOnly} />
                              <DropdownInput name="Store"
                                options={dropDownListObject(id ? storeOptions : storeOptions.filter(item => item.active), "storeName", "id")}
                                value={storeId} setValue={setStoreId} required={true} readOnly={id || readOnly} />
                            </>
                            :
                            <>
                              <DropdownInput name="Supplier" options={supplierList ? (dropDownListObject(id ? supplierList.data : supplierList.data.filter(item => item.active), "aliasName", "id")) : []} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                            </>
                          }
                          <DropdownInput name="Tax Type" options={dropDownListObject(taxTypeList ? taxTypeList.data : [], "name", "id")} value={taxTemplateId} setValue={setTaxTemplateId} required={true} readOnly={readOnly} />
                          <DropdownInput name="Invoice Type" options={invoiceTypes}
                            value={invoiceType} setValue={setInvoiceType} required={true} readOnly={readOnly} />
                          <TextInput name={"E-Way Bill No"} value={eWayBillNo} setValue={setEWayBillNo} readOnly={readOnly} />
                          <TextInput name={"Purchase Order No"} value={poNo} setValue={setPoNo} readOnly={readOnly} />
                        </div>
                      </div>
                    </fieldset>
                    {
                      isDirect
                        ?
                        <FinishedGoodsSalesDetails isWholeSale={isWholeSale} id={id} readOnly={readOnly}
                          setStockDetailsFillGrid={setStockDetailsFillGrid}
                          productionDeliveryDetails={finishedGoodsSalesDetails} setProductionDeliveryDetails={setFinishedGoodsSalesDetails}
                          storeId={storeId} supplierId={supplierId} />
                        :
                        <FinishedGoodsSalesDetailsFromDelivery id={id} readOnly={readOnly} setStockDetailsFillGrid={setStockDetailsFillGrid}
                          productionDeliveryDetails={finishedGoodsSalesDetails} setProductionDeliveryDetails={setFinishedGoodsSalesDetails} supplierId={supplierId}
                        />
                    }
                    <button className="text-sm bg-sky-500 hover:text-white font-semibold hover:bg-sky-800 transition p-1 ml-5 rounded"
                      onClick={() => {
                        if (!taxTemplateId) {
                          toast.info("Please Select Tax Template !", { position: "top-center" })
                          return
                        }
                        setTaxSummary(true)
                      }}>
                      View Summary
                    </button>
                    <Consolidation vehicleNo={vehicleNo} setVehicleNo={setVehicleNo}
                      isRawMaterialSales
                      netAmount={taxDetails?.netAmount ? taxDetails?.netAmount : 0}
                      specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions}
                      remarks={remarks} setRemarks={setRemarks} totalQty={getTotalIssuedQty()} readOnly={readOnly} />
                    <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                      <FormReport
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setId={setId}
                        tableHeaders={tableHeaders}
                        tableDataNames={tableDataNames}
                        data={allData?.data ? allData?.data : []}
                        loading={
                          isLocationLoading || isLocationFetching
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}