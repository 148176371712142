import React from 'react'
import Address from './Address';
import FabricProgram from './FabricProgram';
import RawMaterialYarn from './RawMaterialYarn';
import { findFromList, getCommonParams } from '../../../Utils/helper'
import YarnProgram from './YarnProgram';
import FabricRawMaterial from './FabricRawMaterial';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import { useGetProcessDeliveryByIdQuery } from '../../../redux/ErpServices/ProcessDeliveryServices';
import { useGetPartyByIdQuery } from '../../../redux/ErpServices/PartyMasterServices';
import { useGetBranchByIdQuery } from '../../../redux/services/BranchMasterService';
import QRCode from 'react-qr-code';

export default function Form({ id, innerRef, processDocId, supplierId,
  delDate, processId, programDetails }) {

  const { companyId, branchId } = getCommonParams()

  const params = {
    companyId
  };

  const { data: partyData } = useGetPartyByIdQuery(supplierId)
  const supplierDetails = partyData?.data ? partyData?.data : {}
  const { data: branchData } = useGetBranchByIdQuery(branchId);
  const branchDetails = branchData?.data ? branchData.data : {}
  const { data: processList, isLoading: isProcessLoading, isFetching: isProcessFetching } = useGetProcessQuery({ params });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetProcessDeliveryByIdQuery({ id: id }, { skip: !id });


  const rawMaterialName = processId ? (findFromList(processId, processList.data, "io")).toString().split("_").at(0) : ""
  const processName = processId ? (findFromList(processId, processList.data, "io")).toString().split("_").at(1) : ""
  if (!singleData?.data || isSingleLoading || isSingleFetching || !processList?.data || isProcessLoading || isProcessFetching) return null
  return (
    <div className="border text-xs border-gray-600" ref={innerRef} >
      <div className=' '>
        <div className='text-center w-full'>
          <h1 className='font-bold text-xl'>PROCESS DELIVERY</h1>
        </div>
        <Address supplierDetails={supplierDetails} branchDetails={branchDetails} />
        <div className='grid grid-cols-4'>
          <div className='flex justify-around items-center col-span-3 border-b border-r border-gray-600'>
            <div> <span class="font-bold">DC NO :</span> {processDocId}</div>
            <div><span class="font-bold">DATE:</span> {delDate}</div>
            <div><span class="font-bold">PROCESS :</span> {findFromList(processId, processList.data, "name")}</div>
          </div>
          <div className='mr-2 p-1 flex items-center justify-center border-b border-gray-600'>
            <QRCode value={processDocId} size={60} />
          </div>
        </div>
        {processName?.includes("F")
          ?
          <FabricProgram programDetails={programDetails} />
          :
          <YarnProgram programDetails={programDetails} />
        }
        {rawMaterialName?.includes("F")
          ?
          <FabricRawMaterial programDetails={programDetails} />
          :
          <RawMaterialYarn programDetails={programDetails} />
        }
        <div className='flex font-bold text-sm  mt-2'>
          <h1>
            VECHICLE NO:
          </h1>
          <span className='font-normal ml-1'>
            {singleData?.data?.vehicleNo}
          </span>
        </div>
        <div className='flex font-bold mt-2 gap-3 border-b border-gray-600'>
          <h1>
            REMARKS:
          </h1>
          <span className='font-normal ml-1'>
            {singleData?.data?.remarks}
          </span>
        </div>
        <div className=''>
          <div className='text-right px-24 font-bold italic'>
            For {branchDetails?.branchName}
          </div>
          <div className=' flex justify-around p-2 font-bold mt-3 '>
            <h1>Prepared By</h1>
            <h1>Verified By</h1>
            <h1>Received By</h1>
            <h1>Approved By</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

