import * as React from 'react';
import { COPY_ICON, TICK_ICON } from '../../../icons';

export default function DataTable({
  data,
  setFormula,
  setOperationGroupDetails,
  operationGroupDetails,
  readOnly
}) {

  const [currentActiveFieldIndex, setCurrentActiveFieldIndex] = React.useState({})


  function handleCopyPaste(name) {
    if (currentActiveFieldIndex) {
      setOperationGroupDetails(prev => {
        let newPrev = structuredClone(prev);
        let index = prev.findIndex(i => parseInt(i.operationNameId) === parseInt(currentActiveFieldIndex.operationNameId))
        newPrev[index]["formula"] = `${newPrev[index]["formula"] || ''} ({${name}})`
        return newPrev
      })
    } else {
      setFormula(prev => `${prev} ({${name}})`)
    }
  }
  function addProcess(id) {

    setOperationGroupDetails(prev => [...prev, { operationNameId: id }])
  }
  function removeProcess(id) {

    setOperationGroupDetails(prev => prev.filter(i => parseInt(i.operationNameId) !== parseInt(id)))
  }


  function isAllSelected() {
    return data.every(i => isFieldSelected(parseInt(i.id)))
  }
  function selectAll() {
    if (isAllSelected()) {
      data.forEach(field => {
        removeProcess(field.id)
      });
    } else {
      data.forEach(field => {
        addProcess(field.id)
      });
    }
  }
  function isFieldSelected(id) {
    return operationGroupDetails.findIndex(i => parseInt(i.operationNameId) === parseInt(id)) !== -1
  }
  function handleChange(id) {
    if (isFieldSelected(id)) {
      removeProcess(id)
    } else {
      addProcess(id)
    }
  }
  function handleInputChange(operationNameId, field, value) {
    setOperationGroupDetails(prev => {
      let index = prev.findIndex(i => parseInt(i.operationNameId) === parseInt(operationNameId))
      let newItems = structuredClone(prev);
      newItems[index][field] = value;
      return newItems
    })

  }

  return (
    <div>
      <fieldset className='frame border-gray-400 p-1'>
        <legend className='sub-heading p-1'>Process List</legend>
        <div className='grid grid-cols-1'>
          <table className='w-auto h-full '>
            <thead className=''>
              <tr className='font-semibold text-[14px] border  border-gray-300'>
                <td className='border border-gray-300 '>
                  <div className='flex gap-2 items-center justify-center '>
                    Select
                    <span>
                      <input type="checkbox" name="" id="" checked={isAllSelected()} onChange={selectAll} />
                    </span>
                  </div>
                </td>
                <td className='text-center table-data  border border-gray-300'>Fields</td>

                <td className='text-center table-data w-[100px] border border-gray-300' >Sequence</td>


              </tr>
            </thead>
            <tbody className='border border-gray-300'>
              {data.map(field =>
                <tr key={field.id} className=' border  border-gray-300  text-[12px] '>
                  <td className=' border  border-gray-400 p-1 text-center '>
                    <input type="checkbox" name="" id="" checked={isFieldSelected(field.id)} onChange={() => { handleChange(field.id) }} />
                  </td>
                  <td>
                    {field.name}
                  </td>

                  <td className='text-right p-1 border border-gray-400 '>
                    {isFieldSelected(field.id) &&
                      <>
                        <input className='w-full' value={operationGroupDetails.find(i => i.operationNameId === field.id)?.sequence || ""}
                          onChange={(e) => { handleInputChange(field.id, "sequence", e.target.value) }}
                          disabled={readOnly}
                        />
                      </>
                    }
                  </td>


                </tr>
              )}
            </tbody>
          </table>
        </div>
      </fieldset>
    </div>
  );
}
