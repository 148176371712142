import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetCreditDebitQuery,
  useGetCreditDebitByIdQuery,
  useAddCreditDebitMutation,
  useUpdateCreditDebitMutation,
  useDeleteCreditDebitMutation,
} from "../../../redux/ErpServices/CreditDebitServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DisabledInput, DropdownInput, DateInput, TextInput, TextArea } from "../../../Inputs";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { creditDebitTypes } from '../../../Utils/DropdownData';

import moment from "moment";
import Modal from "../../../UiComponents/Modal";
import FormReport from "./Report";
import { getCommonParams } from "../../../Utils/helper";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "@etsoo/reactprint";
import PrintFormatCreditDebit from "../PrintFormat-CreditDebitNote";
import TaxTemplateDropdown from "../../ReusableComponents/TaxTemplateDropdown";
import useTaxDetailsHook from "../../../CustomHooks/TaxHookDetails";

const MODEL = "Credit Debit Note";


export default function Form() {
  const dispatch = useDispatch()

  const today = new Date()


  const [readOnly, setReadOnly] = useState(false);
  const [docId, setDocId] = useState("")
  const [id, setId] = useState("");
  const [date, setDate] = useState(moment.utc(today).format('YYYY-MM-DD'));
  const [type, setType] = useState("Credit");
  const [supplierId, setSupplierId] = useState("");
  const [narration, setNarration] = useState("");
  const [amount, setAmount] = useState("")
  const [taxTemplateId, setTaxTemplateId] = useState("");
  const [formReport, setFormReport] = useState(false);
  const [tax, setTax] = useState(0)

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: docId,
    pageStyle: ` `
  });

  const { branchId, companyId, finYearId, userId } = getCommonParams()

  const params = {
    branchId, companyId, finYearId, type
  };

  const { data: supplierList } =
    useGetPartyQuery({ params: { ...params } });

  const { data: allData, isLoading, isFetching } = useGetCreditDebitQuery({ params, searchParams: searchValue, finYearId });

  const poItems = [{ price: amount, taxPercent: tax, qty: 1 }]
  const taxDetails = useTaxDetailsHook({
    poItems, taxTypeId: taxTemplateId, isSupplierOutside: false,
    discountType: "Percentage", discountValue: 0
  });

  const getNextDocId = useCallback(() => {
    if (id || isFetching || isLoading) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetCreditDebitByIdQuery({ id }, { skip: !id });

  const [addData] = useAddCreditDebitMutation();
  const [updateData] = useUpdateCreditDebitMutation();
  const [removeData] = useDeleteCreditDebitMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    setNarration(data?.narration ? data.narration : "");
    setType(data?.type ? data?.type : "Credit")
    setTaxTemplateId(data?.taxTemplateId ? data?.taxTemplateId : "")
    setTax(data?.tax ? data?.tax : "")
    setAmount(data?.amount ? data.amount : "");
    setDate(data?.createdAt ? moment.utc(data.createdAt).format("YYYY-MM-DD") : moment.utc(new Date()).format("YYYY-MM-DD"));
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    if (data?.docId) {
      setDocId(data?.docId)
    }
  }, [id]);


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    supplierId,
    branchId, id, userId,
    narration,
    type,
    taxTemplateId,
    tax,
    amount,
    netAmount: taxDetails?.netAmount,
    finYearId
  }

  const validateData = (data) => {
    return data.type && data.supplierId
      && data.amount && data.narration && data.taxTemplateId
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      if (text === "Updated") {
        await callback(data).unwrap();
      } else {
        await callback(data).unwrap();
      }
      dispatch({
        type: `Ledger/invalidateTags`,
        payload: ['Ledger'],
      });
      dispatch({
        type: `billEntry/invalidateTags`,
        payload: ['BillEntry'],
      });
      setId("")
      syncFormWithDb(undefined)
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
        dispatch({
          type: `Ledger/invalidateTags`,
          payload: ['Ledger'],
        });
        dispatch({
          type: `billEntry/invalidateTags`,
          payload: ['BillEntry'],
        });
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined)
    getNextDocId()
  };


  const tableHeadings = ["PoNo", "PoDate", "transType", "DueDate", "Supplier"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
      <div className="hidden">
        <div ref={componentRef}>
          <PrintFormatCreditDebit id={id} />
        </div>
      </div>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <FormReport
          heading={MODEL}
          tableHeaders={tableHeadings}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          tableWidth="100%"
          data={allData?.data || []}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          onPrint={id ? handlePrint : null}
          openReport={() => { setFormReport(true) }}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid`}>
                  <div className={"flex flex-col"}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3 overflow-auto'>
                      <legend className='sub-heading'>Credit/Debit Info</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-5 w-full">
                          <DisabledInput name="Doc Id." value={docId}
                          />
                          <DateInput name="Doc. Date" value={date} type={"date"} readOnly={readOnly} disabled />
                          <DropdownInput name="Type"
                            options={creditDebitTypes}
                            value={type} setValue={(value) => { setType(value) }} required={true} readOnly={id} />
                          <TaxTemplateDropdown setTaxTemplateId={setTaxTemplateId} taxTemplateId={taxTemplateId} readOnly={readOnly} />
                          <DropdownInput name="Supplier"
                            options={dropDownListObject(supplierList?.data ? supplierList.data : [], "aliasName", "id")}
                            value={supplierId} setValue={(value) => { setSupplierId(value) }} required={true} readOnly={id} />
                          <TextInput
                            type={"number"}
                            name={"Amount"}
                            value={amount}
                            readOnly={readOnly}
                            required
                            setValue={(value) => {
                              if (value < 0) return
                              setAmount(value)
                            }}
                          />
                          <TextInput
                            type={"number"}
                            name={"Tax"}
                            value={tax}
                            required
                            readOnly={readOnly}
                            setValue={(value) => {
                              if (value < 0) return
                              setTax(value)
                            }}
                          />
                          <DisabledInput name={"Net Amount"} value={taxDetails?.netAmount ? taxDetails?.netAmount : 0} />
                          <TextArea
                            readOnly={readOnly}
                            type={"text"}
                            name={"Narration"}
                            value={narration}
                            required
                            setValue={setNarration}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}