import React, { useEffect, useState, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetStyleMasterQuery,
    useGetStyleMasterByIdQuery,
    useAddStyleMasterMutation,
    useUpdateStyleMasterMutation,
    useDeleteStyleMasterMutation,
} from "../../../redux/ErpServices/StyleMasterServices";
import { useGetFabricMasterQuery } from "../../../redux/ErpServices/FabricMasterServices"
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { TextInput, CheckBox, MultiSelectDropdown, DropdownInput } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { dropDownListObject, multiSelectOption } from "../../../Utils/contructObject";
import BrowseSingleImage from "../../../Basic/components/BrowseSingleImage";
import { useGetSizeTemplateQuery } from "../../../redux/ErpServices/SizeTemplateMasterServices";
import { useGetColorMasterQuery } from "../../../redux/ErpServices/ColorMasterServices";
import { Loader } from "../../../Basic/components";
import { findFromList, viewBase64String } from "../../../Utils/helper";
import Portion from "./Portion";
import { useGetCategoryMasterQuery } from "../../../redux/ErpServices/categoryMasterServices";
import { useGetSeasonMasterQuery } from "../../../redux/ErpServices/SeasonMasterService";
import { useGetPartyCategoryMasterQuery } from "../../../redux/ErpServices/PartyCategoryMasterServices";


const MODEL = "Style Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [image, setImage] = useState("")
    const [name, setName] = useState("");
    const [styleRefNo, setStyleRefNo] = useState("");
    const [buyerName, setBuyerName] = useState("");
    const [seoTitle, setSeoTitle] = useState("");
    const [sleeve, setSleeve] = useState("");
    const [pattern, setPattern] = useState("");
    const [occassion, setOccassion] = useState("");
    const [material, setMaterial] = useState("");
    const [washCare, setWashCare] = useState("");
    const [active, setActive] = useState(true);
    const [categoryId, setCategoryId] = useState("");
    const [seasonId, setSeasonId] = useState("");
    const [sizeTemplateId, setSizeTemplateId] = useState("")
    const [selectedColorsList, setSelectedColorsList] = useState([])
    const [portionDetails, setPortionDetails] = useState([]);
    const [hsn, setHsn] = useState("")

    const [searchValue, setSearchValue] = useState("");


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: categoryList } =
        useGetCategoryMasterQuery({ params: { ...params, active: true } });
    const { data: seasonList } =
        useGetSeasonMasterQuery({ params: { ...params, active: true } });
    console.log(categoryList, 'categoryList');
    const { data: buyerData } =
        useGetPartyCategoryMasterQuery({ params: { ...params, active: true } });
    const { data: allData, isLoading, isFetching } = useGetStyleMasterQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetStyleMasterByIdQuery(id, { skip: !id });

    const [addData] = useAddStyleMasterMutation();
    const [updateData] = useUpdateStyleMasterMutation();
    const [removeData] = useDeleteStyleMasterMutation();

    const syncFormWithDb = useCallback(
        (data) => {
            if (id) setReadOnly(true);
            setImage(data?.imageBase64 ? viewBase64String(data.imageBase64) : "");
            setStyleRefNo(data?.styleRefNo ? data.styleRefNo : "");
            setHsn(data?.hsn ? data.hsn : "");
            setBuyerName(data?.
                partyCategoryId ? data.partyCategoryId : "");
            setName(data?.name ? data.name : "");
            setSeoTitle(data?.seoTitle ? data.seoTitle : "");
            setSleeve(data?.sleeve ? data.sleeve : "");
            setCategoryId(data?.categoryId ? data.categoryId : "");
            setSeasonId(data?.seasonId ? data.seasonId : "");
            setPattern(data?.pattern ? data.pattern : "");
            setOccassion(data?.occassion ? data.occassion : "");
            setMaterial(data?.material ? data.material : "");
            setWashCare(data?.washCare ? data.washCare : "");
            setActive(id ? (data?.active ? data.active : false) : true);
            setSizeTemplateId(data?.sizeTemplateId ? data.sizeTemplateId : "")
            setSelectedColorsList(data?.StyleOnColor ? data.StyleOnColor.map(item => {
                return { value: item.colorId, label: item.Color.name }
            }) : [])
            setPortionDetails(data?.portionDetails ? data.portionDetails : [])
        },
        [id]
    );
    console.log(buyerName, 'buyer');
    console.log(styleRefNo, 'styleRefNo');

    const { data: sizeTemplateList } = useGetSizeTemplateQuery({ params, searchParams: searchValue });
    const { data: colorList } =
        useGetColorMasterQuery({ params });
    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, styleRefNo, buyerName, name, seoTitle, sleeve, categoryId, sizeTemplateId,
        pattern, occassion, material, active, washCare, hsn,
        companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId"),
        seasonId,
    }

    const validateData = (data) => {
        if (data.name) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            const formData = new FormData()
            for (let key in data) {
                formData.append(key, data[key]);
            }
            formData.append("selectedColorsList", JSON.stringify(selectedColorsList.map(item => item.value)))
            formData.append("portionDetails", JSON.stringify(portionDetails))
            if (image instanceof File) {
                formData.append("image", image);
            } else if (!image) {
                formData.append("isDeleteImage", true);
            }
            let returnData;
            if (text === "Updated") {
                returnData = await callback({ id, body: formData }).unwrap();
            } else {
                returnData = await callback(formData).unwrap();
            }
            setId("")
            syncFormWithDb(undefined)
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                // toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }

    const tableHeaders = [
        "Code", "Name", "Status"
    ]
    const tableDataNames = ["dataObj.styleRefNo", "dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data ? allData?.data : []}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                    childRecord={0}
                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip w-full">
                    <div className="col-span-3 border overflow-auto">
                        <div className='col-span-3'>
                            <div className='mr-1 md:ml-2 flex gap-2 min-h-80'>
                                <fieldset className='frame my-1 w-72'>
                                    <legend className='sub-heading'>Upload Image</legend>
                                    <BrowseSingleImage picture={image} setPicture={setImage} readOnly={readOnly} />
                                </fieldset>
                                <fieldset className='frame my-1 w-full flex flex-col gap-2'>
                                    <legend className='sub-heading'>Style Info</legend>
                                    <div className="grid grid-cols-3">
                                        <TextInput name=" Style code" type="text" value={styleRefNo} setValue={setStyleRefNo} required={true} readOnly={readOnly} />
                                        <TextInput name="Style Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} />


                                        {/* <DropdownInput name="Size Template" options={sizeTemplateList ? dropDownListObject(id ? sizeTemplateList.data : sizeTemplateList.data.filter(item => item.active), "name", "id") : []}
                                            value={sizeTemplateId} setValue={setSizeTemplateId} readOnly={readOnly} /> */}
                                        {/* <DropdownInput name="Size Template" options={sizeTemplateList ? dropDownListObject(id ? sizeTemplateList.data : sizeTemplateList.data.filter(item => item.active), "name", "id") : []}
                                            value={sizeTemplateId} setValue={setSizeTemplateId} readOnly={readOnly} /> */}
                                        <DropdownInput name="Product Category" options={dropDownListObject((categoryList?.data ? categoryList?.data : []), "name", "id")} value={categoryId} setValue={(value) => { setCategoryId(value); }} readOnly={readOnly} required={true} />
                                        <DropdownInput name="Buyer" options={dropDownListObject((buyerData?.data ? buyerData?.data : []), "name", "id")} value={buyerName} setValue={(value) => { setBuyerName(value); }} readOnly={readOnly} required={true} />

                                        <DropdownInput name="Season" options={dropDownListObject((seasonList?.data ? seasonList?.data : []), "name", "id")} value={seasonId} setValue={(value) => { setSeasonId(value); }} readOnly={readOnly} required={true} />
                                        {/* < MultiSelectDropdown name={"Colors"}
                                            setSelected={setSelectedColorsList}
                                            selected={selectedColorsList}
                                            options={(colorList?.data) ? multiSelectOption(id ? colorList.data : colorList.data.filter(item => item.active), "name", "id") : []} readOnly={readOnly} className="" /> */}
                                        <CheckBox name="Active" value={active} setValue={setActive} />
                                        {/* <div className="p-1">
                                            <label htmlFor="" className="text-[12px] ">Portions </label>
                                            <Portion readonly={readOnly} portionDetails={portionDetails} setPortionDetails={setPortionDetails} />  </div> */}

                                    </div>

                                    <div className="grid grid-cols-2">

                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        {/* <div className='mr-1 md:ml-2 col-span-3 overflow-auto'>
                            <fieldset className='frame h-60 w-full flex flex-col gap-2'>
                                <legend className='sub-heading'>Description</legend>
                                <div className="w-[60%]">   <DropdownInput name="Fabric" options={dropDownListObject((categoryList?.data ? categoryList?.data : []), "aliasName", "id")} value={categoryId} setValue={(value) => { setCategoryId(value); }} readOnly={readOnly} required={true} /></div>
                                <div className="grid grid-cols-3">
                                    <TextInput name="Sleeve" type="text" value={sleeve} setValue={setSleeve} required={true} readOnly={readOnly} />
                                    <TextInput name="Pattern" type="text" value={pattern} setValue={setPattern} required={true} readOnly={readOnly} />

                                    <TextInput name="Occassion" type="text" value={occassion} setValue={setOccassion} required={true} readOnly={readOnly} />
                                    <TextInput name="Material" type="text" value={material} setValue={setMaterial} required={true} readOnly={readOnly} />

                                    <TextInput name="Wash Care" type="text" value={washCare} setValue={setWashCare} required={true} readOnly={readOnly} />
                                    <TextInput name="Hsn" type="text" value={hsn} setValue={setHsn} required={true} readOnly={readOnly} />
                                </div>
                            </fieldset>
                        </div> */}
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data ? allData?.data : []}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
