import React, { useEffect } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import ProcessDropdown from '../../ReusableComponents/ProcessDropdown';
import { priceWithTax } from '../../../Utils/helper';

const JobWorkGridDetails = ({ id, gridItems, setGridItems, readOnly, params }) => {
    const handleInputChange = (value, index, field) => {
        setGridItems(prev => {
            const newBlend = structuredClone(prev);
            newBlend[index][field] = value;
            return newBlend
        });
    };

    const newItem = {
        processId: "", qty: "0.000", taxPercent: "0.000", uomId: "", price: "", discountType: "Percentage", discountValue: "0.00"
    }

    useEffect(() => {
        if (gridItems.length >= 3) return
        setGridItems(prev => {
            let newArray = Array.from({ length: 3 - prev.length }, i => {
                return {
                    processId: "", qty: "0.000", taxPercent: "0.000", uomId: "", price: "", discountType: "Percentage", discountValue: "0.00"
                }
            })
            return [...prev, ...newArray]
        }
        )
    }, [setGridItems, gridItems])


    const addRow = () => {
        setGridItems([...gridItems, newItem]);
    };
    const handleDeleteRow = id => {
        setGridItems(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
    };


    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });



    function getTotals(field) {
        const total = gridItems.reduce((accumulator, current) => {
            return accumulator + parseFloat(current[field] ? current[field] : 0)
        }, 0)
        return parseFloat(total)
    }

    function getGross() {
        const total = gridItems.reduce((accumulator, current) => {
            return accumulator + (current.qty * priceWithTax(current.price, current.tax))
        }, 0)
        return parseFloat(total)
    }

    return (
        <fieldset className={` relative w-full overflow-y-auto py-1 frame rounded-tr-lg rounded-bl-lg rounded-br-lg border border-gray-600 p-1 overflow-auto`}>
            <legend className='sub-heading'>Job Work Details</legend>
            <table className=" border border-gray-500 text-xs table-auto  w-full">
                <thead className='bg-blue-200 top-0 border-b border-gray-500'>
                    <tr >
                        <th className="table-data  w-2 text-center">S.no</th>
                        <th className="table-data ">Process<span className="text-red-500">*</span></th>
                        <th className="table-data  w-16">Quantity<span className="text-red-500">*</span></th>
                        <th className="table-data  w-20">UOM<span className="text-red-500">*</span></th>
                        <th className="table-data  w-16">Price<span className="text-red-500">*</span></th>
                        <th className="table-data  w-16">Tax<span className="text-red-500">*</span></th>
                        <th className="table-data  w-16">Price(with Tax)<span className="text-red-500">*</span></th>
                        <th className="table-data  w-16">Gross</th>
                        {readOnly ?
                            "" :
                            <th className='w-20  bg-green-600 text-white'>
                                <div onClick={addRow}
                                    className='hover:cursor-pointer w-full h-full flex items-center justify-center'>
                                    {PLUS}
                                </div>
                            </th>
                        }
                    </tr>
                </thead>
                <tbody className='overflow-y-auto h-full w-full'>
                    {gridItems.map((row, index) => (
                        <tr key={index} className="w-full table-row">
                            <td className="table-data w-2 text-left px-1 py-1">
                                {index + 1}
                            </td>
                            <td className='table-data w-12'>
                                <ProcessDropdown selected={row.processId} setSelected={(value) => handleInputChange(value, index, "processId")} />
                            </td>
                            <td className='table-data'>
                                <input
                                    onKeyDown={e => {
                                        if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                        if (e.key === "Delete") { handleInputChange("0.000", index, "qty") }
                                    }}
                                    min={"0"}
                                    type="number"
                                    className="text-right rounded py-1 px-1 w-full table-data-input"
                                    onFocus={(e) => e.target.select()}
                                    value={(!row.qty) ? 0 : row.qty}
                                    disabled={readOnly}
                                    onChange={(e) =>
                                        handleInputChange(e.target.value, index, "qty")
                                    }
                                    onBlur={(e) => {
                                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty");
                                    }
                                    }
                                />
                            </td>
                            <td className='table-data'>
                                <select
                                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "uomId") } }}
                                    disabled={readOnly || Boolean(row?.alreadyInwardedData?._sum?.qty) || Boolean(row?.alreadyCancelData?._sum?.qty)} className='text-left w-full rounded py-1 table-data-input' value={row.uomId} onChange={(e) => handleInputChange(e.target.value, index, "uomId")}
                                    onBlur={(e) => {
                                        handleInputChange((e.target.value), index, "uomId")
                                    }
                                    }
                                >
                                    <option hidden>
                                    </option>
                                    {((uomList?.data ? uomList?.data : []).filter(item => id ? true : (item.active))).map((blend) =>
                                        <option value={blend.id} key={blend.id}>
                                            {blend.name}
                                        </option>
                                    )}
                                </select>
                            </td>
                            <td className='table-data '>
                                <input
                                    onKeyDown={e => {
                                        if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                        if (e.key === "Delete") { handleInputChange("0", index, "price") }
                                    }}
                                    min={"0"}
                                    type="number"
                                    onFocus={(e) => e.target.select()}
                                    className="text-right rounded py-1 w-full px-1 table-data-input"
                                    value={(!row.price) ? 0 : row.price}
                                    disabled={readOnly}
                                    inputMode='decimal'
                                    onChange={(e) =>
                                        handleInputChange(e.target.value, index, "price")
                                    }
                                    onBlur={(e) => {

                                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "price")

                                    }
                                    }
                                />
                            </td>
                            <td className='table-data'>
                                <input
                                    onKeyDown={e => {
                                        if (e.code === "Minus" || e.code === "NumpadSubtract") e.preventDefault()
                                        if (e.key === "Delete") { handleInputChange("0.00", index, "tax") }
                                    }}
                                    min={"0"}
                                    type="number"
                                    className="text-right rounded py-1 w-full px-1 table-data-input"
                                    onFocus={(e) => e.target.select()}
                                    value={(!row.tax) ? 0 : row.tax}
                                    disabled={readOnly}
                                    onChange={(e) =>
                                        handleInputChange(e.target.value, index, "tax")
                                    }
                                    onBlur={(e) => {
                                        handleInputChange(parseFloat(e.target.value).toFixed(2), index, "tax");

                                    }
                                    }
                                />
                            </td>
                            <td className='table-data text-right px-1'>
                                {priceWithTax(row.price, row.tax).toFixed(2)}
                            </td>
                            <td className='table-data text-right'>
                                {(!row.qty || !row.price) ? 0 : (parseFloat(row.qty) * parseFloat(priceWithTax(row.price, row.tax).toFixed(2))).toFixed(2)}
                            </td>
                            {readOnly
                                ?
                                ""
                                :
                                <td className='table-data w-20'>
                                    <div tabIndex={-1} onClick={() => handleDeleteRow(index)}
                                        className='flex justify-center px-2 py-1.5 items-center cursor-pointer'>
                                        {DELETE}
                                    </div>
                                </td>
                            }
                        </tr>
                    ))}
                    <tr className='bg-blue-200 w-full border border-gray-400 h-7 font-bold'>
                        <td className="table-data text-center w-10 font-bold" colSpan={2}>Total</td>
                        <td className="table-data text-right px-1 w-10">{getTotals("qty").toFixed(3)}</td>
                        <td className="table-data text-center w-10 font-bold" colSpan={4}></td>
                        <td className="table-data text-right px-1 w-10">{getGross().toFixed(3)}</td>
                        {!(readOnly)
                            &&
                            <td className="table-data w-10"></td>
                        }
                    </tr>

                </tbody>
            </table>
        </fieldset>
    )
}

export default JobWorkGridDetails