import React from 'react'
import tw from '../../../Utils/tailwind-react-pdf'
import { View, Text } from '@react-pdf/renderer';

const PageNumberFooter = () => {
    return (
        <View style={tw("text-center w-full text-xs pt-4")}>
            <Text render={({ subPageNumber, subPageTotalPages }) => (
                `Page ${subPageNumber} / ${subPageTotalPages}`
            )} fixed />
        </View>
    )
}

export default PageNumberFooter