import React, { useEffect } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { useGetYarnMasterQuery } from '../../../redux/ErpServices/YarnMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import { VIEW } from '../../../icons';
import secureLocalStorage from 'react-secure-storage';
import { toast } from "react-toastify"


const Yarn = ({ id, programDetails, setProgramDetails, readOnly, setCurrentProgramIndex, finishedGoodsType, currentProgramIndex }) => {
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(programDetails);
        newBlend[index][field] = value;
        if (field !== "qty") {
            newBlend[index]["qty"] = (parseFloat(newBlend[index]["noOfBags"]) * parseFloat(newBlend[index]["weightPerBag"])).toFixed(3)
        }
        setProgramDetails(newBlend);
    };

    useEffect(() => {
        if (id || programDetails.length !== 0) return
        setProgramDetails(Array.from({ length: 1 }, i => {
            return { yarnId: "", qty: "0.000", inwardQty: "0.000", colorId: "", uomId: "", price: "0.00", discountType: "Percentage", discountValue: "0.00", noOfBags: "0", weightPerBag: "0.000", rawMaterials: [] }
        }))
    }, [setProgramDetails, programDetails])


    const addRow = () => {
        const newRow = { yarnId: "", qty: "", inwardQty: "", colorId: "", uomId: "", price: "", discountType: "Percentage", discountValue: "0.00", rawMaterials: [] };
        setProgramDetails([...programDetails, newRow]);
    };
    const handleDeleteRow = id => {
        setProgramDetails(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
    };

    const { data: yarnList } =
        useGetYarnMasterQuery({ params });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });

    function getTotals(field) {
        const total = programDetails.reduce((accumulator, current) => {
            return accumulator + parseFloat(current[field] ? current[field] : 0)
        }, 0)
        return total
    }

    if (!yarnList || !colorList || !uomList) return <Loader />
    return (
        <>
            <div className={` relative w-full overflow-y-auto py-1`}>
                <table className=" border border-gray-500 text-xs table-auto  w-full">
                    <thead className='bg-blue-200 top-0 border-b border-gray-500'>
                        <tr >
                            <th className="table-data w-2 text-center">S.no</th>
                            <th className="table-data w-96">Yarn<span className="text-red-500">*</span></th>
                            <th className="table-data w-16">Colors</th>
                            <th className="table-data w-16">UOM<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Process Cost per Kg<span className="text-red-500">*</span></th>
                            <th className="table-data w-5">Weight Per Bag<span className="text-red-500">*</span></th>
                            <th className="table-data w-5">No. of Bags<span className="text-red-500">*</span></th>
                            <th className="table-data w-5">Quantity<span className="text-red-500">*</span></th>
                            <th className="table-data w-5">Delivery Details</th>
                            {!readOnly
                                &&
                                <th className='bg-green-600 text-white w-2'>
                                    <div onClick={addRow}
                                        className='hover:cursor-pointer w-full h-full flex items-center justify-center'>
                                        {PLUS}
                                    </div>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto h-full w-full'>
                        {programDetails.map((row, index) => (
                            <tr key={index} className={`w-full ${currentProgramIndex === index ? "border-2 border-black" : "table-row"}`}>
                                <td className="table-data w-2 text-center py-1">
                                    {index + 1}
                                </td>
                                <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "yarnId") } }}
                                        tabIndex={"0"} disabled={readOnly} className='text-left w-full rounded py-1 table-data-input' value={row.yarnId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "yarnId")}
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? yarnList.data : yarnList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.aliasName}
                                            </option>)}
                                    </select>
                                </td>
                                {finishedGoodsType.includes("D") && <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "colorId") } }}
                                        disabled={readOnly} className='text-left w-full rounded py-1 table-data-input' value={row.colorId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "colorId")}
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? colorList.data : colorList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>}
                                <td className='table-data'>
                                    <select
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("", index, "uomId") } }}
                                        disabled={readOnly} className='text-left w-full rounded py-1 table-data-input' value={row.uomId}
                                        onChange={(e) => handleInputChange(e.target.value, index, "uomId")}
                                        onBlur={(e) => {
                                            handleInputChange((e.target.value), index, "uomId")
                                        }
                                        }
                                    >
                                        <option hidden>
                                        </option>
                                        {(id ? uomList.data : uomList.data.filter(item => item.active)).map((blend) =>
                                            <option value={blend.id} key={blend.id}>
                                                {blend.name}
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td className='table-data'>
                                    <input
                                        min={"0"}
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "processCost") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 px-1 w-full table-data-input"
                                        value={(!row.processCost) ? "0.00" : row.processCost}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "processCost")
                                        }
                                        onBlur={(e) => {

                                            handleInputChange(parseFloat(e.target.value).toFixed(2), index, "processCost")
                                            
                                        }
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        min={"0"}
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "weightPerBag") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right w-full rounded py-1 px-1 table-data-input"
                                        value={(!row.weightPerBag) ? 0 : row.weightPerBag}
                                        disabled={readOnly}
                                        inputMode='decimal'
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "weightPerBag")
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "weightPerBag")
                                            
                                        }
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        min={"0"}
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0", index, "noOfBags") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right w-full rounded py-1 px-1 table-data-input"
                                        value={(!row.noOfBags) ? 0 : row.noOfBags}
                                        disabled={readOnly}
                                        inputMode='decimal'
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "noOfBags")
                                        }
                                        onBlur={(e) => {
                                            handleInputChange(parseFloat(e.target.value), index, "noOfBags")
                                            
                                        }
                                        }
                                    />
                                </td>
                               
                                <td className='table-data'>
                                    <input

                                        min={"0"}
                                        type="number"
                                        onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "qty") } }}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded w-full py-1 px-1 table-data-input"
                                        value={(!row.qty) ? 0 : row.qty}
                                        disabled={readOnly}
                                        onChange={(e) =>
                                            handleInputChange(e.target.value, index, "qty")
                                        }
                                        onBlur={(e) => {

                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "qty")
                                            
                                        }
                                        }
                                    />
                                </td>
                                <td className='table-data flex justify-center items-center w-full h-full'
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            setCurrentProgramIndex(index);
                                        }
                                    }}
                                    onClick={() => { setCurrentProgramIndex(index) }}
                                >
                                    {VIEW}
                                </td>
                                {!readOnly
                                    &&
                                    <td className='table-data'>
                                        <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer'>
                                            {DELETE}
                                        </div>
                                    </td>
                                }
                            </tr>
                        ))}
                        {Array.from({ length: 4 - programDetails.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                <td className='table-data'>
                                </td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                {!readOnly
                                    &&
                                    <td className="table-data   w-10"></td>
                                }
                            </tr>)
                        }
                        <tr className='bg-blue-200 w-full font-bold'>
                            <td className="table-data text-center" colSpan={5}>Total</td>
                            <td className="table-data   w-10"></td>
                            <td className="table-data text-right px-1 w-10">{getTotals("noOfBags")}</td>
                            <td className="table-data text-right px-1  w-10">{getTotals("qty").toFixed(3)}</td>
                            <td className="table-data   w-10"></td>
                            {!readOnly
                                &&
                                <td className="table-data   w-10"></td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Yarn