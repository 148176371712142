import AccessoryPoItem from './AccessoryPoItem';
import AccessoryDirectItem from './AccessoryDirecttem ';

const AccessoryInwardItems = ({ inwardItems, setInwardItems, readOnly, removeItem, billEntryId }) => {
    const handleInputChange = (value, index, field) => {
        setInwardItems(inwardItems => {
            if(!inwardItems[index]) return inwardItems
            const newBlend = structuredClone(inwardItems);
            newBlend[index][field] = value;
            return newBlend
        });
    };
    return (
        <>
            <div className={`relative w-full overflow-auto py-1`}>
                <table className="border border-gray-500 text-xs table-auto w-full  ">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8 '>
                            <th className="table-data w-5  text-center">S.no</th>
                            <th className="table-data  w-16 text-center">Po.no</th>
                            <th className="table-data  ">Accessory Name</th>
                            <th className="table-data  ">Accessory Items</th>
                            <th className="table-data  ">Accessory Group</th>
                            <th className="table-data  ">Colors</th>
                            <th className="table-data  ">Size</th>
                            <th className="table-data   ">UOM</th>
                            <th className="table-data w-10">Po. Qty</th>
                            <th className="table-data w-10">Grn.Qty</th>
                            <th className="table-data  w-10"> Rtn.Qty</th>
                            <th className="table-data  w-10"> Can.Qty</th>
                            <th className="table-data  w-10">Po.Rate</th>
                            <th className="table-data  w-10"> A.Bill.Qty</th>
                            <th className="table-data  w-10"> Bal.Bill. Qty</th>
                            <th className="table-data  w-10">Bill.Qty</th>
                            <th className="table-data  w-10">Bill.Rate</th>
                            <th className="table-data  w-10">Bill.Amount</th>
                            <th className="table-data  w-10">Disc.Type</th>
                            <th className="table-data  w-10">Disc.Value</th>
                            <th className="table-data  w-10">Amount</th>
                            <th className="table-data  w-10">Tax</th>
                            <th className="table-data  w-10">Notes</th>
                            {!readOnly &&
                                <th className='table-data border  w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) => <>
                            {item?.isPoItem ?
                                <AccessoryPoItem item={item} billEntryId={billEntryId} removeItem={removeItem}
                                    readOnly={readOnly} key={index} index={index} handleInputChange={handleInputChange} />
                                :
                                <AccessoryDirectItem item={item} billEntryId={billEntryId} removeItem={removeItem}
                                    readOnly={readOnly} key={index} index={index} handleInputChange={handleInputChange} />
                            }
                        </>
                        )}
                        {Array.from({ length: 8 - inwardItems.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                {Array.from({ length: 23 }).map(i =>
                                    <td className="table-data   "></td>
                                )}
                                {!readOnly &&
                                    <td className="table-data w-14"></td>
                                }
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default AccessoryInwardItems
