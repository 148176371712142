import { DELETE, PLUS } from '../../../icons';
import { useGetAccessoryMasterQuery } from '../../../redux/ErpServices/AccessoryMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetSizeMasterQuery } from '../../../redux/ErpServices/SizeMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getAllowableReturnQty, isBetweenRange, substract } from '../../../Utils/helper';
import { toast } from 'react-toastify';

const AccessoryReturnItems = ({ transType, finalInwardItems, setFinalInwardItems, readOnly, removeItem, openSelection }) => {
    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(finalInwardItems);
        newBlend[index][field] = value;
        setFinalInwardItems(newBlend);
    };

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )

    const { data: accessoryList } =
        useGetAccessoryMasterQuery({ params: { companyId } });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params: { companyId } });

    function findAccessoryItemName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
        return acc ? acc.accessoryItem.name : null
    }

    function findAccessoryGroupName(id) {
        if (!accessoryList) return 0
        let acc = accessoryList.data.find(item => parseInt(item.id) === parseInt(id))
        return acc ? acc.accessoryItem.AccessoryGroup.name : null
    }

    const { data: sizeList } =
        useGetSizeMasterQuery({ params: { companyId } });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params: { companyId } });
    if (!accessoryList || !colorList || !uomList || !sizeList) return <Loader />
    return (
        <div className={`relative w-full overflow-y-auto`}>
            <table className="border border-gray-500 text-xs table-fixed w-full">
                <thead className='bg-blue-200 top-0 border border-gray-500'>
                    <tr className='h-8'>
                        <th className="table-data w-10 text-center">S.no</th>
                        <th className="table-data w-32">Po.no</th>
                        <th className="table-data ">Accessory Name</th>
                        <th className="table-data ">Accessory Item</th>
                        <th className="table-data ">Accessory Group</th>
                        <th className="table-data ">Color</th>
                        <th className="table-data ">Size</th>
                        <th className="table-data  ">UOM</th>
                        <th className="table-data  ">Inward Qty</th>
                        <th className="table-data  ">Stock Qty</th>
                        <th className="table-data  ">Allowed Return Qty</th>
                        <th className="table-data  ">Return Qty</th>
                        <th className="table-data  ">Price</th>
                        <th className="table-data  ">Gross</th>
                        {!readOnly
                            &&
                            <th className='w-20'>
                                <div onClick={openSelection}
                                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                                    {PLUS}
                                </div>
                            </th>
                        }
                    </tr>
                </thead>
                <tbody className='overflow-y-auto  h-full w-full'>
                    {finalInwardItems.map((row, index) => (
                        <tr key={index} className="w-full table-row">
                            <td className="table-data   text-center">
                                {index + 1}
                            </td>
                            <td className="table-data  text-center">
                                {row.poDocId}
                            </td>
                            <td className='table-data text-left '>
                                {findFromList(row.accessoryId, accessoryList.data, "aliasName")}
                            </td>
                            <td className=''>

                                {findAccessoryItemName(row.accessoryId)}
                            </td>
                            <td className=''>
                                {findAccessoryGroupName(row.accessoryId)}
                            </td>
                            <td className='table-data text-left '>
                                {findFromList(row.colorId, colorList.data, "name")}
                            </td>
                            <td className='table-data text-left '>
                                {findFromList(row.sizeId, sizeList.data, "name")}
                            </td>
                            <td className='table-data text-left '>
                                {findFromList(row.uomId, uomList.data, "name")}
                            </td>
                            <td className="table-data   text-right">
                                {(parseFloat(row.alreadyInwardedQty) + parseFloat(row.alreadyReturnedQty)).toFixed(3)}
                            </td>
                            <td className="table-data text-right ">
                                {parseFloat(row.stockQty).toFixed(3)}
                            </td>
                            <td className="table-data text-right ">
                                {getAllowableReturnQty(row.alreadyInwardedQty, row.alreadyReturnedQty, row.stockQty).toFixed(3)}
                            </td>
                            <td className=' table-data text-right '>
                                <input
                                    type="number"
                                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.000", index, "returnQty") } }}
                                    onFocus={(e) => e.target.select()}
                                    className="text-right rounded w-full table-data-input"
                                    value={(!row.returnQty) ? 0 : row.returnQty}
                                    disabled={readOnly}
                                    onChange={(event) => {
                                        if (!event.target.value) {
                                            handleInputChange(0, index, "returnQty");
                                            return
                                        }
                                        if (isBetweenRange(0, getAllowableReturnQty(row.alreadyInwardedQty, row.alreadyReturnedQty, row.stockQty), event.target.value)) {
                                            handleInputChange(event.target.value.replace(/^0+/, ''), index, "returnQty")
                                        } else {
                                            toast.info("Return Qty Cannot be more than allowable Qty", { position: 'top-center' })
                                        }
                                    }}
                                    onBlur={(e) => {

                                        if (!e.target.value) {
                                            handleInputChange(0.000, index, "returnQty");
                                            return
                                        }
                                        handleInputChange(parseFloat(e.target.value).toFixed(3), index, "returnQty")
                                    }

                                    }
                                />
                            </td>
                            <td className='table-data text-right' >
                                {parseFloat(row.price).toFixed(2)}
                            </td>
                            <td className='table-data text-right '>
                                {(row.price * row.returnQty).toFixed(2)}
                            </td>
                            {!readOnly
                                &&
                                <td className='table-data'>
                                    <div tabIndex={-1} onClick={() => removeItem(row.poNo, row.poItemId)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer bg-gray-300'>
                                        {DELETE}
                                    </div>
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default AccessoryReturnItems