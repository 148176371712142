import React from 'react'
import { Text, View, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import logo from "../../../assets/RainDot.png"
import { RAIN_DOT_DES, RAIN_DOT_WEBSITE_LINK } from '../../../Constants';


const Header = ({ branchData, to, docDate, docId, qrCode, poNo, eWayBillNo, page = 'Original', invoiceType }) => {

    return (
        <View style={tw("border-t border-gray-500 ")}>
            <View style={tw("pt-2 border-x border-gray-500")}>
                <View style={{ fontFamily: "Times-Bold", ...tw("text-sm flex flex-row justify-between w-full px-2") }}>
                    <Text>
                    </Text>
                    <Text>
                        {page}
                    </Text>
                </View>
                <View style={tw("text-xs flex flex-row justify-between w-full items-center mt-1 text-blue-600")}>
                    <View style={tw("p-2 flex flex-col items-center")}>
                        <Image src={logo} style={{ width: '70px', height: '70px' }} />
                        <Text style={tw("text-black uppercase")}>{RAIN_DOT_DES}</Text>
                    </View>
                    <View style={tw("text-center w-full text-[11px]")}>
                        <Text style={{ fontFamily: "Times-Bold", fontWeight: 600, ...tw("text-[18px] text-blue-600") }}>
                            P.S TEX
                        </Text>
                        <Text style={tw("mt-2 ")}>
                            {branchData?.data?.address}
                        </Text>
                        <Text>
                            GSTIN: {branchData?.data?.gstNo}  CELL: {branchData?.data?.contactMobile}
                        </Text>
                        <Text >
                            E-MAIL: <Text style={tw("lowercase")}>{branchData?.data?.contactEmail}</Text>   WEBSITE: {RAIN_DOT_WEBSITE_LINK}
                        </Text>
                        <Text style={{ fontFamily: "Times-Bold", ...tw("text-[14px] mt-3") }}>
                            TAX INVOICE
                        </Text>
                    </View>
                    <View style={tw("p-2 mr-6 text-center flex flex-col items-center")}>
                        <Image src={qrCode} style={{ width: '70px', height: '70px' }} />
                        <Text style={tw("text-center text-lg")}>
                            {invoiceType}
                        </Text>
                    </View>
                </View>
                <View style={{ ...tw("text-xs w-full border-y border-gray-500 h-[80px] flex flex-row"), fontFamily: "Times-Bold" }}>
                    <View style={tw("w-1/2 h-full border-r border-gray-500")}>
                        <View style={tw(" h-4/6 flex flex-row w-full pt-1")}>
                            <Text style={tw("w-2/12 text-right")}>
                                To &nbsp;:&nbsp;
                            </Text>
                            <View style={tw("flex flex-col w-10/12")}>
                                <Text style={tw("text-[12px]")}>{to?.name}</Text>
                                <Text style={tw("flex-1 text-[10px]")}>{to?.address}</Text>
                            </View>
                        </View>
                        <View style={tw("h-2/6 flex flex-row w-full pt-1")}>
                            <Text style={tw("w-2/12 text-right")}>
                                GSTIN&nbsp;:&nbsp;
                            </Text>
                            <Text style={tw("w-10/12")}>{to?.gstNo}</Text>
                        </View>
                    </View>
                    <View style={tw("w-1/2")}>
                        <View style={tw(" h-1/3 w-full items-center w-full flex flex-row")}>
                            <View style={tw('w-[72%] h-full border-b border-r border-gray-500 flex flex-row justify-between items-center p-1')}>
                                <Text style={tw("w-full")}>
                                    Invoice&nbsp;No&nbsp;:&nbsp;{docId}
                                </Text>
                            </View>
                            <View style={tw('w-[28%] h-full border-b border-gray-500 flex flex-row justify-between items-center p-1')}>
                                <Text style={tw("w-full")}>
                                    Date&nbsp;:&nbsp;{docDate}
                                </Text>
                            </View>
                        </View>
                        <View style={tw(" h-1/3 w-full items-center w-full flex flex-row")}>
                            <View style={tw('w-full h-full border-gray-500 flex flex-row items-center p-1')}>
                                <Text style={tw("w-3/12 ")}>
                                    E-way&nbsp;Bill&nbsp;no.&nbsp;:
                                </Text>
                                <Text style={tw("w-7/12")}>
                                    {eWayBillNo}
                                </Text>
                            </View>
                        </View>
                        <View style={tw(" h-1/3 w-full border-t border-gray-500 items-center w-full flex flex-row")}>
                            <Text style={tw("w-4/12 pl-1")}>
                                Purchase&nbsp;Order&nbsp;no.&nbsp;:
                            </Text>
                            <Text style={tw("w-7/12")}>
                                {poNo}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default Header