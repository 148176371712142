import React from 'react'

const SalesOrder = ({ finishedGoodsSalesDetails = [], singleData, taxDetails }) => {
  return (
    <div >
      <table className=" text-xs border  border-gray-500 table-auto w-full ">
        <thead className=' border border-gray-500 top-0 bg-green-200'>
          <tr className='h-8'>
            <th className="table-data text-center w-16">S.no</th>
            <th className="table-data ">Style</th>
            <th className="table-data ">Color</th>
            <th className="table-data  ">UOM</th>
            <th className="table-data  ">Size</th>
            <th className="table-data  "> Qty</th>
          </tr>
        </thead>
        <tbody>
          {finishedGoodsSalesDetails.map((value, index) => (
            <tr className='border  border-gray-500 w-full' key={index}>
              <td className='table-data text-center px-1'>{index + 1} </td>

              <td className='table-data  px-1 '>
                {value.Style?.sku}
              </td>
              <td className='table-data text-center px-1 '>
                {value.Color?.name}
              </td>
              <td className='table-data text-center px-1  '>
                {value.Uom?.name}
              </td>
              <td className='table-data text-center px-1 '> {value.Size?.name}</td>
              <td className='table-data text-right px-1 '>{parseFloat(value.qty).toFixed(3)}</td>
            </tr>
          ))}
          <tr className='border  border-gray-500'>
            <th className='table-data text-center px-1  font-bold text-xs' colSpan={4}></th>
            <th className='table-data text-center px-1  font-bold text-xs' >TOTAL</th>
            <td className='px-1 h-8  text-right'>{finishedGoodsSalesDetails.reduce((a, c) => (a + parseFloat(c.qty)), 0).toFixed(3)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SalesOrder;