import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { OPERATION_NAME } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const operationNameApi = createApi({
    reducerPath: "operationName",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
    }),
    tagTypes: ["OperationName"],
    endpoints: (builder) => ({
        getOperationName: builder.query({
            query: ({ params, searchParams }) => {
                if (searchParams) {
                    return {
                        url: OPERATION_NAME + "/search/" + searchParams,
                        method: "GET",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                        params
                    };
                }
                return {
                    url: OPERATION_NAME,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                    params
                };
            },
            providesTags: ["operationName"],
        }),
        getOperationNameById: builder.query({
            query: (id) => {
                return {
                    url: `${OPERATION_NAME}/${id}`,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: ["operationName"],
        }),
        addOperationName: builder.mutation({
            query: (payload) => ({
                url: OPERATION_NAME,
                method: "POST",
                body: payload,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            }),
            invalidatesTags: ["operationName"],
        }),
        updateOperationName: builder.mutation({
            query: (payload) => {
                const { id, ...body } = payload;
                return {
                    url: `${OPERATION_NAME}/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["operationName"],
        }),
        deleteOperationName: builder.mutation({
            query: (id) => ({
                url: `${OPERATION_NAME}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["operationName"],
        }),
    }),
});

export const {
    useGetOperationNameQuery,
    useGetOperationNameByIdQuery,
    useAddOperationNameMutation,
    useUpdateOperationNameMutation,
    useDeleteOperationNameMutation,


} = operationNameApi;

export default operationNameApi;
