import React, { useEffect, useState } from 'react'
import { useGetPoByIdQuery } from '../../../redux/ErpServices/PoServices';
import YarnPoItem from './YarnPoItem';
import { sumArray } from '../../../Utils/helper';

const YarnPoItemSelection = ({ id, dataObj }) => {
    const [poItems, setPoItems] = useState([])
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetPoByIdQuery(id, { skip: !id });

    useEffect(() => {
        if (!singleData?.data?.PoItems) return
        setPoItems(singleData?.data?.PoItems ? singleData.data?.PoItems : []);
    }, [singleData, isSingleFetching, isSingleLoading])

    return (
        <div className='border bg-gray-200'>
            <table className='table-auto w-full text-xs border border-gray-500 '>
                <thead className=' border-b border-gray-500'>
                    <tr className='bg-blue-200 border border-gray-500 sticky top-10 '>
                        <th className="table-data  w-2 text-center">S.no</th>
                        <th className="table-data w-28">Items</th>
                        <th className="table-data w-14">Colors</th>
                        <th className="table-data  w-14">UOM</th>
                        <th className="table-data w-10">Po. Qty</th>
                        <th className="table-data w-10">Po. Bags</th>
                        <th className="table-data  w-10"> Can. Qty</th>
                        <th className="table-data  w-10"> Can. Bags</th>
                        <th className="table-data  w-10"> In Qty</th>
                        <th className="table-data  w-10"> In Bags</th>
                        <th className="table-data  w-10"> Rtn Qty</th>
                        <th className="table-data  w-10"> Rtn Bags</th>
                        <th className="table-data  w-10">Bal. Qty</th>
                        <th className="table-data  w-10">Bal. Bags</th>
                        <th className="table-data  w-10">Bill Qty</th>
                        <th className="table-data  w-10">Bal Bill Qty</th>
                    </tr>
                </thead>
                <tbody>
                    {poItems.map((item, index) =>
                        <YarnPoItem key={item.id} id={item.id} index={index} dataObj={dataObj} />)
                    }
                    <tr className='table-row'>
                        <td colSpan={3} className='table-data'></td>
                        <td>Totals</td>
                        <td className='text-right  table-data'>{sumArray(poItems, "qty")}</td>
                        <td className='text-right  table-data'>{sumArray(poItems.filter(item => item.noOfBags), "noOfBags")}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    )
}

export default YarnPoItemSelection