import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
  useGetProcessDeliveryReturnQuery,
  useGetProcessDeliveryReturnByIdQuery,
  useAddProcessDeliveryReturnMutation,
  useUpdateProcessDeliveryReturnMutation,
  useDeleteProcessDeliveryReturnMutation,
} from "../../../redux/ErpServices/ProcessDeliveryReturnServices";
import { useGetProcessDeliveryByIdQuery } from "../../../redux/ErpServices/ProcessDeliveryServices";
import {
  useGetProcessQuery,
} from "../../../redux/ErpServices/processMasterServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { LongDropdownInput, LongDisabledInput, DropdownInput, TextInput, DisabledInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { findFromList, getCommonParams, getDateFromDateTime, getItemFullNameFromShortCode, isGridDatasValid } from "../../../Utils/helper";
import moment from "moment";
import { Loader } from "../../../Basic/components";
import Program from "../ProcessDeliveryReturnProgram"
import RawMaterial from "../ProcessDeliveryReturnRawMaterial";
import useOutsideClick from "../../../CustomHooks/handleOutsideClick";
import _ from "lodash";
import Consolidation from "./Consolidation";
import {
  useGetLocationMasterQuery,
} from "../../../redux/ErpServices/LocationMasterServices";
import {
  useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import Modal from "../../../UiComponents/Modal";
import DeliverySelectionFillGrid from "./DeliverySelectionFillGrid";
import ProcessInwardFormReport from "./ProcessInwardFormReport";


const MODEL = "Process Return";

export default function Form() {
  const dispatch = useDispatch()

  const today = new Date()
  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [processId, setProcessId] = useState("");
  const [locationId, setLocationId] = useState('');

  const [storeId, setStoreId] = useState("")

  const [programDetails, setProgramDetails] = useState([])

  const [inwardDetails, setInwardDetails] = useState([]);

  const [supplierId, setSupplierId] = useState("");

  const [currentDcId, setCurrentDcId] = useState("")

  const [docId, setDocId] = useState("");

  const [userInwardDate, setUserInwardDate] = useState("");
  const [delDate, setDelDate] = useState(getDateFromDateTime(today))
  const [currentProgramIndex, setCurrentProgramIndex] = useState(-1);

  const [remarks, setRemarks] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [vehicleNo, setVehicleNo] = useState("");

  const [dcDate, setDcDate] = useState("");
  const [dcNo, setDcNo] = useState("");

  const [openDeliverySelect, setOpenDeliverySelect] = useState(false);

  const [active, setActive] = useState(true)

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);

  const rawMaterialRef = useOutsideClick(() => setCurrentProgramIndex(-1));


  const { branchId, companyId, finYearId, userId } = getCommonParams()


  const params = {
    companyId: secureLocalStorage.getItem(
      sessionStorage.getItem("sessionId") + "userCompanyId"
    ),
  };
  const { data: processList, isLoading: isProcessLoading, isFetching: isProcessFetching } = useGetProcessQuery({ params: { ...params, isPcsStage: false } });
  const { data: locationData, isLoading: isLocationLoading, isFetching: isLocationFetching } = useGetLocationMasterQuery({ params: { branchId }, searchParams: searchValue });
  const { data: branchList, isLoading: isBranchLoading, isFetching: isBranchFetching } = useGetBranchQuery({ params: { companyId } });

  const { data: supplierList } =
    useGetPartyQuery({ params });

  const { data: allData, isLoading, isFetching } = useGetProcessDeliveryReturnQuery({ params: { branchId, processId, finYearId }, searchParams: searchValue });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetProcessDeliveryReturnByIdQuery(id, { skip: !id });
  const {
    data: processDeliveryDetails,
    isFetching: isProcessDeliveryFetching,
    isLoading: isProcessDeliveryLoading,
  } = useGetProcessDeliveryByIdQuery({ id: currentDcId, processDeliveryReturnId: id }, { skip: !currentDcId });
  const getNextDocId = useCallback(() => {
    if (id) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])


  const [addData] = useAddProcessDeliveryReturnMutation();
  const [updateData] = useUpdateProcessDeliveryReturnMutation();
  const [removeData] = useDeleteProcessDeliveryReturnMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setDelDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    setCurrentDcId(data?.processDeliveryId ? data?.processDeliveryId : '')
    if (id) {
      setProgramDetails(data?.inwardDetails ? data.inwardDetails : [])
    }
    setSupplierId(data?.ProcessDelivery?.supplierId ? data?.ProcessDelivery?.supplierId : "")
    setLocationId(data?.storeId ? findFromList(data?.storeId, (locationData?.data ? locationData.data : []), "locationId") : "")
    setStoreId(data?.storeId ? data?.storeId : "");
    if (data?.docId) {
      setDocId(data?.docId)
    }
    if (!id) { setProcessId("") }
    setActive(id ? (data?.active ? data.active : false) : true);
    setUserInwardDate(data?.userInwardDate ? moment(data.userInwardDate).format("YYYY-MM-DD") : "")
    setInwardDetails(data?.ProcessDeliveryReturnProgramDetails
      ? data?.ProcessDeliveryReturnProgramDetails
      : []);
    setDcNo(data?.dcNo ? data.dcNo : "");
    setDcDate(data?.dcDate ? getDateFromDateTime(data.dcDate) : "");
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");
    setRemarks(data?.remarks ? data?.remarks : "");
    setSpecialInstructions(data?.specialInstructions ? data?.specialInstructions : "")
  }, [id]);

  useEffect(() => {
    if (!processDeliveryDetails?.data) return
    setProcessId(processDeliveryDetails.data.processId)
    setProgramDetails(processDeliveryDetails.data.ProcessDeliveryProgramDetails)
  }, [currentDcId, isProcessDeliveryFetching, isProcessDeliveryLoading])

  const deliveryDocId = processDeliveryDetails?.data ? processDeliveryDetails?.data?.docId : ""

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    processDeliveryId: currentDcId,
    userId,
    storeId,
    branchId,
    userInwardDate,
    dcNo,
    dcDate,
    inwardDetails,
    vehicleNo,
    specialInstructions,
    remarks,
    finYearId
  }

  const validateData = (data) => {

    const programPoType = getItemFullNameFromShortCode(findFromList(processId, processList.data, "io").toString().split("_").at(1))

    // let programMandatoryDetails = ["inwardQty",];
    let rawMaterialMandatoryDetails = ["returnQty"]
    if (programPoType === "GreyYarn" || programPoType === "DyedYarn") {
      rawMaterialMandatoryDetails = [...rawMaterialMandatoryDetails, "returnBags"]
      // programMandatoryDetails = [...programMandatoryDetails, ...["inwardBags"]]
    } else if (programPoType === "GreyYarn" || programPoType === "DyedYarn") {
      rawMaterialMandatoryDetails = [...rawMaterialMandatoryDetails, "returnRolls"]
      // programMandatoryDetails = [...programMandatoryDetails, ...["inwardRolls"]]
    }


    return data.processDeliveryId && data.userInwardDate && data.dcNo && data.dcDate && data.vehicleNo
      && data.inwardDetails.length !== 0
      && data.inwardDetails.every(item => ((item?.rawMaterials)
        && isGridDatasValid(item.rawMaterials, false, rawMaterialMandatoryDetails)
      ))
  }


  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback({ id, body: data }).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 1) {
        toast.error(returnData.message);
      } else {
        toast.success(text + "Successfully");
        setId("")
        syncFormWithDb(undefined)
      }
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });
      dispatch({
        type: `ProcessDelivery/invalidateTags`,
        payload: ['ProcessDelivery'],
      });
    } catch (error) {
      console.log("handle", error);
    }
  };

  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        dispatch({
          type: `ProcessDelivery/invalidateTags`,
          payload: ['ProcessDelivery'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
  };

  function onDataClick(id) {
    setId(id);
    setForm(true);
  }
  const tableHeaders = ["Inward No", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const getIssuedQty = (stockItem) => {
    const issueDetails = programDetails.map(item => item.rawMaterials).flat()
    const stockItemIssuedDetails = issueDetails.filter(item => _.isEqual(stockItem.itemDetails, item.itemDetails))
    const totalQty = stockItemIssuedDetails.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.issueQty ? currentValue?.issueQty : 0))
    }, 0)
    return totalQty
  }

  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLoading || isFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  const allSuppliers = supplierList ? supplierList.data : []

  function getTotals(field) {
    const total = inwardDetails.reduce((accumulator, current) => {
      return accumulator + (current?.rawMaterials ? current?.rawMaterials : []).reduce((acc, curr) => acc + parseFloat(curr[field] ? curr[field] : 0), 0)
    }, 0)
    return parseFloat(total)
  }

  function filterSupplier() {
    const isProcessInSupplier = (supplierProcessList) => supplierProcessList.includes(parseInt(processId))
    let finalSupplier = allSuppliers.filter(s => (!(s?.PartyOnProcess)) ? false : isProcessInSupplier(s.PartyOnProcess.map(item => parseInt(item.processId))))
    return finalSupplier
  }
  let supplierListBasedOnSupply = filterSupplier()

  if (isProcessLoading || isProcessFetching || isLocationFetching || isLocationLoading) return <Loader />
  const storeOptions = locationData ?
    locationData.data.filter(item => parseInt(item.locationId) === parseInt(locationId)) :
    [];

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
    >
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <ProcessInwardFormReport
          heading={MODEL}
          loading={
            isLoading || isFetching
          }
          tableDataNames={tableDataNames}
          tableHeaders={tableHeaders}
          tableWidth="100%"
          setForm={setForm}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <Modal isOpen={openDeliverySelect} onClose={() => { setOpenDeliverySelect(false) }} widthClass={"h-[500px]"}>
        <DeliverySelectionFillGrid onClick={(id) => { setCurrentDcId(id); setOpenDeliverySelect(false) }}
          processId={processId} supplierId={supplierId} />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          openReport={() => setFormReport(true)}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid h-full relative overflow-auto`}>
                  <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                      <legend className='sub-heading'>Delivery Info</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-4">
                          <DisabledInput name={"Return No."} value={processId ? docId : "Choose process to Generate..."} />
                          <DisabledInput name="Return Date" value={delDate} type={"date"} required={true} readOnly={readOnly} />
                          <DropdownInput
                            className={"w-[110px]"}
                            name="From Process"
                            options={dropDownListObject(id ? processList.data : processList.data.filter(item => item.active), "name", "id")}
                            value={processId}
                            setValue={setProcessId}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          <LongDropdownInput name="Supplier" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                          <TextInput name={"User Return Date"} value={userInwardDate} setValue={setUserInwardDate} type={"date"} readOnly={readOnly} />
                          <TextInput name={"Dc No."} value={dcNo} setValue={setDcNo} readOnly={readOnly} required />
                          <TextInput name={"Dc Date."} value={dcDate} setValue={setDcDate} type={"date"} readOnly={readOnly} required />
                          <DropdownInput name="Location"
                            options={branchList ? (dropDownListObject(id ? branchList.data : branchList.data.filter(item => item.active), "branchName", "id")) : []}
                            value={locationId}
                            setValue={(value) => { setLocationId(value); setStoreId("") }}
                            required={true} readOnly={id || readOnly} />
                          <DropdownInput name="Store"
                            options={dropDownListObject(id ? storeOptions : storeOptions.filter(item => item.active), "storeName", "id")}
                            value={storeId} setValue={setStoreId} required={true} readOnly={id || readOnly} />
                          <DisabledInput name={"Delivery No."} value={deliveryDocId} readOnly={readOnly} required />
                          {!readOnly &&
                            <div className="flex justify-start w-full">
                              <button onKeyDown={(e) => {
                                e.preventDefault()
                                if (e.key = "Enter") {
                                  if (!supplierId || !processId || !storeId) {
                                    toast.info("Please Select Process, Supplier & Store", { position: "top-center" })
                                    return
                                  }
                                  setOpenDeliverySelect(true)
                                }
                              }} className="p-1.5 text-xs bg-lime-400 rounded hover:bg-lime-600 font-semibold transition hover:text-white"
                                onClick={() => {
                                  if (!supplierId || !processId || !storeId) {
                                    toast.info("Please Select Process, Supplier & Store", { position: "top-center" })
                                    return
                                  }
                                  setOpenDeliverySelect(true)
                                }}
                              >Select Delivery</button>
                            </div>
                          }
                        </div>
                      </div>
                    </fieldset>
                    {currentDcId
                      &&
                      <Program inwardDetails={inwardDetails} readOnly={readOnly} setInwardDetails={setInwardDetails} setCurrentProgramIndex={setCurrentProgramIndex} finishedGoodsType={processId ? (findFromList(processId, processList.data, "io")).toString().split("_").at(1) : ""}
                        programDetails={programDetails} setProgramDetails={setProgramDetails} />
                    }
                    {(currentProgramIndex >= 0) &&
                      <fieldset ref={rawMaterialRef} className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 overflow-auto'>
                        <legend className='sub-heading'>Delivery from stock</legend>
                        <RawMaterial inwardDetails={inwardDetails} setInwardDetails={setInwardDetails} readOnly={readOnly}
                          storeId={storeId} getIssuedQty={getIssuedQty} currentProgramIndex={currentProgramIndex} programDetails={programDetails} setProgramDetails={setProgramDetails} rawMaterialType={processId ? (findFromList(processId, processList.data, "io")).toString().split("_").at(0) : ""} />
                      </fieldset>
                    }
                    <Consolidation totalQty={getTotals("returnQty")} vehicleNo={vehicleNo} setVehicleNo={setVehicleNo} remarks={remarks} setRemarks={setRemarks}
                      specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}