import React from 'react';
import FabricPoItem from './FabricPoItem';
import { toast } from 'react-toastify';



const FabricCancelItems = ({ inwardItems, setInwardItems, readOnly, removeItem, purchaseInwardId }) => {
    const handleInputChange = (value, index, field, balanceQty) => {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            newBlend[index][field] = value
            if (field === "qty") {
                if (parseFloat(balanceQty) < parseFloat(value)) {
                    toast.info("Cancel Qty Can not be more than balance Qty", { position: 'top-center' })
                    return inwardItems
                }
            }
            return newBlend
        });
    };

    return (
        <>
            <div className={`relative w-full overflow-y-auto `}>
                <table className=" text-xs border border-gray-500  w-full">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className=''>
                            <th className='text-center w-8 table-data '>
                                S.no
                            </th>
                            <th className='text-center table-data'>
                                Po.no
                            </th>
                            <th className='table-data'>
                                Fabric Name
                            </th>
                            <th className='w-16 table-data'>
                                Color
                            </th>
                            <th className='w-16 table-data'>
                                Design
                            </th>
                            <th className=' table-data'>
                                Gauge
                            </th>
                            <th className=' table-data'>
                                LL
                            </th>
                            <th className=' table-data'>
                                Gsm
                            </th>
                            <th className=' table-data'>
                                K-Dia
                            </th>
                            <th className=' table-data'>
                                F-Dia
                            </th>
                            <th className=' table-data'>
                                Uom
                            </th>
                            <th className=' table-data'>
                                Po qty
                            </th>
                            <th className='table-data'>
                                A.Can. qty
                            </th>
                            <th className='table-data'>
                                A.Can. Rolls
                            </th>
                            <th className='table-data'>
                                A.In. qty
                            </th>
                            <th className='table-data'>
                                A.In. Rolls
                            </th>
                            <th className='table-data'>
                                A.Rtn. qty
                            </th>
                            <th className='table-data'>
                                A.Rtn Rolls
                            </th>

                            <th className='table-data'>
                                Bal.Qty
                            </th>

                            {/* <th className="table-data  w-16">Bal. Rolls<span className="text-red-500">*</span></th> */}
                            <th className='table-data w-16'><span className="text-red-500">*</span>
                                Can. Qty
                            </th>
                            {/* <th className='table-data'>
                                Can. Rolls
                            </th> */}
                            <th className=' table-data'>
                                Price
                            </th>
                            {/* <th className='w-16 table-data'>
                                Gross
                            </th> */}
                            {!readOnly &&
                                <th className=' table-data'>Del</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) => <FabricPoItem noOfRolls={item.noOfRolls} removeItem={removeItem} key={item.poItemsId}
                            qty={item.qty} poItemId={item.poItemsId} index={index} handleInputChange={handleInputChange} purchaseInwardId={purchaseInwardId} readOnly={readOnly} />)}
                        {Array.from({ length: 8 - inwardItems.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                {Array.from({ length: 21 }).map(i =>
                                    <td className="table-data   "></td>
                                )}
                                {!readOnly &&
                                    <td className="table-data w-10"></td>
                                }
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default FabricCancelItems