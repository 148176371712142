import { useGetTaxTemplateByIdQuery } from '../../redux/ErpServices/TaxTemplateServices';
import { useGetTaxTermMasterQuery } from '../../redux/ErpServices/TaxTermMasterServices';
import { groupBy } from 'lodash';
import { convertGroupedObjectIntoArray } from '../../Utils/helper';
import getTaxDetails from '../../Utils/taxHelper';
import useTaxDetailsHook from '.';

const useTaxDetailsWithTaxSplitUp = ({ taxTemplateId, items, discountType, discountValue, isSupplierOutside }) => {
    const { data: taxTypeData } = useGetTaxTemplateByIdQuery(taxTemplateId, { skip: !taxTemplateId })

    const { data: taxTermMaster } = useGetTaxTermMasterQuery(taxTemplateId, { skip: !taxTemplateId })

    const { igstAmount, sgstAmount, cgstAmount, netAmount, grossAmount, roundOffAmount } =
        useTaxDetailsHook({ poItems: items.map(i => ({ ...i, taxPercent: i.tax })), taxTypeId: taxTemplateId, discountType: discountType, discountValue: discountValue, isSupplierOutside })


    let taxGroupWise = groupBy(items, "tax");
    let taxGroupArr = convertGroupedObjectIntoArray(taxGroupWise)
    let taxGridData = [];
    for (let index = 0; index < taxGroupArr.length; index++) {
        const taxItem = taxGroupArr[index];
        const { igstAmount, sgstAmount, cgstAmount } = getTaxDetails({ poItems: (taxItem?.items || []).map(i => ({ ...i, taxPercent: i.tax })), taxTypeId: taxTemplateId, discountType, discountValue, isSupplierOutside, data: taxTypeData, taxTermMaster })
        taxGridData.push({
            taxPercent: taxItem.groupName,
            igstAmount, sgstAmount, cgstAmount
        })
    }
    return { taxGridData, igstAmount, sgstAmount, cgstAmount, netAmount, grossAmount, roundOffAmount }

}

export default useTaxDetailsWithTaxSplitUp