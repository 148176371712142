import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useGetAdvanceAdjustmentQuery,
  useGetAdvanceAdjustmentByIdQuery,
  useAddAdvanceAdjustmentMutation,
  useUpdateAdvanceAdjustmentMutation,
  useDeleteAdvanceAdjustmentMutation,
} from "../../../redux/ErpServices/AdvanceAdjustment.Services";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DisabledInput, DropdownInput, DateInput, TextInput } from "../../../Inputs";
import { dropDownListObject, } from '../../../Utils/contructObject';

import moment from "moment";
import Modal from "../../../UiComponents/Modal";
import FormReport from "./Report";
import { getCommonParams, isGridDatasValid, substract, sumArray } from "../../../Utils/helper";
import BillEntrySelection from "./BillEntrySelection";
import BillItems from "./BillItems";
import { useDispatch } from "react-redux";
import AdvanceSelectionGrid from "./AdvanceSelectionGrid";
import { useGetPayOutByIdQuery } from "../../../redux/ErpServices/PayOut.Services";

const MODEL = "Advance Adjustment";


export default function Form() {
  const dispatch = useDispatch()

  const today = new Date()

  const [readOnly, setReadOnly] = useState(false);
  const [docId, setDocId] = useState("")
  const [id, setId] = useState("");
  const [date, setDate] = useState(moment.utc(today).format('YYYY-MM-DD'));
  const [supplierId, setSupplierId] = useState("");
  const [amount, setAmount] = useState("")
  const [advanceAdjustmentItems, setAdvanceAdjustmentItems] = useState([])
  const [selectBillItems, setSelectBillItems] = useState(false)

  const [advanceSelection, setAdvanceSelection] = useState(false)

  const [advancePayOutId, setAdvancePayOutId] = useState("")

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const childRecord = useRef(0);

  const { branchId, companyId, finYearId, userId } = getCommonParams()

  const branchIdFromApi = useRef(branchId);
  const params = {
    branchId, companyId, finYearId
  };

  const { data: supplierList } =
    useGetPartyQuery({ params: { ...params } });

  const { data: allData, isLoading, isFetching } = useGetAdvanceAdjustmentQuery({ params, searchParams: searchValue });

  const {
    data: advancePayOutData,
    isFetching: advancePayOutFetching,
    isLoading: advancePayOutLoading,
  } = useGetPayOutByIdQuery({ id: advancePayOutId, advanceAdjustmentId: id }, { skip: !advancePayOutId });


  const getAdvanceData = useCallback((property) => {
    if (advancePayOutLoading || advancePayOutLoading) return
    if (advancePayOutData?.data[property]) {
      return advancePayOutData?.data[property]
    }
    return 0
  }, [advancePayOutData, advancePayOutLoading, advancePayOutFetching, id])


  const getNextDocId = useCallback(() => {
    if (id || isFetching || isLoading) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetAdvanceAdjustmentByIdQuery(id, { skip: !id });

  const [addData] = useAddAdvanceAdjustmentMutation();
  const [updateData] = useUpdateAdvanceAdjustmentMutation();
  const [removeData] = useDeleteAdvanceAdjustmentMutation();

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    setAmount(data?.amount ? data.amount : "");
    setAdvancePayOutId(data?.advancePayOutId ? data.advancePayOutId : "");
    setAdvanceAdjustmentItems(data?.AdvanceAdjustDetails ? data.AdvanceAdjustDetails : [])
    setSupplierId(data?.AdvancePayOut ? data.AdvancePayOut.supplierId : "")
    setDate(data?.createdAt ? moment.utc(data.createdAt).format("YYYY-MM-DD") : moment.utc(today).format("YYYY-MM-DD"));
    if (data?.docId) {
      setDocId(data?.docId)
    }
    if (data?.date) setDate(data?.date);
    if (data?.branchId) {
      branchIdFromApi.current = data?.branchId
    }
  }, [id]);


  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    branchId, id, userId,
    advanceAdjustmentItems,
    amount,
    advancePayOutId, finYearId
  }

  const validateData = (data) => {
    let mandatoryFields = ["amount"];
    return data.amount && data.advancePayOutId
      && (isGridDatasValid(data.advanceAdjustmentItems, false, mandatoryFields) && data.advanceAdjustmentItems.length !== 0)
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      dispatch({
        type: `Ledger/invalidateTags`,
        payload: ['Ledger'],
      });
      dispatch({
        type: `PayOut/invalidateTags`,
        payload: ['PayOut'],
      });
      dispatch({
        type: `billEntry/invalidateTags`,
        payload: ['BillEntry'],
      });
      setId("")
      syncFormWithDb(undefined)
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };


  const saveData = () => {
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if ((parseFloat(data.amount) !== sumArray(data.advanceAdjustmentItems, "amount"))) {
      toast.info("PayAmount and Bill Amount Total Not Matched", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        toast.success("Deleted Successfully");
        dispatch({
          type: `Ledger/invalidateTags`,
          payload: ['Ledger'],
        });
        dispatch({
          type: `PayOut/invalidateTags`,
          payload: ['PayOut'],
        });
        dispatch({
          type: `billEntry/invalidateTags`,
          payload: ['BillEntry'],
        });
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined)
    getNextDocId()
  };


  const tableHeadings = ["PoNo", "PoDate", "transType", "DueDate", "Supplier"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  let advanceAmount = getAdvanceData("amount");
  let alreadyAdjustedAmount = getAdvanceData("alreadyAdjustedAmount")
  let balanceAmount = substract(advanceAmount, alreadyAdjustedAmount)

  return (
    <div
      onKeyDown={handleKeyDown}
      className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <FormReport
          heading={MODEL}
          tableHeaders={tableHeadings}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          tableWidth="100%"
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <Modal isOpen={advanceSelection} onClose={() => setAdvanceSelection(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <AdvanceSelectionGrid supplierId={supplierId} dataOnClick={(id) => { setAdvancePayOutId(id); setAdvanceSelection(false); }} />
      </Modal>
      <Modal isOpen={selectBillItems} onClose={() => setSelectBillItems(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <BillEntrySelection supplierId={supplierId}
          advanceAdjustmentItems={advanceAdjustmentItems} setAdvanceAdjustmentItems={setAdvanceAdjustmentItems} setSelectBillItems={setSelectBillItems} />
      </Modal>
      <div className="flex flex-col frame w-full h-full">
        <FormHeader
          onNew={onNew}
          model={MODEL}
          saveData={saveData}
          setReadOnly={setReadOnly}
          deleteData={deleteData}
          openReport={() => { setFormReport(true) }}
          childRecord={childRecord.current}
        />
        <div className="flex-1 grid gap-x-2">
          <div className="col-span-3 grid overflow-auto">
            <div className='col-span-3 grid overflow-auto'>
              <div className='mr-1'>
                <div className={`grid`}>
                  <div className={"flex flex-col"}>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3 overflow-auto'>
                      <legend className='sub-heading'>Bill Info</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-5 w-full gap-2">
                          <DisabledInput name="Doc Id." value={docId}
                          />
                          <DateInput name="Doc. Date" value={date} type={"date"} readOnly={readOnly} disabled />
                          <DropdownInput name="Supplier"
                            options={dropDownListObject(supplierList?.data ? supplierList.data : [], "aliasName", "id")}
                            value={supplierId} setValue={(value) => { setAdvanceAdjustmentItems([]); setSupplierId(value) }} required={true} readOnly={id} />
                          <DisabledInput name="Advance Doc Id" value={getAdvanceData("docId")} />
                          <div className="w-full flex justify-center">
                            <button className={`px-1.5 text-xs bg-lime-400 rounded hover:bg-lime-600 font-semibold transition hover:text-white ${id ? "hidden" : "block"}`}
                              onClick={() => {
                                if (!supplierId) {
                                  toast.info("Please Select Suppplier", { position: "top-center" })
                                  return
                                }
                                setAdvanceSelection(true)
                              }}
                            >Select Adv. Entry</button>
                          </div>
                          <DisabledInput name="Adv. Amount" value={advanceAmount} />
                          <DisabledInput name="A. Adjusted" value={alreadyAdjustedAmount} />
                          <DisabledInput name="Bal. Amount" value={balanceAmount} />
                          <TextInput
                            type={"number"}
                            name={"Amount"}
                            value={amount}
                            required
                            setValue={(value) => {
                              if (value < 0) return
                              if (parseFloat(value) > parseFloat(balanceAmount)) {
                                toast.info("Amount Cannot be More Than Balance Amount", { position: "top-center" })
                                return
                              }
                              setAmount(value)
                            }}
                          />
                          <div className="w-full flex justify-center">
                            <button className={`px-1.5 text-xs bg-lime-400 rounded hover:bg-lime-600 font-semibold transition hover:text-white ${id ? "hidden" : "block"}`}
                              onClick={() => {
                                if (!supplierId) {
                                  toast.info("Please Select Suppplier", { position: "top-center" })
                                  return
                                }
                                setSelectBillItems(true)
                              }}
                            >Select Bill Items</button>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3 overflow-auto'>
                      <legend className='sub-heading'>Bill Items</legend>
                      <BillItems readOnly={readOnly} payOutItems={advanceAdjustmentItems}
                        setPayOutItems={setAdvanceAdjustmentItems} advanceAdjustmentId={id} />
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}