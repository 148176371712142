import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
  useGetProductionDeliveryQuery,
  useGetProductionDeliveryByIdQuery,
  useAddProductionDeliveryMutation,
  useUpdateProductionDeliveryMutation,
  useDeleteProductionDeliveryMutation,
} from "../../../redux/ErpServices/ProductionDeliveryServices";
import {
  useGetProcessQuery,
} from "../../../redux/ErpServices/processMasterServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { DropdownInput, DateInput, DisabledInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { findFromList, getDateFromDateTime, isGridDatasValid, getCommonParams } from "../../../Utils/helper";
import moment from "moment";
import { Loader } from "../../../Basic/components";

import {
  useGetLocationMasterQuery,
} from "../../../redux/ErpServices/LocationMasterServices";
import {
  useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import { useGetStyleMasterByIdQuery, useGetStyleMasterQuery } from "../../../redux/ErpServices/StyleMasterServices";
import Modal from "../../../UiComponents/Modal";

import ProductionDeliveryFormReport from "./ProductionDeliveryFormReport";
import ProductionDetailsFillGrid from "./ProductionDetailsFillGrid";
import { inHouseOutsideTypes } from "../../../Utils/DropdownData";
import ProductionDeliveryDetails from "./ProductDeliveryDetails";
import Consolidation from "../Consolidation";
import PrintFormatProductionDelivery from "../PrintFormat-ProductionDelivery";
import { useReactToPrint } from "@etsoo/reactprint";
import { groupByMultipleKeys } from "../../../Utils/groupbyMultipleKeys";

const MODEL = "Production Delivery";

export default function Form() {

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const dispatch = useDispatch()

  const today = new Date()
  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [docId, setDocId] = useState("");
  const [stockDetailsFillGrid, setStockDetailsFillGrid] = useState(false);
  const [deliveryToId, setDeliveryToId] = useState("")
  const [productionDeliveryDetails, setProductionDeliveryDetails] = useState([])
  const [dueDate, setDueDate] = useState("");

  const [storeId, setStoreId] = useState("")

  const [supplierId, setSupplierId] = useState("");

  const [delDate, setDelDate] = useState(getDateFromDateTime(today))

  const [remarks, setRemarks] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [vehicleNo, setVehicleNo] = useState("");

  const [styleId, setStyleId] = useState("");

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const childRecord = useRef(0);
  const [processIdFrom, setProcessIdFrom] = useState("");
  const [processIdTo, setProcessIdTo] = useState("");
  const [productionType, setProductionType] = useState("INHOUSE")

  const { branchId, companyId, finYearId, userId } = getCommonParams()


  const params = {
    companyId
  };
  const { data: locationData, isLoading: isLocationLoading, isFetching: isLocationFetching } = useGetLocationMasterQuery({ params: { branchId }, searchParams: searchValue });
  const { data: branchList } = useGetBranchQuery({ params: { companyId } });

  const { data: allData, isLoading, isFetching } = useGetProductionDeliveryQuery({ params: { branchId, finYearId }, searchParams: searchValue });
  const { data: supplierList } =
    useGetPartyQuery({ params });
  const { data: processList } = useGetProcessQuery({ params: { ...params, isPcsStage: true } });

  const { data: styleList, isLoading: isStyleLoading, isFetching: isStyleFetching } =
    useGetStyleMasterQuery({ params, searchParams: searchValue });

  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetProductionDeliveryByIdQuery({ id }, { skip: !id });

  const [addData] = useAddProductionDeliveryMutation();
  const [updateData] = useUpdateProductionDeliveryMutation();
  const [removeData] = useDeleteProductionDeliveryMutation();

  const getNextDocId = useCallback(() => {
    console.log("CALLING")
    if (id) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setDelDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    if (data?.docId) {
      setDocId(data.docId);
    }
    setProductionType(data?.productionType ? data?.productionType : "INHOUSE")
    setProcessIdFrom(data?.fromProcessId ? data?.fromProcessId : "")
    setProcessIdTo(data?.toProcessId ? data?.toProcessId : "")
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setDeliveryToId(data?.deliveryId ? data?.deliveryId : "");
    setLocationId(data?.storeId ? findFromList(data?.storeId, (locationData?.data ? locationData.data : []), "locationId") : "")
    setStoreId(data?.storeId ? data?.storeId : "");
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");
    setProductionDeliveryDetails(data?.ProductionDeliveryDetails ? data?.ProductionDeliveryDetails : [])
    setStyleId(data?.styleId ? data.styleId : "");
    setSpecialInstructions(data?.specialInstructions ? data?.specialInstructions : "")
    setRemarks(data?.remarks ? data?.remarks : "")
    setDueDate(data?.dueDate ? getDateFromDateTime(data.dueDate) : "")
  }, [id, locationData]);

  useEffect(() => { if (!id) { setDeliveryToId(supplierId) } }, [supplierId, id]);

  const { data: styleDetail } = useGetStyleMasterByIdQuery(styleId, { skip: !styleId })

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);



  const data = {
    supplierId,
    branchId,
    storeId,
    fromProcessId: processIdFrom,
    productionType,
    toProcessId: processIdTo,
    vehicleNo,
    specialInstructions,
    remarks,
    styleId,
    deliveryId: deliveryToId,
    productionDeliveryDetails,
    id, userId,
    dueDate,
    finYearId
  }

  const isOutside = productionType === "OUTSIDE";

  function packingValidation() {
    const isPacking = findFromList(processIdTo, (processList?.data ? processList?.data : []), "isPacking");
    if (!isPacking) return true;
    const groupedItems = groupByMultipleKeys(productionDeliveryDetails, ["colorId", "sizeId", "prevProcessId"]);
    return groupedItems.every(item => {
      if (item.length !== styleDetail?.data?.portionDetails.length) return false;
      let delQty = parseFloat(item[0]?.delQty ? item[0]?.delQty : 0);
      return item.every(i => parseFloat(i.delQty) === delQty)
    })
  }

  const validateData = (data) => {
    return (isOutside ? data.supplierId && data.deliveryId && data.vehicleNo : true)
      && data.storeId && data.styleId && data.fromProcessId && data.toProcessId
      && data.productionDeliveryDetails.length > 0
      && isGridDatasValid(data.productionDeliveryDetails, false, ["delQty", "processCost"])
  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 1) {
        toast.error(returnData.message);
      } else {
        toast.success(text + "Successfully");
        setId("")
        syncFormWithDb(undefined)
      }
      dispatch({
        type: `po/invalidateTags`,
        payload: ['po'],
      });
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });

    } catch (error) {
      console.log("handle", error);
    }
  };

  const saveData = () => {
    if (!packingValidation()) {
      toast.info("All portions should be Equal", { position: "top-center" })
      return
    }
    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
    getNextDocId();
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

  const getTotalIssuedQty = () => {
    const totalQty = productionDeliveryDetails.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.delQty ? currentValue?.delQty : 0))
    }, 0)
    return totalQty
  }


  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLocationLoading || isLocationFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  if (isLocationFetching || isLocationLoading || isStyleLoading || isStyleFetching || !processList) return <Loader />
  const storeOptions = locationData ?
    locationData.data.filter(item => parseInt(item.locationId) === parseInt(locationId)) :
    [];
  return (
    <>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <ProductionDeliveryFormReport
          heading={MODEL}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          allData={allData}
          tableWidth="100%"
          setForm={setForm}
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <Modal isOpen={stockDetailsFillGrid} onClose={() => { setStockDetailsFillGrid(false) }} widthClass={"bg-gray-300"}>
        <ProductionDetailsFillGrid
          storeId={storeId}
          setFillGrid={setStockDetailsFillGrid} productionDeliveryDetails={productionDeliveryDetails}
          setProductionDeliveryDetails={setProductionDeliveryDetails} fromProcessId={processIdFrom} styleId={styleId} />
      </Modal>
      <div className="hidden">
        <PrintFormatProductionDelivery
          remarks={remarks}
          id={id}
          innerRef={componentRef}
        />
      </div>
      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
      >
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}
            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            onPrint={id ? handlePrint : null}
            openReport={() => setFormReport(true)}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <div >
                    <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                      <legend className='sub-heading'>Production Delivery</legend>
                      <div className='flex flex-col justify-center items-start flex-1 w-full'>
                        <div className="grid grid-cols-4">
                          <DisabledInput name={"Delivery No"} value={docId} />
                          <DateInput name="Del. Date" value={delDate} type={"date"} required={true} disabled />
                          <DateInput name={"Due. Date"} value={dueDate} setValue={setDueDate} type={"date"} readOnly={readOnly} />
                          <DropdownInput
                            name="In House / Outside"
                            options={inHouseOutsideTypes}
                            value={productionType}
                            setValue={setProductionType}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          <DropdownInput name="Location"
                            options={branchList ? (dropDownListObject(id ? branchList.data : branchList.data.filter(item => item.active), "branchName", "id")) : []}
                            value={locationId}
                            setValue={(value) => { setLocationId(value); setStoreId("") }}
                            required={true} readOnly={id || readOnly} />
                          <DropdownInput name="Store"
                            options={dropDownListObject(id ? storeOptions : storeOptions.filter(item => item.active), "storeName", "id")}
                            value={storeId} setValue={setStoreId} required={true} readOnly={id || readOnly} />
                          {isOutside &&
                            <>
                              <DropdownInput name="Supplier" options={supplierList ? (dropDownListObject(id ? supplierList.data : supplierList.data.filter(item => item.active), "aliasName", "id")) : []} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                              <DropdownInput name="Delivery To" options={supplierList ? (dropDownListObject(id ? supplierList.data : supplierList.data.filter(item => item.active), "aliasName", "id")) : []} value={deliveryToId} setValue={setDeliveryToId} required={true} readOnly={readOnly} />
                            </>
                          }
                          <DropdownInput
                            name="Style"
                            options={dropDownListObject(id ? (styleList ? styleList.data : []) : (styleList ? styleList.data.filter(item => item.active) : []), "sku", "id")}
                            value={styleId}
                            setValue={setStyleId}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          <DropdownInput
                            name="From Process"
                            options={dropDownListObject(id ? (processList ? processList.data : []) : (processList ? processList.data.filter(item => item.active) : []), "name", "id")}
                            value={processIdFrom}
                            setValue={setProcessIdFrom}
                            required={true}
                            readOnly={id || readOnly}
                          />
                          <DropdownInput
                            onBlur={() => {
                              if (!storeId || !styleId || !processIdFrom || !processIdTo) return
                              if (productionDeliveryDetails.length === 0) {
                                setStockDetailsFillGrid(true)
                              }
                            }}
                            name="To Process"
                            options={dropDownListObject(id ? (processList ? processList.data : []) : (processList ? processList.data.filter(item => item.active) : []), "name", "id")}
                            value={processIdTo}
                            setValue={setProcessIdTo}
                            required={true}
                            readOnly={id || readOnly}
                          />
                        </div>
                      </div>
                    </fieldset>
                    {styleId &&
                      <ProductionDeliveryDetails id={id} readOnly={readOnly} setStockDetailsFillGrid={setStockDetailsFillGrid}
                        productionDeliveryDetails={productionDeliveryDetails} setProductionDeliveryDetails={setProductionDeliveryDetails}
                        styleId={styleId} storeId={storeId} fromProcessId={processIdFrom} />
                    }
                    <Consolidation vehicleNo={vehicleNo} setVehicleNo={setVehicleNo}
                      specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions}
                      remarks={remarks} setRemarks={setRemarks} totalQty={getTotalIssuedQty()} readOnly={readOnly} />
                    <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                      <FormReport
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setId={setId}
                        tableHeaders={tableHeaders}
                        tableDataNames={tableDataNames}
                        data={allData?.data ? allData?.data : []}
                        loading={
                          isLocationLoading || isLocationFetching
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}