import React from 'react'
import Address from './Address';
import GridDetails from './GridDetails';
import RainDot from "../../../assets/RainDot.png"
import QRCode from "react-qr-code";
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import { useGetCreditDebitByIdQuery } from '../../../redux/ErpServices/CreditDebitServices';
import TaxDetails from './TaxDetails';
import useTaxDetailsHook from '../../../CustomHooks/TaxHookDetails';
const numberToText = require('number-to-text')
require('number-to-text/converters/en-in');

export default function PrintFormatCreditDebit({ id }) {
  const { data } = useGetCreditDebitByIdQuery({ id }, { skip: !id });
  let details = data?.data;
  const taxTemplateId = details?.taxTemplateId;
  const branchData = details?.Branch ? details?.Branch : {}
  const supplier = details?.Supplier ? details?.Supplier : {};
  let poItems = details ? [details] : [];
  poItems = poItems.map(i => ({ ...i, price: i.amount, taxPercent: i?.tax ? i?.tax : 0, qty: 1 }))
  const discountType = "Percentage";
  const discountValue = 0;
  const taxDetails = useTaxDetailsHook({
    poItems, taxTypeId: taxTemplateId, isSupplierOutside: false,
    discountType, discountValue
  });
  return (
    <div className="h-[550px] w-full flex flex-col justify-between border-x border-gray-500 m-0 p-5" id='poPrint'>
      <div>
        <div className='flex border-t border-x  border-gray-500'>
          <div className='flex items-center justify-center p-3'>
            <img src={RainDot} alt='logo' className="h-10" />
          </div>
          <div className='text-center p-1 text-md flex items-center justify-center font-bold text-green-700 w-full border-t border-gray-500'>
            {details?.type === "Credit" ?
              "CREDIT" : "DEBIT"}  NOTE</div>
        </div>
        <div className='flex justify-between text-sm border-t border-x border-gray-600' >
          <div className='grid grid-cols-2 w-full py-2 items-center'>
            <div className='flex ml-1'><span className="font-bold">Doc No :</span> {details?.docId}</div>
            <div className='flex ml-1'><span className="font-bold">Doc Date :</span> {getDateFromDateTimeToDisplay(details?.createdAt)}</div>
          </div>
          <div className='mr-2 p-1'>
            <QRCode value={details?.docId ? details?.docId : ""} size={60} />
          </div>
        </div>
        <Address branchDetails={branchData} supplierDetails={supplier} />
        <GridDetails poItems={poItems} taxDetails={taxDetails} />
        <div className='flex justify-end border border-gray-500'>
          <table className='border border-gray-500 text-center'>
            <tbody>
              <tr className='border border-gray-500 '><th className='p-2 bg-green-200 text-xs' colSpan={2}>Tax Details</th></tr>
              <TaxDetails items={poItems} taxTemplateId={taxTemplateId} discountType={discountType} discountValue={discountValue} />
              <tr className='border border-gray-500 text-xs '>
                <td className='table-data p-1'>
                  ROUNDOFF
                </td>
                <td className='table-data text-right p-1'>
                  {parseFloat(taxDetails?.roundOffAmount).toFixed(2)}
                </td>
              </tr>
              <tr className='border border-gray-500 text-xs'>
                <td className='table-data p-1 bg-green-200 text-xs'>
                  NET AMOUNT
                </td>
                <td className='table-data p-1 text-xs'>
                  {parseFloat(taxDetails?.netAmount).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className=' w-full border-x border-gray-500 border-b'>
        <div className='w-full '>
          <div className='w-full border border-gray-600 flex items-center'>
            <h1 className='font-bold text-sm'>Amount in words    :
            </h1>
            <span className='text-xs'>
              Rs.{numberToText.convertToText(taxDetails?.netAmount ? taxDetails?.netAmount : 0, { language: "en-in", separator: "" })} Only
            </span>
          </div>
        </div>
        <div className='mt-3'>
          <div className='text-sm text-right px-2 font-bold italic'>
            For {branchData?.branchName}
          </div>
          <div className='grid grid-rows-1 grid-flow-col p-2 font-bold text-xs mt-8 justify-between'>
            <h1>Prepared By</h1>
            <h1>Verified By</h1>
            <h1>Received By</h1>
            <h1>Approved By</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

