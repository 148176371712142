import React from 'react';
import FabricPoItem from './FabricPoItem';
import { toast } from 'react-toastify';
import { sumArray } from '../../../Utils/helper';



const FabricInwardItems = ({ inwardItems, setInwardItems, readOnly, removeItem, purchaseInwardId }) => {
    const handleInputChange = (value, index, field, balanceQty) => {
        const newBlend = structuredClone(inwardItems);
        newBlend[index][field] = value
        if (field === "qty") {
            if (parseFloat(balanceQty) < parseFloat(value)) {
                toast.info("Inward Qty Can not be more than balance Qty", { position: 'top-center' })
                return
            }
        }
        setInwardItems(newBlend);
    };

    function handleInputChangeLotNo(value, index, lotIndex, field) {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]["lotDetails"]) return inwardItems
            newBlend[index]["lotDetails"][lotIndex][field] = value;
            return newBlend
        });
    }
    function addNewLotNo(index) {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]) return inwardItems
            if (newBlend[index]["lotDetails"]) {
                newBlend[index]["lotDetails"] = [
                    ...newBlend[index]["lotDetails"],
                    { lotNo: "", qty: "0.000", noOfRolls: 0 }]
            } else {
                newBlend[index]["lotDetails"] = [{ lotNo: "", qty: "0.000", noOfRolls: 0 }]
            }
            return newBlend
        })
    }
    function removeLotNo(index, lotIndex) {
        setInwardItems(inwardItems => {
            const newBlend = structuredClone(inwardItems);
            if (!newBlend[index]["lotDetails"]) return inwardItems
            newBlend[index]["lotDetails"] = newBlend[index]["lotDetails"].filter((_, index) => index != lotIndex)
            return newBlend
        })
    }

    return (
        <>
            <div className={`relative w-full overflow-y-auto py-1`}>
                <table className="table-data text-xs table-auto border border-gray-500  w-full">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8'>
                            <th className='text-center w-8 table-data '>
                                S.no
                            </th>
                            <th className='text-center w-24 table-data'>
                                Po.no
                            </th>
                            <th className='w-24 table-data'>
                                Fabric Name
                            </th>
                            <th className='w-10 table-data'>
                                Color
                            </th>
                            <th className='w-16 table-data'>
                                Design
                            </th>
                            <th className='w-12 table-data'>
                                Gauge
                            </th>
                            <th className='w-12 table-data'>
                                LL
                            </th>
                            <th className='w-12 table-data'>
                                Gsm
                            </th>
                            <th className='w-12 table-data'>
                                K-Dia
                            </th>
                            <th className='w-12 table-data'>
                                F-Dia
                            </th>
                            <th className='w-12 table-data'>
                                Uom
                            </th>
                            <th className="table-data w-10">Po. Qty</th>
                            <th className="table-data  w-10"> Can. Qty</th>
                            <th className="table-data  w-10"> A. In Qty</th>
                            <th className="table-data  w-10"> A. Return Qty</th>
                            <th className="table-data  w-10">Bal. Qty</th>
                            <th className="table-data  w-10">Lot Det.<span className="text-red-500">*</span></th>
                            <th className="table-data  w-10">No. Rolls</th>
                            <th className="table-data  w-10">In. Qty</th>
                            <th className="table-data  w-10">Po Price</th>
                            <th className="table-data  w-10">Gross</th>
                            {!readOnly &&
                                <th className='table-data table-data w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) => <FabricPoItem
                            removeLotNo={removeLotNo} addNewLotNo={addNewLotNo} handleInputChangeLotNo={handleInputChangeLotNo}
                            removeItem={removeItem} key={item.poItemsId}
                            item={item} index={index} handleInputChange={handleInputChange}
                             purchaseInwardId={purchaseInwardId} readOnly={readOnly} />)}
                        {Array.from({ length: 8 - inwardItems.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                {Array.from({ length: 21 }).map(i =>
                                    <td className="table-data   "></td>
                                )}
                                {!readOnly &&
                                    <td className="table-data w-10"></td>
                                }
                            </tr>)
                        }
                    </tbody>
                </table>
            </div >
        </>
    )
}

export default FabricInwardItems