import React, { useCallback } from 'react';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, substract } from '../../../Utils/helper';
import { toast } from 'react-toastify';
import { DELETE, PLUS } from '../../../icons';
import { useGetProcessQuery } from '../../../redux/ErpServices/processMasterServices';
import { useState } from 'react';
import Modal from '../../../UiComponents/Modal';
import { useGetFabricMasterQuery } from '../../../redux/ErpServices/FabricMasterServices';
import { useGetGaugeQuery } from '../../../redux/ErpServices/GaugeMasterServices';
import { useGetdesignQuery } from '../../../redux/ErpServices/DesignMasterServices';
import { useGetgsmQuery } from '../../../redux/ErpServices/GsmMasterServices';
import { useGetDiaQuery } from '../../../redux/ErpServices/DiaMasterServices';
import { useGetLoopLengthQuery } from '../../../redux/ErpServices/LoopLengthMasterServices';
import FabricFillGrid from './FabricFillGrid';

const Fabric = ({ rawMaterials, setRawMaterials, readOnly, getIssuedQty, removeItem, programDetails, setProgramDetails, rawMaterialsFillGrid }) => {

    const [fillGrid, setFillGrid] = useState(rawMaterials.length === 0)

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const handleInputChange = (value, index, field) => {
        const newBlend = structuredClone(rawMaterials);
        newBlend[index][field] = value;
        setRawMaterials(newBlend);
    };

    const getProgramDetailsData = useCallback((processDeliveryProgramDetailsId, property) => {
        let program = rawMaterialsFillGrid.find(item => parseInt(item.id) === parseInt(processDeliveryProgramDetailsId));
        if (!program) return ""
        return program[property]
    }, [programDetails])

    const { data: fabricList } =
        useGetFabricMasterQuery({ params });

    const { data: colorList, isLoading: isColorLoading, isFetching: isColorFetching } =
        useGetColorMasterQuery({ params });

    const { data: uomList } =
        useGetUnitOfMeasurementMasterQuery({ params });

    const { data: gaugeList } =
        useGetGaugeQuery({ params });

    const { data: designList } =
        useGetdesignQuery({ params });

    const { data: gsmList } =
        useGetgsmQuery({ params });

    const { data: loopLengthList } =
        useGetLoopLengthQuery({ params });

    const { data: diaList } =
        useGetDiaQuery({ params });

    const deleteItem = (id) => {
        setRawMaterials(prev => {
            return prev.filter(item => parseInt(item.rawMaterialsId) !== parseInt(id))
        })
    }

    const { data: processList, isLoading: isProcessLoading, isFetching: isProcessFetching } = useGetProcessQuery({ params });

    function getTotals(field) {
        const total = rawMaterials.reduce((accumulator, current) => {
            return accumulator + parseFloat(current[field] ? current[field] : 0)
        }, 0)
        return parseFloat(total)
    }


    if (!fabricList || !colorList || !uomList || !gaugeList || !designList || !gsmList || !loopLengthList || !diaList || !processList) return <Loader />

    return (
        <>
            <Modal isOpen={fillGrid}  >
                <FabricFillGrid
                    onDone={() => { setFillGrid(false) }}
                    rawMaterials={rawMaterials} setRawMaterials={setRawMaterials} rawMaterialsFillGrid={rawMaterialsFillGrid}
                />
            </Modal>
            <div className={`relative w-full overflow-y-auto`}>
                <table className=" text-xs table-fixed  w-full">
                    <thead className='bg-blue-200 top-0'>
                        <tr className=''>
                            <th className=' table-data  w-7'>
                                S.no
                            </th>
                            <th className="table-data  w-32">Fabric<span className="text-red-500">*</span></th>
                            <th className="table-data  w-32">Color<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Design<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">Gauge<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">LL<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">GSM<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">K Dia<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">F Dia<span className="text-red-500">*</span></th>
                            <th className="table-data  w-16">UOM<span className="text-red-500">*</span></th>
                            <th className='table-data  w-16'>
                                Lot No.
                            </th>
                            <th className="table-data  w-20">
                                Prev. Process
                            </th>
                            <th className="table-data  w-20">
                                Delivery qty
                            </th>
                            <th className="table-data  w-20">
                                A. Used qty
                            </th>
                            <th className="table-data  w-20">
                                A. Rtn. qty
                            </th>
                            <th className="table-data  w-20">
                                Bal. qty
                            </th>
                            <th className="table-data  w-20">
                                Cons. Qty
                            </th>
                            <th className="table-data  w-20">
                                Loss Qty
                            </th>
                            <th className="table-data  w-5 text-green-600" onClick={() => { setFillGrid(true) }}> {PLUS} </th>
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {rawMaterials.map((item, index) => (
                            <tr key={index} className="w-full table-row">
                                <td className="table-data  w-2  py-1 text-center">
                                    {index + 1}
                                </td>
                                <td className='table-data '>
                                    {findFromList(getProgramDetailsData(item.rawMaterialsId, "fabricId"), fabricList.data, "aliasName")}
                                </td>
                                <td className=' table-data '>
                                    {findFromList(getProgramDetailsData(item.rawMaterialsId, "colorId"), colorList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(getProgramDetailsData(item.rawMaterialsId, "designId"), designList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(getProgramDetailsData(item.rawMaterialsId, "gaugeId"), gaugeList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(getProgramDetailsData(item.rawMaterialsId, "loopLengthId"), loopLengthList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(getProgramDetailsData(item.rawMaterialsId, "gsmId"), gsmList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(getProgramDetailsData(item.rawMaterialsId, "kDiaId"), diaList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {findFromList(getProgramDetailsData(item.rawMaterialsId, "fDiaId"), diaList.data, "name")}
                                </td>
                                <td className=' table-data '>
                                    {findFromList(getProgramDetailsData(item.rawMaterialsId, "uomId"), uomList.data, "name")}
                                </td>
                                <td className='table-data'>
                                    {getProgramDetailsData(item.rawMaterialsId, "lotNo")}
                                </td>
                                <td className=' px-1 table-data  '>
                                    {findFromList(item.processId, processList.data, "name")}
                                </td>
                                <td className='table-data text-right'>
                                    {getProgramDetailsData(item.rawMaterialsId, "qty").toFixed(3)}
                                </td>
                                <td className='table-data text-right'>
                                    {getProgramDetailsData(item.rawMaterialsId, "alreadyUsedQty")}
                                </td>
                                <td className='table-data text-right'>
                                    {getProgramDetailsData(item.rawMaterialsId, "alreadyDeliveryReturnQty")}
                                </td>
                                <td className="table-data text-right">
                                    {substract(getProgramDetailsData(item.rawMaterialsId, "qty"),
                                        getProgramDetailsData(item.rawMaterialsId, "alreadyUsedQty") + getProgramDetailsData(item.rawMaterialsId, "alreadyDeliveryReturnQty"))?.toFixed(3)}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        min={"0"}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-full px-1 table-data-input"
                                        value={((!item.consumptionQty) ? "0.000" : item.consumptionQty)}
                                        disabled={readOnly}
                                        autoFocus={index === 0}
                                        inputMode='decimal'
                                        onChange={(e) => {
                                            let con = parseFloat(e.target.value);
                                            let loss = parseFloat(item?.lossQty ? item.lossQty : 0);
                                            let bal = substract(getProgramDetailsData(item.rawMaterialsId, "qty"),
                                                getProgramDetailsData(item.rawMaterialsId, "alreadyUsedQty") + getProgramDetailsData(item.rawMaterialsId, "alreadyDeliveryReturnQty"))
                                            if (parseFloat(con + loss) > parseFloat(bal)) {
                                                toast.info("Can not be More than Bal Qty", { position: "top-center" });
                                                return
                                            }
                                            handleInputChange(e.target.value.replace(/^0+/, ''), index, "consumptionQty")
                                        }
                                        }
                                        onBlur={(e) =>
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "consumptionQty")
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <input
                                        type="text"
                                        min={"0"}
                                        onFocus={(e) => e.target.select()}
                                        className="text-right rounded py-1 w-full px-1 table-data-input"
                                        value={((!item.lossQty) ? 0 : item.lossQty)}
                                        disabled={readOnly}
                                        autoFocus={index === 0}
                                        inputMode='decimal'
                                        onChange={(e) => {
                                            let con = parseFloat(item?.consumptionQty ? item.consumptionQty : 0);
                                            let loss = parseFloat(e.target.value);
                                            let bal = substract(getProgramDetailsData(item.rawMaterialsId, "qty"),
                                                getProgramDetailsData(item.rawMaterialsId, "alreadyUsedQty") + getProgramDetailsData(item.rawMaterialsId, "alreadyDeliveryReturnQty"))
                                            if (parseFloat(con + loss) > parseFloat(bal)) {
                                                toast.info("Can not be More than Bal Qty", { position: "top-center" });
                                                return
                                            }
                                            handleInputChange(e.target.value.replace(/^0+/, ''), index, "lossQty")
                                        }
                                        }
                                        onBlur={(e) =>
                                            handleInputChange(parseFloat(e.target.value).toFixed(3), index, "lossQty")
                                        }
                                    />
                                </td>
                                <td className='table-data'>
                                    <button
                                        className=" rounded py-1 w-full"
                                        onClick={() => deleteItem(item.rawMaterialsId)}>
                                        {DELETE}
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {Array.from({ length: 2 - rawMaterials.length }).map(i =>
                            <tr className='w-full font-bold h-8 border-gray-400 border'>
                                <td className='table-data'>
                                </td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                            </tr>)
                        }
                        <tr className='bg-blue-200 w-full border border-gray-400 h-7 font-bold'>
                            <td className='table-data'>
                            </td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   "></td>
                            <td className="table-data   ">Total</td>
                            <td className="table-data    text-right">{getTotals("consumptionQty").toFixed(3)}</td>
                            <td className="table-data    text-right">{getTotals("lossQty").toFixed(3)}</td>
                            <td className="table-data   "></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Fabric