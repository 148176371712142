import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";

import {
  useGetProductionReceiptQuery,
  useGetProductionReceiptByIdQuery,
  useAddProductionReceiptMutation,
  useUpdateProductionReceiptMutation,
  useDeleteProductionReceiptMutation,
} from "../../../redux/ErpServices/ProductionReceiptServices";
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { DropdownInput, TextInput, DateInput, DisabledInput } from "../../../Inputs";
import ReportTemplate from "../../../Basic/components/ReportTemplate";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { useDispatch } from "react-redux";
import { findFromList, getCommonParams, getDateFromDateTime, isGridDatasValid } from "../../../Utils/helper";
import moment from "moment";
import { Loader } from "../../../Basic/components";

import {
  useGetLocationMasterQuery,
} from "../../../redux/ErpServices/LocationMasterServices";
import {
  useGetBranchQuery,
} from '../../../redux/services/BranchMasterService';
import { useGetStyleMasterQuery } from "../../../redux/ErpServices/StyleMasterServices";
import Modal from "../../../UiComponents/Modal";
import ProductionReceiptDetails from "./ProductionReceiptDetails";
import ProductionReceiptFormReport from "./ProductionReceiptFormReport";
import {
  useGetProductionDeliveryByIdQuery,
} from "../../../redux/ErpServices/ProductionDeliveryServices";
import ProductionDeliveryDetailsFillGrid from "./ProductionDeliveryDetailsFillGrid";
import Consolidation from "../Consolidation";
import ProductionDeliveryFormReport from "../ProductionDelivery/ProductionDeliveryFormReport";
import { useGetProcessQuery } from "../../../redux/ErpServices/processMasterServices";
import { groupByMultipleKeys } from "../../../Utils/groupbyMultipleKeys";

const MODEL = "Production Receipt";

export default function Form() {

  const handlePrint = () => { }


  const dispatch = useDispatch()

  const today = new Date()
  const [id, setId] = useState("");
  const [form, setForm] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [productionDeliveryId, setProductionDeliveryId] = useState("");
  const [docId, setDocId] = useState("");
  const [deliveryToId, setDeliveryToId] = useState("")

  const [productionReceiptDetails, setProductionReceiptDetails] = useState([]);

  const [productionDeliveryDetailsFillData, setProductionDeliveryDetailsFillData] = useState([])

  const [storeId, setStoreId] = useState("")

  const [supplierId, setSupplierId] = useState("");

  const [prevProcessId, setPrevProcessId] = useState("");

  const [delDate, setDelDate] = useState(getDateFromDateTime(today))

  const [remarks, setRemarks] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [vehicleNo, setVehicleNo] = useState("");

  const [styleId, setStyleId] = useState("");

  const [formReport, setFormReport] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [productionDeliveryFillGrid, setProductionDeliveryFillGrid] = useState(false);
  const childRecord = useRef(0);
  const [dcNo, setDcNo] = useState("")
  const [productionDeliveryDetailsFillGrid, setProductionDeliveryDetailsFillGrid] = useState(false);

  const { branchId, companyId, finYearId, userId } = getCommonParams()

  const params = {
    companyId
  };
  const { data: locationData, isLoading: isLocationLoading, isFetching: isLocationFetching } = useGetLocationMasterQuery({ params: { branchId }, searchParams: searchValue });
  const { data: branchList } = useGetBranchQuery({ params: { companyId } });
  const { data: styleList, isLoading: isStyleLoading, isFetching: isStyleFetching } = useGetStyleMasterQuery({ params, searchParams: searchValue });

  const { data: allData, isLoading, isFetching } = useGetProductionReceiptQuery({ params: { branchId, finYearId }, searchParams: searchValue });
  const {
    data: singleData,
    isFetching: isSingleFetching,
    isLoading: isSingleLoading,
  } = useGetProductionReceiptByIdQuery({ id }, { skip: !id });

  const {
    data: productionDeliveryDetailsFillDataFromApi,
    isFetching: productionDeliveryDetailsFillDataFromApiFetching,
    isLoading: productionDeliveryDetailsFillDataFromApiLoading,
  } = useGetProductionDeliveryByIdQuery({ id: productionDeliveryId, productionReceiptId: id }, { skip: !productionDeliveryId });

  const { data: processList } = useGetProcessQuery({ params: { ...params, isPcsStage: true } });

  const isPacking = useCallback(() => {
    return findFromList(prevProcessId, (processList?.data ? processList?.data : []), "isPacking");
  }, [prevProcessId, processList])

  useEffect(() => {
    if (!productionDeliveryDetailsFillDataFromApi?.data) return
    if (isPacking()) {
      const groupedItems = groupByMultipleKeys(productionDeliveryDetailsFillDataFromApi.data.ProductionDeliveryDetails, ["colorId", "sizeId", "prevProcessId", "uomId"]);
      setProductionDeliveryDetailsFillData(groupedItems.map(i => i[0]))
    } else {
      setProductionDeliveryDetailsFillData(productionDeliveryDetailsFillDataFromApi.data.ProductionDeliveryDetails)
    }
  }, [productionDeliveryDetailsFillDataFromApi, productionDeliveryDetailsFillDataFromApiFetching, productionDeliveryDetailsFillDataFromApiLoading, isPacking])


  const [addData] = useAddProductionReceiptMutation();
  const [updateData] = useUpdateProductionReceiptMutation();
  const [removeData] = useDeleteProductionReceiptMutation();

  useEffect(() => {
    if (!productionDeliveryId) return
    if (productionReceiptDetails.length === 0) {
      setProductionDeliveryDetailsFillGrid(true);
    }
  }, [productionDeliveryId, productionReceiptDetails])

  const getNextDocId = useCallback(() => {
    if (id || isLoading || isFetching) return
    if (allData?.nextDocId) {
      setDocId(allData.nextDocId)
    }
  }, [allData, isLoading, isFetching, id])

  useEffect(getNextDocId, [getNextDocId])

  const syncFormWithDb = useCallback((data) => {
    if (id) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
    if (data?.createdAt) setDelDate(moment.utc(data?.createdAt).format("YYYY-MM-DD"));
    if (data?.docId) {
      setDocId(data.docId);
    }
    setSupplierId(data?.supplierId ? data?.supplierId : "");
    setDeliveryToId(data?.deliveryId ? data?.deliveryId : "");
    setLocationId(data?.storeId ? findFromList(data?.storeId, (locationData?.data ? locationData.data : []), "locationId") : "")
    setStoreId(data?.storeId ? data?.storeId : "");
    setVehicleNo(data?.vehicleNo ? data.vehicleNo : "");
    setProductionDeliveryId(data?.productionDeliveryId ? data?.productionDeliveryId : "")
    setStyleId(data?.styleId ? data?.styleId : "");
    setSpecialInstructions(data?.specialInstructions ? data?.specialInstructions : "")
    setRemarks(data?.remarks ? data?.remarks : "")
    setDcNo(data?.supplierDc ? data.supplierDc : "");
    setProductionReceiptDetails(data?.ProductionReceiptDetails ? data.ProductionReceiptDetails : []);
    setPrevProcessId(data?.prevProcessId ? data.prevProcessId : "");
  }, [id, locationData]);

  useEffect(() => { if (!id) { setDeliveryToId(supplierId) } }, [supplierId, id])

  useEffect(() => {
    if (id) {
      syncFormWithDb(singleData?.data);
    } else {
      syncFormWithDb(undefined);
    }
  }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

  const data = {
    delDate,
    supplierId,
    branchId,
    storeId,
    styleId,
    deliveryId: deliveryToId,
    productionDeliveryId,
    productionReceiptDetails,
    vehicleNo,
    remarks,
    specialInstructions,
    supplierDc: dcNo,
    id, userId,
    prevProcessId,
    finYearId
  }
  const validateData = (data) => {
    let inwardDetails = ["receivedQty"];
    // let lossDetails = ["lossReasonId", "lossQty"]
    return data.productionDeliveryId && data.supplierDc && data.vehicleNo && data.prevProcessId
      && data.productionReceiptDetails.length !== 0
      && isGridDatasValid(data.productionReceiptDetails, false, inwardDetails)
    // && data.productionReceiptDetails.every(item => item?.lossDetails
    //   && isGridDatasValid(item?.lossDetails, false, lossDetails)
    // )

  }

  const handleSubmitCustom = async (callback, data, text) => {
    try {
      let returnData;
      if (text === "Updated") {
        returnData = await callback(data).unwrap();
      } else {
        returnData = await callback(data).unwrap();
      }
      if (returnData.statusCode === 1) {
        toast.error(returnData.message);
      } else {
        toast.success(text + "Successfully");
        setId("")
        syncFormWithDb(undefined)
      }
      dispatch({
        type: `CuttingOrder/invalidateTags`,
        payload: ['CuttingOrder'],
      });
      dispatch({
        type: `stock/invalidateTags`,
        payload: ['Stock'],
      });
    } catch (error) {
      console.log("handle", error);
    }
  };


  const saveData = () => {

    if (!validateData(data)) {
      toast.info("Please fill all required fields...!", { position: "top-center" })
      return
    }
    if (id) {
      handleSubmitCustom(updateData, data, "Updated");
    } else {
      handleSubmitCustom(addData, data, "Added");
    }
  }

  const deleteData = async () => {
    if (id) {
      if (!window.confirm("Are you sure to delete...?")) {
        return;
      }
      try {
        await removeData(id)
        setId("");
        onNew();
        dispatch({
          type: `po/invalidateTags`,
          payload: ['po'],
        });
        dispatch({
          type: `stock/invalidateTags`,
          payload: ['Stock'],
        });
        toast.success("Deleted Successfully");
      } catch (error) {
        toast.error("something went wrong");
      }
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      saveData();
    }
  };

  const onNew = () => {
    setId("");
    setForm(true);
    setSearchValue("");
    setReadOnly(false);
    syncFormWithDb(undefined);
    getNextDocId();
  };

  function onDataClick(id) {
    setId(id);
    onNew();
    setForm(true);
  }
  const tableHeaders = ["Po", "Status"]
  const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']


  if (!form)
    return (
      <ReportTemplate
        heading={MODEL}
        tableHeaders={tableHeaders}
        tableDataNames={tableDataNames}
        loading={
          isLocationLoading || isLocationFetching
        }
        setForm={setForm}
        data={allData?.data}
        onClick={onDataClick}
        onNew={onNew}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    );

  if (isLocationFetching || isLocationLoading || isStyleLoading || isStyleFetching || productionDeliveryDetailsFillDataFromApiFetching || productionDeliveryDetailsFillDataFromApiLoading) return <Loader />
  const storeOptions = locationData ?
    locationData.data.filter(item => parseInt(item.locationId) === parseInt(locationId)) :
    [];

  const getTotalIssuedQty = () => {
    const totalQty = productionReceiptDetails.reduce((accumulation, currentValue) => {
      return (parseFloat(accumulation) + parseFloat(currentValue?.receivedQty ? currentValue?.receivedQty : 0))
    }, 0)
    return totalQty
  }

  return (
    <>
      <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
        <ProductionReceiptFormReport
          heading={MODEL}
          tableDataNames={tableDataNames}
          loading={
            isLoading || isFetching
          }
          allData={allData}
          tableWidth="100%"
          setForm={setForm}
          data={allData?.data}
          onClick={(id) => {
            setId(id);
            setFormReport(false);
          }
          }
          onNew={onNew}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Modal>
      <Modal isOpen={productionDeliveryFillGrid} onClose={() => { setProductionDeliveryFillGrid(false) }} widthClass={"bg-gray-300 w-[90%] h-[70%]"}>
        <ProductionDeliveryFormReport onClick={(id) => { setProductionDeliveryId(id); setProductionDeliveryFillGrid(false) }} styleId={styleId} prevProcessId={prevProcessId} />
      </Modal>
      <Modal isOpen={productionDeliveryDetailsFillGrid} onClose={() => { setProductionDeliveryDetailsFillGrid(false) }} widthClass={"bg-gray-300"}>
        <ProductionDeliveryDetailsFillGrid
          isPacking={isPacking()}
          productionReceiptDetails={productionReceiptDetails}
          setProductionReceiptDetails={setProductionReceiptDetails}
          productionDeliveryDetailsFillData={productionDeliveryDetailsFillData}
          setProductionDeliveryDetailsFillData={setProductionDeliveryDetailsFillData}
          styleId={styleId} onDone={() => { setProductionDeliveryDetailsFillGrid(false) }} />
      </Modal>
      <div
        onKeyDown={handleKeyDown}
        className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto"
      >
        <div className="flex flex-col frame w-full h-full">
          <FormHeader
            onNew={onNew}
            model={MODEL}
            saveData={saveData}
            setReadOnly={setReadOnly}
            deleteData={deleteData}
            onPrint={id ? handlePrint : null}
            openReport={() => setFormReport(true)}
            childRecord={childRecord.current}
          />
          <div className="flex-1 grid gap-x-2">
            <div className="col-span-3 grid overflow-auto">
              <div className='col-span-3 grid overflow-auto'>
                <div className='mr-1'>
                  <div className={`grid ${formReport ? "grid-cols-12" : "grid-cols-1"} h-full relative overflow-auto`}>
                    <div className={`${formReport ? "col-span-9" : "col-span-1"}`}>
                      <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border border-gray-600 p-1 overflow-auto'>
                        <legend className='sub-heading'>Fabric Delivery To Cutting</legend>
                        <div className='flex flex-col justify-center items-start flex-1 w-full'>
                          <div className="grid grid-cols-5">
                            <DisabledInput name={"Receipt No"} value={docId} />
                            <DateInput name="Receipt Date" value={delDate} type={"date"} required={true} disabled />
                            <DropdownInput name="Location"
                              options={branchList ? (dropDownListObject(id ? branchList.data : branchList.data.filter(item => item.active), "branchName", "id")) : []}
                              value={locationId}
                              setValue={(value) => { setLocationId(value); setStoreId("") }}
                              required={true} readOnly={id || readOnly} />
                            <DropdownInput name="Store"
                              options={dropDownListObject(id ? storeOptions : storeOptions.filter(item => item.active), "storeName", "id")}
                              value={storeId} setValue={setStoreId} required={true} readOnly={id || readOnly} />
                            {/* <DropdownInput name="Supplier" options={supplierList ? (dropDownListObject(id ? supplierList.data : supplierList.data.filter(item => item.active), "aliasName", "id")) : []} value={supplierId} setValue={setSupplierId} required={true} readOnly={id || readOnly} />
                            <DropdownInput name="Delivery To" options={supplierList ? (dropDownListObject(id ? supplierList.data : supplierList.data.filter(item => item.active), "aliasName", "id")) : []} value={deliveryToId} setValue={setDeliveryToId} required={true} readOnly={id || readOnly} /> */}
                            <DropdownInput
                              name="Process"
                              options={dropDownListObject(id ? (processList ? processList.data : []) : (processList ? processList.data.filter(item => item.active) : []), "name", "id")}
                              value={prevProcessId}
                              setValue={setPrevProcessId}
                              required={true}
                              readOnly={readOnly}
                            />
                            <DropdownInput
                              name="Style"
                              options={dropDownListObject(id ? (styleList ? styleList.data : []) : (styleList ? styleList.data.filter(item => item.active) : []), "sku", "id")}
                              value={styleId}
                              setValue={setStyleId}
                              required={true}
                              readOnly={id || readOnly}
                            />
                            <TextInput name="Supplier Dc" type="text" value={dcNo} setValue={setDcNo} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                            <div className="grid grid-cols-12 items-center">
                              <div className="col-span-10">
                                <DisabledInput name={"Production Delivery"} value={productionDeliveryDetailsFillDataFromApi ? productionDeliveryDetailsFillDataFromApi?.data?.docId : ""} />
                              </div>
                              {!readOnly &&
                                <button className="p-0.5 text-xs bg-lime-400 rounded hover:bg-lime-600 font-semibold transition hover:text-white -ml-6"
                                  onClick={() => {
                                    if (!styleId || !prevProcessId) {
                                      toast.info("Please Select Style and Process ", { position: "top-center" })
                                      return
                                    }
                                    setProductionDeliveryFillGrid(true)
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      if (!styleId || !prevProcessId) {
                                        toast.info("Please Select Style and Process ", { position: "top-center" })
                                        return
                                      }
                                      setProductionDeliveryFillGrid(true)
                                    }
                                  }}
                                >Select</button>
                              }
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      {
                        productionReceiptDetails &&
                        <ProductionReceiptDetails
                          id={id}
                          isPacking={isPacking()}
                          productionDeliveryId={productionDeliveryId}
                          productionReceiptDetails={productionReceiptDetails}
                          setProductionReceiptDetails={setProductionReceiptDetails}
                          productionDeliveryDetailsFillData={productionDeliveryDetailsFillData}
                          setFillGrid={setProductionDeliveryDetailsFillGrid}
                          readOnly={readOnly} />
                      }
                    </div>
                    <Consolidation totalQty={getTotalIssuedQty()} vehicleNo={vehicleNo}
                      setVehicleNo={setVehicleNo} remarks={remarks} setRemarks={setRemarks} readOnly={readOnly}
                      specialInstructions={specialInstructions} setSpecialInstructions={setSpecialInstructions} />
                    <div className={`frame h-[500px] overflow-x-hidden ${formReport ? "block" : "hidden"} col-span-3`}>
                      <FormReport
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setId={setId}
                        tableHeaders={tableHeaders}
                        tableDataNames={tableDataNames}
                        data={allData?.data ? allData?.data : []}
                        loading={
                          isLocationLoading || isLocationFetching
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}