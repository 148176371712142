import { DELETE } from '../../../icons';
import { useGetAccessoryMasterQuery } from '../../../redux/ErpServices/AccessoryMasterServices';
import { useGetColorMasterQuery } from '../../../redux/ErpServices/ColorMasterServices';
import { useGetSizeMasterQuery } from '../../../redux/ErpServices/SizeMasterServices';
import { useGetUnitOfMeasurementMasterQuery } from '../../../redux/ErpServices/UnitOfMeasurementServices';
import { Loader } from '../../../Basic/components';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, isBetweenRange, substract } from '../../../Utils/helper';
import { toast } from 'react-toastify';
import AccessoryPoItem from './AccessoryPoItem';


const AccessoryInwardItems = ({ inwardItems, setInwardItems,  readOnly, removeItem ,purchaseInwardId}) => {
    const handleInputChange = (value, index, field) => {
        console.log("value", value, index, field)
        const newBlend = structuredClone(inwardItems);
        newBlend[index][field] = value;
        setInwardItems(newBlend);
    };
    
    return (
        <>
            <div className={`relative w-full overflow-auto py-1`}>
                <table className="border border-gray-500 text-xs table-auto w-full  ">
                    <thead className='bg-blue-200 border border-gray-500 top-0'>
                        <tr className='h-8 '>
                            <th className="table-data   text-center">S.no</th>
                            <th className="table-data    text-center">Po.no</th>
                            <th className="table-data  ">Accessory Name</th>
                            <th className="table-data  ">Accessory Items</th>
                            <th className="table-data  ">Accessory Group</th>
                            <th className="table-data  ">Colors</th>
                            <th className="table-data  ">Size</th>
                            <th className="table-data   ">UOM</th>
                            <th className="table-data   ">Stock Qty</th>
                            <th className="table-data   ">A. Inwarded Qty</th>
                            <th className="table-data">
                                A. Return Qty
                            </th>
                            <th className="table-data   ">Allowed Return Qty</th>
                            <th className="table-data   ">Return Qty</th>
                            <th className="table-data   ">Price</th>
                            <th className="table-data   ">Gross</th>
                            {!readOnly &&
                                <th className='table-data border  w-12'>Delete</th>
                            }
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto  h-full w-full'>
                        {inwardItems.map((item, index) => <AccessoryPoItem stockId={item.stockId} purchaseInwardId={purchaseInwardId} removeItem={removeItem} readOnly={readOnly} key={item.poItemsId} qty={item.qty} poItemId={item.poItemsId} index={index} handleInputChange={handleInputChange} />)}
                        {Array.from({ length: 7 - inwardItems.length }).map(i =>
                            <tr className='w-full font-bold h-8 border border-gray-400 table-row'>
                                <td className='table-data'>
                                </td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data    "></td>
                                <td className="table-data   "></td>
                                <td className="table-data   "></td>
                                {!readOnly &&
                                    <td className="table-data w-10"></td>
                                }
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default AccessoryInwardItems
