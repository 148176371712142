import React, { useCallback, useEffect, useMemo } from 'react';
import { DELETE, PLUS } from '../../../icons';
import { toast } from 'react-toastify';
import { getCommonParams, substract } from '../../../Utils/helper';
import { useGetOnlineSalesDeliveryQuery } from '../../../redux/ErpServices/onlineSalesDeliveryServices';

const OnlineSalesDeliveryDetails = ({ id, readOnly, productionDeliveryDetails, setProductionDeliveryDetails,
  setStockDetailsFillGrid, storeId }) => {
  const { branchId } = getCommonParams()
  const handleInputChange = (value, index, field) => {
    const newBlend = structuredClone(productionDeliveryDetails);
    newBlend[index][field] = value;
    setProductionDeliveryDetails(newBlend);
  };
  const { data } = useGetOnlineSalesDeliveryQuery({
    params: {
      itemWise: true,
      branchId,
      onlineSalesReturnId: id
    }
  })

  let stockData = useMemo(() => data?.data ? data.data : [], [data])

  const getStockItem = useCallback((checkItem) => {
    let item = stockData.find(item =>
      parseInt(checkItem.onlineSalesDetailsId) === parseInt(item.id)
    )
    if (!item) return ""
    return item
  }, [stockData])

  useEffect(() => {
    setProductionDeliveryDetails(prev => {
      if (prev.length === 0) return prev
      return prev.map(item => {
        let newItem = structuredClone(item)
        const stockQty = getStockItem(item)?.qty
        if (parseFloat(stockQty) < parseFloat(item.qty)) {
          newItem["qty"] = 0;
        }
        return newItem
      })
    })
  }, [setProductionDeliveryDetails, getStockItem])

  const handleDeleteRow = id => {
    setProductionDeliveryDetails(yarnBlend => yarnBlend.filter((row, index) => index !== parseInt(id)));
  };
  return (
    <fieldset disabled={readOnly} className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg w-full border
                            border-gray-600 overflow-auto min-h-[100px]'>
      <legend className='sub-heading'>Sales Details</legend>
      <div className={`relative w-full overflow-y-auto p-1`}>
        <table className="table-data border border-gray-500 text-xs table-auto w-full">
          <thead className='bg-blue-200 border border-gray-500 top-0'>
            <tr className='border border-gray-500'>
              <th className="w-20 border border-gray-500">S.no</th>
              <th className="border border-gray-500">Delivery No.</th>
              <th className="border border-gray-500">Style</th>
              <th className="border border-gray-500">Color</th>
              <th className="border border-gray-500">Size</th>
              <th className="border border-gray-500">Sales Qty</th>
              <th className="border border-gray-500">A. Return Qty</th>
              <th className="border border-gray-500">Bal. Qty</th>
              <th className="table-data w-20">Qty</th>
              {readOnly ?
                "" :
                <th className='w-20  bg-green-600 text-white'>
                  <div onClick={() => {
                    if (!storeId) {
                      return toast.info("Please Select Store ...!")
                    }
                    setStockDetailsFillGrid(true)
                  }}
                    className='hover:cursor-pointer py-2 flex items-center justify-center bg-green-600 text-white'>
                    {PLUS}
                  </div>
                </th>
              }
            </tr>
          </thead>
          <tbody className='overflow-y-auto table-data h-full w-full'>
            {productionDeliveryDetails.map((row, index) => (
              <tr key={index} className="w-full table-row">
                <td className='table-data'>{index + 1}</td>
                <td className='table-data '>
                  {getStockItem(row)?.OnlineSales?.docId}
                </td>
                <td className='table-data '>
                  {getStockItem(row)?.Style?.sku}
                </td>
                <td className='table-data '>
                  {getStockItem(row)?.Color?.name}
                </td>
                <td className='table-data'>
                  {getStockItem(row)?.Size?.name}
                </td>
                <td className='table-data text-right'>
                  {getStockItem(row)?.qty}
                </td>
                <td className='table-data text-right'>
                  {getStockItem(row)?.aReturnQty}
                </td>
                <td className='table-data text-right'>
                  {substract(getStockItem(row)?.qty, getStockItem(row)?.aReturnQty)}
                </td>
                <td className='table-data'>
                  <input type="number"
                    onKeyDown={e => { if (e.key === "Delete") { handleInputChange("0.00", index, "qty") } }}
                    value={(!row.qty) ? 0 : row.qty}
                    min={0}
                    autoFocus={index === 0}
                    onChange={(e) => {
                      if (parseFloat(e.target.value) > parseFloat(substract(getStockItem(row)?.qty, getStockItem(row)?.aReturnQty))) {
                        toast.info("Return Qty Cannot be more than Sales Qty", { position: "top-center" })
                        return
                      }
                      handleInputChange(e.target.value, index, "qty")
                    }}
                    className="text-right rounded py-1 w-full px-1 table-data-input"
                    inputMode='decimal'
                    onBlur={(e) =>
                      handleInputChange(parseFloat(e.target.value).toFixed(2), index, "qty")
                    } />
                </td>
                {readOnly
                  ?
                  ""
                  :
                  <td className='table-data w-20'>
                    <div tabIndex={-1} onClick={() => handleDeleteRow(index)} className='flex justify-center px-2 py-1.5 items-center cursor-pointer'>
                      {DELETE}
                    </div>
                  </td>
                }
              </tr>
            ))}
            {Array.from({ length: 8 - productionDeliveryDetails.length }).map(i =>
              <tr className='w-full font-bold h-6 border-gray-400 border'>
                <td className='table-data'>
                </td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                <td className="table-data   "></td>
                {!readOnly
                  &&
                  <td className="table-data"></td>
                }
              </tr>)
            }
          </tbody>
        </table>
      </div>
    </fieldset>
  )
}

export default OnlineSalesDeliveryDetails